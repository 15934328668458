import { Box, Paper, Skeleton, Chip, Typography, Stack, styled, Button, CircularProgress } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { useI18n } from 'react-simple-i18n';
import keeperEgg from 'assets/img/nfts/keeperEgg.png';
import { NFT, BaseNFT, Order } from 'utils/fetch/graph';
import { BASE_IMAGE_URL } from 'constants/index';
import KeeperMintModal from 'components/KeeperMintModal';
import { ReactComponent as UsdtIcon } from 'assets/img/marketPlace/usdtIcon.svg';
import { useNavigate } from 'react-router-dom';
import { useActiveWeb3React } from 'hooks';
import { useValidAddress } from 'hooks/useUser';
import { showRegisterModal } from 'store/features/componentSlice';
import { useAppDispatch } from 'hooks/redux';
import { showWalletModal } from 'store/features/componentSlice';
import useIntervalFrame from 'hooks/useIntervalFrame';
import { useActivityMint } from 'hooks/useNFT';
import moment from 'moment';
import { useIsNodeNft, useLevel } from 'hooks/useNFT';
import low from 'assets/img/invitation/low.png';
import middle from 'assets/img/invitation/middle.png';
import high from 'assets/img/invitation/high.png';
import _ from 'lodash';

const PaperBox = styled(Paper)<{ isActivitingMint?: boolean }>(({ theme, isActivitingMint }) => ({
  width: theme.spacing(21),
  height: theme.spacing(36.5),
  background: isActivitingMint ? '#78DBE0' : '#15253B',
  borderRadius: 18,
  padding: theme.spacing(1.5, 2.5, 0),
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: theme.spacing(2),
  cursor: 'pointer',
  '& .bottomStack': {
    marginRight: theme.spacing(2),
  },
  '& .btnStack': {
    width: theme.spacing(22.5),
  },
  '& .nftSkeleton': {
    background: '#01060d',
    width: theme.spacing(21),
    height: theme.spacing(36.5),
  },
  '& .nftNameSkeleton': {
    background: '#01060d',
    width: theme.spacing(12),
    height: theme.spacing(3),
  },
  '& .btnSkeleton': {
    width: theme.spacing(10),
    height: theme.spacing(4),
    background: '#01060d',
    borderRadius: 9,
  },
  '& .nftImage': {
    transition: 'all 0.6s',
    transform: 'scale(1)',
    height: '100%',
    width: '100%',
  },
  '&:hover': {
    '& .nftImage': {
      transition: 'all 0.6s',
      transform: 'scale(1.4)',
    },
  },
  [theme.breakpoints.down('sm')]: {
    width: `calc(50% - ${theme.spacing(2.5)})`,
    height: theme.spacing(29),
    padding: theme.spacing(2, 1, 0),
    gap: theme.spacing(1.5),
    '& .bottomStack': {
      marginRight: theme.spacing(1),
    },
    '& .btnStack': {
      width: theme.spacing(17),
    },
    '& .nftSkeleton': {
      width: `calc(100%)`,
      height: theme.spacing(29),
    },
    '& .btnSkeleton': {
      height: theme.spacing(3),
      width: theme.spacing(7.5),
    },
  },
}));

const MintPaperBox = styled(Box)(({ theme }) => ({
  width: theme.spacing(19),
  height: theme.spacing(27),
  background: '#15253B',
  borderRadius: 10,
  padding: theme.spacing(1),
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: theme.spacing(0.5),
  cursor: 'pointer',
  margin: 0,
  '&.active': {
    background: '#6BB9DD',
  },
  '& .nftMintImageSkeleton': {
    background: '#01060d',
    width: theme.spacing(19),
    height: theme.spacing(30),
  },
  '& .nftMintTextSkeleton': {
    background: '#01060d',
    width: theme.spacing(16),
    height: theme.spacing(4),
  },
  '& .nftMintTypeSkeleton': {
    background: '#01060d',
    width: theme.spacing(12),
    height: theme.spacing(4),
  },
  '& .nftMintPriceSkeleton': {
    background: '#01060d',
    width: theme.spacing(8),
    height: theme.spacing(4),
  },
  '& .nftImage': {
    transition: 'all 0.6s',
    transform: 'scale(1)',
    height: '100%',
    width: '100%',
  },
  '&:hover': {
    '& .nftImage': {
      transition: 'all 0.6s',
      transform: 'scale(1.4)',
    },
  },
  [theme.breakpoints.down('sm')]: {
    borderRadius: 0,
    width: 'auto',
    height: theme.spacing(29),
    padding: theme.spacing(1),
    gap: theme.spacing(0.5),
  },
}));

const MintImageBox = styled(Box)(({ theme }) => ({
  width: theme.spacing(18),
  height: theme.spacing(18),
  background: '#e9e9e940',
  borderRadius: 5,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  overflow: 'hidden',
  // [theme.breakpoints.down('sm')]: {
  //   width: theme.spacing(19),
  //   height: theme.spacing(19),
  // },
}));

const ImageBox = styled(Box)(({ theme }) => ({
  width: theme.spacing(22.5),
  height: theme.spacing(22.5),
  background: '#e9e9e940',
  borderRadius: 5,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  overflow: 'hidden',
  position: 'relative',
  [theme.breakpoints.down('sm')]: {
    width: theme.spacing(17),
    height: theme.spacing(17),
  },
}));

const FreeMintTime = styled(Stack)(({ theme }) => ({
  width: theme.spacing(22.5),
  height: theme.spacing(3),
  position: 'absolute',
  top: 0,
  right: 0,
  [theme.breakpoints.down('sm')]: {
    width: theme.spacing(17),
  },
}));

const FreeTime = styled(Stack)(({ theme }) => ({
  width: theme.spacing(12),
  height: theme.spacing(3),
  backgroundColor: '#434343',
  borderRadius: '5px',
  [theme.breakpoints.down('sm')]: {
    width: theme.spacing(12),
    zoom: 0.8,
  },
}));

const LevelChip = styled(Chip)(({ theme }) => ({
  position: 'absolute',
  top: 0,
  right: 0,
  width: theme.spacing(7),
  height: theme.spacing(3),
  background: '#43434399',
  color: '#D1D1D1',
  fontSize: 13,
  fontWeight: 700,
  [theme.breakpoints.down('sm')]: {
    width: theme.spacing(6),
    height: theme.spacing(2.5),
    fontSize: 12,
  },
}));

const NodeNftLevel = styled('img')(({ theme }) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  width: theme.spacing(3),
  height: theme.spacing(3),
}));

const KeeperEggImage = styled('img')(({ theme }) => ({
  width: theme.spacing(19.5),
  height: theme.spacing(19.5),
  [theme.breakpoints.down('sm')]: {
    width: theme.spacing(15),
    height: theme.spacing(15),
  },
}));

const NftImage = styled('img')(({ theme }) => ({
  width: '100%',
  height: '100%',
  [theme.breakpoints.down('sm')]: {
    width: theme.spacing(15),
    height: theme.spacing(15),
  },
}));

const MintKeeperButton = styled(Button)(({ theme }) => ({
  width: theme.spacing(19),
  height: theme.spacing(4),
  borderRadius: 7,
  whiteSpace: 'nowrap',
  [theme.breakpoints.down('sm')]: {
    height: theme.spacing(3),
    width: theme.spacing(17),
    fontSize: 12,
  },
}));

const BuyKeeperButton = styled(Button)(({ theme }) => ({
  width: theme.spacing(19),
  height: theme.spacing(4),
  borderRadius: 7,
  whiteSpace: 'nowrap',
  [theme.breakpoints.down('sm')]: {
    height: theme.spacing(3),
    width: theme.spacing(17),
    fontSize: 12,
  },
}));

const BuyAndSellButton = styled(Button)(({ theme }) => ({
  width: theme.spacing(10.5),
  height: theme.spacing(4),
  background: '#43434399',
  color: '#D2D2D2',
  borderRadius: 9,
  // whiteSpace: 'nowrap',
  lineHeight: '10px',
  fontSize: 12,
  '&:hover': {
    background: '#434343',
  },
  [theme.breakpoints.down('sm')]: {
    height: theme.spacing(3),
    width: theme.spacing(9.5),
    fontSize: 12,
    zoom: 0.8,
  },
}));

const CancelButton = styled(Button)(({ theme }) => ({
  width: '100%',
  height: theme.spacing(4),
  background: '#43434399',
  color: '#D2D2D2',
  borderRadius: 9,
  '&:hover': {
    background: '#434343',
  },
  [theme.breakpoints.down('sm')]: {
    height: theme.spacing(3),
    fontSize: 12,
    zoom: 0.8,
  },
}));

const MarketBuyButton = styled(Button)(({ theme }) => ({
  width: theme.spacing(10),
  height: theme.spacing(4),
  borderRadius: 9,
  [theme.breakpoints.down('sm')]: {
    height: theme.spacing(3),
    width: theme.spacing(7.5),
    fontSize: 12,
  },
}));

const NameTypography = styled(Typography)(({ theme }) => ({
  width: theme.spacing(19),
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  [theme.breakpoints.down('sm')]: { fontSize: 14 },
}));

export function DefaultCard() {
  const { t } = useI18n();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { account } = useActiveWeb3React();
  const [openKeeperMintModal, setOpenKeeperMintModal] = useState(false);
  const registered = useValidAddress(account ?? undefined);
  const [isActivitingMint, setIsActivitingMint] = useState(false);
  const { startId, endId, startTime, endTime, maxCount, mintCount, mintPrice } = useActivityMint();
  const {
    timeInfo: { days, hours, minutes, seconds },
  } = useIntervalFrame({
    delay: 1000,
    endTime: endTime * 1000,
    endTimeOut: () => setIsActivitingMint(false),
  });

  useEffect(() => {
    setIsActivitingMint(
      moment(new Date()).valueOf() < endTime * 1000 &&
        startTime * 1000 < moment(new Date()).valueOf() &&
        Number(maxCount?.toString()) > Number(mintCount?.toString())
    );
  }, [endTime, maxCount, mintCount, startTime]);

  return (
    <PaperBox isActivitingMint={isActivitingMint}>
      <ImageBox>
        <KeeperEggImage src={keeperEgg} />
        {isActivitingMint && (
          <FreeMintTime direction="row" justifyContent="flex-end" alignItems="center">
            <Typography
              sx={(theme) => ({
                color: '#4A4A4A',
                fontSize: 12,
                zoom: 0.8,
                [theme.breakpoints.down('sm')]: { zoom: 0.55 },
              })}
            >
              {t('nfts.freeMintEnd')}
            </Typography>
            <FreeTime direction="row" justifyContent="space-around">
              <Stack alignItems="center" width={25}>
                <Typography sx={{ color: '#fff', zoom: 0.8, lineHeight: '15px' }}>{days}</Typography>
                <Typography sx={{ color: '#fff', zoom: 0.6, lineHeight: 'unset' }}>{t('nfts.days')}</Typography>
              </Stack>
              <Stack alignItems="center" width={25}>
                <Typography sx={{ color: '#fff', zoom: 0.8, lineHeight: '15px' }}>{hours}</Typography>
                <Typography sx={{ color: '#fff', zoom: 0.6, lineHeight: 'unset' }}>{t('nfts.hours')}</Typography>
              </Stack>
              <Stack alignItems="center" width={25}>
                <Typography sx={{ color: '#fff', zoom: 0.8, lineHeight: '15px' }}>{minutes}</Typography>
                <Typography sx={{ color: '#fff', zoom: 0.6, lineHeight: 'unset' }}>{t('nfts.minutes')}</Typography>
              </Stack>
              <Stack alignItems="center" width={25}>
                <Typography sx={{ color: '#fff', zoom: 0.8, lineHeight: '15px' }}>{seconds}</Typography>
                <Typography sx={{ color: '#fff', zoom: 0.6, lineHeight: 'unset' }}>{t('nfts.seconds')}</Typography>
              </Stack>
            </FreeTime>
          </FreeMintTime>
        )}
      </ImageBox>
      <Stack spacing={1.5} className="bottomStack" alignItems="center">
        <BuyKeeperButton
          color={isActivitingMint ? 'secondary' : 'primary'}
          onClick={() => navigate('/market-place')}
          variant="contained"
        >
          <Typography>{t('nfts.buyKeeper')}</Typography>
        </BuyKeeperButton>
        <MintKeeperButton
          variant="contained"
          color={isActivitingMint ? 'primary' : 'info'}
          onClick={() => {
            if (!account) {
              dispatch(showWalletModal());
              return;
            }
            if (!registered) {
              dispatch(showRegisterModal());
              return;
            }
            setOpenKeeperMintModal(true);
          }}
        >
          {isActivitingMint ? t('nfts.freeMint') : t('nfts.mintKeeper')}
        </MintKeeperButton>
      </Stack>
      {openKeeperMintModal ? (
        <KeeperMintModal
          startId={startId}
          isActivitingMint={isActivitingMint}
          endId={endId}
          mintPrice={mintPrice}
          open={openKeeperMintModal}
          handleClose={() => setOpenKeeperMintModal(false)}
        />
      ) : null}
    </PaperBox>
  );
}

interface NftMintCardProps {
  active?: boolean;
  item: BaseNFT;
  onSelect?: (nft: BaseNFT) => void;
  startId: number;
  endId: number;
  mintPrice: number;
  isActivityMint: boolean;
  isActivitingMint: boolean;
}

export function NftMintCard(props: NftMintCardProps) {
  const { active, item, onSelect, startId, endId, mintPrice, isActivityMint, isActivitingMint } = props;

  return (
    <MintPaperBox className={active ? 'active' : ''} onClick={() => onSelect && onSelect(item)}>
      <MintImageBox sx={{ background: 'transparent' }}>
        <NftImage className="nftImage" src={`${BASE_IMAGE_URL}${item.id}.png`} />
      </MintImageBox>
      <NameTypography color={'#fff'}>{item.name}</NameTypography>
      <NameTypography color={active ? '#202F44' : '#6BB9DD'}>{item.grade}</NameTypography>
      <NameTypography>
        <Typography
          component="span"
          color={active ? '#202F44' : '#6BB9DD'}
          sx={{
            textDecoration:
              isActivitingMint && Number(item.id) > startId && Number(item.id) < endId && isActivityMint
                ? 'line-through'
                : 'atuo',
          }}
        >
          {`${item.price} U`}
        </Typography>
        {isActivitingMint && Number(item.id) > startId && Number(item.id) < endId && isActivityMint && (
          <Typography
            component="span"
            sx={{
              padding: '0 4px',
              textDecoration: 'auto',
              background: '#78DBE0',
              color: '#fff',
              borderRadius: 1,
              marginLeft: '4px',
            }}
          >
            {`${mintPrice.toString()} U`}
          </Typography>
        )}
      </NameTypography>
    </MintPaperBox>
  );
}

export function NftMintSkeletonCard() {
  return (
    <MintPaperBox>
      <MintImageBox sx={{ background: 'transparent' }}>
        <Skeleton className="nftMintImageSkeleton" />
      </MintImageBox>
      <NameTypography>
        <Skeleton className="nftMintTextSkeleton" />
      </NameTypography>
      <NameTypography>
        <Skeleton className="nftMintTypeSkeleton" />
      </NameTypography>
      <NameTypography>
        <Skeleton className="nftMintPriceSkeleton" />
      </NameTypography>
    </MintPaperBox>
  );
}

interface NftCardProps {
  item: NFT;
  goDetail?: () => void;
  leftButton?: (event: any, isNodeNft: string) => void;
  leftLoading?: boolean;
  leftText?: string;
  rightButton?: (event: any) => void;
  rightLoading?: boolean;
  onCancel?: (event: any) => void;
  cancelText?: string;
  cancelLoading?: boolean;
  rightText?: string;
}

export default function NftCard(props: NftCardProps) {
  const {
    item,
    goDetail,
    leftButton,
    leftText,
    rightButton,
    rightText,
    onCancel,
    cancelText,
    cancelLoading,
    leftLoading,
    rightLoading,
  } = props;
  const { t, i18n } = useI18n();
  const [imageLoading] = useState(false);
  const isNodeNft = useIsNodeNft(item.id || '');
  const level = useLevel(item.id || '');

  return (
    <PaperBox onClick={goDetail}>
      <ImageBox sx={{ background: 'transparent' }}>
        {imageLoading ? (
          <Skeleton className="nftSkeleton" />
        ) : (
          <NftImage className="nftImage" src={`${BASE_IMAGE_URL}${item.id}.png`} />
        )}
        <LevelChip label={`LV ${level ? level : ''}`} size="small" />
        {isNodeNft && _.includes(['1', '2', '3'], isNodeNft?.toString()) && (
          <NodeNftLevel src={isNodeNft?.toString() === '1' ? low : isNodeNft?.toString() === '2' ? middle : high} />
        )}
      </ImageBox>
      <Typography
        sx={(theme) => ({
          fontWeight: 700,
          fontSize: 18,
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          width: theme.spacing(22.5),
          [theme.breakpoints.down('sm')]: { fontSize: 14, width: theme.spacing(17) },
        })}
        color={'#78DBE0'}
      >
        {imageLoading ? <Skeleton className="nftNameSkeleton" /> : item?.name}
      </Typography>
      {item.isList ? (
        <CancelButton onClick={onCancel} disabled={cancelLoading}>
          {cancelLoading && <CircularProgress size={14} sx={{ color: '#00000042', marginRight: 0.3 }} />}
          {cancelText || t('nfts.cancel')}
        </CancelButton>
      ) : (
        <Stack className="btnStack" justifyContent="space-between" direction="row" alignItems="center">
          {imageLoading ? (
            <Skeleton className="btnSkeleton" />
          ) : (
            <BuyAndSellButton
              onClick={(e) => leftButton && leftButton(e, isNodeNft ? isNodeNft?.toString() : '')}
              disabled={leftLoading}
            >
              {leftLoading && <CircularProgress size={14} sx={{ color: '#00000042', marginRight: 0.3 }} />}
              <Typography
                fontSize={12}
                lineHeight={'12px'}
                sx={{
                  zoom: i18n.getLang() === 'deDE' ? 0.8 : 1,
                  wordBreak: i18n.getLang() === 'deDE' ? 'break-all' : 'keep-all',
                }}
              >
                {leftText || t('nfts.sell')}
              </Typography>
            </BuyAndSellButton>
          )}
          {imageLoading ? (
            <Skeleton className="btnSkeleton" />
          ) : (
            <BuyAndSellButton onClick={rightButton} disabled={rightLoading}>
              {rightLoading && <CircularProgress size={14} sx={{ color: '#00000042', marginRight: 0.3 }} />}
              <Typography
                fontSize={12}
                lineHeight={'12px'}
                sx={{
                  zoom: i18n.getLang() === 'deDE' ? 0.8 : 1,
                  wordBreak: i18n.getLang() === 'deDE' ? 'break-all' : 'keep-all',
                }}
              >
                {rightText || t('nfts.stake')}
              </Typography>
            </BuyAndSellButton>
          )}
        </Stack>
      )}
    </PaperBox>
  );
}

interface MarketNftCardProps {
  item: Order;
  goDetail?: () => void;
  rightButton?: (event: any) => void;
  rightText?: string;
  nftPrice: string | number;
}

export function MarketNftCard(props: MarketNftCardProps) {
  const { item, goDetail, rightButton, rightText, nftPrice } = props;
  const { t } = useI18n();

  return (
    <PaperBox onClick={goDetail}>
      <ImageBox sx={{ background: 'transparent' }}>
        <NftImage className="nftImage" src={`${BASE_IMAGE_URL}${item.id}.png`} />
        <LevelChip label={`LV ${item.level}`} size="small" />
      </ImageBox>
      <Typography
        sx={(theme) => ({
          fontWeight: 700,
          fontSize: 18,
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          width: theme.spacing(22.5),
          [theme.breakpoints.down('sm')]: { fontSize: 14, width: theme.spacing(17) },
        })}
        color={'#78DBE0'}
      >
        {item?.name}
      </Typography>
      <Stack className="btnStack" justifyContent="space-between" direction="row" alignItems="center">
        <Typography color={'#fff'} fontWeight={700} sx={{ display: 'flex', gap: '4px', alignItems: 'center' }}>
          {nftPrice}
          <UsdtIcon />
        </Typography>
        <MarketBuyButton variant="contained" onClick={rightButton}>
          {rightText || t('nfts.sell')}
        </MarketBuyButton>
      </Stack>
    </PaperBox>
  );
}

interface BuyNftCardProps {
  tokenId: string;
  name: string;
  level: string;
  price: string | number;
}

export function BuyNftCard(props: BuyNftCardProps) {
  const { tokenId, name, price, level } = props;

  return (
    <PaperBox sx={(theme) => ({ zoom: 0.8, [theme.breakpoints.down('sm')]: { zoom: 0.9 } })}>
      <ImageBox sx={{ background: 'transparent' }}>
        <NftImage className="nftImage" src={`${BASE_IMAGE_URL}${tokenId}.png`} />
        <LevelChip label={`LV ${level}`} size="small" />
      </ImageBox>
      <Typography
        sx={(theme) => ({
          fontWeight: 700,
          fontSize: 18,
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          width: theme.spacing(22.5),
          [theme.breakpoints.down('sm')]: { fontSize: 14, width: theme.spacing(17) },
        })}
        color={'#78DBE0'}
      >
        {name}
      </Typography>
      <Stack className="btnStack" justifyContent="space-between" direction="row" alignItems="center">
        <Typography color={'#fff'} fontWeight={700} sx={{ display: 'flex', gap: '4px', alignItems: 'center' }}>
          {price}
          <UsdtIcon />
        </Typography>
      </Stack>
    </PaperBox>
  );
}
