import React, { useState } from 'react';
import { styled, Select, MenuItem } from '@mui/material';
import AppBar from 'components/AppBar';
import { useI18n } from 'react-simple-i18n';
import RankContent from 'components/RankContent';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Check from '@mui/icons-material/Check';
import { useRankingList } from 'hooks/useRanking';
import { Outlet } from 'react-router-dom';

const Container = styled('div')`
  height: 100%;
  width: 100%;
  overflow: hidden;
`;

const MainContainer = styled('div')(({ theme }) => ({
  display: 'grid',
  height: 'calc(100%)',
  width: `calc(100% - ${theme.spacing(14)})`,
  padding: theme.spacing(0, 7),
  gridTemplateRows: `${theme.spacing(10.5)} 1fr`,
  [theme.breakpoints.down('sm')]: {
    gridTemplateRows: `${theme.spacing(9)} 1fr`,
    padding: theme.spacing(0, 3),
    width: `calc(100% - ${theme.spacing(6)})`,
  },
  gridTemplateColumns: '1fr',
  gridTemplateAreas: `
    'rankT'
    'rankB'
  `,
}));

const Header = styled('div')({
  gridArea: 'rankT',
  position: 'relative',
  // backgroundImage: `url(${headerBg})`,
  // backgroundPosition: 'top center',
  // backgroundSize: 'cover',
  // backgroundRepeat: 'no-repeat',
});

const Rank = () => {
  const { t } = useI18n();
  const [curRankingType, setCurRankingType] = useState(1);
  const rankingType = ['Top Ranking', 'Top Level', 'Top Charisma'];

  type Sort = 'asc' | 'desc';
  type RankingTypeValue = 'ranking' | 'level' | 'charisma';
  const rankingTypeValue: RankingTypeValue[] = ['ranking', 'level', 'charisma'];
  const [sort] = useState<Sort>('desc');
  const [searchContent] = useState('');
  const { result: rankingList, page } = useRankingList(searchContent, sort, rankingTypeValue[curRankingType]);

  const SelectRank = () => (
    <Select
      sx={(theme) => ({
        background: '#F0F0F0',
        width: 160,
        height: 30,
        borderRadius: '7px',
        alignSelf: 'center',
        '& .MuiOutlinedInput-notchedOutline': { border: 'none' },
        [theme.breakpoints.down('sm')]: { zoom: 0.8 },
      })}
      displayEmpty
      value={curRankingType}
      IconComponent={ExpandMoreIcon}
      inputProps={{
        sx: {
          border: 'none',
          paddingY: 0,
          display: 'flex',
          '& .MuiListItemIcon-root': { svg: { display: 'none' } },
        },
      }}
      MenuProps={{
        sx: (theme) => ({
          marginTop: 1,
          '& .MuiPaper-root': {
            [theme.breakpoints.down('sm')]: { zoom: 0.8 },
            borderRadius: '10px',
            boxShadow: 'unset',
          },
          '& .MuiList-root': { padding: '0 12px', background: '#F0F0F0' },
        }),
      }}
      onChange={(event) => {
        if (event.target.value === curRankingType) return;
        page.setCurrentPage(1);
        setCurRankingType(Number(event.target.value));
      }}
    >
      {rankingType.map((item, index) => (
        <MenuItem
          divider={index !== rankingType.length - 1}
          key={item}
          selected={false}
          sx={(theme) => ({
            height: 38,
            background: '#F0F0F0',
            justifyContent: 'space-between',
            padding: '2px 0',
            '&:hover': { backgroundColor: '#F0F0F0' },
            '&.Mui-selected': { backgroundColor: '#F0F0F0 !important' },
            '&.Mui-selected:hover': { backgroundColor: '#F0F0F0' },
            [theme.breakpoints.down('sm')]: { height: 38, minHeight: 'unset' },
          })}
          value={index}
        >
          <ListItemText primary={item} />
          {curRankingType === index ? (
            <ListItemIcon sx={{ justifyContent: 'flex-end' }}>
              <Check />
            </ListItemIcon>
          ) : null}
        </MenuItem>
      ))}
    </Select>
  );

  return (
    <Container>
      <Outlet />
      <MainContainer>
        <Header>
          <AppBar title={t('rank.title')}>
            <SelectRank />
          </AppBar>
        </Header>
        <RankContent rankingList={rankingList} curRankingType={curRankingType} page={page}>
          <SelectRank />
        </RankContent>
      </MainContainer>
    </Container>
  );
};

export default Rank;
