import React, { useState, useCallback, useMemo, useEffect } from 'react';
import {
  styled,
  Box,
  Typography,
  Button,
  Stack,
  InputBase,
  RadioGroup,
  FormControlLabel,
  Radio,
  InputAdornment,
  MenuItem,
} from '@mui/material';
import { useAppDispatch } from 'hooks/redux';
import {
  showTransactionConfirmModal,
  showTransactionPendingModal,
  showTransactionErrorModal,
} from 'store/features/componentSlice';
import BaseModal from 'components/BaseModal';
import useBreakpoint from 'hooks/useBreakpoint';
import { NftMintCard, NftMintSkeletonCard } from 'components/Card';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { FACTION_LIST } from 'constants/nft';
import { useNameValid, useNFT } from 'hooks/useNFT';
import { LYNKNFT_ADDRESS, USDT } from 'constants/index';
import { BaseNFT, getSingleNFT } from 'utils/fetch/graph';
import { ApprovalState, useApproveCallback } from 'hooks/useApproveCallback';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Carousel from 'react-material-ui-carousel';
import useAsyncMemo from 'hooks/useAsyncMemo';
import { useI18n } from 'react-simple-i18n';
import { tryParseAmountNftMint } from 'utils/parseAmount';
import { Token, TokenAmount } from 'constants/token';
import _ from 'lodash';
import { useTokenBalance } from 'hooks/useWallet';
import { useActiveWeb3React } from 'hooks';
import { useIsActivityMint } from 'hooks/useNFT';

const MintModalBox = styled(Box)(({ theme }) => ({
  padding: theme.spacing(0, 3),
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(0, 1.5),
  },
}));

const StackBox = styled(Stack)(({ theme }) => ({
  gap: theme.spacing(3),
  [theme.breakpoints.down('sm')]: {
    gap: theme.spacing(1.5),
  },
}));

const InfoStack = styled(Stack)(({ theme }) => ({
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'start',
    gap: theme.spacing(0.5),
  },
}));

const GenderStack = styled(Stack)(({ theme }) => ({
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',
  gap: theme.spacing(4),
  '& .MuiFormGroup-root': {
    marginTop: 0,
  },
  [theme.breakpoints.down('sm')]: {
    gap: theme.spacing(0.5),
    flexDirection: 'column',
    alignItems: 'start',
  },
}));

const NameInput = styled(InputBase)(({ theme }) => ({
  borderRadius: 13,
  height: theme.spacing(4),
  width: theme.spacing(37),
  background: '#F0F0F0',
  border: '1px solid #00000099',
  padding: theme.spacing(0, 2),
  '&.usdtNumber': {
    padding: theme.spacing(0, 0, 0, 2),
  },
  [theme.breakpoints.down('sm')]: {
    width: theme.spacing(27),
  },
}));

const TypeButton = styled(Button)(({ theme }) => ({
  borderRadius: 13,
  height: theme.spacing(4),
  width: theme.spacing(8.5),
  background: '#F0F0F0',
  border: '1px solid #00000099',
  color: '#454545',
  fontWeight: 600,
  fontSize: 13,
  padding: theme.spacing(0, 0.5),
  textTransform: 'none',
  lineHeight: '10px',
  '&.active': {
    background: '#00000099',
    color: '#fff',
  },
  [theme.breakpoints.down('sm')]: {
    width: theme.spacing(6),
    zoom: 0.8,
  },
}));

const KeeperMintModal = ({
  open,
  handleClose,
  startId,
  endId,
  mintPrice,
  isActivitingMint,
  isNodeNftMint,
  isLeaderNftMint,
}: {
  open: boolean;
  handleClose: () => void;
  isActivitingMint: boolean;
  isNodeNftMint?: boolean;
  isLeaderNftMint?: boolean;
  startId: number;
  endId: number;
  mintPrice: number;
}): JSX.Element => {
  const { t } = useI18n();
  const { account, library } = useActiveWeb3React();
  const { mint, mintNode } = useNFT();
  const dispatch = useAppDispatch();
  const [step, setStep] = useState(1);
  const [faction, setFaction] = useState(0);
  const [sex, setSex] = useState(1);
  const [curName, setCurName] = useState('');
  const [index, setIndex] = useState(0);
  const [currencyType, setCurrencyType] = useState('USDT');
  const [selectedNFT, setSelectedNFT] = useState<BaseNFT | undefined>(undefined);
  const [mobileSelectedNFTIndex, setMobileSelectedNFTIndex] = useState(0);
  const { loading, valid } = useNameValid(curName);
  const [token] = useState<Token | undefined>(USDT);
  const [tokenId, setTokenId] = useState('');
  const payCurrencyAmount = useMemo(() => {
    if (!token || !selectedNFT) return undefined;
    return tryParseAmountNftMint(selectedNFT.price, token) as TokenAmount;
  }, [selectedNFT, token]);
  const [approvalState, approveCallback] = useApproveCallback(payCurrencyAmount, LYNKNFT_ADDRESS);
  const isMobile = useBreakpoint();
  const balance = useTokenBalance(account ?? undefined, payCurrencyAmount?.token);
  const enoughAsset = balance && payCurrencyAmount && !balance.lessThan(payCurrencyAmount);
  const isActivityMint = useIsActivityMint(tokenId, account || '');

  const getNewIdList = useCallback(async () => {
    if (index === 0) return [undefined, undefined, undefined];
    let l1, l2, l3;
    while (!l1 || !l2 || !l3) {
      if (!l1) {
        const randomL1 =
          Math.floor(Math.floor(1000 * Math.random()) + 1 + (faction * 2 + sex) * 10000) +
          (isNodeNftMint ? 300000 : isLeaderNftMint ? 200000 : 100000);
        const resL1 = await getSingleNFT(randomL1.toString());
        if (!resL1) l1 = randomL1;
      }
      if (!l2) {
        const randomL2 =
          Math.floor(Math.floor(1000 * Math.random()) + 1 + (faction * 2 + sex) * 10000) +
          (isNodeNftMint ? 300000 : 200000);
        const resL2 = await getSingleNFT(randomL2.toString());
        if (!resL2) l2 = randomL2;
      }
      if (!l3) {
        const randomL3 =
          Math.floor(Math.floor(1000 * Math.random()) + 1 + (faction * 2 + sex) * 10000) +
          (isLeaderNftMint ? 200000 : 300000);
        const resL3 = await getSingleNFT(randomL3.toString());
        if (!resL3) l3 = randomL3;
      }
    }
    return [l1, l2, l3];
  }, [faction, index, isLeaderNftMint, isNodeNftMint, sex]);

  const newIdList = useAsyncMemo(getNewIdList, [undefined, undefined, undefined], [index]);
  const newNFTList: (BaseNFT | undefined)[] = useMemo(() => {
    return newIdList?.map((id, index) => {
      return id
        ? {
            id: id.toString(),
            name: curName,
            level: '0',
            grade: index === 0 ? 'Common' : index === 1 ? 'Special' : 'Rare',
            price: isNodeNftMint
              ? ['1000', '3000', '5000'][index]
              : isLeaderNftMint
              ? ['500', '1000', '3000'][index]
              : ['10', '100', '300'][index],
          }
        : undefined;
    });
  }, [curName, isLeaderNftMint, isNodeNftMint, newIdList]);

  useEffect(() => {
    if (isMobile && newNFTList?.[mobileSelectedNFTIndex] && newNFTList[mobileSelectedNFTIndex]) {
      const nft = _.cloneDeep(newNFTList[mobileSelectedNFTIndex]);
      if (nft && isActivitingMint && Number(nft?.id) > startId && Number(nft?.id) < endId && isActivityMint) {
        nft.price = mintPrice.toString();
      }
      setSelectedNFT(nft);
    }
  }, [endId, isActivitingMint, isActivityMint, isMobile, mintPrice, mobileSelectedNFTIndex, newNFTList, startId]);

  const mintCallback = useCallback(() => {
    if (!library || !account || !selectedNFT?.id) return;
    handleClose();
    dispatch(showTransactionPendingModal());
    mint(selectedNFT.id, USDT.address, curName)
      .then(() => {
        dispatch(showTransactionConfirmModal());
      })
      .catch((err: any) => {
        dispatch(
          showTransactionErrorModal(
            err.error && err?.error?.data?.message
              ? err?.error?.data?.message
              : err.error && err.error.message
              ? err.error.message
              : err.message ?? t('global.networkError')
          )
        );
      });
  }, [account, curName, dispatch, handleClose, library, mint, selectedNFT?.id, t]);

  const mintNodeCallback = useCallback(() => {
    if (!library || !account || !selectedNFT?.id || !selectedNFT?.price) return;
    const nodeType = isLeaderNftMint
      ? selectedNFT?.price === '500'
        ? '1'
        : selectedNFT?.price === '1000'
        ? '2'
        : '3'
      : selectedNFT?.price === '1000'
      ? '1'
      : selectedNFT?.price === '3000'
      ? '2'
      : '3';
    handleClose();
    dispatch(showTransactionPendingModal());
    mintNode(selectedNFT.id, nodeType, curName)
      .then(() => {
        dispatch(showTransactionConfirmModal());
      })
      .catch((err: any) => {
        dispatch(
          showTransactionErrorModal(
            err.error && err?.error?.data?.message
              ? err?.error?.data?.message
              : err.error && err.error.message
              ? err.error.message
              : err.message ?? t('global.networkError')
          )
        );
      });
  }, [
    account,
    curName,
    dispatch,
    handleClose,
    library,
    mintNode,
    selectedNFT?.id,
    selectedNFT?.price,
    t,
    isLeaderNftMint,
  ]);

  useEffect(() => {
    if (newNFTList?.[0]) {
      newNFTList.forEach((el) => {
        if (el && Number(el?.id) > startId && Number(el?.id) < endId) {
          setTokenId(el?.id);
        }
      });
    }
  }, [endId, newNFTList, startId]);

  const goNextStep = () => {
    if (step === 3) {
      approvalState === ApprovalState.NOT_APPROVED
        ? approveCallback()
        : isNodeNftMint || isLeaderNftMint
        ? mintNodeCallback()
        : mintCallback();
      return;
    }
    if (step === 1) {
      setIndex(index + 1);
    }
    setStep((step) => step + 1);
  };

  const goPreStep = () => {
    if (step === 0) return;
    if (step === 2) {
      setIndex(0);
      setSelectedNFT(undefined);
    }
    setStep((step) => step - 1);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSex(Number((event.target as HTMLInputElement).value));
  };

  const onChangeCurrencyType = (event: SelectChangeEvent) => {
    setCurrencyType(event.target.value);
  };

  return (
    <BaseModal
      open={open}
      title={
        step === 1
          ? isNodeNftMint
            ? t('nodeNft.mintNodeNft')
            : isLeaderNftMint
            ? t('leaderNft.mintLeaderNft')
            : t('nfts.mintKeeperNft')
          : step === 2
          ? t('nfts.selectNft')
          : t('nfts.mint')
      }
      showGoBack={step > 1}
      contentWidth={step === 2 ? 640 : 434}
      contentMobileWidth={step === 2 ? '80vw' : 0}
      goBack={_.debounce(goPreStep, 50)}
      handleClose={handleClose}
      confirmText={
        step === 1
          ? curName.length === 0
            ? t('nfts.enterName')
            : !valid
            ? t('nfts.nameUsed')
            : t('nfts.proceed')
          : step === 2
          ? isNodeNftMint
            ? t('nodeNft.mintNode')
            : isLeaderNftMint
            ? t('leaderNft.mintLeader')
            : t('nfts.mintKeeper')
          : !enoughAsset
          ? t('global.insufficient')
          : approvalState === ApprovalState.NOT_APPROVED
          ? t('global.approve')
          : t('global.confirm')
      }
      confirm={_.debounce(goNextStep, 50)}
      confirmDisabled={curName.length === 0 || !valid || (step === 2 && !selectedNFT) || (step === 3 && !enoughAsset)}
      confirmLoading={curName.length !== 0 && loading}
      confirmRightText={step === 2 ? t('nfts.reSelect') : ''}
      confirmRight={() => {
        setSelectedNFT(undefined);
        setIndex(index + 1);
      }}
    >
      <MintModalBox>
        <StackBox sx={{ display: step === 1 ? 'flex' : 'none' }}>
          <InfoStack>
            <Typography>{t('nfts.nftName')}</Typography>
            <NameInput
              value={curName}
              onChange={(e) => {
                setCurName(e.target.value.replace(/[^a-z0-9]/g, '').substring(0, 15));
              }}
              endAdornment={<InputAdornment position="end">.Lynk</InputAdornment>}
            />
          </InfoStack>
          <GenderStack spacing={4}>
            <Typography>{t('nfts.selectGender')}</Typography>
            <RadioGroup row value={sex} onChange={handleChange}>
              <FormControlLabel value={1} control={<Radio size="small" color="default" />} label={t('nfts.male')} />
              <FormControlLabel value={0} control={<Radio size="small" color="default" />} label={t('nfts.female')} />
            </RadioGroup>
          </GenderStack>
          <InfoStack>
            <Typography>{t('nfts.selectFaction')}</Typography>
            <Stack direction="row" alignItems="center" spacing={0.5}>
              {FACTION_LIST.map(({ value, title }) => {
                return (
                  <TypeButton
                    className={faction === value ? 'active' : ''}
                    onClick={() => setFaction(value)}
                    key={value}
                  >
                    {t(title)}
                  </TypeButton>
                );
              })}
            </Stack>
          </InfoStack>
        </StackBox>
        {isMobile ? (
          <Stack
            direction="row"
            sx={{ display: step === 2 ? 'flex' : 'none' }}
            justifyContent="center"
            alignItems="center"
            spacing={1}
          >
            <Carousel
              autoPlay={false}
              indicators={false}
              animation="slide"
              index={mobileSelectedNFTIndex}
              cycleNavigation={false}
              navButtonsAlwaysVisible={true}
              PrevIcon={<ArrowBackIosNewIcon fontSize="large" />}
              NextIcon={<ArrowForwardIosIcon fontSize="large" />}
              onChange={(num) => {
                if (num || num === 0) {
                  setMobileSelectedNFTIndex(() => num);
                }
              }}
              navButtonsProps={{
                style: {
                  backgroundColor: 'transparent',
                  color: '#121425',
                },
              }}
              sx={(theme) => ({ width: theme.spacing(21), borderRadius: '10px', position: 'unset' })}
            >
              {newNFTList?.map((item) => {
                return item ? (
                  <NftMintCard
                    isActivityMint={isActivityMint}
                    isActivitingMint={isActivitingMint}
                    key={item.id}
                    item={item}
                    startId={startId}
                    endId={endId}
                    mintPrice={mintPrice}
                  />
                ) : (
                  <NftMintSkeletonCard />
                );
              })}
            </Carousel>
          </Stack>
        ) : (
          <Stack
            direction="row"
            sx={{ display: step === 2 ? 'flex' : 'none' }}
            justifyContent="center"
            alignItems="center"
            spacing={1}
          >
            {newNFTList?.map((item, index) => {
              return item ? (
                <NftMintCard
                  onSelect={(nft) => {
                    setMobileSelectedNFTIndex(index);
                    setSelectedNFT(
                      isActivitingMint && Number(nft.id) > startId && Number(nft.id) < endId && isActivityMint
                        ? { ...nft, price: mintPrice.toString() }
                        : nft
                    );
                  }}
                  key={item.id}
                  item={item}
                  active={selectedNFT?.id === item.id}
                  startId={startId}
                  endId={endId}
                  mintPrice={mintPrice}
                  isActivityMint={isActivityMint}
                  isActivitingMint={isActivitingMint}
                />
              ) : (
                <NftMintSkeletonCard />
              );
            })}
          </Stack>
        )}
        <Stack
          sx={{ display: step === 3 ? 'flex' : 'none', height: 80 }}
          justifyContent="center"
          alignItems="center"
          spacing={0.5}
        >
          <NameInput
            value={selectedNFT?.price}
            readOnly
            className="usdtNumber"
            endAdornment={
              <InputAdornment position="end">
                <Select
                  value={currencyType}
                  onChange={onChangeCurrencyType}
                  displayEmpty
                  IconComponent={ExpandMoreIcon}
                  sx={{
                    color: '#000',
                    fontWeight: 700,
                    height: 32,
                    '& .MuiOutlinedInput-notchedOutline': {
                      border: 'none',
                      borderRadius: 0,
                      borderLeft: '1px solid #00000099',
                    },
                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                      borderLeft: '1px solid #00000099',
                    },
                  }}
                  inputProps={{ 'aria-label': 'Without label' }}
                >
                  <MenuItem value="USDT">USDT</MenuItem>
                  {/* <MenuItem value="LYNK">LYNK</MenuItem> */}
                </Select>
              </InputAdornment>
            }
          />
          <Typography
            variant="body2"
            sx={(theme) => ({
              width: theme.spacing(37),
              textAlign: 'start',
              [theme.breakpoints.down('sm')]: {
                width: theme.spacing(27),
              },
            })}
          >
            {`${t('global.balance')}: ${balance ? balance.toFixed(2, { groupSeparator: ',' }) : '--'}`}
          </Typography>
        </Stack>
      </MintModalBox>
    </BaseModal>
  );
};

export default KeeperMintModal;
