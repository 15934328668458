import React, { HTMLProps, useCallback } from 'react';
import MuiCloseIcon from '@mui/icons-material/Close';
import MuiBacksIcon from '@mui/icons-material/KeyboardBackspace';
import { Link, IconButton, keyframes, styled, Theme } from '@mui/material';
import { SxProps } from '@mui/system';
import { ChainId } from 'constants/chain';
import { getEtherscanLink, shortenString } from 'utils';

export function CloseIcon({ onClick }: { onClick?: () => void }) {
  return (
    <IconButton
      onClick={onClick}
      size="large"
      sx={{
        padding: 0,
        position: 'absolute',
        top: '15px',
        right: '10px',
        '&:hover $closeIcon': {
          opacity: 0.8,
        },
      }}
    >
      <MuiCloseIcon sx={{ color: '#141420' }} />
    </IconButton>
  );
}

export function BackIcon({ onClick }: { onClick?: () => void }) {
  return (
    <IconButton
      onClick={onClick}
      size="large"
      sx={{
        padding: 0,
        position: 'absolute',
        top: '20px',
        left: '20px',
        '&:hover $closeIcon': {
          opacity: 0.8,
        },
      }}
    >
      <MuiBacksIcon sx={{ color: '#141420' }} />
    </IconButton>
  );
}

export function getTxLink(tx: string) {
  return <ExternalLink href={getEtherscanLink(ChainId.MAINNET, tx, 'transaction')}>{shortenString(tx)}</ExternalLink>;
}

export function ExternalLink({
  target = '_blank',
  href,
  rel = 'noopener noreferrer',
  style,
  sx,
  className,
  children,
  underline,
}: Omit<HTMLProps<HTMLAnchorElement>, 'as' | 'ref' | 'onClick'> & {
  href: string;
  style?: React.CSSProperties;
  sx?: SxProps<Theme>;
  underline?: 'always' | 'hover' | 'none';
  className?: string;
}) {
  const handleClick = useCallback(
    (event: React.MouseEvent<HTMLAnchorElement>) => {
      if (target === '_blank' || event.ctrlKey || event.metaKey) {
        console.log('_blank');
      } else {
        event.preventDefault();
        window.location.href = href;
      }
    },
    [href, target]
  );
  return (
    <Link
      className={className}
      target={target}
      rel={rel}
      href={href}
      onClick={handleClick}
      style={style}
      sx={sx}
      underline={underline ?? 'none'}
    >
      {children}
    </Link>
  );
}

const pulse = keyframes`
  0% {
    transform: scale(1);
  }
  60% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
`;

export const AnimatedWrapper = styled('div')(`
pointer-events: none;
display: flex;
align-items: center;
justify-content: center;
height: 100%;
width: 100%;
`);

export const AnimatedImg = styled('div')(`
animation: ${pulse} 800ms linear infinite;
& > * {
  width: 72px;
})
`);

export const Dots = styled('span')(`
  &::after {
    display: inline-block;
    animation: ellipsis 1.25s infinite;
    content: '.';
    width: 1em;
    text-align: left;
  }
  @keyframes ellipsis {
    0% {
      content: '.';
    }
    33% {
      content: '..';
    }
    66% {
      content: '...';
    }
  }
`);
