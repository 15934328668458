// const CA_LIST = [0, 100, 500, 1000, 3000, 5000, 10000, 20000, 30000, 50000, 100000];
const CA_LIST = [0, 100, 500, 1000, 2000, 3000, 4000, 5000, 6000, 7000, 8000, 9000, 10000, 20000];
const VA_LIST = [0, 10, 20, 40, 80, 160, 320, 640, 1280, 2560, 5120, 10240, 20480, 40960];
const IN_LIST = [0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100, 110, 120, 130];
const DX_LIST = [0, 5, 10, 15, 20, 25, 30, 40, 50, 60, 70, 80, 100, 120];

export enum Attr {
  CA,
  VA,
  IN,
  DX,
}

export const SUPPORT_ATTR: { [attr in Attr]: { interval: number[]; name: string; attr: string } } = {
  [Attr.CA]: { interval: CA_LIST, name: 'Charisma', attr: '0' },
  [Attr.VA]: { interval: VA_LIST, name: 'Vitality', attr: '1' },
  [Attr.IN]: { interval: IN_LIST, name: 'Intellect', attr: '2' },
  [Attr.DX]: { interval: DX_LIST, name: 'Dexterity', attr: '3' },
};

export function loadAttr(
  attr: Attr,
  value: number | undefined,
  level: number
): { value: number; level: number; max: number; levelMax: number } {
  const list: number[] = SUPPORT_ATTR[attr].interval;
  if (!value?.toString()) return { value: 0, level: 0, max: 0, levelMax: list[level + 1] };
  for (let i = 0; i < list.length - 1; i++) {
    if (list[i] <= value && value < list[i + 1]) {
      return { value, level: i + 1, max: list[i + 1], levelMax: list[level + 1] };
    } else if (!(value < list[list.length - 1])) {
      return { value, level: list.length, max: list[list.length - 1], levelMax: list[level + 1] };
    }
  }
  return { value, level: 0, max: 0, levelMax: list[level + 1] };
}
