import { TransactionResponse } from '@ethersproject/providers';
import { useCallback, useMemo } from 'react';
import { useHasPendingApproval, useTransactionAdder } from 'hooks/useTransaction';
import { calculateGasMargin } from '../utils';
import { Contract } from '@ethersproject/contracts';
import { useSingleCallResult } from 'hooks/useRequest';
import { ApprovalState } from './useApproveCallback';
import { useActiveWeb3React } from 'hooks';
import { useNFTContract } from './useContract';
import { useAppDispatch } from 'hooks/redux';
import {
  showTransactionConfirmModal,
  showTransactionPendingModal,
  showTransactionErrorModal,
} from 'store/features/componentSlice';
import { useI18n } from 'react-simple-i18n';
// import useModal from './useModal';
// import TransactionPendingModal from '../components/Modal/TransactionModals/TransactionPendingModal';
// import TransactionSubmittedModal from '../components/Modal/TransactionModals/TransactiontionSubmittedModal';

function useGetApproved(contract: Contract | undefined, spender: string | undefined) {
  const { account } = useActiveWeb3React();
  const res = useSingleCallResult(account ? contract : null, 'isApprovedForAll', [account || '', spender]);
  return useMemo(() => {
    if (res.loading || !res.result) return undefined;
    return res.result?.[0];
  }, [res.loading, res.result]);
}

export function useERC721ApproveAllCallback(
  contractAddress: string | undefined,
  spender: string | undefined
): [ApprovalState, () => Promise<void>] {
  // const { account } = useActiveWeb3React()
  // const { hideModal, showModal } = useModal();
  const dispatch = useAppDispatch();
  const { t } = useI18n();
  const contract = useNFTContract(contractAddress);
  const isApproved = useGetApproved(contract ?? undefined, spender);
  const pendingApproval = useHasPendingApproval(contract?.address, spender);
  // check the current approval status
  const approvalState: ApprovalState = useMemo(() => {
    // if (!spender) return ApprovalState.UNKNOWN
    // we might not have enough data to know whether or not we need to approve
    if (isApproved) return ApprovalState.APPROVED;
    if (pendingApproval) return ApprovalState.PENDING;
    if (isApproved === undefined) return ApprovalState.UNKNOWN;
    return ApprovalState.NOT_APPROVED;
  }, [isApproved, pendingApproval]);

  const addTransaction = useTransactionAdder();

  const approve = useCallback(async (): Promise<void> => {
    if (approvalState !== ApprovalState.NOT_APPROVED) {
      console.error('approve was called unnecessarily');
      return;
    }

    if (!contract) {
      console.error('Contract is null');
      return;
    }

    if (!spender) {
      console.error('no spender');
      return;
    }

    const estimatedGas = await contract.estimateGas.setApprovalForAll(spender, true).catch((error: Error) => {
      console.debug('Failed to approve nft', error);
      throw error;
    });

    dispatch(showTransactionPendingModal());

    return contract
      .setApprovalForAll(spender, true, {
        gasLimit: calculateGasMargin(estimatedGas),
      })
      .then((response: TransactionResponse) => {
        dispatch(showTransactionConfirmModal());
        addTransaction(response, {
          summary: '$%global.approve$% NFT',
          approval: { tokenAddress: contract.address, spender },
        });
      })
      .catch((err: any) => {
        // hideModal();
        dispatch(
          showTransactionErrorModal(
            err.error && err.error.message
              ? err.error.message
              : err?.data?.message
              ? err?.data?.message
              : err.message ?? t('global.networkError')
          )
        );
        console.log('Failed to approve nft', err);
        // throw error;
      });
  }, [approvalState, contract, spender, dispatch, addTransaction, t]);

  return [approvalState, approve];
}
