import React from 'react';
import { styled } from '@mui/material';
import AppBar from 'components/AppBar';
import { useI18n } from 'react-simple-i18n';
import HistoryContent from 'components/HistoryContent';

const MainContainer = styled('div')(({ theme }) => ({
  display: 'grid',
  height: 'calc(100%)',
  width: `calc(100% - ${theme.spacing(14)})`,
  padding: theme.spacing(0, 7),
  gridTemplateRows: `${theme.spacing(10.5)} 1fr`,
  [theme.breakpoints.down('sm')]: {
    gridTemplateRows: `${theme.spacing(9)} 1fr`,
    padding: theme.spacing(0, 3),
    width: `calc(100% - ${theme.spacing(6)})`,
  },
  gridTemplateColumns: '1fr',
  gridTemplateAreas: `
    'historyT'
    'historyB'
  `,
}));

const Header = styled('div')({
  gridArea: 'historyT',
  position: 'relative',
});

const History = () => {
  const { t } = useI18n();

  return (
    <MainContainer>
      <Header>
        <AppBar title={t('history.title')}></AppBar>
      </Header>
      <HistoryContent />
    </MainContainer>
  );
};

export default History;
