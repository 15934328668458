import * as ABIS from '../constants/abis';

const globalConfig = {
  sepoliaTest: {
    abis: {
      AP_ABI: ABIS.AP.sepoliaTest,
      DB_ABI: ABIS.DB.sepoliaTest,
      MARKET_ABI: ABIS.MARKET.sepoliaTest,
      USER_ABI: ABIS.USER.sepoliaTest,
      LYNKNFT_ABI: ABIS.LYNKNFT.sepoliaTest,
      STAKING_ABI: ABIS.STAKING.sepoliaTest,
      SLYNKNFT_ABI: ABIS.SLYNKNFT.sepoliaTest,
      ILYNKNFT_ABI: ABIS.ILYNKNFT.sepoliaTest,
      SWAP_ABI: ABIS.SWAP.sepoliaTest,
    },
    address: {
      //usdt address --> mock_usdt.json
      usdt: '0xb575400Da99E13e2d1a2B21115290Ae669e361f0',
      lrt: '0x8Dfd7403c26CA19148a3e40C5F9Ad4E328855455',
      lynk: '0xbEF98DDd131973914a1a0dE223D67c9679B7665F',
      ap: '0x3d01E20428544855ef98B1144552468435e6D36F',
      market: '0x26665D2A687f2f37ce0324EF736c4778058037F1',
      user: '0xDBd2Aa94cf7608c4f4dB879F1FEAb13fdde80A88',
      lynkNFT: '0xc0330C26c3C2A77ba9E191568C5179F34aA74459',
      db: '0x5f9BBb7bBFC56072ae37872eBB077E4a6cA8A1b7',
      staking: '0xee91613755fABEF98823BcA27Fb23Bd25d90362e',
      sLynkNFT: '0xa10F5211c0dD9C5e34eA46c13b46707fb09D9e87',
      iLynkNFT: '0x07C63BC738F85D4802671F3d05a811851Be8B701',
      swap: '0x707E39736AAfb7497F2dc39c69Cc1D3E42651b32',
    },
    baseImageUrl: 'https://app.lynk.im/NFT/Finder/',
    baseJsonUrl: 'https://res.lynk.im/jsons',
    chainId: 11155111,
    chainName: 'ethereumMainnet',
    chain: 'Ethereum Mainnet',
    nativeCurrency: {
      name: 'Ethereum',
      symbol: 'ETH',
      decimals: 18,
    },
    chainIdUin256: '0xaa36a7',
    rpcUrls: 'https://sepolia.infura.io/v3/f338fa7411a945db8bed616683b2ade5',
    blockExplorerUrls: 'https://sepolia.etherscan.io',
    multicallNetChainId: '0xE78D911B56a6321bF622172D32D916f9563e8D84',
    subgraph: 'https://subgraph.lynk.im/subgraphs/name/alyx_nft_subgraph',
    countRewardApi: '',
    sumcaCountRewardApi: '',
    mintStartTime: 1677396600000,
    mintEndTime: 1677397200000,
    allOpenTime: 1677397200000,
  },
  goerliTest: {
    abis: {
      AP_ABI: ABIS.AP.goerliTest,
      DB_ABI: ABIS.DB.goerliTest,
      MARKET_ABI: ABIS.MARKET.goerliTest,
      USER_ABI: ABIS.USER.goerliTest,
      LYNKNFT_ABI: ABIS.LYNKNFT.goerliTest,
      STAKING_ABI: ABIS.STAKING.goerliTest,
      SLYNKNFT_ABI: ABIS.SLYNKNFT.goerliTest,
      ILYNKNFT_ABI: ABIS.ILYNKNFT.goerliTest,
      SWAP_ABI: ABIS.SWAP.goerliTest,
    },
    address: {
      //usdt --> mock_usdt.json
      usdt: '0x9e1B200C5b5a02348Cd18447671d5F58f32f1488',
      lrt: '0x7A7547feACf3aaB49bD37C49235A77c56e1c1C43',
      lynk: '0xCbE687190bD61A3C56B3cA11E20489Ef67aAEA6e',
      ap: '0xB9a2e4411A64D371d4713eEA3Fc4137a2f64db9b',
      market: '0xAa2F61d1C3A5b001B8995b44274A9d624a7805Df',
      user: '0x591442c0EbbAC91145C2772135D132c9908694D2',
      lynkNFT: '0xed889470edc8299F4e8C49Bbf78b78A1e6C82e88',
      db: '0xEde2D599e4d3f6d46F60a3D8Bb14D2e9Bc2f6CD0',
      staking: '0x5318b3c14BdC06D41684f7a6A558BD2c1d840949',
      sLynkNFT: '0xdFCa2F244bB504f82dC2a092D6a5A11AdD702d06',
      iLynkNFT: '0x07B36dbC381DF2e4F4ce5cE45eefbe6b6997e09a',
      swap: '0x896aD96F548d3CEF818bc2A5E94934B52f98f1b0',
    },
    baseImageUrl: 'https://nfts.lynk.im/images/',
    baseJsonUrl: 'https://res.lynk.im/jsons',
    chainId: 5,
    chainName: 'ethereumMainnet',
    chain: 'Ethereum Mainnet',
    nativeCurrency: {
      name: 'Ethereum',
      symbol: 'ETH',
      decimals: 18,
    },
    chainIdUin256: '0x5',
    rpcUrls: 'https://goerli.blockpi.network/v1/rpc/public',
    blockExplorerUrls: 'https://goerli.etherscan.io',
    multicallNetChainId: '0x5ba1e12693dc8f9c48aad8770482f4739beed696',
    subgraph: 'https://api.studio.thegraph.com/query/42837/goerli_test/v0.0.1',
    countRewardApi: '',
    sumcaCountRewardApi: '',
    mintStartTime: 1677396600000,
    mintEndTime: 1677397200000,
    allOpenTime: 1677397200000,
  },
  arbitrumTest: {
    abis: {
      AP_ABI: ABIS.AP.arbitrumTest,
      DB_ABI: ABIS.DB.arbitrumTest,
      MARKET_ABI: ABIS.MARKET.arbitrumTest,
      USER_ABI: ABIS.USER.arbitrumTest,
      LYNKNFT_ABI: ABIS.LYNKNFT.arbitrumTest,
      STAKING_ABI: ABIS.STAKING.arbitrumTest,
      SLYNKNFT_ABI: ABIS.SLYNKNFT.arbitrumTest,
      ILYNKNFT_ABI: ABIS.ILYNKNFT.arbitrumTest,
      SWAP_ABI: ABIS.SWAP.arbitrumTest,
    },
    address: {
      //usdt --> mock_usdt.json
      usdt: '0x032148cdD61955eb3e68edE11932B0f74E3174db',
      lrt: '0xeEf063C536a18CC92AA01917ae41aafb034a3E45',
      lynk: '0x5d4C5608D2a5b966Fd6B9f405c7565FF49e620Cf',
      ap: '0xb5803f698Aa849D13E1107Ea5e90A599c9da66BA',
      market: '0x578CdE72Ba6DcC959b2F9caa40F0b65C1428Fcd7',
      user: '0xDF8036B300Cf49aC031B00d6Dd98023B3a311B2c',
      lynkNFT: '0x24bE1210B374bBb364455d2c2d22985aF7368eA9',
      db: '0x822dd76FfDdA4C2C0ebc5e6d60920a44CCA3Db17',
      staking: '0x850bDE2AF4B6986633BFD13D9900fA9a969925F4',
      sLynkNFT: '0xF9b93E3d112f2A9cDeE0E9357c5eb9fe83c50E7a',
      iLynkNFT: '0x5C8FDfE4DA1B6A43Fc5DD6584EC51584519D47ee',
      swap: '0x7517A2461d5e8eB102312B8f5a8f4588D9a707e1',
    },
    baseImageUrl: 'https://res.lynk.im/images/',
    baseJsonUrl: 'https://res.lynk.im/jsons',
    chainId: 42161,
    chainName: 'arbitrumOne',
    chain: 'Arbitrum One',
    nativeCurrency: {
      name: 'Arbitrum',
      symbol: 'ETH',
      decimals: 18,
    },
    chainIdUin256: '0xa4b1',
    rpcUrls: 'https://arb1.arbitrum.io/rpc',
    blockExplorerUrls: 'https://arbiscan.io',
    multicallNetChainId: '0xD8e58Cc18CB097ACbFb626C24B3e21205D1825ba',
    subgraph: 'https://sub.lynk.im/subgraphs/name/lynk_test',
    countRewardApi: '',
    sumcaCountRewardApi: '',
    mintStartTime: 1677506400000,
    mintEndTime: 1682604600000,
    allOpenTime: 1677507000000,
  },
  arbitrum: {
    abis: {
      AP_ABI: ABIS.AP.arbitrum,
      DB_ABI: ABIS.DB.arbitrum,
      MARKET_ABI: ABIS.MARKET.arbitrum,
      USER_ABI: ABIS.USER.arbitrum,
      LYNKNFT_ABI: ABIS.LYNKNFT.arbitrum,
      STAKING_ABI: ABIS.STAKING.arbitrum,
      SLYNKNFT_ABI: ABIS.SLYNKNFT.arbitrum,
      ILYNKNFT_ABI: ABIS.ILYNKNFT.arbitrum,
      SWAP_ABI: ABIS.SWAP.arbitrum,
    },
    address: {
      //usdt --> mock_usdt.json
      usdt: '0xFd086bC7CD5C481DCC9C85ebE478A1C0b69FCbb9',
      lrt: '0x2C2c60d003c207Eaf727ee5ED5603A1Ec54BAA70',
      lynk: '0x52e48A7f804968C500c8690908583Ec7b09B795D',
      ap: '0x87d385e5fC886261d129B2061Cb0108ce66D8422',
      market: '0x75Bb83496607cdFe5B787AbEbBD2e2BaB27FE477',
      user: '0x8a9bE5E9f8CE41bAFaAD769bE01D861161A1029E',
      lynkNFT: '0x2E8C8B031dc236E11AF09c4eC4826bc6593bF5AD',
      db: '0x37Ff4e89108d0e10B7040641fB9C8eD140c56602',
      staking: '0xf8a19e868891Ff916455ea07a8aB0D1414DC63B8',
      sLynkNFT: '0xCFc7600F90e63EcDe36C175B2B36760b1B51b598',
      iLynkNFT: '0x576eBc73862E05d6A08947774DABFfe8598Da8F9',
      swap: '0x43AE8C4272aaDE45AD7CcF316A343f74f18a9365',
    },
    baseImageUrl: 'https://res.lynk.im/images/',
    baseJsonUrl: 'https://res.lynk.im/jsons',
    chainId: 42161,
    chainName: 'arbitrumOne',
    chain: 'Arbitrum One',
    nativeCurrency: {
      name: 'Arbitrum',
      symbol: 'ETH',
      decimals: 18,
    },
    chainIdUin256: '0xa4b1',
    rpcUrls: 'https://arb1.arbitrum.io/rpc',
    blockExplorerUrls: 'https://arbiscan.io',
    multicallNetChainId: '0xD8e58Cc18CB097ACbFb626C24B3e21205D1825ba',
    subgraph: 'https://sub.lynk.im/subgraphs/name/lynk_prod',
    countRewardApi: 'https://sub.lynk.im/api/countReward',
    sumcaCountRewardApi: 'https://stat.lynk.im',
    mintStartTime: 1677407280000,
    mintEndTime: 1677407400000,
    allOpenTime: 1677407520000,
  },
  ethTest: {
    abis: {
      AP_ABI: ABIS.AP.ethTest,
      DB_ABI: ABIS.DB.ethTest,
      MARKET_ABI: ABIS.MARKET.ethTest,
      USER_ABI: ABIS.USER.ethTest,
      LYNKNFT_ABI: ABIS.LYNKNFT.ethTest,
      STAKING_ABI: ABIS.STAKING.ethTest,
      SLYNKNFT_ABI: ABIS.SLYNKNFT.ethTest,
      ILYNKNFT_ABI: ABIS.ILYNKNFT.ethTest,
      SWAP_ABI: ABIS.SWAP.ethTest,
    },
    address: {
      //usdt --> mock_usdt.json
      usdt: '0x9b7c3800e4A6Ff3EA1C86D16317A2f516b47e43C',
      lrt: '0x1Ac55484b651FD47B90eD3099797F666812756b0',
      lynk: '0x81A1D112236E263D37E327CC6a848DC928874Bc4',
      ap: '0x6A211290eF623d93f2e1A17B565c5aF05B8E23E0',
      market: '0x340ab3071A70B03406A39aa7988F451d6ed91C98',
      user: '0x28a612B0130ac1bd1FB5f932b0B3B752F6Aac478',
      lynkNFT: '0xAAEAA525bf8bCD67396e8c165719319b32Fa9ac1',
      db: '0x3298731111e6C6011Cf6311382c9ad72A03085DC',
      staking: '0x26bf82464a998bD3047B69619aD6BFfB9305A6e9',
      sLynkNFT: '0x8059569231688A2BF4fEfd34F80c7E77705db11A',
      iLynkNFT: '0x60470E83149c1ee71cd5863C9ba23b985aAC499F',
      swap: '0xF24F524ddaE28174Bf462dB5B0ee591C4104a573',
    },
    baseImageUrl: 'https://nfts.lynk.im/images/',
    baseJsonUrl: 'https://res.lynk.im/jsons',
    chainId: 1,
    chainName: 'ethereumMainnet',
    chain: 'Ethereum Mainnet',
    nativeCurrency: {
      name: 'Ethereum',
      symbol: 'ETH',
      decimals: 18,
    },
    chainIdUin256: '0x1',
    rpcUrls: 'https://eth.llamarpc.com',
    blockExplorerUrls: 'https://etherscan.io',
    multicallNetChainId: '0x5ba1e12693dc8f9c48aad8770482f4739beed696',
    subgraph: 'https://api.studio.thegraph.com/query/42691/lynktest/v0.0.5',
  },
};

export default globalConfig.arbitrum;
