import { useEffect, useState } from 'react';
import { getNFTRanking, NFT } from 'utils/fetch/graph';

export function useRankingList(
  searchName: string,
  sort: 'asc' | 'desc',
  curRankingType: 'ranking' | 'level' | 'charisma'
) {
  const [refresh, setRefresh] = useState(Math.random());
  const [result, setResult] = useState<
    | NFT[]
    | {
        address: string;
        id: string;
        level: number;
      }[]
  >();
  const [currentPage, setCurrentPage] = useState(1);

  const [loading, setLoading] = useState<boolean>(false);
  // const [total, setTotal] = useState<number>(0)
  const pageSize = 10;

  useEffect(() => {
    setCurrentPage(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchName, curRankingType]);

  useEffect(() => {
    (async () => {
      setLoading(true);
      try {
        const list = await getNFTRanking(searchName, sort, curRankingType, (currentPage - 1) * pageSize, pageSize);
        setLoading(false);
        setResult(list);
      } catch (error) {
        setResult(undefined);
        setLoading(false);
        console.error('useRankingList', error);
      }
    })();
  }, [searchName, sort, curRankingType, refresh, currentPage]);

  return {
    loading: loading,
    page: {
      setCurrentPage,
      currentPage,
      hasPrev: currentPage > 1,
      hasNext: result?.length === pageSize,
      pageSize,
    },
    refresh: () => setRefresh(Math.random()),
    result,
  };
}
