export default {
  global: {
    connectWallet: 'Conectar Cartera',
    swapLynk: 'SWAP $LYNK',
    connectToWallet: 'Conectar a Cartera',
    metamask: 'METAMASK',
    walletConnect: 'CONECTAR CARTERA',
    coinbaseWallet: 'CARTERA COINBASE',
    walletDes: '*Al conectar un monedero, acepta las Condiciones del servicio y la Política de privacidad de Lynkverse',
    connecting: 'Conectando...',
    connectedWallet: 'Conectado con %s%',
    wrongNetwork: 'Red incorrecta',
    error: 'Error',
    myWallet: 'MI CARTERA',
    myAddress: 'Mi Dirección',
    addToken: 'añadir token',
    crossChain: 'cruzar cadena',
    logout: 'SALIR',
    change: 'CAMBIAR',
    recentTransactions: 'Transacciones recientes',
    clearAll: 'Borrar todo',
    confirm: 'CONFIRMAR',
    confirmed: 'Confirmado',
    balance: 'Saldo',
    confirmTransaction: 'Por favor, confirme esta transacción en su cartera',
    viewOnChain: 'Ver en %s%',
    invitationLink: 'Enlace de invitación',
    invitationCode: 'Código de invitación',
    copy: 'COPIA',
    insufficient: 'Saldo insuficiente',
    approve: 'Aprobar',
    tip: 'Consejo',
    drop: 'Gota',
    swap: 'Intercambio',
    register: 'Registro',
    networkError: 'Error de red',
    initializing: 'Inicializando...',
    errorConnecting: 'Error al conectar',
    errorConnectingDes: 'Error al conectar. Intenta actualizar la página.',
    binanceSmartChain: 'Cadena inteligente de Binance',
    connectTip: 'Por favor conéctese al',
    ethereumMainnet: 'red principal ethereum',
    connect: 'Conectar a',
    tryAgain: 'Intentar otra vez',
    changeWallet: 'Cambiar billetera',
    exchangeRate: 'Tipo de cambio',
    approving: 'Aprobatoria',
    swapTitle: 'INTERCAMBIO LYNK',
    registerTitle: 'INSERTA TU CÓDIGO DE INVITACIÓN',
    registerPlh: 'Por favor ingrese su código aquí',
    registerDes: 'TIP: Puede ir a Discord de Lynk para recuperar su código de invitación.',
    unValidCode: 'Código de registro no válido',
    registerInviterDes: 'Aún no se ha registrado, por favor regístrese',
    transactionsDes: 'Tus transacciones aparecerán aquí....',
    copied: 'COPIADA',
    finish: 'Finalizar',
    minting: 'acuñación',
    comingSoon: 'Muy pronto',
  },
  navMenu: {
    myDashboard: 'MI PANEL',
    nfts: 'NFTs',
    stake: 'APUESTA',
    invitation: 'INVITACIÓN',
    history: 'HISTORIA',
    activitiesCampaigns: 'ACTIVIDADES Y CAMPAÑAS',
    nodeNft: 'Programa Node NFT',
    leaderNft: 'Programa LEADER NFT',
    finders: 'Mint de Finders NFT',
    marketPlace: 'MARKETPLACE',
    rank: 'RANK',
    more: 'MÁS',
    chatRoom: 'SALA DE CHAT',
    lynkAlliance: 'ALIANZA LYNK',
    domainServer: 'Servidor de nombres de Dominio LYNK',
    feed: 'Dinámica',
  },
  nfts: {
    title: 'USUARIO NFTs',
    mintKeeper: 'Forjar KEEPER',
    buyKeeper: 'Comprar KEEPER',
    freeMint: 'Forja libre',
    owned: 'propiedad',
    staked: 'apostado',
    sell: 'Vender',
    stake: 'apuesta',
    mintKeeperNft: 'MINT KEEPER NFT',
    nftName: 'Nombre NFT',
    selectGender: 'Seleccionar género',
    male: 'Hombre',
    female: 'Mujer',
    selectFaction: 'Selecciona facción',
    spirits: 'Espíritus',
    martials: 'Marciales',
    exos: 'Exos',
    voids: 'Voids',
    selectNft: 'SELECCIONAR NFT',
    common: 'COMMON',
    special: 'ESPECIAL',
    rare: 'RARO',
    reSelect: 'Volver a seleccionar',
    mint: 'MENTA',
    proceed: 'PROCEDER',
    enterName: 'Ingrese su nombre',
    nameUsed: 'Nombre ocupado',
    cancel: 'Cancelar',
    claim: 'Afirmar',
    freeMintEnd: 'FIN DEL TIEMPO',
    days: 'DÍAS',
    hours: 'HRS',
    minutes: 'MIN',
    seconds: 'SEG',
  },
  nftDetail: {
    back: 'Atrás',
    attributes: 'ATRIBUTOS',
    leveltooltip: '¡Acelere sus atributos NFT para subir de nivel y ganar más recompensas de participación!',
    purchaseAp: 'COMPRA AP',
    userStats: 'USER STATS',
    nfts: 'NFTs',
    charisma: 'Carisma',
    dexterity: 'Destreza',
    intellect: 'Intelecto',
    vitality: 'Vitalidad',
    accelerate: 'ACELERAR',
    details: 'DETALLES',
    currentHolder: 'Titular actual',
    mintTime: 'Hora de acuñación',
    mintAddress: 'Dirección mint',
    estimatedPrice: 'Precio estimado',
    endPrice: 'Precio final',
    role: 'Rol',
    contractAddress: 'Dirección del contrato',
    tokenID: 'Identificador de la ficha',
    tokenStandard: 'Estándar del token',
    chain: 'Cadena',
    background: 'Fondo',
    effect: 'Efecto',
    Body: 'Cuerpo',
    clothing: 'Ropa',
    head: 'Cabeza',
    weapon: 'Arma',
    bodyAttachment: 'Accesorio del cuerpo',
    artifact: 'Artefacto',
    purchaseAP: 'Purchase AP',
    balance: 'Balance',
    amount: 'Importe',
    price: 'Precio',
    buy: 'Comprar',
    caUpgrade: 'CA UPGRADE',
    vaUpgrade: 'VA UPGRADE',
    inUpgrade: 'ACTUALIZACIÓN IN',
    dxUpgrade: 'ACTUALIZACIÓN DX',
    max: 'Max',
    unStake: 'Desapostar',
    claimAp: 'RECLAMAR AP',
    apReward: 'Recompensa AP',
    apRewardtooltip:
      'Solo los NFT de nivel 2 y superior tienen derecho a recibir una recompensa por logros. Los miembros deben reclamar el AP recompensado por cada día para reanudar el siguiente ciclo de conteo.\nDe lo contrario, el AP permanecerá en 1, a pesar de apostar durante 2 días o más.',
    stakingTime: 'Tiempo de apuesta acumulado',
    claimApHelpDes:
      'Los miembros están obligados a reclamar el AP recompensado por cada día para reanudar el próximo ciclo AP. Si no lo hace, la recompensa AP se mantendrá en 1. A pesar de apostar si durante 100 días o más.',
    claimProfit: 'Puedes reclamar beneficios',
    claim: 'RECLAMAR',
    sell: 'VENDER',
    referencePrice: 'Precio de referencia',
    onSale: 'EN VENTA',
    unListing: 'UNLISTING',
    unList: 'UNLIST',
    insufficientLynk: 'Enlace insuficiente',
    fullLevel: 'Nivel Completo',
    upLimit: 'Debe ser más alta que',
    oneDayMaxVA: 'VA ha sido actualizado hoy',
    enterPoint: 'Introducir punto',
    noReward: 'No hay recompensa disponible',
    mustHigherThanLevel2: 'Debes ser superior al nivel 2',
    mintAction: 'Buscador de menta NFT',
    mint: 'menta',
    unstake: 'Dejar de apostar',
    upgrade: 'Mejora',
    loading: 'Cargando',
  },
  stake: {
    title: 'APUESTA MIS NFTS',
    redeem: 'Canjear',
    claim: 'Reclamar',
    accumulatedEarned: '$LRT ganados acumulados',
    available: 'Disponible $LRT',
    claiming: 'Reclamando',
  },
  invitation: {
    title: 'TABLÓN DE INVITACIONES',
    invitationDes: 'Invita a amigos para ganar recompensas juntos',
    inviteFriends: 'INVITAR AMIGOS',
    rewardSummary: 'RESUMEN DE RECOMPENSAS',
    socialRewardUp: 'RECOMPENSA SOCIAL',
    socialRewardDes:
      'Construye tu equipo y red de Guardianes invitando a tus amigos a unirse a la comunidad LYNK. Cuanto mayor sea el rango de tu Guardián, mayores serán las recompensas sociales obtenidas',
    communityReward: 'RECOMPENSA COMUNITARIA',
    communityReward1: 'Recompensa Comunitaria',
    communityRewardDes:
      'Al conectar con miembros en el Santuario, los jugadores son capaces de construir alianzas nuevas o existentes para ganar recompensas más altas.',
    contributionRewardUp: 'RECOMPENSA DE CONTRIBUCIÓN',
    contributionRewardDes:
      'Además de recibir LRT como ganancias, también se otorgará AP por completar ciertas tareas. Un usuario que reclute a un nuevo miembro recibirá de 1 a 6 AP por cada 100 CA acumuladas por sus seguidores (acreditadas inmediatamente).',
    AchievementReward: 'RECOMPENSA DE LOGRO',
    AchievementRewardDes:
      'Además de recibir LRT como ganancias, también se otorgarán AP por completar ciertas tareas. ¡Apuesta por un NFT de nivel 2 o superior para recibir recompensas AP todos los días! La cantidad de recompensas de AP vendrá determinada por el nivel de tu NFT',
    StakingReward: 'RECOMPENSA DE APUESTA',
    StakingRewardDes:
      'Un usuario puede poseer múltiples NFTs al mismo tiempo. Los usuarios pueden optar a beneficios diarios siempre que su(s) NFT(s) cumpla(n) los requisitos de apuesta.',
    bonusRatio: 'Ratio de bonificación',
    totalLRT: 'LRT total',
    qualified: 'Cualificado',
    cumulateCA: 'CA acumulada',
    totalAP: 'Total AP',
    cycles: 'Ciclos',
    stakingNFT: 'Apuesta NFT',
    elite: 'Élite',
    epic: 'Épico',
    master: 'Maestro',
    legendary: 'Legendario',
    mythic: 'Mítico',
    divine: 'Divino',
    nourishInviter: 'Patrocinador directo',
    teamGrade: 'Acumulado',
    levelPrivilege: 'PRIVILEGIO DE NIVEL',
    socialReward: 'Recompensa social',
    contributionReward: 'Recompensa de contribución',
    achievementReward: 'Recompensa de logro',
    inactive: 'INACTIVO',
    activated: 'ACTIVADO',
    myLevel: 'MI NIVEL',
    generation: 'Generación',
    inviteRule: 'Consulte cada nuevo 100CA, obtenga',
    NFTStakingRule: 'Participación NFT de nivel 2 o superior durante 1 día y obtenga',
    inactivated: 'INACTIVADO',
    activate: 'ACTIVAR',
    AcaStastistics: 'ESTADÍSTICAS SUMCA',
    daily: 'A diario',
    yesterday: 'Ayer',
    today: 'Hoy',
    weekly: 'Semanalmente',
    lastWeek: 'La semana pasada',
    thisWeek: 'Esta semana',
    monthly: 'Mensual',
    lastMonth: 'El mes pasado',
    thisMonth: 'Este mes',
  },
  history: {
    title: 'HISTORY',
    stakingRewardRecords: 'Registros de recompensas de apuestas',
    mintingRecords: 'Registros de acuñación de NFT',
    transactionsRecords: 'Registros de transacciones de NFT',
    recommendedRecords: 'Registros recomendados',
    referralRewardRecords: 'Registros de recompensas por recomendación',
    contributionRewardRecords: 'Registros de recompensa por contribución',
    achievementRewardRecords: 'registros de recompensas por logros',
    communityRewardRecords: 'registros de recompensas comunitarias',
    time: 'Tiempo',
    reward: 'Recompensa',
    tx: 'TX',
    name: 'Nombre',
    amount: 'Cantidad',
    buyerAddress: 'Dirección del comprador',
    sellerAddress: 'Dirección del vendedor',
    address: 'DIRECCIÓN',
  },
  rank: {
    title: 'USUARIOS SUPERIORES POR',
    top: 'TOP',
    ranking: ' Clasificación ',
    levelUp: 'NIVEL',
    charisma: 'CHARISMA',
    nft: 'NFT',
    level: 'Nivel',
    tx: 'TX',
  },
  finders: {
    title: 'BUSCADORES',
    findersDes1: 'Los Buscadores proceden del nexo,',
    findersDes2: 'punto central de todas las coordenadas espaciotemporales',
    mint: 'MINT',
    soldOut: 'AGOTADO',
    info1:
      'Los Buscadores proceden del Nexo, un punto central de todas las coordenadas espaciotemporales. Estos seres han existido desde el principio del omniverso conocido y han observado el nacimiento de innumerables mundos. Recogen todos los conocimientos y datos de todos los Metaversos, preservándolos mediante la sincronización de sus mentes en cohesión. Cada cierto tiempo, aparece un Metaverso extraordinario con potencial para avanzar más allá de los límites de los demás Metaversos, lo que incita a los Buscadores a actuar. Entrando en LYNKVERSE a través de la grieta, los Buscadores se disponen a llevar a cabo su misión: seleccionar a unos pocos elegidos para guiar a otros habitantes y garantizar que el Metaverso desarrolle todo su potencial',
    info2:
      'Cada Buscador crea almas-semilla que contienen una parte de sus poderes y las otorga a los elegidos. Al asimilar la semilla de alma, nace una nueva raza. Ven a llevar el manto de la esperanza y conviértete en el líder de la nueva era.',
    totalAmount: 'IMPORTE TOTAL DE BUSCADORES:',
    totalBalance: 'SALDO TOTAL DE BUSCADORES DISPONIBLES:',
    minted: 'ACUÑADA',
  },
  nodeNft: {
    title: 'Programa NODE NFT Generación 1',
    mintNow: 'Mint Ahora',
    mintNode: 'MINT NODE NFT',
    mintNodeNft: 'MINT NODE NFT',
    nodeNftDes1: 'Obtenga una ventaja con un Node NFT',
    nodeNftDes2: 'Únase a las filas y deje su huella en la comunidad LYNK',
    info11: `¡Bienvenido al Programa Node NFT, LYNK fam!`,
    info12: `El programa Node NFT es nuestro programa principal exclusivamente para los primeros patrocinadores que han demostrado una fuerte iniciativa para hacer crecer la comunidad LYNK. La generación I del programa sirve para reconocer a campeones como usted, con NFT de Keeper únicos que representarán su identidad y estado como líderes de la comunidad Web3 más emocionante.`,
    info13: `Este emocionante programa le ofrece la oportunidad de acuñar un Nodo NFT único en uno de los tres niveles: Común, Especial o Raro. Esto es lo que obtendrá en cada nivel:`,
    infoCommon: `NFT de nodo común: Mint por 1000 USDT y reciba 1,200 CA, 110 AP y una insignia NFT de nodo común. También avanzarás directamente al rango LEGENDARIO.`,
    infoSpecial: `NFT de nodo especial: Mint por 3000 USDT y recibe 4,000 CA, 450 AP y una insignia NFT de nodo especial. También avanzarás directamente al rango LEGENDARIO.`,
    infoRare: `NFT de nodo raro: Mint por 5000 USDT y recibe 7,000 CA, 1,200 AP y una insignia NFT de nodo raro. También avanzarás directamente al rango LEGENDARIO.`,
    info21: `¡Como miembro de rango Legendario, obtenga recompensas adicionales por cada referencia exitosa! Cuando tus amigos compren un Node NFT, recibirás recompensas proporcionales correspondientes al nivel que compren.`,
    info22: `Cuando su referencia compre un NFT de nodo raro, ¡recibirá 5,000 adicionales en tokens CA acumulados, 280 AP y 560 LRT en recompensas!`,
    info23: `Cuando su referido compre un NFT de nodo especial, ¡recibirá 3,000 adicionales en tokens CA acumulados, 160 AP y 320 LRT en recompensas!`,
    info24: `Cuando su referido compre un NFT de nodo común, ¡recibirá 1,000 adicionales en tokens CA acumulados, 48 AP y 96 LRT en recompensas!`,
    info25: `Los NFT de nodo están diseñados para ayudarlo a avanzar en su búsqueda para construir su red en nuestra vibrante comunidad Web3. ¡Cuanto más amplíe su red dentro de la comunidad LYNK, más beneficios desbloqueará y más ganará!`,
    info26: `Entonces, comience a invitar a sus amigos y disfrute de las ventajas que conlleva ser parte del equipo de Node NFT. ¡Únase a nosotros ahora y comience a ganar más hoy!`,
    minted: 'ACUÑADA',
    soldOut: 'AGOTADO',
  },
  leaderNft: {
    title: 'Programa LEADER NFT Generación 1',
    mintNow: 'Mint Ahora',
    mintLeader: 'MINT LEADER NFT',
    mintLeaderNft: 'MINT LEADER NFT',
    LeaderNftDes1: 'Obtenga una ventaja con un Leader NFT',
    LeaderNftDes2: 'Únase a las filas y deje su huella en la comunidad LYNK',
    info11: `¡Bienvenido al Programa Leader NFT, LYNK fam!`,
    info12: `El programa Leader NFT es nuestro programa principal exclusivamente para los primeros patrocinadores que han demostrado una fuerte iniciativa para hacer crecer la comunidad LYNK. La generación I del programa sirve para reconocer a campeones como usted, con NFT de Keeper únicos que representarán su identidad y estado como líderes de la comunidad Web3 más emocionante.`,
    info13: `Este emocionante programa le ofrece la oportunidad de acuñar un Líder NFT único en uno de los tres niveles: Común, Especial o Raro. Esto es lo que obtendrá en cada nivel:`,
    infoCommon: `NFT de líder común: Mint por 500 USDT y reciba 500 CA, También avanzarás directamente al rango ÉPICO.`,
    infoSpecial: `NFT de líder especial: Mint por 1000 USDT y recibe 1,000 CA, También avanzarás directamente al rango MAESTRO.`,
    infoRare: `NFT de líder raro: Mint por 3000 USDT y recibe 3,000 CA, También avanzarás directamente al rango LEGENDARIO.`,
    info21: `¡Como miembro de rango Legendario, obtenga recompensas adicionales por cada referencia exitosa! Cuando tus amigos compren un Leader NFT, recibirás recompensas proporcionales correspondientes al nivel que compren.`,
    info22: `Cuando su referencia compre un NFT de líder raro, ¡recibirá 3,000 adicionales en tokens CA acumulados, 120 AP y 240 LRT en recompensas!`,
    info23: `Cuando su referido compre un NFT de líder especial, ¡recibirá 1,000 adicionales en tokens CA acumulados, 40 AP y 80 LRT en recompensas!`,
    info24: `Cuando su referido compre un NFT de líder común, ¡recibirá 500 adicionales en tokens CA acumulados, 20 AP y 40 LRT en recompensas!`,
    info25: `Los NFT de líder están diseñados para ayudarlo a avanzar en su búsqueda para construir su red en nuestra vibrante comunidad Web3. ¡Cuanto más amplíe su red dentro de la comunidad LYNK, más beneficios desbloqueará y más ganará!`,
    info26: `Entonces, comience a invitar a sus amigos y disfrute de las ventajas que conlleva ser parte del equipo de Leader NFT. ¡Únase a nosotros ahora y comience a ganar más hoy!`,
    minted: 'ACUÑADA',
    soldOut: 'AGOTADO',
  },
  marketPlace: {
    title: 'MARKETPLACE',
    totalVolume: 'Volumen Total',
    floorPrice: 'Precio mínimo',
    higherPrice: 'Precio superior',
    owner: 'Propietario',
    tradingFee: 'Tarifa de negociación',
    faction: 'FACCIÓN',
    type0: 'Buscador',
    type1: 'Espíritus',
    type2: 'Exos',
    type3: 'Marciales',
    type4: 'Vacíos',
    gender: 'GÉNERO',
    boy: 'Masculino',
    girl: 'Female',
    level: 'NIVEL',
    level0: 'Nivel 2-3',
    level1: 'Nivel 4-6',
    level2: 'Nivel 7-9',
    level3: 'Nivel 10-12',
    level4: 'Nivel 13',
    priceDesc: 'Precio: de mayor a menor',
    priceAsc: 'Precio: de bajo a alto',
    priceHint: {
      empty: 'Por favor, introduzca el rango de precios',
      numberError: 'El precio máximo no puede ser inferior al precio mínimo',
    },
    priceUsdt: 'PRECIO USDT',
    to: 'PARA',
    all: 'TODOS',
    buy: 'Buy',
    rare: 'RARO',
    price: 'Precio',
    balance: 'Balance',
  },
};
