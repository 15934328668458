import { AbstractConnector } from '@web3-react/abstract-connector';
import React from 'react';
import { Box } from '@mui/material';
import Button from '@mui/material/Button';
// import { ReactComponent as CrossCircle } from 'assets/componentsIcon/statusIcon/error_icon.svg'
// import { OutlinedCard } from 'components/Card'
import CircularProgress from '@mui/material/CircularProgress';
import Chip from '@mui/material/Chip';
import { useI18n } from 'react-simple-i18n';
import Typography from '@mui/material/Typography';
import { ReactComponent as Error } from 'assets/svg/error.svg';

export default function PendingView({
  connector,
  error = false,
  setPendingError,
  tryActivation,
  children,
}: {
  children: React.ReactNode;
  connector?: AbstractConnector;
  error?: boolean;
  setPendingError: (error: boolean) => void;
  tryActivation: (connector: AbstractConnector) => void;
}): JSX.Element {
  const { t } = useI18n();
  return (
    <Box display="grid" width="100%" gap="20px" justifyItems="center">
      {error ? (
        <Box display="grid" justifyItems="center" gap="16px" width="100%">
          <Error />
          <Typography sx={{ fontWeight: 500 }}>Error connecting. Please try again</Typography>
        </Box>
      ) : (
        <Chip
          sx={(theme) => ({
            width: theme.spacing(25),
            borderRadius: '10px',
            borderWidth: 2,
            borderColor: '#0A1A32',
            '& .MuiChip-label': {
              color: '#0A1A32',
              fontSize: 13,
              fontWeight: 700,
            },
          })}
          icon={<CircularProgress size={16} />}
          label={t('global.initializing')}
          variant="outlined"
        />
      )}

      {error && (
        <Box display="flex" justifyContent="center" gap="10px" width="100%">
          {children}
          {error && (
            <Button
              onClick={() => {
                setPendingError(false);
                connector && tryActivation(connector);
              }}
              sx={(theme) => ({ height: 29, [theme.breakpoints.down('sm')]: { zoom: 0.8 } })}
              variant="contained"
            >
              {t('global.tryAgain')}
            </Button>
          )}
        </Box>
      )}
    </Box>
  );
}
