import { useStakingContract, useUserContract } from 'hooks/useContract';
import { useCallback } from 'react';
import { calculateGasMargin } from 'utils';
import { TransactionResponse } from '@ethersproject/providers';
import { useActiveWeb3React } from 'hooks';
import { useTransactionAdder } from 'hooks/useTransaction';
import { useSingleCallResult } from 'hooks/useRequest';
import { CurrencyAmount } from 'constants/token';

export function useStaking() {
  const { account } = useActiveWeb3React();
  const contract = useStakingContract();
  const addTransaction = useTransactionAdder();

  const stake = useCallback(
    async (tokenId: string) => {
      if (!account) throw new Error('none account');
      if (!contract) throw new Error('none contract');
      const args = [tokenId];
      const method = 'stake';
      console.log(contract.estimateGas[method], 'error111111');
      return contract.estimateGas[method](...args, { from: account })
        .then((estimatedGasLimit) => {
          return contract[method](...args, {
            gasLimit: calculateGasMargin(estimatedGasLimit),
            // gasLimit: '3500000',
            from: account,
          })
            .then((response: TransactionResponse) => {
              addTransaction(response, {
                summary: '$%nfts.stake$%',
                claim: { recipient: `${account}_staking_${tokenId}` },
              });
              return response.hash;
            })
            .catch((error: string | undefined) => {
              console.log(error, 'error111111');
              throw new Error(error);
            });
        })
        .catch((error) => {
          console.log(error, 'error111111');
          throw new Error(error);
        });
    },
    [account, addTransaction, contract]
  );

  const withdraw = useCallback(
    async (tokenId: string) => {
      if (!account) throw new Error('none account');
      if (!contract) throw new Error('none contract');
      const args = [tokenId];
      const method = 'unstake';
      return contract.estimateGas[method](...args, { from: account }).then((estimatedGasLimit) => {
        return contract[method](...args, {
          gasLimit: calculateGasMargin(estimatedGasLimit),
          // gasLimit: '3500000',
          from: account,
        }).then((response: TransactionResponse) => {
          addTransaction(response, {
            summary: '$%nftDetail.unstake$%',
            claim: { recipient: `${account}_withdraw_${tokenId}` },
          });
          return response.hash;
        });
      });
    },
    [account, addTransaction, contract]
  );

  return { stake, withdraw };
}

export function useReward(tokenId: string) {
  const { account } = useActiveWeb3React();
  const contract = useUserContract();
  const info = useSingleCallResult(tokenId ? contract : null, 'stakeNFTs', [tokenId]);
  const reward = useSingleCallResult(
    tokenId ? contract : null,
    'calcAchievementReward',
    account ? [account, tokenId] : [undefined]
  );
  return {
    duration: info?.result ? info.result.stakedDuration : undefined,
    lastUpdateTime: info?.result ? info.result.lastUpdateTime : undefined,
    reward: reward?.result ? CurrencyAmount.ether(reward?.result?.[0]) : undefined,
  };
}

export function useClaim() {
  const { account } = useActiveWeb3React();
  const contract = useUserContract();
  const addTransaction = useTransactionAdder();

  const claim = useCallback(
    async (tokenId: string) => {
      if (!account) throw new Error('none account');
      if (!contract) throw new Error('none contract');
      const args = [tokenId];
      const method = 'claimAchievementReward';
      return contract.estimateGas[method](...args, { from: account }).then((estimatedGasLimit) => {
        return contract[method](...args, {
          gasLimit: calculateGasMargin(estimatedGasLimit),
          // gasLimit: '3500000',
          from: account,
        }).then((response: TransactionResponse) => {
          addTransaction(response, {
            summary: '$%stake.claim$%',
            claim: { recipient: `${account}_claim_${tokenId}` },
          });
          return response.hash;
        });
      });
    },
    [account, addTransaction, contract]
  );

  return { claim };
}

export function useLYNKClaim() {
  const { account } = useActiveWeb3React();
  const useContract = useUserContract();
  const stakingContract = useStakingContract();
  const addTransaction = useTransactionAdder();

  const earnedLYNK = useSingleCallResult(useContract, 'userInfoOf', [account ?? undefined])?.result?.stakeRev;
  const claimableLYNK = useSingleCallResult(stakingContract, 'claimableOf', [account ?? undefined])?.result?.[0];

  const claim = useCallback(async () => {
    if (!account) throw new Error('none account');
    if (!stakingContract) throw new Error('none contract');
    const method = 'claimReward';
    return stakingContract.estimateGas[method]({ from: account }).then((estimatedGasLimit) => {
      return stakingContract[method]({
        gasLimit: calculateGasMargin(estimatedGasLimit),
        // gasLimit: '3500000',
        from: account,
      }).then((response: TransactionResponse) => {
        addTransaction(response, {
          summary: '$%stake.claim$%',
          claim: { recipient: `${account}_claim_lynk` },
        });
        return response.hash;
      });
    });
  }, [account, addTransaction, stakingContract]);

  return {
    claim,
    earnedLYNK: earnedLYNK ? CurrencyAmount.ether(earnedLYNK.toString()) : undefined,
    claimableLYNK: claimableLYNK ? CurrencyAmount.ether(claimableLYNK.toString()) : undefined,
  };
}
