import * as React from 'react';
import Tooltip, { TooltipProps } from '@mui/material/Tooltip';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import useBreakpoint from 'hooks/useBreakpoint';

export default function BaseTooltip(props: TooltipProps): JSX.Element {
  const { children } = props;
  const isMobile = useBreakpoint();
  const [open, setOpen] = React.useState(false);

  return isMobile ? (
    <ClickAwayListener onClickAway={() => setOpen(false)}>
      <Tooltip
        PopperProps={{
          disablePortal: true,
        }}
        onClose={() => setOpen(false)}
        open={open}
        disableFocusListener
        disableHoverListener
        disableTouchListener
        {...props}
      >
        {React.cloneElement(props.children, { onClick: () => setOpen(true) })}
      </Tooltip>
    </ClickAwayListener>
  ) : (
    <Tooltip
      sx={(theme) => ({
        [theme.breakpoints.between('sm', 'xl')]: {
          zoom: 1.25,
        },
      })}
      {...props}
    >
      {children}
    </Tooltip>
  );
}
