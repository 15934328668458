import { useActiveWeb3React } from './index';
import { useSwapContract } from './useContract';
import { useTransactionAdder } from 'hooks/useTransaction';
import { useCallback } from 'react';
import { calculateGasMargin } from '../utils';
import { TransactionResponse } from '@ethersproject/providers';
import { CurrencyAmount, TokenAmount } from '../constants/token';
import { useSingleCallResult } from 'hooks/useRequest';
// import JSBI from 'jsbi'

export function useSwap() {
  const { account } = useActiveWeb3React();
  const contract = useSwapContract();
  const addTransaction = useTransactionAdder();

  // const price = useSingleCallResult(DB, 'lrtPriceInLYNK')?.result?.[0]
  const price = useSingleCallResult(contract, 'getSwapOut', ['1000000000000000000'])?.result?.[0];
  // const price = swapOut && JSBI.divide(JSBI.BigInt(10 ** 37), JSBI.BigInt(swapOut))
  // console.log(swapOut?.toString())

  const swap = useCallback(
    async (amount: TokenAmount) => {
      if (!account) throw new Error('none account');
      if (!contract) throw new Error('none contract');
      console.log('price', price);
      const args = [amount.raw.toString()];
      const method = 'swap';
      return contract.estimateGas[method](...args, { from: account }).then((estimatedGasLimit) => {
        return contract[method](...args, {
          gasLimit: calculateGasMargin(estimatedGasLimit),
          // gasLimit: '3500000',
          from: account,
        }).then((response: TransactionResponse) => {
          addTransaction(response, {
            summary: `$%global.swap$% ${
              price ? amount.multiply(CurrencyAmount.ether(price)).toFixed(2, { groupSeparator: ',' }) : ''
            } LYNK`,
          });
          return response.hash;
        });
      });
    },
    [account, addTransaction, contract, price]
  );

  return { swap, price: price ? CurrencyAmount.ether(price) : undefined };
}
