import React, { useState, useCallback } from 'react';
import { styled, Typography, Stack, Box, InputBase, Paper } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { useI18n } from 'react-simple-i18n';
import { useNavigate, createSearchParams } from 'react-router-dom';
import { useActiveWeb3React } from 'hooks';
import { useUserHasSubmitted } from 'hooks/useTransaction';
import NftCard, { DefaultCard } from 'components/Card';
import useAsyncMemo from 'hooks/useAsyncMemo';
import { getAccountNFT, NFT } from 'utils/fetch/graph';
import { useBlockNumber } from 'hooks/useStoreState';
import _ from 'lodash';
import { useAppDispatch } from 'hooks/redux';
import { ApprovalState } from 'hooks/useApproveCallback';
import { useERC721ApproveAllCallback } from 'hooks/useERC721ApproveAllCallback';
import {
  showTransactionConfirmModal,
  showTransactionPendingModal,
  showTransactionErrorModal,
} from 'store/features/componentSlice';
import { useMarket } from 'hooks/useMarket';
import JSBI from 'jsbi';
import { useStaking } from 'hooks/useStaking';
import { showTipModal } from 'store/features/componentSlice';
import MakeOrderModal from 'components/MakeOrderModal';
import { useSwap } from 'hooks/useSwap';
import ClaimModal from 'components/ClaimModal';
import { useAppSelector } from 'hooks/redux';
import { getNftMintPrice } from 'utils';
import { LYNKNFT_ADDRESS, STAKING_ADDRESS } from 'constants/index';
import moment from 'moment';
import { useLeaderMinted } from 'hooks/useNFT';

const NftsContentBox = styled('div')(({ theme }) => ({
  gridArea: 'nftsB',
  display: 'grid',
  paddingTop: theme.spacing(5),
  gridTemplateRows: `${theme.spacing(8)} ${theme.spacing(8)} 1fr ${theme.spacing(3)}`,
  gridTemplateColumns: `1fr ${theme.spacing(31)}`,
  gridTemplateAreas: `
    'nftsList nftsSearch'
    'nftsList nftsNumber'
    'nftsList nftsNumberno'
    'nftsNumbernone nftsNumbernone'
  `,
  overflow: 'hidden',
  [theme.breakpoints.down('sm')]: {
    paddingTop: theme.spacing(0),
    gridTemplateRows: `${theme.spacing(4)} ${theme.spacing(8)} ${theme.spacing(8)} 1fr ${theme.spacing(2)}`,
    gridTemplateColumns: `1fr`,
    overflow: 'unset',
    gridTemplateAreas: `
      'nftsTitle'
      'nftsSearch'
      'nftsNumber'
      'nftsList'
      'nftsNumbernone'
    `,
  },
}));

const NftsTitle = styled('div')(({ theme }) => ({
  gridArea: 'nftsTitle',
  [theme.breakpoints.up('sm')]: {
    display: 'none',
  },
}));

const NftsList = styled('div')(({ theme }) => ({
  gridArea: 'nftsList',
  display: 'flex',
  alignContent: 'flex-start',
  gap: theme.spacing(4),
  flexWrap: 'wrap',
  [theme.breakpoints.down('sm')]: {
    gap: theme.spacing(1),
  },
  [theme.breakpoints.up('sm')]: {
    overflow: 'overlay',
  },
}));

const NftsSearch = styled('div')(({ theme }) => ({
  gridArea: 'nftsSearch',
  display: 'flex',
  justifyContent: 'flex-end',
  [theme.breakpoints.down('sm')]: {
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

const NftsNumber = styled('div')(({ theme }) => ({
  gridArea: 'nftsNumber',
  display: 'flex',
  justifyContent: 'flex-end',
  '& .totalStack': {
    width: '90%',
  },
  [theme.breakpoints.down('sm')]: {
    justifyContent: 'center',
    '& .totalStack': {
      width: '100%',
    },
  },
}));

const TypographyDes = styled(Typography)(({ theme }) => ({
  fontSize: 32,
  fontWeight: 700,
  [theme.breakpoints.down('sm')]: {
    fontSize: 20,
  },
}));

const SearchInput = styled(InputBase)(({ theme }) => ({
  border: '2px solid #000000',
  borderRadius: 7,
  height: 30,
  width: '90%',
  padding: theme.spacing(0, 0.5),
  [theme.breakpoints.down('sm')]: {
    width: '100%',
  },
}));

const TypographyTotal = styled(Typography)(({ theme }) => ({
  fontSize: 32,
  fontWeight: 700,
  color: '#6BB9DD',
  [theme.breakpoints.down('sm')]: {
    fontSize: 25,
  },
}));

const TypographyTotalDes = styled(Typography)(({ theme }) => ({
  fontSize: 13,
  fontWeight: 500,
  color: '#000000',
  [theme.breakpoints.down('sm')]: {
    fontSize: 12,
  },
}));

const PaperBox = styled(Paper)(({ theme }) => ({
  width: theme.spacing(13),
  height: theme.spacing(7.5),
  border: '2px solid #000000',
  borderRadius: 7,
  display: 'flex',
  alignItems: 'center',
  [theme.breakpoints.down('sm')]: {
    width: '45%',
    height: theme.spacing(5),
    '& .totalDes': {
      display: 'flex',
      gap: 4,
    },
  },
}));

const NftsContent = () => {
  const { t, i18n } = useI18n();
  const dispatch = useAppDispatch();
  const { account } = useActiveWeb3React();
  const blockNumber = useBlockNumber();
  const { cancel } = useMarket();
  const [tokenId, setTokenId] = useState('');
  const { stake, withdraw } = useStaking();
  const { submitted: staking } = useUserHasSubmitted(`${account}_staking_${tokenId}`);
  const { submitted: selling } = useUserHasSubmitted(`${account}_selling_${tokenId}`);
  const { submitted: withdrawing } = useUserHasSubmitted(`${account}_withdraw_${tokenId}`);
  const { submitted: canceling } = useUserHasSubmitted(`${account}_cancel_${tokenId}`);
  const [estimatePrice, setEstimatePrice] = useState('--');
  const [nftName, setNftName] = useState('');
  const [openMakeModal, setOpenMakeModal] = useState(false);
  const [openClaimModal, setOpenClaimModal] = useState(false);
  const { price } = useSwap();
  const { inviter } = useAppSelector((state) => state.application);
  const [approvalState, approveCallback] = useERC721ApproveAllCallback(LYNKNFT_ADDRESS, STAKING_ADDRESS);
  const navigate = useNavigate();
  const isLeaderNft = useLeaderMinted(account || '');

  const getMyNFT = useCallback(async () => {
    if (!account) return [];
    return await getAccountNFT(account ?? '', nftName);
  }, [account, nftName]);

  const getAllMyNFT = useCallback(async () => {
    if (!account) return [];
    return await getAccountNFT(account ?? '');
  }, [account]);

  const NFTList: NFT[] = useAsyncMemo(getMyNFT, [], [blockNumber, nftName]);

  const allNFTList: NFT[] = useAsyncMemo(getAllMyNFT, [], [blockNumber]);

  const cancelCallback = useCallback(
    async (tokenId: string) => {
      dispatch(showTransactionPendingModal());
      cancel(tokenId || '')
        .then(() => {
          dispatch(showTransactionConfirmModal());
        })
        .catch((err: any) => {
          dispatch(
            showTransactionErrorModal(
              err.error && err.error.message
                ? err.error.message
                : err?.data?.message
                ? err?.data?.message
                : err.message ?? t('global.networkError')
            )
          );
        });
    },
    [cancel, dispatch, t]
  );

  const withdrawCallback = useCallback(
    async (tokenId: string) => {
      dispatch(showTransactionPendingModal());
      withdraw(tokenId || '')
        .then(() => {
          dispatch(showTransactionConfirmModal());
        })
        .catch((err: any) => {
          dispatch(
            showTransactionErrorModal(
              err.error && err.error.message
                ? err.error.message
                : err?.data?.message
                ? err?.data?.message
                : err.message ?? t('global.networkError')
            )
          );
          console.error(err);
        });
    },
    [dispatch, withdraw, t]
  );

  const stakeCallback = useCallback(
    async (tokenId: string) => {
      dispatch(showTransactionPendingModal());
      stake(tokenId || '')
        .then(() => {
          dispatch(showTransactionConfirmModal());
        })
        .catch((err: any) => {
          dispatch(
            showTransactionErrorModal(
              err.error && err.error.message
                ? err.error.message
                : err?.data?.message
                ? err?.data?.message
                : err.message ?? t('global.networkError')
            )
          );
          console.error(err);
        });
    },
    [dispatch, stake, t]
  );

  const unstakeAndSell = (nft: NFT, isNodeNft: string) => {
    setTokenId(nft.id);
    if (nft.isStaking) {
      withdrawCallback(nft.id);
    } else {
      if (!nft.level || JSBI.lessThan(JSBI.BigInt(nft.level), JSBI.BigInt(2))) {
        dispatch(showTipModal(t('nftDetail.mustHigherThanLevel2')));
        return;
      }
      if (
        nft.charisma.toString() &&
        nft.vitality.toString() &&
        nft.intellect.toString() &&
        nft.dexterity.toString() &&
        price &&
        nft.id
      ) {
        if (isNodeNft && isLeaderNft) {
          const dayDiff = moment().diff(moment(Number(nft.createdTime) * 1000), 'days');
          const dayDiffDiscount = dayDiff < 16 ? 0.8 : dayDiff < 31 ? 0.9 : 1;
          if (isNodeNft === '0' && isLeaderNft === '0') {
            const nftPrice = getNftMintPrice(nft.id);
            if (nftPrice?.toString()) {
              const etPrice = Number(
                Number(
                  (Number(nft.charisma) +
                    nftPrice +
                    (Number(nft.vitality) + Number(nft.intellect) + Number(nft.dexterity)) *
                      30 *
                      (1 / Number(price?.toSignificant()))) *
                    dayDiffDiscount
                ).toFixed(4)
              ).toString();
              setEstimatePrice(etPrice);
            } else {
              setEstimatePrice('--');
            }
          } else {
            const nodePrice = isNodeNft === '1' ? 200 : isNodeNft === '2' ? 1000 : isNodeNft === '3' ? 2000 : 0;
            setEstimatePrice(((Number(nft.charisma) - nodePrice) * dayDiffDiscount).toFixed(4).toString());
          }
        } else {
          setEstimatePrice('--');
        }
      } else {
        setEstimatePrice('--');
      }
      setOpenMakeModal(true);
    }
  };

  const stakeAndClaimAp = (nft: NFT) => {
    setTokenId(nft.id);
    if (nft.isStaking) {
      // if (!reward || reward.equalTo('0')) {
      //   dispatch(showTipModal(t('nftDetail.noReward')));
      //   return;
      // }
      setOpenClaimModal(true);
    } else {
      approvalState === ApprovalState.NOT_APPROVED ? approveCallback() : stakeCallback(nft.id);
    }
  };

  return (
    <NftsContentBox>
      <MakeOrderModal
        tokenId={tokenId || ''}
        estimatePrice={estimatePrice}
        open={openMakeModal}
        handleClose={() => setOpenMakeModal(false)}
      />
      <ClaimModal open={openClaimModal} tokenId={tokenId || ''} handleClose={() => setOpenClaimModal(false)} />
      <NftsTitle>
        <TypographyDes color={'#000000'}>{t('nfts.title')}</TypographyDes>
      </NftsTitle>
      <NftsList>
        <DefaultCard />
        {NFTList.map((nft) => {
          return (
            <NftCard
              goDetail={() =>
                navigate(
                  {
                    pathname: `/profile/nfts/${nft.id}`,
                    search:
                      inviter &&
                      createSearchParams({
                        referrer: inviter,
                      }).toString(),
                  },
                  {
                    state: {
                      name: nft?.name,
                      ownNftNum: allNFTList?.length || 0,
                      stakeNftNum: _.filter(allNFTList, (o: NFT) => o.isStaking)?.length || 0,
                    },
                  }
                )
              }
              leftText={t(nft.isStaking ? 'nftDetail.unStake' : selling ? 'nftDetail.onSale' : 'nftDetail.sell')}
              leftButton={(event, isNodeNft) => {
                event.stopPropagation();
                unstakeAndSell(nft, isNodeNft);
              }}
              leftLoading={withdrawing || selling}
              rightText={t(nft.isStaking ? 'nftDetail.claimAp' : 'navMenu.stake')}
              rightButton={(event) => {
                event.stopPropagation();
                stakeAndClaimAp(nft);
              }}
              rightLoading={approvalState === ApprovalState.PENDING || staking}
              onCancel={(event) => {
                event.stopPropagation();
                cancelCallback(nft.id);
                setTokenId(nft.id);
              }}
              cancelLoading={canceling}
              cancelText={t(canceling ? 'nftDetail.unListing' : 'nfts.cancel')}
              item={nft}
              key={nft.id}
            />
          );
        })}
      </NftsList>
      <NftsSearch>
        <SearchInput
          onChange={_.debounce((event) => {
            setNftName(event.target.value);
          }, 500)}
          startAdornment={<SearchIcon fontSize="small" />}
        />
      </NftsSearch>
      <NftsNumber>
        <Stack className="totalStack" direction="row" justifyContent="space-between">
          <PaperBox>
            <Stack direction="row" padding={1} alignItems="center" spacing={1}>
              <TypographyTotal variant="body1">{allNFTList?.length || 0}</TypographyTotal>
              <Box className="totalDes">
                <TypographyTotalDes variant="body1">{t('nftDetail.nfts')}</TypographyTotalDes>
                <TypographyTotalDes variant="body1" sx={{ zoom: i18n.getLang() === 'ruRU' ? 0.8 : 1 }}>
                  {t('nfts.owned')}
                </TypographyTotalDes>
              </Box>
            </Stack>
          </PaperBox>
          <PaperBox>
            <Stack direction="row" padding={1} alignItems="center" spacing={1}>
              <TypographyTotal variant="body1">
                {_.filter(allNFTList, (o: NFT) => o.isStaking)?.length || 0}
              </TypographyTotal>
              <Box className="totalDes">
                <TypographyTotalDes variant="body1">{t('nftDetail.nfts')}</TypographyTotalDes>
                <TypographyTotalDes variant="body1" sx={{ zoom: i18n.getLang() === 'ruRU' ? 0.8 : 1 }}>
                  {t('nfts.staked')}
                </TypographyTotalDes>
              </Box>
            </Stack>
          </PaperBox>
        </Stack>
      </NftsNumber>
    </NftsContentBox>
  );
};

export default NftsContent;
