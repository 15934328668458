import React from 'react';
import { useI18n } from 'react-simple-i18n';
import { useAppSelector, useAppDispatch } from 'hooks/redux';
import { closeTipModal } from 'store/features/componentSlice';
import BaseModal from 'components/BaseModal';
import { Stack, Typography } from '@mui/material';

export default function TipModal(): JSX.Element {
  const { t } = useI18n();
  const dispatch = useAppDispatch();
  const { tipValue, tipModalOpen } = useAppSelector((state) => state.component);

  return (
    <BaseModal
      open={tipModalOpen}
      contentWidth={434}
      title={t('global.tip')}
      handleClose={() => dispatch(closeTipModal())}
    >
      <Stack
        sx={(theme) => ({
          height: theme.spacing(14),
          justifyContent: 'space-between',
          [theme.breakpoints.down('sm')]: {
            height: theme.spacing(22.5),
            justifyContent: 'center',
            gap: theme.spacing(4),
          },
        })}
        alignItems="center"
      >
        <Typography
          sx={(theme) => ({
            lineHeight: theme.spacing(9),
            textAlign: 'center',
            [theme.breakpoints.down('sm')]: {
              lineHeight: 'unset',
              width: theme.spacing(20),
            },
          })}
        >
          {tipValue}
        </Typography>
      </Stack>
    </BaseModal>
  );
}
