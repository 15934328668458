import React, { useState } from 'react';
import { styled, Typography, Button, Stack } from '@mui/material';
import { useI18n } from 'react-simple-i18n';
import { useValidAddress } from 'hooks/useUser';
import { useActiveWeb3React } from 'hooks';
import { useUserHasSubmitted } from 'hooks/useTransaction';
import { useAppDispatch } from 'hooks/redux';
import { showRegisterModal } from 'store/features/componentSlice';
import KeeperMintModal from 'components/KeeperMintModal';
import { showWalletModal } from 'store/features/componentSlice';
import { useNodeMinted, useNftMintEnable } from 'hooks/useNFT';

const NodeNftContentBox = styled('div')(({ theme }) => ({
  gridArea: 'nodeNftB',
  display: 'grid',
  paddingTop: theme.spacing(5),
  gridTemplateRows: `0px auto ${theme.spacing(8)} 1fr`,
  gridTemplateColumns: `1fr ${theme.spacing(6)} ${theme.spacing(65)}`,
  [theme.breakpoints.down('sm')]: {
    paddingTop: theme.spacing(0),
    gridTemplateRows: `${theme.spacing(4)} auto ${theme.spacing(10)} 1fr`,
    gridTemplateColumns: `1fr 0px 0px`,
  },
  gridTemplateAreas: `
    'nodeNftTitle nodeNftTitle nodeNftTitle'
    'nodeNftDes nodeNftDes nodeNftDes'
    'nodeNftMint nodeNftBg nodeNftBg'
    'nodeNftInfo nodeNftBg nodeNftBg'
  `,
  [theme.breakpoints.up('sm')]: {
    overflow: 'hidden',
  },
}));

const NodeNftTitle = styled('div')(({ theme }) => ({
  gridArea: 'nodeNftTitle',
  [theme.breakpoints.up('sm')]: {
    display: 'none',
  },
}));

const NodeNftDes = styled('div')({
  gridArea: 'nodeNftDes',
});

const NodeNftMint = styled('div')({
  gridArea: 'nodeNftMint',
});

const NodeNftInfo = styled('div')(({ theme }) => ({
  gridArea: 'nodeNftInfo',
  [theme.breakpoints.up('sm')]: {
    overflow: 'overlay',
  },
}));

const TypographyDes = styled(Typography)(({ theme }) => ({
  fontSize: 32,
  fontWeight: 700,
  [theme.breakpoints.down('sm')]: {
    fontSize: 20,
  },
}));

const TypographyInfo = styled(Typography)(({ theme }) => ({
  lineHeight: theme.spacing(2.5),
  whiteSpace: 'pre-wrap',
  '& ul': {
    paddingInlineStart: theme.spacing(2),
    marginBlockStart: 0,
    marginBlockEnd: 0,
  },
  [theme.breakpoints.down('sm')]: {
    lineHeight: theme.spacing(3),
  },
}));

const MintButton = styled(Button)(({ theme, disabled }) => ({
  fontSize: 13,
  fontWeight: 700,
  width: disabled ? 200 : 160,
  background: disabled ? 'linear-gradient(90deg, #C4C4C4 -4.11%, #666666 113.36%)' : '',
  height: theme.spacing(4.1),
  marginTop: theme.spacing(2),
}));

const NodeNftContent = () => {
  const { t } = useI18n();
  const { account } = useActiveWeb3React();
  const { submitted: registering } = useUserHasSubmitted(`${account}_register` ?? undefined);
  const registered = useValidAddress(account ?? undefined);
  const dispatch = useAppDispatch();
  const [openNodeNftMintModal, setOpenNodeNftMintModal] = useState(false);
  const nodeMinted = useNodeMinted(account || '');
  const nftMintEnable = useNftMintEnable();

  return (
    <NodeNftContentBox>
      {openNodeNftMintModal ? (
        <KeeperMintModal
          startId={0}
          isActivitingMint={false}
          endId={0}
          mintPrice={0}
          open={openNodeNftMintModal}
          isNodeNftMint={true}
          handleClose={() => setOpenNodeNftMintModal(false)}
        />
      ) : null}
      <NodeNftTitle>
        <TypographyDes color={'#000000'}>{t('nodeNft.title')}</TypographyDes>
      </NodeNftTitle>
      <NodeNftDes>
        <TypographyDes color={'#121425'}>{t('nodeNft.nodeNftDes1')}</TypographyDes>
        <TypographyDes color={'#72BCDE'}>{t('nodeNft.nodeNftDes2')}</TypographyDes>
      </NodeNftDes>
      <NodeNftMint>
        <MintButton
          onClick={() => {
            if (!account) {
              dispatch(showWalletModal());
              return;
            }
            if (!registered) {
              dispatch(showRegisterModal());
            } else {
              nodeMinted?.toString() === '0' && setOpenNodeNftMintModal(true);
            }
          }}
          variant="contained"
          // disabled={registering || (nodeMinted && nodeMinted?.toString() !== '0') || nftMintEnable === false}
          disabled={true}
        >
          {/* {nftMintEnable === false
            ? t('nodeNft.soldOut')
            : nodeMinted && nodeMinted?.toString() !== '0'
            ? t('nodeNft.minted')
            : t('nodeNft.mintNow')} */}
          {t('nodeNft.soldOut')}
        </MintButton>
      </NodeNftMint>
      <NodeNftInfo>
        <Stack spacing={1.8}>
          <TypographyInfo variant="body2">{t('nodeNft.info11')}</TypographyInfo>
          <TypographyInfo variant="body2">{t('nodeNft.info12')}</TypographyInfo>
          <TypographyInfo variant="body2">
            {t('nodeNft.info13')}
            <ul>
              <li>{t('nodeNft.infoCommon')}</li>
              <li>{t('nodeNft.infoSpecial')}</li>
              <li>{t('nodeNft.infoRare')}</li>
            </ul>
          </TypographyInfo>
          <TypographyInfo variant="body2">{t('nodeNft.info21')}</TypographyInfo>
          <TypographyInfo variant="body2">
            <ul>
              <li>{t('nodeNft.info22')}</li>
              <li>{t('nodeNft.info23')}</li>
              <li>{t('nodeNft.info24')}</li>
            </ul>
          </TypographyInfo>
          <TypographyInfo variant="body2">{t('nodeNft.info25')}</TypographyInfo>
          <TypographyInfo variant="body2">{t('nodeNft.info26')}</TypographyInfo>
        </Stack>
      </NodeNftInfo>
      {/* <NodeNftTotal>
        <Stack spacing={0}>
          <TypographyTotal variant="body1">
            {t('finders.totalAmount')}
            {totalNum ? totalNum.toString() : '--'}
          </TypographyTotal>
          <TypographyTotal variant="body1">
            {t('finders.totalBalance')}
            {totalNum ? remainNum.toString() : '--'}
          </TypographyTotal>
        </Stack>
      </NodeNftTotal>
      <NodeNftTime>
        <Stack direction={'row'} spacing={1}>
          {[days, hours, minutes, seconds].map((item, index) => {
            return index === 0 && item === 0 ? null : <TimeBox key={index}>{item}</TimeBox>;
          })}
        </Stack>
      </NodeNftTime> */}
    </NodeNftContentBox>
  );
};

export default NodeNftContent;
