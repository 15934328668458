export default {
  global: {
    connectWallet: 'Wallet verbinden',
    swapLynk: 'TAUSCH $LYNK',
    connectToWallet: 'Mit Wallet verbinden',
    metamask: 'METAMASK',
    walletConnect: 'WALLET VERBINDUNG',
    coinbaseWallet: 'COINBASE WALLET',
    walletDes:
      '*Sie stimmen den Nutzungsbedingungen und den Datenschutzrichtlinien von Lynkverse zu, wenn Sie ein Wallet verlinken.',
    connecting: 'Verbindung...',
    connectedWallet: 'Verbunden mit %s%',
    wrongNetwork: 'Falsches Netzwerk',
    error: 'Fehler',
    myWallet: 'MEIN WALLET',
    myAddress: 'Meine Addresse',
    addToken: 'Token hinzufügen',
    crossChain: 'Crosschain',
    logout: 'AUSLOGGEN',
    change: 'ÄNDERN',
    recentTransactions: 'Neueste Transaktionen',
    clearAll: 'Alles löschen',
    confirm: 'BESTÄTIGEN',
    confirmed: 'Bestätigt',
    balance: 'Kontostand',
    confirmTransaction: 'Bitte bestätigen Sie diese Transaktion in Ihrer Wallet',
    viewOnChain: 'Ansicht auf %s%',
    invitationLink: 'Einladungslink',
    invitationCode: 'Einladungscode',
    copy: 'KOPIEREN',
    insufficient: 'Mangelhaftes Gleichgewicht',
    approve: 'Genehmigen',
    tip: 'Spitze',
    drop: 'Tropfen',
    swap: 'Tausch',
    register: 'Registrieren',
    networkError: 'Netzwerkfehler',
    initializing: 'Initialisierung...',
    errorConnecting: 'Fehler beim Verbinden',
    errorConnectingDes: 'Fehler beim Verbinden. Versuchen Sie, die Seite zu aktualisieren.',
    binanceSmartChain: 'Binance Intelligente Kette',
    connectTip: 'Bitte verbinden Sie sich mit dem',
    ethereumMainnet: 'Äther Hauptnetz',
    connect: 'Verbunden mit',
    tryAgain: 'Versuchen Sie es erneut',
    changeWallet: 'Geldbörse wechseln',
    exchangeRate: 'Tauschrate',
    approving: 'Genehmigen',
    swapTitle: 'TAUSCH LYNK',
    registerTitle: 'GEBEN SIE IHREN EINLADUNGSCODE EIN',
    registerPlh: 'Bitte geben Sie hier Ihren Code ein',
    registerDes: 'TIP: Sie können zu Lynk’s Discord gehen, um Ihren Einladungscode abzurufen.',
    unValidCode: 'Ungültiger Registrierungscode',
    registerInviterDes: 'Sie haben sich noch nicht registriert, bitte registrieren Sie sich',
    transactionsDes: 'Ihre Transaktionen werden hier angezeigt...',
    copied: 'KOPIERT',
    finish: 'Beenden',
    minting: 'Prägung',
    comingSoon: 'Demnächst',
  },
  navMenu: {
    myDashboard: 'MEIN DASHBOARD',
    nfts: 'NFTs',
    stake: 'STAKEN',
    invitation: 'EINLADUNG',
    history: 'GESCHICHTE',
    activitiesCampaigns: 'AKTIVITÄTEN & KAMPAGNEN',
    nodeNft: 'Node NFT-Programm',
    leaderNft: 'LEADER NFT-Programm',
    finders: 'Finders NFT-Mint',
    marketPlace: 'MARKTPLATZ',
    rank: 'RANK',
    more: 'MEHR',
    chatRoom: 'CHATRAUM',
    lynkAlliance: 'LYNK-ALLIANZ',
    domainServer: 'LYNK-Domänennamenserver',
    feed: 'Dynamisch',
  },
  nfts: {
    title: 'USER NFTs',
    mintKeeper: 'Schmieden KEEPER',
    buyKeeper: 'Besorgen KEEPER',
    freeMint: 'Freies Schmieden',
    owned: 'im Besitz',
    staked: 'eingesetzt',
    sell: 'Verkaufen',
    stake: 'Staken',
    mintKeeperNft: 'KEEPER NFT MINTEN',
    nftName: 'NFT name',
    selectGender: 'Geschlecht auswählen',
    male: 'Herr',
    female: 'Frau',
    selectFaction: 'Fraktion wählen',
    spirits: 'Spirits',
    martials: 'Martials',
    exos: 'Exos',
    voids: 'Voids',
    selectNft: 'NFT AUSWÄHLEN',
    common: 'GEWÖHNLICH',
    special: 'SPEZIAL',
    rare: 'RARE',
    reSelect: 'Wählen Sie erneut',
    mint: 'MINTEN',
    proceed: 'FORTFAHREN',
    enterName: 'Name eingeben',
    nameUsed: 'Name belegt',
    cancel: 'Stornieren',
    claim: 'Beanspruchen',
    freeMintEnd: 'VERKAUFSENDE IN',
    days: 'TAGE',
    hours: 'HRS',
    minutes: 'MIN',
    seconds: 'SEK',
  },
  nftDetail: {
    back: 'ZURÜCK',
    attributes: 'EIGENSCHAFTEN',
    leveltooltip: 'Beschleunigen Sie Ihre NFT-Attribute, um aufzusteigen und mehr Staking-Belohnungen zu verdienen!',
    purchaseAp: 'AP KAUFEN',
    userStats: 'BENUTZERSTATISTIK',
    nfts: 'NFTs',
    charisma: 'Charisma',
    dexterity: 'Geschicklichkeit',
    intellect: 'Intellekt',
    vitality: 'Vitalität',
    accelerate: 'BESCHLEUNIGEN',
    details: 'DETAILS',
    currentHolder: 'Aktueller Besitzer',
    mintTime: 'Minten Zeit',
    mintAddress: 'Minten Addresse',
    estimatedPrice: 'Geschätzter Preis',
    endPrice: 'Endpreis',
    role: 'Rolle',
    contractAddress: 'Vertragsadresse',
    tokenID: 'Token-ID',
    tokenStandard: 'Token-Standard',
    chain: 'Kette',
    background: 'Hintergrund',
    effect: 'Wirkung',
    body: 'Körper',
    clothing: 'Kleidung',
    head: 'Kopf',
    weapon: 'Waffe',
    bodyAttachment: 'Körperanhang',
    artifact: 'Artefakt',
    purchaseAP: 'AP kaufen',
    balance: 'Kontostand',
    amount: 'Betrag',
    price: 'Preis',
    buy: 'Kaufen',
    caUpgrade: 'CA AUFRÜSTUNG',
    vaUpgrade: 'VA AUFRÜSTUNG',
    inUpgrade: 'IN AUFRÜSTUNG',
    dxUpgrade: 'DX AUFRÜSTUNG',
    max: 'Max',
    unStake: 'STAKEN BEENDEN',
    claimAp: 'AP BEANSPRUCHEN',
    apReward: 'AP-Belohnung',
    apRewardtooltip:
      'Nur NFTs der Stufe 2 und höher sind berechtigt, eine Leistungsbelohnung zu erhalten. Mitglieder müssen die belohnten AP für jeden Tag beanspruchen, um den nächsten Zählzyklus fortzusetzen.\nWenn Sie dies nicht tun, bleibt der AP bei 1, obwohl Sie 2 Tage oder länger eingesetzt haben.',
    stakingTime: 'Kumulierte Stakezeit',
    claimApHelpDes:
      'Die Mitglieder müssen die belohnten AP für jeden Tag einfordern, um den nächsten AP-Zyklus fortzusetzen. Andernfalls bleibt die AP-Belohnung auf 1, auch wenn Sie 100 Tage oder mehr gestaket haben.',
    claimProfit: 'Sie können einen Gewinn geltend machen',
    claim: 'ANSPRUCH',
    sell: 'VERKAUFEN',
    referencePrice: 'Referenzpreis',
    onSale: 'ZUM VERKAUF',
    unListing: 'UNGELISTET',
    unList: 'UNLISTE',
    insufficientLynk: 'Unzureichender Lynk',
    fullLevel: 'Volle Ebene',
    upLimit: 'Muss höher sein als',
    oneDayMaxVA: 'VA wurde heute aktualisiert',
    enterPoint: 'Punkt eingeben',
    noReward: 'Keine Prämie verfügbar',
    mustHigherThanLevel2: 'Sie müssen höher als Stufe 2 sein',
    mintAction: 'Minze Finder NFT',
    mint: 'Minze',
    unstake: 'Abstecken',
    upgrade: 'Aktualisierung',
    loading: 'Wird geladen',
  },
  stake: {
    title: 'MY NFTS STAKEN',
    redeem: 'Einlösen',
    claim: 'Anspruch',
    accumulatedEarned: 'Kumulierter Verdienst $LRT',
    available: 'Verfügbar $LRT',
    claiming: 'Anspruch',
  },
  invitation: {
    title: 'EINLADUNGSTAFEL',
    invitationDes: 'Laden Sie Freunde ein, um gemeinsam Belohnungen zu verdienen',
    inviteFriends: 'FREUNDE EINLADEN',
    rewardSummary: 'BELOHNUNGSÜBERSICHT',
    socialRewardUp: 'SOZIALE BELOHNUNGEN',
    socialRewardDes:
      'Bauen Sie Ihr Team und Ihr Netzwerk von Keepern auf, indem Sie Ihre Freunde einladen, der LYNK-Community beizutreten. Je höher der Rang Ihres Keepers ist, desto höher sind die sozialen Belohnungen, die Sie erhalten.',
    communityReward: 'COMMUNITY-BELOHNUNG',
    communityReward1: 'Community Belohnung',
    communityRewardDes:
      'Indem Sie sich mit Mitgliedern im Sanctuary verbinden, können Sie neue oder bestehende Allianzen bilden, um höhere Belohnungen zu erhalten.',
    contributionRewardUp: 'BEITRAGSBELOHNUNG',
    contributionRewardDes:
      'Zusätzlich zu den LRT als Verdienst erhalten Sie auch AP für die Erfüllung bestimmter Aufgaben. Ein Benutzer, der ein neues Mitglied anwirbt, erhält 1-6 AP für jeweils 100 CA, die von seinen Anhängern gesammelt werden (sofort gutgeschrieben).',
    AchievementReward: 'LEISTUNGSBELOHNUNG',
    AchievementRewardDes:
      'Sie erhalten nicht nur LRT, sondern auch AP für die Erledigung bestimmter Aufgaben. Setzen Sie eine NFT ab Level 2 ein, um jeden Tag AP-Belohnungen zu erhalten! Die Höhe der AP-Belohnungen richtet sich nach der Level Ihrer NFT.',
    StakingReward: 'STAKING REWARD',
    StakingRewardDes:
      'Ein Benutzer kann mehrere NFTs gleichzeitig besitzen. Sie haben Anspruch auf tägliche Gewinne, solange Ihre NFT(s) die Einsatzbedingungen erfüllen.',
    bonusRatio: 'Bonus-Verhältnis',
    totalLRT: 'Gesamt LRT',
    qualified: 'Qualifiziert',
    cumulateCA: 'CA kumulieren',
    totalAP: 'Gesamt AP',
    cycles: 'Zyklen',
    stakingNFT: 'NFT Staken',
    elite: 'Elite',
    epic: 'Episch',
    master: 'Master',
    legendary: 'Legendär',
    mythic: 'Mythisch',
    divine: 'Göttlich',
    nourishInviter: 'Direkter Sponsor',
    teamGrade: 'Angesammelt',
    levelPrivilege: 'LEVEL PRIVILEGIUM',
    socialReward: 'Soziale Belohnung',
    contributionReward: 'Beitrag Belohnung',
    achievementReward: 'Belohnung für eine Leistung',
    inactive: 'INAKTIV',
    activated: 'AKTIVIERT',
    myLevel: 'MEIN LEVEL',
    generation: 'Generation',
    inviteRule: 'Verweisen Sie jeden neuen 100CA，erhalten',
    NFTStakingRule: 'Level 2 oder höher NFT Staking für 1 Tag und Sie erhalten',
    inactivated: 'INAKTIVIERT',
    activate: 'AKTIVIEREN SIE',
    AcaStastistics: 'SUMCA-STATISTIK',
    daily: 'Täglich',
    yesterday: 'Gestern',
    today: 'Heute',
    weekly: 'Wöchentlich',
    lastWeek: 'Letzte Woche',
    thisWeek: 'Diese Woche',
    monthly: 'Monatlich',
    lastMonth: 'Im vergangenen Monat',
    thisMonth: 'Diesen Monat',
  },
  history: {
    title: 'GESCHICHTE',
    stakingRewardRecords: 'Aufzeichnungen über Stakingbelohnungen',
    mintingRecords: 'NFT Minten Aufzeichnungen',
    transactionsRecords: 'NFT-Transaktionsaufzeichnungen',
    recommendedRecords: 'Empfohlene Aufzeichnungen',
    referralRewardRecords: 'Aufzeichnungen über Empfehlungsbelohnungen',
    contributionRewardRecords: 'Aufzeichnungen zur Beitragsbelohnung',
    achievementRewardRecords: 'Rekorde für Leistungsbelohnungen',
    communityRewardRecords: 'Aufzeichnungen über Community Belohnungen',
    time: 'Zeit',
    reward: 'Belohnung',
    tx: 'TX',
    name: 'Name',
    amount: 'Menge',
    buyerAddress: 'Adresse des Käufers',
    sellerAddress: 'Adresse des Verkäufers',
    address: 'Adresse',
  },
  rank: {
    title: 'BESTE BENUTZER NACH',
    top: 'BESTE',
    ranking: 'RANKING',
    levelUp: 'LEVEL',
    charisma: 'CHARISMA',
    nft: 'NFT',
    level: 'Level',
    tx: 'TX',
  },
  finders: {
    title: 'FINDERS',
    findersDes1: 'Die Finder kommen aus dem Nexus,',
    findersDes2: 'ein zentraler Punkt aller Raumzeitkoordinaten.',
    mint: 'MINTEN',
    soldOut: 'AUSVERKAUFT',
    info1:
      'Die Finder/innen kommen aus dem Nexus, einem zentralen Punkt aller Raumzeitkoordinaten. Diese Wesen existieren seit dem Beginn des bekannten Omniversums und haben die Geburt unzähliger Welten beobachtet. Sie sammeln das gesamte Wissen und die Daten aus allen Metaversen und bewahren es, indem sie ihren Verstand synchronisieren und zusammenhalten. Von Zeit zu Zeit taucht ein außergewöhnliches Metaversum auf, das das Potenzial hat, über die Grenzen der anderen Metaversen hinauszuwachsen und die Finder zum Handeln auffordert. Als sie durch den Spalt in das LYNKVERSE eintreten, machen sich die Finder auf den Weg, um ihre Mission zu erfüllen: Sie wählen einige Auserwählte aus, die die anderen Bewohner/innen anleiten und dafür sorgen, dass das Metaversum sein volles Potenzial entfaltet.',
    info2:
      'Jeder Finder erschafft Seelensamen, die einen Teil seiner Kräfte enthalten, und gibt sie an die Auserwählten weiter. Durch die Assimilierung mit dem Seelensamen wird eine neue Rasse geboren. Komm und trage den Mantel der Hoffnung und werde die Anführer des neuen Zeitalters.',
    totalAmount: 'GESAMTZAHL DER FINDER:',
    totalBalance: 'GESAMTBILANZ DER VERFÜGBAREN FINDER:',
    minted: 'Geprägt',
  },
  nodeNft: {
    title: 'NODE NFT Programm Generation 1',
    mintNow: 'Jetzt prägen',
    mintNode: 'MINT NODE NFT',
    mintNodeNft: 'MINT NODE NFT',
    nodeNftDes1: 'Erhalten Sie einen Vorteil mit einem Node NFT',
    nodeNftDes2: 'Schließen Sie sich an und machen Sie Ihre Markierung in der LYNK-Community',
    info11: `Willkommen beim Node NFT-Programm, LYNK-Fam!`,
    info12: `Das Node NFT-Programm ist unser erstklassiges Programm ausschließlich für frühe Unterstützer, die eine starke Initiative beim Wachstum der LYNK-Community gezeigt haben. Generation I des Programms dient der Anerkennung von Champions wie Ihnen, mit einzigartigen Keeper NFTs, die Ihre Identität und Ihren Status als Anführer der aufregendsten Web3-Community repräsentieren.`,
    info13: `Dieses aufregende Programm bietet Ihnen die Möglichkeit, einen einzigartigen Node-NFT auf einer von drei Ebenen zu prägen: Common, Special oder Rare. Folgendes erhalten Sie auf jeder Stufe:`,
    infoCommon: `Common Node NFT: Mint für 1000 USDT und erhalte 1,200 CA, 110 AP und ein Common Node NFT Badge. Sie werden auch direkt zum LEGENDÄREN Rang aufsteigen.`,
    infoSpecial: `Special Node NFT: Mint für 3000 USDT und erhaltet 4,000 CA, 450 AP und ein Special Node NFT Badge. Sie werden auch direkt zum LEGENDÄREN Rang aufsteigen.`,
    infoRare: `Rare Node NFT: Mint für 5000 USDT und erhalte 7,000 CA, 1,200 AP und ein Rare Node NFT Badge. Sie werden auch direkt zum LEGENDÄREN Rang aufsteigen.`,
    info21: `Erhalten Sie als Mitglied mit dem Rang „Legendär“ zusätzliche Belohnungen für jede erfolgreiche Empfehlung! Wenn Ihre Freunde ein Node NFT kaufen, erhalten Sie proportionale Belohnungen entsprechend der Stufe, die sie kaufen.`,
    info22: `Wenn Ihre Empfehlung ein Rare Node NFT kauft, erhalten Sie zusätzlich 5,000 kumulierte CA-, 280 AP- und 560 LRT-Token als Belohnung!`,
    info23: `Wenn Ihre Empfehlung ein Special Node NFT kauft, erhalten Sie zusätzlich 3,000 kumulierte CA-, 160 AP- und 320 LRT-Token als Belohnung!`,
    info24: `Wenn Ihre Empfehlung ein Common Node NFT kauft, erhalten Sie zusätzlich 1,000 kumulierte CA-, 48 AP- und 96 LRT-Token als Belohnung!`,
    info25: `Knoten-NFTs wurden entwickelt, um Ihnen beim Aufbau Ihres Netzwerks in unserer lebendigen Web3-Community zu helfen. Je mehr Sie Ihr Netzwerk innerhalb der LYNK-Community erweitern, desto mehr Vorteile werden Sie freischalten und desto mehr verdienen Sie!`,
    info26: `Laden Sie also Ihre Freunde ein und genießen Sie die Vorteile, die sich daraus ergeben, Teil der Node NFT-Crew zu sein. Melden Sie sich jetzt bei uns an und verdienen Sie noch heute mehr!`,
    minted: 'Geprägt',
    soldOut: 'AUSVERKAUFT',
  },
  leaderNft: {
    title: 'LEADER NFT Programm Generation 1',
    mintNow: 'Jetzt prägen',
    mintLeader: 'MINT LEADER NFT',
    mintLeaderNft: 'MINT LEADER NFT',
    leaderNftDes1: 'Erhalten Sie einen Vorteil mit einem Leader NFT',
    leaderNftDes2: 'Schließen Sie sich an und machen Sie Ihre Markierung in der LYNK-Community',
    info11: `Willkommen beim Leader NFT-Programm, LYNK-Fam!`,
    info12: `Das Leader NFT-Programm ist unser erstklassiges Programm ausschließlich für frühe Unterstützer, die eine starke Initiative beim Wachstum der LYNK-Community gezeigt haben. Generation I des Programms dient der Anerkennung von Champions wie Ihnen, mit einzigartigen Keeper NFTs, die Ihre Identität und Ihren Status als Anführer der aufregendsten Web3-Community repräsentieren.`,
    info13: `Dieses aufregende Programm bietet Ihnen die Möglichkeit, einen einzigartigen Leader-NFT auf einer von drei Ebenen zu prägen: Common, Special oder Rare. Folgendes erhalten Sie auf jeder Stufe:`,
    infoCommon: `Common Leader NFT: Mint für 500 USDT und erhalte 500 CA. Sie werden auch direkt zum EPOS Rang aufsteigen.`,
    infoSpecial: `Special Leader NFT: Mint für 1000 USDT und erhaltet 1,000 CA. Sie werden auch direkt zum MEISTER Rang aufsteigen.`,
    infoRare: `Rare Leader NFT: Mint für 3000 USDT und erhalte 3,000 CA. Sie werden auch direkt zum LEGENDÄREN Rang aufsteigen.`,
    info21: `Erhalten Sie als Mitglied mit dem Rang „Legendär“ zusätzliche Belohnungen für jede erfolgreiche Empfehlung! Wenn Ihre Freunde ein Leader NFT kaufen, erhalten Sie proportionale Belohnungen entsprechend der Stufe, die sie kaufen.`,
    info22: `Wenn Ihre Empfehlung ein Rare Leader NFT kauft, erhalten Sie zusätzlich 3,000 kumulierte CA-, 120 AP- und 240 LRT-Token als Belohnung!`,
    info23: `Wenn Ihre Empfehlung ein Special Leader NFT kauft, erhalten Sie zusätzlich 1,000 kumulierte CA-, 40 AP- und 80 LRT-Token als Belohnung!`,
    info24: `Wenn Ihre Empfehlung ein Common Leader NFT kauft, erhalten Sie zusätzlich 500 kumulierte CA-, 20 AP- und 40 LRT-Token als Belohnung!`,
    info25: `Knoten-NFTs wurden entwickelt, um Ihnen beim Aufbau Ihres Netzwerks in unserer lebendigen Web3-Community zu helfen. Je mehr Sie Ihr Netzwerk innerhalb der LYNK-Community erweitern, desto mehr Vorteile werden Sie freischalten und desto mehr verdienen Sie!`,
    info26: `Laden Sie also Ihre Freunde ein und genießen Sie die Vorteile, die sich daraus ergeben, Teil der Leader NFT-Crew zu sein. Melden Sie sich jetzt bei uns an und verdienen Sie noch heute mehr!`,
    minted: 'Geprägt',
    soldOut: 'AUSVERKAUFT',
  },
  marketPlace: {
    title: 'MARKTPLATZ',
    totalVolume: 'Gesamtverkehr',
    floorPrice: 'Bodenpreis',
    higherPrice: 'Höherer Preis',
    owner: 'Besitzer',
    tradingFee: 'Handelsgebühr',
    faction: 'FRAKTION',
    type0: 'Finder',
    type1: 'Spirits',
    type2: 'Exos',
    type3: 'Martials',
    type4: 'Voids',
    gender: 'GESCHLECHT',
    boy: 'Herr',
    girl: 'Frau',
    level: 'LEVEL',
    level0: 'Level 2-3',
    level1: 'Level 4-6',
    level2: 'Level 7-9',
    level3: 'Level 10-12',
    level4: 'Level 13',
    priceDesc: 'Preis: hoch bis niedrig',
    priceAsc: 'Preis: niedrig bis hoch',
    priceHint: {
      empty: 'Bitte Preisspanne eingeben',
      numberError: 'Der Höchstpreis kann nicht niedriger sein als der Mindestpreis',
    },
    priceUsdt: 'PREIS USDT',
    to: 'AN',
    all: 'ALLE',
    buy: 'Kaufen',
    rare: 'SELTEN',
    price: 'Preis',
    balance: 'Kontostand',
  },
};
