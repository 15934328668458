import React, { useState, useEffect, useRef } from 'react';
import {
  styled,
  Slide,
  List,
  Box,
  IconButton,
  ListItemButton,
  ListItemText,
  Collapse,
  Divider,
  Dialog,
  Stack,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { TransitionProps } from '@mui/material/transitions';
import { ReactComponent as Logo } from 'assets/img/header/lynkLogo.svg';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { drawerFooter } from './config/drawerFooter';
import { router } from './config/router';
import { languageList } from './config/language';
import { useNavigate, useLocation, createSearchParams } from 'react-router-dom';
import { useAppSelector, useAppDispatch } from 'hooks/redux';
import { updateLanguage } from 'store/features/userSlice';
import { useClickAway } from 'ahooks';
import arrow from 'assets/img/drawer/arrow.svg';
import { useI18n } from 'react-simple-i18n';
import { showTipModal } from 'store/features/componentSlice';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="right" ref={ref} {...props} />;
});

const MenuFooter = styled('div')({
  display: 'flex',
  height: 70,
  width: '100%',
  alignItems: 'center',
  justifyContent: 'center',
});

const ItemText = styled(ListItemText)<{ path: string; checkpath: string }>(({ path, checkpath, theme }) => ({
  '& > span > span': {
    paddingLeft: 8,
    paddingRight: 20,
    fontSize: 13,
    fontWeight: 700,
    color: path === checkpath ? `${theme.textColor.text3}` : `${theme.textColor.text1}`,
    background:
      path === checkpath
        ? `linear-gradient(75deg, ${theme.bgColor.bg3} 0, ${theme.bgColor.bg3} 92%, transparent 50%)`
        : 'transparent',
  },
}));

const LanguageBox = styled(Box)(({ theme }) => ({
  background: '#203044',
  width: theme.spacing(13.5),
  border: '1px solid #fff',
  borderRadius: 7,
  display: 'grid',
  gridTemplateRows: '1fr',
  marginLeft: theme.spacing(3.5),
  marginTop: theme.spacing(5),
  '& .languageList': {
    width: '100%',
    background: '#ffffff33',
    maxHeight: theme.spacing(20),
    overflowY: 'auto',
  },
}));

const LanguageButton = styled(ListItemButton)(({ theme }) => ({
  width: '100%',
  height: theme.spacing(3.5),
  padding: theme.spacing(0, 0.5, 0, 1),
  '& .MuiTypography-root': {
    fontSize: 13,
    fontWeight: 700,
    color: '#fff',
  },
  '& .MuiSvgIcon-root': {
    color: '#fff',
  },
  '& .arrowDown': {
    marginRight: theme.spacing(1),
  },
  '& .arrow': {
    marginRight: theme.spacing(1),
    transform: 'rotate(180deg)',
  },
}));

export default function FullScreenDialog({
  open,
  handleClose,
  children,
}: {
  open: boolean;
  handleClose: () => void;
  children?: React.ReactNode;
}) {
  const navigate = useNavigate();
  const { t } = useI18n();
  const dispatch = useAppDispatch();
  const { pathname } = useLocation();
  const { inviter } = useAppSelector((state) => state.application);
  const { lang } = useAppSelector((state) => state.user);
  const [checkPath, setpCheckPath] = useState('');
  const [collapsePath, setpCollapsePath] = useState('');
  const [openSelect, setOpenSelect] = useState(false);
  const ref = useRef<any>(null);
  useClickAway(() => {
    setOpenSelect(false);
  }, ref);

  useEffect(() => {
    setpCheckPath(pathname);
  }, [pathname]);

  const openLink = (url: string) => {
    window.open(url, '_blank');
  };

  const selectPath = (path: string, children: Array<{ title: string; path: string }>) => {
    if (children.length) {
      setpCollapsePath(path === collapsePath ? '' : path);
    } else {
      if (!path.includes(collapsePath)) {
        setpCollapsePath('');
      }
      if (path.includes('/more')) {
        dispatch(showTipModal(t('global.comingSoon')));
        return;
      }
      handleClose();
      setTimeout(() => {
        navigate({
          pathname: path,
          search: createSearchParams({
            referrer: inviter,
          }).toString(),
        });
      }, 350);
    }
  };
  return (
    <Dialog
      fullScreen
      open={open}
      sx={(theme) => ({
        '& .MuiPaper-root': { bgcolor: `${theme.bgColor.bg1}` },
      })}
      onClose={handleClose}
      aria-describedby="mobile-menu-modal"
      TransitionComponent={Transition}
    >
      <Stack direction="row" justifyContent="space-between" sx={(theme) => ({ padding: theme.spacing(0, 2.5) })}>
        <IconButton>
          <Logo />
        </IconButton>
        <IconButton onClick={() => handleClose()}>
          <CloseIcon sx={{ color: '#fff' }} />
        </IconButton>
      </Stack>
      {children}
      <List
        component="nav"
        sx={{
          width: '100%',
          bgcolor: 'background.bg1',
          height: 'calc(100% - 200px)',
          overflowY: 'auto',
        }}
        aria-labelledby="nested-list-subheader"
      >
        {router.map(({ title, path, children }) => {
          return (
            <Box
              key={title}
              sx={{
                bgcolor: path === collapsePath ? 'background.paper' : 'bgColor.bg1',
                borderTopRightRadius: 15,
                borderBottomRightRadius: 15,
              }}
            >
              <ListItemButton
                onClick={() => selectPath(path, children)}
                disableRipple
                sx={(theme) => ({
                  padding: theme.spacing(0.8, 3.5),
                })}
              >
                <ListItemText
                  primary={t(title)}
                  sx={{
                    '& .MuiTypography-root': {
                      fontSize: 13,
                      fontWeight: 700,
                    },
                    flex: '0 0 auto',
                    color: path === collapsePath || checkPath.includes(path) ? 'text.text3' : 'background.paper',
                  }}
                />
                {!children?.length ? null : path === collapsePath ? (
                  <ExpandLess sx={{ color: 'text.text3' }} />
                ) : (
                  <ExpandMore
                    sx={{
                      color: checkPath.includes(path) ? 'text.text3' : 'background.paper',
                    }}
                  />
                )}
              </ListItemButton>
              {children.length ? (
                <Collapse in={path === collapsePath} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding sx={{ width: '100%' }}>
                    {children.map((el) => (
                      <ListItemButton
                        disableRipple
                        key={el.title}
                        sx={{ pl: 4 }}
                        onClick={() => selectPath(el.path, [])}
                      >
                        <ItemText checkpath={checkPath} path={el.path}>
                          <span>{t(el.title)}</span>
                        </ItemText>
                      </ListItemButton>
                    ))}
                  </List>
                </Collapse>
              ) : null}
              <Divider
                sx={{
                  borderColor: '#fff',
                  ml: 3.5,
                  mr: 3.5,
                  borderBottomWidth: path === collapsePath ? 'inherit' : 'thin',
                }}
              />
            </Box>
          );
        })}
        <LanguageBox ref={ref}>
          <LanguageButton onClick={() => setOpenSelect(!openSelect)} disableRipple>
            <ListItemText primary={languageList.filter((el) => el.value === lang)[0]?.title} />
            {openSelect ? <img className="arrow" src={arrow} /> : <img className="arrowDown" src={arrow} />}
          </LanguageButton>
          <Collapse in={openSelect} timeout="auto" unmountOnExit>
            <List component="div" className="languageList" disablePadding>
              {languageList.map((item) => (
                <LanguageButton
                  key={item.value}
                  onClick={() => {
                    dispatch(updateLanguage(item.value));
                    setOpenSelect(false);
                  }}
                  disableRipple
                >
                  <ListItemText primary={item.title} />
                </LanguageButton>
              ))}
            </List>
          </Collapse>
        </LanguageBox>
      </List>
      <MenuFooter sx={{ gap: '20px' }}>
        {drawerFooter.map(({ name, Icon, path }) => {
          return (
            <img src={Icon} key={name} onClick={() => openLink(path)} />
            // <IconButton aria-label={name} key={name} onClick={() => openLink(path)}>
            //   <Icon />
            // </IconButton>
          );
        })}
      </MenuFooter>
    </Dialog>
  );
}
