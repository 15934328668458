import React from 'react';
import { Chain } from 'models/chain';
import { ReactComponent as ETH } from 'assets/svg/eth_logo.svg';
import EthUrl from 'assets/svg/eth_logo.svg';
import CONFIG from '../config';

const { chainIdUin256, chain, nativeCurrency } = CONFIG;

export enum ChainId {
  MAINNET = 42161,
}

export const NETWORK_CHAIN_ID: ChainId = process?.env?.REACT_APP_CHAIN_ID
  ? parseInt(process?.env?.REACT_APP_CHAIN_ID)
  : ChainId.MAINNET;

export const SUPPORT_NETWORK_CHAIN_IDS: ChainId[] = process?.env?.REACT_APP_CHAIN_IDS
  ? process?.env?.REACT_APP_CHAIN_IDS.split(',').map((v) => Number(v) as ChainId)
  : [ChainId.MAINNET];

export const AllChainList = [
  {
    icon: <ETH />,
    logo: EthUrl,
    symbol: 'Rinkeby',
    name: 'Rinkeby Testnet',
    id: ChainId.MAINNET,
    hex: '0x4',
  },
];

export const ChainList = AllChainList.filter((v) => SUPPORT_NETWORK_CHAIN_IDS.includes(v.id));

export const ChainListMap: {
  [key: number]: { icon: JSX.Element; link?: string; selectedIcon?: JSX.Element } & Chain;
} = ChainList.reduce((acc, item) => {
  acc[item.id] = item;
  return acc;
}, {} as any);

export const SUPPORTED_NETWORKS: {
  [chainId in ChainId]?: {
    chainId: string;
    chainName: string;
    nativeCurrency: {
      name: string;
      symbol: string;
      decimals: number;
    };
    rpcUrls: string[];
    blockExplorerUrls: string[];
  };
} = {
  [ChainId.MAINNET]: {
    chainId: chainIdUin256,
    chainName: chain,
    nativeCurrency: {
      name: nativeCurrency.name,
      symbol: nativeCurrency.symbol,
      decimals: nativeCurrency.decimals,
    },
    rpcUrls: [CONFIG.rpcUrls],
    blockExplorerUrls: [CONFIG.blockExplorerUrls],
  },
};
