export const languageList = [
  {
    title: 'ENGLISH',
    value: 'enUS',
  },
  {
    title: 'Français',
    value: 'frFR',
  },
  {
    title: 'Español',
    value: 'esES',
  },
  {
    title: 'Pу́сский',
    value: 'ruRU',
  },
  {
    title: 'Tiếng Việt',
    value: 'viVN',
  },
  {
    title: 'Deutsch',
    value: 'deDE',
  },
  {
    title: 'Indonesia',
    value: 'hiIN',
  },
  {
    title: 'ภาษาไทย',
    value: 'thTH',
  },
  {
    title: '한국어',
    value: 'koKR',
  },
  {
    title: '日語',
    value: 'jaJP',
  },
  {
    title: '中文',
    value: 'zhCN',
  },
];
