import React from 'react';
import { styled } from '@mui/material';
import AppBar from 'components/AppBar';
import NftsContent from 'components/NftsContent';
import { useI18n } from 'react-simple-i18n';
import { Outlet } from 'react-router-dom';

const Container = styled('div')`
  height: 100%;
  width: 100%;
  overflow: hidden;
`;

const MainContainer = styled('div')(({ theme }) => ({
  display: 'grid',
  height: 'calc(100%)',
  width: `calc(100% - ${theme.spacing(14)})`,
  padding: theme.spacing(0, 7),
  gridTemplateRows: `${theme.spacing(10.5)} 1fr`,
  [theme.breakpoints.down('sm')]: {
    gridTemplateRows: `${theme.spacing(9)} 1fr`,
    width: `calc(100% - ${theme.spacing(6)})`,
    padding: theme.spacing(0, 3),
    overflow: 'overlay',
  },
  gridTemplateColumns: '1fr',
  gridTemplateAreas: `
    'nftsT'
    'nftsB'
  `,
}));

const Header = styled('div')({
  gridArea: 'nftsT',
  position: 'relative',
  // backgroundImage: `url(${headerBg})`,
  // backgroundPosition: 'top center',
  // backgroundSize: 'cover',
  // backgroundRepeat: 'no-repeat',
});

const Nfts = () => {
  const { t } = useI18n();

  return (
    <Container>
      <Outlet />
      <MainContainer>
        <Header>
          <AppBar title={t('nfts.title')}></AppBar>
        </Header>
        <NftsContent />
      </MainContainer>
    </Container>
  );
};

export default Nfts;
