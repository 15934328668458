import React, { useCallback, useState, useMemo } from 'react';
import { styled, Typography, Box, Paper, Stack, Button, Divider } from '@mui/material';
import { useAppDispatch } from 'hooks/redux';
import { showWalletModal } from 'store/features/componentSlice';
import useBreakpoint from 'hooks/useBreakpoint';
import { useActiveWeb3React } from 'hooks';
import { clearAllTransactions, TransactionDetails } from 'store/features/transactionsSlice';
import { isTransactionRecent, useAllTransactions } from 'hooks/useTransaction';
import { LYNK, AP, LRT, SUPPORTED_WALLETS, USDT } from 'constants/index';
import { injected } from 'connectors';
import { useTokenBalance } from 'hooks/useWallet';
import { registerToken, getEtherscanLink } from 'utils';
import { useWeb3Instance } from 'hooks/useWeb3Instance';
import { ChainId } from 'constants/chain';
import { useI18n } from 'react-simple-i18n';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import useCopyClipboard from 'hooks/useCopyClipboard';
import _ from 'lodash';

const PaperBox = styled(Paper)(({ theme }) => ({
  display: 'flex',
  width: theme.spacing(35),
  height: theme.spacing(55),
  backgroundColor: `${theme.bgColor.bg2}`,
}));

const WalletContent = styled(Box)(({ theme }) => ({
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1),
  padding: `${theme.spacing(3.5, 2, 1)}`,
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(0, 4),
  },
}));

const BalanceBox = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
});
export interface AllBalance {
  name: string;
  balance: string;
  addToken: () => Promise<void | undefined> | Window | null;
  addTokenName: string;
}

// we want the latest one to come first, so return negative if a is after b
const newTransactionsFirst = (a: TransactionDetails, b: TransactionDetails) => {
  return b.addedTime - a.addedTime;
};

const FormatConnectorName = () => {
  const { ethereum } = window;
  const { t } = useI18n();
  const isMetaMask = !!(ethereum && ethereum.isMetaMask);
  const { connector } = useActiveWeb3React();
  const name = Object.keys(SUPPORTED_WALLETS)
    .filter(
      (k) =>
        SUPPORTED_WALLETS[k].connector === connector && (connector !== injected || isMetaMask === (k === 'METAMASK'))
    )
    .map((k) => SUPPORTED_WALLETS[k].name)[0];
  return (
    <Typography color="text.text2" sx={{ textAlign: 'center' }}>
      {t('global.connectedWallet', name)}
    </Typography>
  );
};

const Transaction = ({ hash }: { hash: string }) => {
  const { chainId } = useActiveWeb3React();
  const allTransactions = useAllTransactions();
  const tx = allTransactions?.[hash];
  const { t } = useI18n();
  const summary = tx?.summary;
  const pending = !tx?.receipt;
  const success = !pending && tx && (tx.receipt?.status === 1 || typeof tx.receipt?.status === 'undefined');

  if (!chainId && !process.env.STORYBOOK_MODE) return null;

  const jumpEtherscan = (chainId: ChainId | undefined) => {
    const href = chainId ? getEtherscanLink(chainId, hash, 'transaction') : '';
    window.open(href);
  };

  return (
    <Stack
      height={100}
      sx={{ cursor: 'pointer' }}
      onClick={() => jumpEtherscan(chainId)}
      direction="row"
      justifyContent="space-between"
    >
      <Typography color="text.text2">
        {summary
          ? _.split(summary, '$%')
              .map((item) => (item ? t(item) : ''))
              .join('')
          : hash}{' '}
        ↗
      </Typography>
      <Typography color="text.text2">{pending ? 'pending' : success ? 'success' : 'error'}</Typography>
    </Stack>
  );
};

const MyWallet = ({ showTitle = true, closeMenu }: { showTitle?: boolean; closeMenu?: () => void }): JSX.Element => {
  const dispatch = useAppDispatch();
  const { t } = useI18n();
  const isMobile = useBreakpoint();
  const { chainId, account, deactivate } = useActiveWeb3React();
  const apBalance = useTokenBalance(account ?? undefined, AP);
  const lynkBalance = useTokenBalance(account ?? undefined, LRT);
  const usdtBalance = useTokenBalance(account ?? undefined, USDT);
  const alyxBalance = useTokenBalance(account ?? undefined, LYNK);
  const [ethBalance, setEthBalance] = useState(0);
  const [, setCopied] = useCopyClipboard();
  const web3 = useWeb3Instance();
  account &&
    web3?.eth?.getBalance(account).then((res) => {
      setEthBalance(Number(res));
    });
  const allTransactions = useAllTransactions();
  const sortedRecentTransactions = useMemo(() => {
    const txs = Object.values(allTransactions);
    return txs.filter(isTransactionRecent).sort(newTransactionsFirst);
  }, [allTransactions]);
  const pending = sortedRecentTransactions.filter((tx) => !tx.receipt).map((tx) => tx.hash);
  const confirmed = sortedRecentTransactions.filter((tx) => tx.receipt).map((tx) => tx.hash);

  const allBalance: AllBalance[] = useMemo(() => {
    const balances = [
      {
        name: 'AP',
        balance: apBalance ? apBalance.toFixed(2, { groupSeparator: ',' }) : '--',
        addToken: () => registerToken(AP.address, AP.symbol ?? '', AP.decimals, ''),
        addTokenName: t('global.addToken'),
      },
      {
        name: 'LRT',
        balance: lynkBalance ? lynkBalance.toFixed(2, { groupSeparator: ',' }) : '--',
        addToken: () => registerToken(LRT.address, LRT.symbol ?? '', LRT.decimals, ''),
        addTokenName: t('global.addToken'),
      },
      {
        name: 'LYNK',
        balance: alyxBalance ? alyxBalance.toFixed(2, { groupSeparator: ',' }) : '--',
        addToken: () => registerToken(LYNK.address, LYNK.symbol ?? '', LYNK.decimals, ''),
        addTokenName: t('global.addToken'),
      },
      {
        name: 'USDT',
        balance: usdtBalance ? usdtBalance.toFixed(2, { groupSeparator: ',' }) : '--',
        addToken: () => registerToken(USDT.address, USDT.symbol ?? '', USDT.decimals, ''),
        addTokenName: t('global.addToken'),
      },
      {
        name: 'ETH',
        balance: ethBalance ? (ethBalance / 10 ** 18).toFixed(4) : '--',
        addToken: () => window.open('https://bridge.arbitrum.io/', '_blank'),
        addTokenName: t('global.crossChain'),
      },
    ];
    return balances;
  }, [apBalance, t, lynkBalance, alyxBalance, usdtBalance, ethBalance]);

  const clearAllTransactionsCallback = useCallback(() => {
    if (chainId) dispatch(clearAllTransactions({ chainId }));
  }, [dispatch, chainId]);

  return (
    <PaperBox elevation={isMobile ? 0 : 4}>
      <WalletContent>
        {showTitle ? (
          <Typography color="text.text1" sx={{ fontWeight: 700, textAlign: 'center' }}>
            {t('global.myWallet')}
          </Typography>
        ) : null}
        <FormatConnectorName />
        <Box>
          <Typography color="text.text1">{t('global.myAddress')}</Typography>
          <Typography color="text.text2" sx={{ zoom: 0.75, display: 'flex', alignItems: 'center', gap: '4px' }}>
            {account}{' '}
            <ContentCopyIcon
              onClick={() => account && setCopied(account)}
              sx={{ cursor: 'pointer', zoom: 0.75 }}
              fontSize="small"
            />
          </Typography>
        </Box>
        <BalanceBox>
          {allBalance.map(({ name, balance, addToken, addTokenName }) => (
            <Stack direction="row" justifyContent="space-between" key={name}>
              <Typography color="text.text1" width={36}>
                {name}
              </Typography>
              <Typography color="text.text1" width={100}>{`: ${balance}`}</Typography>
              <Typography
                onClick={() => addToken()}
                component="a"
                width={70}
                sx={{ cursor: 'pointer', fontSize: 10, textAlign: 'end' }}
                color="text.text3"
              >
                {addTokenName}
              </Typography>
            </Stack>
          ))}
        </BalanceBox>
        <Stack direction="row" justifyContent="center" alignItems="center" spacing={6} height={55}>
          <Button
            size="small"
            color="secondary"
            variant="outlined"
            onClick={(event) => {
              event.stopPropagation();
              deactivate();
              dispatch(showWalletModal());
              closeMenu && closeMenu();
            }}
          >
            {t('global.logout')}
          </Button>
          <Button
            size="small"
            color="secondary"
            variant="contained"
            onClick={(event) => {
              event.stopPropagation();
              dispatch(showWalletModal());
              closeMenu && closeMenu();
            }}
          >
            {t('global.change')}
          </Button>
        </Stack>
        <Divider />
        <Stack direction="row" justifyContent="space-between" sx={{ '&:hover': { a: { display: 'block' } } }}>
          <Typography color="text.text1">{t('global.recentTransactions')}</Typography>
          <Typography
            component="a"
            onClick={clearAllTransactionsCallback}
            color="text.text3"
            sx={{ cursor: 'pointer', display: 'none' }}
          >
            {!!pending.length || !!confirmed.length ? t('global.clearAll') : ''}
          </Typography>
        </Stack>
        {!!pending.length || !!confirmed.length ? (
          <Stack height={100} sx={{ overflowY: 'auto' }}>
            {pending.map((hash, i) => {
              return <Transaction key={i} hash={hash} />;
            })}
            {confirmed.map((hash, i) => {
              return <Transaction key={i} hash={hash} />;
            })}
          </Stack>
        ) : (
          <Typography color="text.text1">{t('global.transactionsDes')}</Typography>
        )}
      </WalletContent>
    </PaperBox>
  );
};

export default MyWallet;
