import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { styled } from '@mui/material';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import Fade from '@mui/material/Fade';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import CircularProgress from '@mui/material/CircularProgress';

interface DialogTitleProps {
  title: string;
  handleClose: () => void;
  headerHeight?: number;
  goBack?: () => void;
  showGoBack?: boolean;
  showHandleClose?: boolean;
}

const DialogBaseActions = styled(DialogActions)(({ theme }) => ({
  height: theme.spacing(10.5),
  display: 'flex',
  justifyContent: 'center',
  position: 'relative',
  [theme.breakpoints.down('sm')]: {
    height: theme.spacing(6.5),
  },
}));

const ContainedButton = styled(Button)(({ theme }) => ({
  height: theme.spacing(4),
  borderRadius: 7,
  fontSize: 13,
  fontWeight: 700,
  [theme.breakpoints.down('sm')]: {
    height: theme.spacing(4),
    fontSize: 12,
    width: 'auto',
  },
}));

function BaseDialogTitle(props: DialogTitleProps) {
  const { title, handleClose, goBack, showGoBack, showHandleClose, headerHeight } = props;

  return (
    <DialogTitle
      sx={(theme) => ({
        m: 0,
        padding: theme.spacing(1),
        height: headerHeight || theme.spacing(5.5),
        minHeight: theme.spacing(2.5),
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        fontSize: 13,
        fontWeight: 700,
        [theme.breakpoints.down('sm')]: {
          fontSize: 12,
          fontWeight: 600,
          padding: theme.spacing(1, 0),
        },
      })}
    >
      {showGoBack ? (
        <IconButton
          aria-label="close"
          onClick={goBack}
          sx={{
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <KeyboardBackspaceIcon />
        </IconButton>
      ) : (
        <Box width={40} />
      )}
      {title}
      {showHandleClose ? (
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon fontSize="small" />
        </IconButton>
      ) : (
        <Box width={40} />
      )}
    </DialogTitle>
  );
}

interface BaseModalProps {
  open: boolean;
  title: string;
  handleClose: () => void;
  headerHeight?: number;
  children?: React.ReactNode;
  contentWidth?: number;
  contentMobileWidth?: number | string;
  showSlideModal?: boolean;
  bgColor?: string;
  goBack?: () => void;
  showGoBack?: boolean;
  showHandleClose?: boolean;
  confirmText?: string;
  confirm?: () => void;
  confirmLoading?: boolean;
  confirmDisabled?: boolean;
  confirmRightText?: string;
  confirmRight?: () => void;
}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function BaseModal(props: BaseModalProps): JSX.Element {
  const {
    open,
    handleClose,
    headerHeight,
    title,
    children,
    contentWidth = 400,
    contentMobileWidth = 0,
    showSlideModal = false,
    bgColor,
    goBack,
    showGoBack,
    showHandleClose = true,
    confirmText,
    confirm,
    confirmLoading = false,
    confirmDisabled = false,
    confirmRightText = '',
    confirmRight,
  } = props;

  return (
    <Dialog
      open={open}
      TransitionComponent={showSlideModal ? Transition : Fade}
      onClose={handleClose}
      sx={(theme) => ({
        '& .MuiDialog-scrollPaper': {
          alignItems: showSlideModal ? 'flex-end' : 'center',
        },
        '& .MuiPaper-root': {
          margin: showSlideModal ? 0 : theme.spacing(4),
          backgroundColor: bgColor || '#fff',
          borderRadius: showSlideModal ? theme.spacing(3, 3, 0, 0) : theme.spacing(1.1),
          width: showSlideModal ? '100%' : 'auto',
          maxWidth: 1000,
        },
      })}
    >
      <BaseDialogTitle
        title={title}
        handleClose={handleClose}
        showGoBack={showGoBack}
        showHandleClose={showHandleClose}
        goBack={goBack}
        headerHeight={headerHeight}
      />
      <DialogContent
        sx={(theme) => ({
          padding: 0,
          width: contentWidth,
          position: 'relative',
          [theme.breakpoints.down('sm')]: {
            width: showSlideModal ? '100%' : contentMobileWidth || theme.spacing(30),
          },
        })}
      >
        {children}
      </DialogContent>
      {confirmText ? (
        <DialogBaseActions>
          <ContainedButton
            disabled={confirmDisabled || confirmLoading}
            variant="contained"
            onClick={confirm}
            startIcon={confirmLoading ? <CircularProgress size={16} sx={{ color: '#00000042' }} /> : null}
          >
            {confirmText}
          </ContainedButton>
          {confirmRightText ? (
            <Typography
              onClick={confirmRight}
              sx={(theme) => ({
                position: 'absolute',
                right: 150,
                color: '#64B6DC',
                cursor: 'pointer',
                textDecoration: 'underline',
                [theme.breakpoints.down('sm')]: {
                  right: '10%',
                },
              })}
            >
              {confirmRightText}
            </Typography>
          ) : null}
        </DialogBaseActions>
      ) : null}
    </Dialog>
  );
}
