import React from 'react';
import { styled } from '@mui/material';
import AppBar from 'components/AppBar';
import { useI18n } from 'react-simple-i18n';
import InvitationContent from 'components/InvitationContent';

const MainContainer = styled('div')(({ theme }) => ({
  display: 'grid',
  height: 'calc(100%)',
  width: `calc(100% - ${theme.spacing(14)})`,
  padding: theme.spacing(0, 7),
  gridTemplateRows: `${theme.spacing(10.5)} 1fr`,
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(0, 3),
    width: `calc(100% - ${theme.spacing(6)})`,
    gridTemplateRows: `${theme.spacing(9)} 1fr`,
    overflow: 'overlay',
  },
  gridTemplateColumns: '1fr',
  gridTemplateAreas: `
    'invitationT'
    'invitationB'
  `,
}));

const Header = styled('div')({
  gridArea: 'invitationT',
  position: 'relative',
});

const Invitatin = () => {
  const { t } = useI18n();

  return (
    <MainContainer>
      <Header>
        <AppBar title={t('invitation.title')}></AppBar>
      </Header>
      <InvitationContent />
    </MainContainer>
  );
};

export default Invitatin;
