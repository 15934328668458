import React from 'react';
import { styled } from '@mui/material';
import AppBar from 'components/AppBar';
import NftDetail from 'components/NftDetail';
import { useParams, useLocation } from 'react-router-dom';

const MainContainer = styled('div')(({ theme }) => ({
  display: 'grid',
  height: 'calc(100%)',
  width: `calc(100% - ${theme.spacing(14)})`,
  padding: theme.spacing(0, 7),
  gridTemplateRows: `${theme.spacing(10.5)} 1fr`,
  [theme.breakpoints.down('sm')]: {
    gridTemplateRows: `${theme.spacing(9)} 1fr`,
    width: `calc(100% - ${theme.spacing(6)})`,
    padding: theme.spacing(0, 3),
    overflow: 'overlay',
  },
  gridTemplateColumns: '1fr',
  gridTemplateAreas: `
    'detailT'
    'detailB'
  `,
}));

const Header = styled('div')({
  gridArea: 'detailT',
  position: 'relative',
});

const Detail = () => {
  const { tokenId } = useParams();
  const { state } = useLocation();

  return (
    <MainContainer>
      <Header>
        <AppBar title={state?.name || ''}></AppBar>
      </Header>
      <NftDetail tokenId={tokenId} ownNftNum={state?.ownNftNum} stakeNftNum={state?.stakeNftNum} />
    </MainContainer>
  );
};

export default Detail;
