import React from 'react';
import { styled, Typography, Stack, Paper, Box } from '@mui/material';
import AppBar from 'components/AppBar';
import { useI18n } from 'react-simple-i18n';
import headerBg from 'assets/img/marketPlace/headerBg.png';
import JSBI from 'jsbi';
import { CurrencyAmount } from 'constants/token';
import { useFloorPrice, useHighestPrice, useMarketOverview, useTradingFee, useHoldersNum } from 'hooks/useMarket';
import MarketPlaceContent from 'components/MarketPlaceContent';
import { Outlet } from 'react-router-dom';
import { useInViewport } from 'ahooks';

const Container = styled('div')`
  height: 100%;
  width: 100%;
  overflow: hidden;
`;

const MainContainer = styled('div')(({ theme }) => ({
  display: 'grid',
  height: 'calc(100%)',
  width: `calc(100% - ${theme.spacing(14)})`,
  padding: theme.spacing(0, 7),
  gridTemplateRows: `${theme.spacing(41.5)} 1fr`,
  [theme.breakpoints.down('sm')]: {
    gridTemplateRows: `${theme.spacing(30.5)} 1fr`,
    padding: theme.spacing(0),
    // padding: theme.spacing(0, 3),
    // width: `calc(100% - ${theme.spacing(6)})`,
    width: `calc(100%)`,
    overflow: 'hidden overlay',
  },
  gridTemplateColumns: '1fr',
  gridTemplateAreas: `
    'marketPlaceT'
    'marketPlaceB'
  `,
}));

const Header = styled('div')(({ theme }) => ({
  gridArea: 'marketPlaceT',
  position: 'relative',
  borderRadius: '25px',
  backgroundImage: `url(${headerBg})`,
  backgroundPosition: 'center',
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
  [theme.breakpoints.down('sm')]: {
    backgroundPosition: '-88px center',
    borderRadius: 0,
  },
}));

const NumberBox = styled(Box)(({ theme }) => ({
  position: 'absolute',
  bottom: theme.spacing(-4),
  left: 0,
  height: theme.spacing(11),
  display: 'flex',
  width: '100%',
  justifyContent: 'center',
  [theme.breakpoints.down('sm')]: {
    height: theme.spacing(18),
    bottom: theme.spacing(-10),
  },
}));

const MarketPlaceTitle = styled('div')(({ theme }) => ({
  position: 'absolute',
  bottom: theme.spacing(3),
  left: theme.spacing(3),
  height: theme.spacing(11),
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    display: 'none',
  },
}));

const TypographyDes = styled(Typography)({
  fontSize: 20,
  fontWeight: 700,
  color: '#fff',
});

const NumberPaper = styled(Paper)(({ theme }) => ({
  borderRadius: '15px',
  width: `calc(100% - ${theme.spacing(12)})`,
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'space-around',
  alignItems: 'center',
  [theme.breakpoints.down('sm')]: {
    width: `calc(100% - ${theme.spacing(6)})`,
    zoom: 0.9,
    gap: theme.spacing(2),
  },
}));

const MarketPlace = () => {
  const { t } = useI18n();
  const tradingFee = useTradingFee();
  const floorPrice = useFloorPrice();
  const highestPrice = useHighestPrice();
  const holdersNum = useHoldersNum();
  const { result: marketOverview } = useMarketOverview();
  const [inViewport] = useInViewport(() => document.getElementById('marketplaceHeader'), {
    threshold: [0.45],
    root: () => document.getElementById('marketplaceContainer'),
  });

  return (
    <Container>
      <Outlet />
      <MainContainer id="marketplaceContainer">
        <Header id="marketplaceHeader">
          <AppBar ismarketplace={inViewport} title={t('marketPlace.title')}></AppBar>
          <MarketPlaceTitle>
            <TypographyDes color={'#000000'}>{t('marketPlace.title')}</TypographyDes>
          </MarketPlaceTitle>
          <NumberBox>
            <NumberPaper elevation={3}>
              <Stack alignItems={'center'}>
                <Typography fontSize={22} fontWeight={700} color="#000">
                  {marketOverview?.tradeAmount.toFixed(2) || '-'} USDT
                </Typography>
                <Typography fontWeight={500} color="#00000099">
                  {t('marketPlace.totalVolume')}
                </Typography>
              </Stack>
              <Stack alignItems={'center'}>
                <Typography fontSize={22} fontWeight={700} color="#000">
                  {floorPrice ? CurrencyAmount.USDT(floorPrice.toString()).toFixed() : '--'} USDT
                </Typography>
                <Typography fontWeight={500} color="#00000099">
                  {t('marketPlace.floorPrice')}
                </Typography>
              </Stack>
              <Stack alignItems={'center'}>
                <Typography fontSize={22} fontWeight={700} color="#000">
                  {highestPrice ? CurrencyAmount.USDT(highestPrice.toString()).toFixed() : '--'} USDT
                </Typography>
                <Typography fontWeight={500} color="#00000099">
                  {t('marketPlace.higherPrice')}
                </Typography>
              </Stack>
              <Stack alignItems={'center'}>
                <Typography fontSize={22} fontWeight={700} color="#000">
                  {holdersNum || '-'}
                </Typography>
                <Typography fontWeight={500} color="#00000099">
                  {t('marketPlace.owner')}
                </Typography>
              </Stack>
              <Stack alignItems={'center'}>
                <Typography fontSize={22} fontWeight={700} color="#000">
                  {tradingFee
                    ? `${Number(String(JSBI.divide(JSBI.BigInt(tradingFee?.toString()), JSBI.BigInt(10 ** 15)))) / 10}%`
                    : '--'}
                </Typography>
                <Typography fontWeight={500} color="#00000099">
                  {t('marketPlace.tradingFee')}
                </Typography>
              </Stack>
            </NumberPaper>
          </NumberBox>
        </Header>
        <MarketPlaceContent />
      </MainContainer>
    </Container>
  );
};

export default MarketPlace;
