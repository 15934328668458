import React from 'react';
import { useI18n } from 'react-simple-i18n';
import BaseModal from 'components/BaseModal';
import { Stack, Typography } from '@mui/material';
import { useClaim, useReward } from 'hooks/useStaking';
// import ActionButton from '../../Button/ActionButton';
import { useCallback } from 'react';
import { useUserHasSubmitted } from 'hooks/useTransaction';
import { useActiveWeb3React } from 'hooks';
import { useAppDispatch } from 'hooks/redux';
import {
  showTransactionConfirmModal,
  showTransactionPendingModal,
  showTransactionErrorModal,
} from 'store/features/componentSlice';
import CircularProgress from '@mui/material/CircularProgress';

export default function ClaimModal({
  open,
  handleClose,
  tokenId,
}: {
  open: boolean;
  handleClose: () => void;
  tokenId: string;
}): JSX.Element {
  const { t } = useI18n();
  const dispatch = useAppDispatch();
  const { account } = useActiveWeb3React();
  const { reward } = useReward(tokenId);
  const { claim } = useClaim();
  const { submitted: claiming } = useUserHasSubmitted(`${account}_claim_${tokenId}`);

  const claimCallback = useCallback(async () => {
    dispatch(showTransactionPendingModal());
    claim(tokenId)
      .then(() => {
        handleClose();
        dispatch(showTransactionConfirmModal());
      })
      .catch((err: any) => {
        dispatch(
          showTransactionErrorModal(
            err.error && err.error.message
              ? err.error.message
              : err?.data?.message
              ? err?.data?.message
              : err.message ?? t('global.networkError')
          )
        );
        console.error(err);
      });
  }, [claim, dispatch, handleClose, t, tokenId]);

  return (
    <BaseModal
      open={open}
      confirmText={!reward || reward.equalTo('0') ? '' : t('global.confirm')}
      confirmLoading={claiming}
      confirm={claimCallback}
      contentWidth={434}
      title={t('nftDetail.claimAp')}
      handleClose={handleClose}
    >
      {!reward || reward.equalTo('0') ? (
        <Stack direction="row" justifyContent="center" alignItems="center" height={50} paddingBottom={10}>
          {reward?.equalTo('0') ? (
            t('nftDetail.noReward')
          ) : (
            <Stack direction="row" justifyContent="center" alignItems="center" sx={{ gap: 1.5 }}>
              <CircularProgress sx={{ color: '#000000de' }} size={16} />
              {t('nftDetail.loading')}
            </Stack>
          )}
        </Stack>
      ) : (
        <Stack
          sx={(theme) => ({
            height: theme.spacing(10),
            justifyContent: 'space-between',
            [theme.breakpoints.down('sm')]: {
              // height: theme.spacing(10),
              justifyContent: 'center',
            },
          })}
          alignItems="center"
        >
          <Typography
            sx={(theme) => ({
              lineHeight: theme.spacing(2),
              textAlign: 'center',
              [theme.breakpoints.down('sm')]: {
                lineHeight: 'unset',
                width: theme.spacing(20),
              },
            })}
          >
            {t('nftDetail.claimProfit')}
          </Typography>
          <Typography
            sx={(theme) => ({
              lineHeight: theme.spacing(8),
              textAlign: 'center',
              fontSize: 35,
              [theme.breakpoints.down('sm')]: {
                lineHeight: 'unset',
                width: theme.spacing(20),
              },
            })}
          >
            {reward ? reward.toFixed(2, { groupSeparator: ',' }).toString() : '--'}
            <Typography sx={{ marginLeft: 1 }} component="span">
              AP
            </Typography>
          </Typography>
        </Stack>
      )}
    </BaseModal>
  );
}
