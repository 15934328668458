import enUS from './en-US';
import zhCN from './zh-CN';
import deDE from './de-DE';
import esES from './es-ES';
import frFR from './fr-FR';
import hiIN from './hi-IN';
import jaJP from './ja-JP';
import koKR from './ko-KR';
import ruRU from './ru-RU';
import thTH from './th-TH';
import viVN from './vi-VN';

export const langData = {
  enUS,
  zhCN,
  deDE,
  esES,
  frFR,
  hiIN,
  jaJP,
  koKR,
  ruRU,
  thTH,
  viVN,
};
