import React from 'react';
import { useI18n } from 'react-simple-i18n';
import BaseModal from 'components/BaseModal';
import { Stack, Typography, Button } from '@mui/material';
import { useActiveWeb3React } from 'hooks';
import useCopyClipboard from 'hooks/useCopyClipboard';

export default function InvitationLinkModal({
  open,
  handleClose,
}: {
  open: boolean;
  handleClose: () => void;
}): JSX.Element {
  const { t, i18n } = useI18n();
  const { account } = useActiveWeb3React();
  const [isCopied, setCopied] = useCopyClipboard();
  const [isCopiedCode, setCopiedCode] = useCopyClipboard();

  return (
    <BaseModal
      open={open}
      contentWidth={460}
      contentMobileWidth={308}
      title={t('global.invitationLink')}
      handleClose={handleClose}
    >
      <Stack
        sx={(theme) => ({
          padding: theme.spacing(1, 2, 3),
          gap: theme.spacing(3),
        })}
        justifyContent="center"
        alignItems="center"
      >
        <Stack
          sx={(theme) => ({
            flexDirection: 'row',
            [theme.breakpoints.down('sm')]: { flexDirection: 'column', gap: theme.spacing(1) },
          })}
          width={'100%'}
          justifyContent="space-between"
        >
          <Typography color="#454545" sx={(theme) => ({ height: theme.spacing(3), width: theme.spacing(12.5) })}>{`${t(
            'global.invitationLink'
          )}: `}</Typography>
          <Typography
            color="#454545"
            sx={(theme) => ({
              height: theme.spacing(3),
              background: '#E9E9E9',
              borderRadius: '7px',
              width: theme.spacing(30),
              padding: theme.spacing(0, 0.5),
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              [theme.breakpoints.down('sm')]: {
                width: '100%',
              },
            })}
          >
            {`${window.location.hostname}/#/?referrer=${window.btoa(account ?? '')}`}
          </Typography>
          <Button
            variant="contained"
            onClick={() => setCopied(`${window.location.hostname}/#/?referrer=${window.btoa(account ?? '')}`)}
            sx={(theme) => ({
              height: theme.spacing(3),
              padding: '6px 8px',
              width: theme.spacing(5),
              lineHeight: '12px',
            })}
          >
            <Typography fontSize={12} lineHeight={'12px'} sx={{ zoom: i18n.getLang() === 'ruRU' ? 0.6 : 1 }}>
              {isCopied ? t('global.copied') : t('global.copy')}
            </Typography>
          </Button>
        </Stack>
        <Stack
          sx={(theme) => ({
            flexDirection: 'row',
            [theme.breakpoints.down('sm')]: { flexDirection: 'column', gap: theme.spacing(1) },
          })}
          width={'100%'}
          justifyContent="space-between"
        >
          <Typography color="#454545" sx={(theme) => ({ height: theme.spacing(3), width: theme.spacing(12.5) })}>{`${t(
            'global.invitationCode'
          )}: `}</Typography>
          <Typography
            color="#454545"
            sx={(theme) => ({
              height: theme.spacing(3),
              background: '#E9E9E9',
              borderRadius: '7px',
              width: theme.spacing(30),
              padding: theme.spacing(0, 0.5),
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              [theme.breakpoints.down('sm')]: {
                width: '100%',
              },
            })}
          >
            {`${window.btoa(account ?? '')}`}
          </Typography>
          <Button
            variant="contained"
            onClick={() => setCopiedCode(`${window.btoa(account ?? '')}`)}
            sx={(theme) => ({
              height: theme.spacing(3),
              padding: '6px 8px',
              width: theme.spacing(5),
              lineHeight: '12px',
            })}
          >
            <Typography fontSize={12} lineHeight={'12px'} sx={{ zoom: i18n.getLang() === 'ruRU' ? 0.6 : 1 }}>
              {isCopiedCode ? t('global.copied') : t('global.copy')}
            </Typography>
          </Button>
        </Stack>
      </Stack>
    </BaseModal>
  );
}
