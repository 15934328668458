import React from 'react';
import { createTheme, styled, ThemeProvider as MuiThemeProvider } from '@mui/material/styles';

// interface Gradient {
//   gradient1: string;
// }

// interface Height {
//   header: string;
//   subHeader: string;
//   mobileHeader: string;
//   footer: string;
//   large: string;
//   medium: string;
//   small: string;
// }

// interface Width {
//   sidebar: string;
//   maxContent: string;
//   card: string;
//   smallCard: string;
// }

interface TextColor {
  text1: string;
  text2: string;
  text3: string;
  text4: string;
  text5: string;
}

interface BgColor {
  bg1: string;
  bg2: string;
  bg3: string;
  bg4: string;
  bg5: string;
}

// interface Header {
//   bg: string;
// }

declare module '@mui/material/styles' {
  interface Theme {
    textColor: TextColor;
    bgColor: BgColor;
    // gradient: Gradient;
    // height: Height;
    // width: Width;
  }
}

declare module '@mui/material/styles/createTheme' {
  interface ThemeOptions {
    textColor: TextColor;
    bgColor: BgColor;
    // palette: {
    //   text: {
    //     text1: string;
    //   };
    // };
    // gradient: Gradient;
    // height: Height;
    // width: Width;
  }
  interface Theme {
    textColor: TextColor;
    bgColor: BgColor;
    // gradient: Gradient;
    // height: Height;
    // width: Width;
    // header: Header;
  }
}

export const theme = {
  // header: {
  //   bg: '#141420',
  // },
  palette: {
    primary: {
      // light: '#ffda98',
      main: '#64B6DC',
      // dark: '#da9c00',
      contrastText: '#ffffff',
    },
    secondary: {
      main: '#0A1A32',
      contrastText: '#ffffff',
    },
    info: {
      main: '#72DADF',
      contrastText: '#ffffff',
    },
    text: {
      primary: 'rgba(0, 0, 0, 0.87)',
      secondary: 'rgba(0, 0, 0, 0.6)',
      disabled: 'rgba(0, 0, 0, 0.38)',
      text1: '#454545',
      text2: '#8B8B8B',
      text3: '#64B6DC',
      text4: 'red',
      text5: '#8A899E',
    },
    background: {
      paper: '#fff',
      default: '#fff',
      bg1: '#15253B',
      bg2: '#E9E9E9',
      bg3: '#7FF3F9',
      bg4: '#303030',
      bg5: '#A1A1A1',
    },
    // error: {
    //   main: '#FA0E0E',
    //   light: '#FA0E0E10',
    // },
    // warning: {
    //   main: '#F0B90B',
    // },
    // info: {
    //   main: '#F0B90B',
    // },
    // success: {
    //   main: '#31B047',
    // },
    // background: {
    //   default: '#FFFFFF',
    //   paper: '#FFFFFF',
    // },
    // text: {
    //   primary: '#252525',
    //   secondary: 'rgba(0, 0, 0, 0.6)',
    //   disabled: '#F2F5FA',
    // },
    // action: {
    //   disabledOpacity: 0.8,
    // },
    // grey: {
    //   A700: '#191919',
    //   A400: '#252525',
    //   A200: '#303030',
    //   A100: '#A1A1A1',
    // },
  },
  typography: {
    fontFamily: 'Chakra Petch',
    body1: {
      fontWeight: 600,
      fontSize: 13,
    },
    body2: {
      fontWeight: 400,
      fontSize: 13,
      color: '#121425',
    },
  },
  textColor: {
    text1: '#454545',
    text2: '#8B8B8B',
    text3: '#64B6DC',
    text4: 'red',
    text5: '#8A899E',
  },
  bgColor: {
    bg1: '#15253B',
    bg2: '#E9E9E9',
    bg3: '#7FF3F9',
    bg4: '#303030',
    bg5: '#A1A1A1',
  },
  // gradient: {
  //   gradient1: '#ffffff linear-gradient(154.62deg, #77C803 9.44%, #28A03E 59.25%);',
  // },
  // height: {
  //   header: '60px',
  //   subHeader: '80px',
  //   mobileHeader: '60px',
  //   footer: '60px',
  //   large: '50px',
  //   medium: '46px',
  //   small: '20px',
  // },
  // width: {
  //   sidebar: '250px',
  //   maxContent: '1110px',
  //   card: '260px',
  //   smallCard: '200px',
  // },
  // shape: {
  //   border: '1px solid',
  //   borderRadius: 15,
  // },
  // spacing: (factor: number) => `${1 * factor}px`,
  // gray: {
  //   main: '#333333',
  //   dark: '#262626',
  // },
};

// export const override: any = {
//   MuiCssBaseline: {
//     styleOverrides: {
//       body: {
//         backgroundColor: theme.palette.background.default,
//         fontSize: 16,
//         overflow: 'auto!important',
//         paddingRight: '0px!important',
//         fontWeight: 'bold',
//       },
//       'html, input, textarea, button, body': {
//         fontFamily: 'PingFang SC, Roboto, -apple-system, BlinkMacSystemFont, sans-serif',
//         fontDisplay: 'fallback',
//       },
//       '@supports (font-variation-settings: normal)': {
//         'html, input, textarea, button, body': {
//           fontFamily: 'PingFang SC, Roboto, -apple-system, BlinkMacSystemFont, sans-serif',
//           fontDisplay: 'fallback',
//         },
//       },
//     },
//   },
//   MuiButtonBase: {
//     styleOverrides: {
//       root: {
//         fontSize: 16,
//         fontWeight: 'bold',
//         fontFamily: 'PingFang SC, Roboto, -apple-system, BlinkMacSystemFont, sans-serif!important',
//       },
//     },
//   },
//   MuiButton: {
//     styleOverrides: {
//       root: {
//         fontFamily: 'PingFang SC, Roboto, -apple-system, BlinkMacSystemFont, sans-serif!important',
//         color: theme.palette.primary.contrastText,
//         fontWeight: 'bold',
//         borderRadius: theme.shape.borderRadius,
//         transition: '.3s',
//         textTransform: 'none' as const,
//       },
//       contained: {
//         backgroundColor: theme.palette.primary.main,
//         color: theme.palette.primary.contrastText,
//         boxShadow: 'unset',
//         '&:hover, :active': {
//           opacity: 0.6,
//         },
//         '&:disabled': {
//           opacity: 0.3,
//         },
//       },
//       containedSecondary: {
//         backgroundColor: theme.palette.secondary.main,
//         color: theme.palette.secondary.contrastText,
//         boxShadow: 'unset',
//         '&:hover, :active': {
//           opacity: 0.6,
//         },
//         '&:disabled': {
//           backgroundColor: theme.palette.secondary.light,
//           color: '#412E6A',
//         },
//       },
//       outlined: {
//         borderColor: theme.palette.primary.contrastText,
//         color: theme.palette.primary.contrastText,
//         '&:hover, :active': {
//           opacity: 0.6,
//         },
//       },
//       outlinedPrimary: {
//         borderColor: theme.palette.primary.main,
//         color: theme.palette.primary.main,
//         '&:hover, :active': {
//           opacity: 0.6,
//         },
//       },
//       text: {
//         color: theme.palette.primary.contrastText,
//         '&:hover, :active': {
//           opacity: 0.6,
//         },
//       },
//       textPrimary: {
//         color: theme.palette.primary.main,
//         '&:hover, :active': {
//           opacity: 0.6,
//         },
//       },
//       textSecondary: {
//         color: theme.palette.secondary.main,
//         '&:hover, :active': {
//           opacity: 0.6,
//         },
//       },
//     },
//   },
//   MuiTypography: {
//     styleOverrides: {
//       root: {
//         lineHeight: 1.2,
//         fontFamily: 'PingFang SC, Roboto, -apple-system, BlinkMacSystemFont, sans-serif!important',
//         fontWeight: 'bold',
//       },
//       body1: {
//         fontSize: 14,
//       },
//       body2: {
//         fontSize: 12,
//       },
//       h5: {
//         fontSize: 28,
//         fontWeight: 'bold',
//       },
//       h6: {
//         fontSize: 22,
//         fontWeight: 'bold',
//       },
//       caption: {
//         fontSize: 12,
//         color: theme.textColor.text3,
//       },
//       subtitle1: {},
//       subtitle2: {},
//     },
//   },
// };

export const HideOnMobile = styled('div', {
  shouldForwardProp: () => true,
})<{ breakpoint?: 'sm' | 'md' }>(({ theme, breakpoint }) => ({
  [theme.breakpoints.down(breakpoint ?? 'sm')]: {
    display: 'none',
  },
}));

export const ShowOnMobile = styled('div', {
  shouldForwardProp: () => true,
})<{ breakpoint?: 'sm' | 'md' }>(({ theme, breakpoint }) => ({
  display: 'none',
  [theme.breakpoints.down(breakpoint ?? 'sm')]: {
    display: 'block',
  },
}));

export default createTheme({
  ...theme,
  // components: {
  //   ...override,
  // },
});

export function ThemeProvider({ children, theme }: any) {
  return <MuiThemeProvider theme={theme}>{children}</MuiThemeProvider>;
}
