import { ChainId } from 'constants/chain';
import { useCallback, useMemo } from 'react';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { useActiveWeb3React } from 'hooks';
import { PopupContent, addPopup, removePopup } from 'store/features/applicationSlice';

export function useBlockNumber(chainId?: ChainId): number | undefined {
  const { chainId: curChainId } = useActiveWeb3React();

  return useAppSelector((state) => state.application.blockNumber[chainId || curChainId || -1]);
}

export function useInviter(): string | undefined {
  return useAppSelector((state) => state.application.inviter);
}

// export function useModalOpen(modal: ApplicationModal): boolean {
//   const openModal = useAppSelector((state) => state.application.openModal);
//   return openModal === modal;
// }

// export function useToggleModal(modal: ApplicationModal): () => void {
//   const open = useModalOpen(modal);
//   const dispatch = useAppDispatch();
//   return useCallback(() => dispatch(setOpenModal(open ? null : modal)), [dispatch, modal, open]);
// }

// export function useOpenModal(modal: ApplicationModal): () => void {
//   const dispatch = useAppDispatch();
//   return useCallback(() => dispatch(setOpenModal(modal)), [dispatch, modal]);
// }

// export function useCloseModals(): () => void {
//   const dispatch = useAppDispatch();
//   return useCallback(() => dispatch(setOpenModal(null)), [dispatch]);
// }

// export function useWalletModalToggle(): () => void {
//   return useToggleModal(ApplicationModal.WALLET);
// }

// export function useSettingsModalToggle(): () => void {
//   return useToggleModal(ApplicationModal.SETTINGS);
// }

// returns a function that allows adding a popup
export function useAddPopup(): (content: PopupContent, key?: string) => void {
  const dispatch = useAppDispatch();

  return useCallback(
    (content: PopupContent, key?: string) => {
      dispatch(addPopup({ content, key }));
    },
    [dispatch]
  );
}

// returns a function that allows removing a popup via its key
export function useRemovePopup(): (key: string) => void {
  const dispatch = useAppDispatch();
  return useCallback(
    (key: string) => {
      dispatch(removePopup({ key }));
    },
    [dispatch]
  );
}

// get the list of active popups
export function useActivePopups() {
  const list = useAppSelector((state) => state.application.popupList);
  return useMemo(() => list.filter((item) => item.show), [list]);
}
