export default {
  global: {
    connectWallet: 'เชื่อมต่อกับกระเป๋าเงิน',
    swapLynk: 'สลับ $LYNK',
    connectToWallet: 'เชื่อมต่อกับกระเป๋าเงิน',
    metamask: 'เมตามาก์ส',
    walletConnect: 'กระเป๋าเงน เชื่อมต่อ',
    coinbaseWallet: 'กระเป๋าเงิน สำหรับเหรียญ',
    walletDes: '*คุณยินยอมตามข้อกําหนดในการให้บริการและนโยบายความเป็นส่วนตัวของ Lynkverse โดยการเชื่อมโยงกระเป๋าเงิน',
    connecting: 'การเชื่อมต่อ...',
    connectedWallet: 'เชื่อมต่อกับ %s%',
    wrongNetwork: 'เครือข่ายไม่ถูกต้อง',
    error: 'ข้อผิดพลาด',
    myWallet: 'กระเป๋าเงินของฉัน',
    myAddress: 'ที่อยู่ของฉัน',
    addToken: 'เพิ่มโทเค็น',
    crossChain: 'โซ่ข้าม',
    logout: 'ออกจากระบบ',
    change: 'เปลี่ยน',
    recentTransactions: 'ธุรกรรมล่าสุด',
    clearAll: 'ล้างทั้งหมด',
    confirm: 'ยืนยัน',
    confirmed: 'ยืนยันแล้ว',
    balance: 'สมดุล',
    confirmTransaction: 'โปรดยืนยันการทําธุรกรรมนี้ในกระเป๋าเงินของคุณ',
    viewOnChain: 'ดูบน %s%',
    invitationLink: 'ลิงก์เชิญ',
    invitationCode: 'รหัสเชิญ',
    copy: 'คัดลอก',
    insufficient: 'ยอดเงินคงเหลือไม่เพียงพอ',
    approve: 'อนุมัติ',
    tip: 'เคล็ดลับ',
    drop: 'หยด',
    swap: 'แลกเปลี่ยน',
    register: 'ลงทะเบียน',
    networkError: 'ข้อผิดพลาดของเครือข่าย',
    initializing: 'กำลังเริ่มต้น...',
    errorConnecting: 'เกิดข้อผิดพลาดในการเชื่อมต่อ',
    errorConnectingDes: 'เกิดข้อผิดพลาดในการเชื่อมต่อ ลองรีเฟรชหน้า.',
    binanceSmartChain: 'Binance สมาร์ทเชน',
    connectTip: 'กรุณาเชื่อมต่อกับ',
    ethereumMainnet: 'อีเธอเรียม เมนเน็ต',
    connect: 'เชื่อมต่อกับ',
    tryAgain: 'ลองอีกครั้ง',
    changeWallet: 'เปลี่ยนกระเป๋าสตางค์',
    exchangeRate: 'อัตราแลกเปลี่ยน',
    approving: 'กำลังอนุมัติ',
    swapTitle: 'แลกลิงค์',
    registerTitle: 'ใส่รหัสเชิญของคุณ',
    registerPlh: 'กรุณาใส่รหัสของคุณที่นี่',
    registerDes: `TIP: คุณสามารถไปที่ Lynk's Discord เพื่อรับรหัสคำเชิญของคุณ.`,
    unValidCode: 'รหัสการลงทะเบียนไม่ถูกต้อง',
    registerInviterDes: 'คุณยังไม่ได้ลงทะเบียน กรุณาลงทะเบียน',
    transactionsDes: 'ธุรกรรมของคุณจะปรากฏที่นี่...',
    copied: 'คัดลอก',
    finish: 'เสร็จ',
    minting: 'โรงกษาปณ์',
    comingSoon: 'เร็วๆ นี้',
  },
  navMenu: {
    myDashboard: 'แดชบอร์ดของฉัน',
    nfts: 'NFT',
    stake: 'เดิมพัน',
    invitation: 'คําเชิญ',
    history: 'ประวัติศาสตร์',
    activitiesCampaigns: 'กิจกรรมและแคมเปญ',
    nodeNft: 'โปรแกรม Node NFT',
    leaderNft: 'โปรแกรม LEADER NFT',
    finders: 'Finders NFT Mint',
    marketPlace: 'ตลาด',
    rank: 'อันดับ',
    more: 'เพิ่มเติม',
    chatRoom: 'ห้องสนทนา',
    lynkAlliance: 'พันธมิตร LYNK',
    domainServer: 'เซิร์ฟเวอร์ชื่อโดเมน LYNK',
    feed: 'พลวัต',
  },
  nfts: {
    title: 'ผู้ใช้ NFT',
    mintKeeper: 'การปลอม KEEPER',
    buyKeeper: 'ซื้อ Keeper',
    freeMint: 'ฟรีปลอม',
    owned: 'เป็นเจ้าของ',
    staked: 'เดิมพัน',
    sell: 'ขาย',
    stake: 'เดิมพัน',
    mintKeeperNft: 'Keeper NFT',
    nftName: 'ชื่อ NFT',
    selectGender: 'เลือกเพศ',
    male: 'ชาย',
    female: 'หญิง',
    selectFaction: 'เลือกฝ่าย',
    spirits: 'วิญญาณ',
    martials: 'การต่อสู้',
    exos: 'Exos',
    voids: 'โมฆะ',
    selectNft: 'เลือก NFT',
    common: 'ทั่วไป',
    special: 'พิเศษ',
    rare: 'หายาก',
    reSelect: 'เลือกใหม่',
    mint: 'มิ้นท์',
    proceed: 'ดำเนินการ',
    enterName: 'ใส่ชื่อ',
    nameUsed: 'ชื่อถูกครอบครอง',
    cancel: 'ยกเลิก',
    claim: 'เรียกร้อง',
    freeMintEnd: 'สิ้นสุดการขายใน',
    days: 'วัน',
    hours: 'ชม',
    minutes: 'นาที',
    seconds: 'วินาที',
  },
  nftDetail: {
    back: 'กลับ',
    attributes: 'ธาตุ',
    leveltooltip: 'เร่งคุณสมบัติ NFT ของคุณเพื่อเลื่อนระดับและรับรางวัลเดิมพันมากขึ้น!',
    purchaseAp: 'ซื้อ AP',
    userStats: 'สถานะผู้ใช้',
    nfts: 'NFT',
    charisma: 'ความสามารถพิเศษ',
    dexterity: 'ความชํานาญ',
    intellect: 'สติปัญญา',
    vitality: 'พลัง',
    accelerate: 'เร่ง',
    details: 'รายละเอียด',
    currentHolder: 'ผู้ถือปัจจุบัน',
    mintTime: 'เวลามิ้นท์',
    mintAddress: 'ที่อยู่มิ้นท์',
    estimatedPrice: 'ราคาโดยประมาณ',
    endPrice: 'ราคาสิ้นสุด',
    role: 'บทบาท',
    contractAddress: 'ที่อยู่ติดต่อ',
    tokenID: 'รหัสโทเค็น',
    tokenStandard: 'มาตรฐานโทเค็น',
    chain: 'โซ่',
    background: 'พื้นหลัง',
    effect: 'ผล',
    body: 'ร่างกาย',
    clothing: 'เสื้อผ้า',
    head: 'หัว',
    weapon: 'อาวุธ',
    bodyAttachment: 'สิ่งที่แนบมากับร่างกาย',
    artifact: 'สิ่งประดิษฐ์',
    purchaseAP: 'ซื้อ AP',
    balance: 'สมดุล',
    amount: 'จํานวนเงิน',
    price: 'ราคา',
    buy: 'ซื้อ',
    caUpgrade: 'อัพเกรด CA',
    vaUpgrade: 'อัพเกรด VA',
    inUpgrade: 'อัพเกรด IN',
    dxUpgrade: 'อัพเกรด DX',
    max: 'สูงสุด',
    unStake: 'เลิกเกิมพัน',
    claimAp: 'อ้างวิทธิ์ AP',
    apReward: 'รางวัล AP',
    apRewardtooltip:
      'เฉพาะ NFT ระดับ 2 ขึ้นไปเท่านั้นที่มีสิทธิ์รับรางวัลความสำเร็จ สมาชิกจะต้องรับ AP ที่ได้รับรางวัลในแต่ละวันเพื่อเริ่มรอบการนับครั้งถัดไป\nหากไม่ทำเช่นนั้นจะส่งผลให้ AP ยังคงอยู่ที่ 1 แม้จะเดิมพันเป็นเวลา 2 วันหรือมากกว่านั้น',
    stakingTime: 'เวลาปักหลักสะสม',
    claimApHelpDes:
      'สมาชิกจะต้องรับ AP ที่ได้รับรางวัลในแต่ละวันเพื่อกลับมาเล่นรอบ AP ครั้งต่อไป  หาก ไม่ทําเช่นนั้นจะส่งผลให้รางวัล AP ยังคงอยู่ที่ 1 แม้จะปักหลักถ้าเป็นเวลา 100 วันหรือมากกว่านั้น',
    claimProfit: 'คุณสามารถเรียกร้องผลกําไร',
    claim: 'เรียกร้อง',
    sell: 'ขาย',
    referencePrice: 'ราคาอ้างอิง',
    onSale: 'ลดราคา',
    unListing: 'ไม่แสดงรายการ',
    unList: 'ไม่แสดงรายการ',
    insufficientLynk: 'ลิงค์ไม่เพียงพอ',
    fullLevel: 'ระดับเต็ม',
    upLimit: 'ต้องสูงกว่า',
    oneDayMaxVA: 'วันนี้ VA ได้รับการอัปเกรดแล้ว',
    enterPoint: 'ใส่จุด',
    noReward: 'ไม่มีรางวัลให้',
    mustHigherThanLevel2: 'คุณต้องสูงกว่าระดับ 2',
    mintAction: 'ตัวค้นหาเหรียญกษาปณ์ NFT',
    mint: 'สะระแหน่',
    unstake: 'เดิมพัน',
    upgrade: 'อัปเกรด',
    loading: 'กำลังโหลด',
  },
  stake: {
    title: 'เดิมพัน NFT ของฉัน',
    redeem: 'แลก',
    claim: 'เรียกร้อง',
    accumulatedEarned: 'สะสมที่ได้รับ $LRT',
    available: 'มี$LRT',
    claiming: 'การอ้างสิทธิ์',
  },
  invitation: {
    title: 'กระดานเรียนเชิญ',
    invitationDes: 'เชิญเพื่อนเพื่อรับรางวัลด้วยกัน',
    inviteFriends: 'เชิญเพื่อน',
    rewardSummary: 'สรุปรางวัล',
    socialRewardUp: 'รางวัลทางสังคม',
    socialRewardDes:
      'สร้างทีมและเครือข่าย Keeper ของคุณโดยเชิญเพื่อนของคุณให้เข้าร่วมชุมชน LYNK ยิ่งอันดับของ Keeper ของคุณสูงเท่าไหร่รางวัลทางสังคมก็จะยิ่งสูงขึ้นเท่านั้น',
    communityReward: 'รางวัลชุมชน',
    communityReward1: 'รางวัลชุมชน',
    communityRewardDes:
      'ด้วยการเชื่อมต่อกับสมาชิกในวิหาร ผู้เล่นสามารถสร้างพันธมิตรใหม่หรือที่มีอยู่เพื่อรับรางวัลที่สูงขึ้น',
    contributionRewardUp: 'รางวัลสมทบ',
    contributionRewardDes:
      'นอกจากจะได้รับ LRT เป็นรายได้แล้ว AP ยังได้รับรางวัลสําหรับการทํางานบางอย่างให้สําเร็จอีกด้วย ผู้ใช้ที่รับสมัครสมาชิกใหม่จะได้รับ AP 1-6 สําหรับทุกๆ 100 CA ที่สะสมโดยผู้ติดตามของพวกเขา (เครดิตทันที)',
    AchievementReward: 'รางวัลความสําเร็จ',
    AchievementRewardDes:
      'นอกจากจะได้รับ LRT เป็นรายได้แล้ว AP ยังได้รับรางวัลสําหรับการทํางานบางอย่างให้สําเร็จอีกด้วย เดิมพัน NFT ที่ระดับ 2 ขึ้นไปเพื่อรับรางวัล AP ทุกวัน! จํานวนรางวัล AP จะถูกกําหนดโดยระดับ NFT ของคุณ',
    StakingReward: 'รางวัลการเดิมพัน',
    StakingRewardDes:
      'ผู้ใช้รายหนึ่งสามารถเป็นเจ้าของ NFT ได้หลายรายการในเวลาเดียวกัน ผู้ใช้มีสิทธิ์ได้รับผลกําไรรายวันตราบใดที่ NFT ของคุณปฏิบัติตามข้อกําหนดการปักหลัก',
    bonusRatio: 'อัตราส่วนโบนัส',
    totalLRT: 'LRT ทั้งหมด',
    qualified: 'ผ่านการรับรอง',
    cumulateCA: 'สะสม CA',
    totalAP: 'AP ทั้งหมด',
    cycles: 'รอบ',
    stakingNFT: 'เดิมพัน NFT',
    elite: 'ยอด',
    epic: 'มหากาพย์',
    master: 'ปรมาจารย์',
    legendary: 'ตํานาน',
    mythic: 'ตํานาน',
    divine: 'พระเจ้า',
    nourishInviter: 'ผู้สนับสนุนโดยตรง',
    teamGrade: 'สะสม',
    levelPrivilege: 'สิทธิพิเศษระดับ',
    socialReward: 'รางวัลทางสังคม',
    contributionReward: 'รางวัลสมทบ',
    achievementReward: 'รางวัลความสําเร็จ',
    inactive: 'ไม่ได้ใช้งาน',
    activated: 'เปิดใช้งาน',
    myLevel: 'ระดับของฉัน',
    generation: 'รุ่น',
    inviteRule: 'อ้างอิงทุกๆ 100CA ใหม่ รับ',
    NFTStakingRule: 'การเดิมพัน NFT ระดับ 2 ขึ้นไปเป็นเวลา 1 วัน และรับ',
    inactivated: 'ปิดการใช้งาน',
    activate: 'เปิดใช้งาน',
    AcaStastistics: 'สถิติ SUMCA',
    daily: 'รายวัน',
    yesterday: 'เมื่อวาน',
    today: 'วันนี้',
    weekly: 'รายสัปดาห์',
    lastWeek: 'อาทิตย์ที่แล้ว',
    thisWeek: 'ในสัปดาห์นี้',
    monthly: 'รายเดือน',
    lastMonth: 'เดือนที่แล้ว',
    thisMonth: 'เดือนนี้',
  },
  history: {
    title: 'ประวัติศาสตร์',
    stakingRewardRecords: 'บันทึกรางวัลการเดิมพัน',
    mintingRecords: 'บันทึกการสร้าง NFT',
    transactionsRecords: 'บันทึกธุรกรรม NFT',
    recommendedRecords: 'บันทึกที่แนะนํา',
    referralRewardRecords: 'บันทึกรางวัลผู้แนะนํา',
    contributionRewardRecords: 'บันทึกรางวัลสมทบ',
    achievementRewardRecords: 'บันทึกรางวัลความสําเร็จ',
    communityRewardRecords: 'บันทึกรางวัลชุมชน',
    time: 'เวลา',
    reward: 'รางวัล',
    tx: 'TX',
    name: 'ชื่อ',
    amount: 'จำนวน',
    buyerAddress: 'ที่อยู่กระเป๋าสตางค์ของผู้ซื้อ',
    sellerAddress: 'ที่อยู่กระเป๋าเงินผู้ขาย',
    address: 'ที่อยู่กระเป๋าเงิน',
  },
  rank: {
    title: 'ผู้ใช้สูงสุดโดย',
    top: 'สูงสุด',
    ranking: 'การจัดอันดับ',
    levelUp: 'ระดับ',
    charisma: 'ความสามารถพิเศษ',
    nft: 'NFT',
    level: 'ระดับ',
    tx: 'TX',
  },
  finders: {
    title: 'Finder',
    findersDes1: 'Finder มาจาก nexus',
    findersDes2: 'จุดศูนย์กลางของพิกัดเวลาอวกาศทั้งหมด',
    mint: 'มิ้นท์',
    soldOut: 'ขายหมด',
    info1:
      'ผู้ค้นหสมาจาก Nexus ซึ่งเป็นจุดศูนย์กลางของพิกัดกาลอวกาศทั้งหมด สิ่งมีชีวิตเหล่านี้มีมาตั้งแต่เริ่มต้นของทุกสิ่งที่รู้จักและได้สังเกตการเกิดของโลกนับไม่ถ้วน พวกเขารวบรวมความรู้และข้อมูลทั้งหมดจากเมตาเวิสทั้งหมด รักษาไว้โดยการประสานจิตใจของพวกเขาในการทํางานร่วมกัน ในบางครั้ง เมตาเวิสที่ไม่ธรรมดาที่มีศักยภาพในการก้าวข้ามขีดจํากัดของเมตาเวิสอื่นๆจะปรากฏขึ้นกระตุ้นให้ Finder ดําเนินการ เมื่อก้าวเข้าสู่ LYNKVERSE ผ่านรอยแยก Finder มุ่งมั่นที่จะปฏิบัติภารกิจของพวกเขา - เพื่อเลือกไม่กี่คนที่ได้รับเลือกเพื่อนําทางผู้อยู่อาศัยคนอื่นๆ และทําให้แน่ใจว่าเมตาเวิสเติบโตอย่างเต็มศักยภาพ',
    info2:
      'Finder ทุกคนสร้างเมล็ดวิญญาณที่มีส่วนหนึ่งของพลังและมอบให้กับคนที่ถูกเลือก โดยการหลอมรวมกับเมล็ดวิญญาณเผ่าพันธุ์ใหม่จะเกิด มาแบกเสื้อคลุมแห่งความหวังและกลายเป็นผู้นําแห่งยุคใหม่ จงแบกเสื้อคลุมแห่งความหวังและกลายเป็นผู้นําแห่งยุคใหม่',
    totalAmount: 'จํานวน Finder ทั้งหมด:',
    totalBalance: 'ยอดคงเหลือทั้งหมดของ Finder ที่มีอยู่:',
    minted: 'มิ้นต์',
  },
  nodeNft: {
    title: 'โปรแกรม NODE NFT รุ่นหนึ่ง',
    mintNow: 'Mint Now',
    mintNode: 'MINT NODE NFT',
    mintNodeNft: 'MINT NODE NFT',
    nodeNftDes1: 'มีความเป็นเลิศด้วย Node NFT',
    nodeNftDes2: 'เข้าร่วมและทำเครื่องหมายของคุณในชุมชน LYNK',
    info11: `ยินดีต้อนรับสู่โปรแกรม Node NFT, LYNK ครอบครัว!`,
    info12: `โปรแกรม Node NFT คือโปรแกรมหลักของเราที่เฉพาะกับผู้สนับสนุนตั้งแต่ต้นแรกที่ได้แสดงให้เห็นถึงความกระตือรือร้นในการเติบโตของชุมชน LYNK อย่างแข็งขัน รุ่นที่ 1 ของโปรแกรมจะเป็นการรับรู้ผู้นำเช่นคุณด้วย NFT ชั้นนำที่ไม่ซ้ำกันซึ่งจะแทนตัวตนและสถานะของคุณเป็นผู้นำของชุมชน Web3 ที่น่าตื่นเต้นที่สุด`,
    info13: `โปรแกรมนี้จะให้คุณมีโอกาสสร้าง Node NFT ที่ไม่ซ้ำกันได้ในสามระดับ: Common, Special, หรือ Rare ต่อไปนี้คือสิ่งที่คุณจะได้รับในแต่ละระดับ:`,
    infoCommon: `Common Node NFT: ซื้อสำหรับ 1000 USDT เพื่อรับ 1,200 CA, 110 AP, และตราประทับ Common Node NFT Badge นอกจากนี้ยังสามารถเลื่อนขั้นไปยังระดับ LEGENDARY โดยตรงได้เลยค่ะ.`,
    infoSpecial: `Special Node NFT: Mint โดยจ่ายเงิน 3000 ดอลลาร์สหรัฐฯ และได้รับ 4,000 CA, 450 AP และตราประทับ NFT ของโหนดพิเศษ นอกจากนี้คุณยังจะได้รับการเลื่อนระดับโดยตรงไปเป็นอันดับ LEGENDARY ด้วย.`,
    infoRare: `Rare Node NFT: Mint ด้วยราคา 5000 USDT เพื่อรับ 7,000 CA, 1,200 AP และตราประทับ Rare Node NFT Badge โดยที่คุณยังจะได้รับการเลื่อนขั้นไปเป็นตำแหน่ง LEGENDARY โดยตรงด้วยค่ะ.`,
    info21: `เป็นสมาชิกระดับตำแหน่งตำแหน่งแห่งความสำเร็จ คุณจะได้รับสิทธิประโยชน์เพิ่มเติมสำหรับการแนะนำเพื่อน! เมื่อเพื่อนของคุณซื้อ Node NFT คุณจะได้รับสิทธิ์ประโยชน์ที่สัมพันธ์กับระดับที่พวกเขาซื้.`,
    info22: `เมื่อผู้แนะนำของคุณซื้อ Rare Node NFT คุณจะได้รับเพิ่มเติมอีก 5,000 CA, 280 AP และ 560 LRT โทเค็นในรางวัลที่สะสมไว้!`,
    info23: `เมื่อบุคคลที่คุณแนะนำซื้อ Special Node NFT คุณจะได้รับสิทธิพิเศษในการรับรางวัล CA สะสมเพิ่มเติมอีก 3,000 หน่วย รวมถึง AP 160 หน่วยและ LRT 320 หน่วย!`,
    info24: `เมื่อผู้แนะนำของคุณซื้อ Common Node NFT คุณจะได้รับเงินรางวัลเพิ่มเติมอย่างรวดเร็ว 1,000 CA, 48 AP และ 96 LRT tokens ที่สะสมไว้!`,
    info25: `NFT ของโนดถูกออกแบบมาเพื่อช่วยให้คุณได้ก้าวหน้าในการสร้างเครือข่ายของคุณในชุมชน Web3 ที่หลากหลายและมีชีวิตชีวา โดยการขยายเครือข่ายของคุณภายในชุมชน LYNK มากขึ้น คุณจะปลดล็อคประโยชน์มากขึ้น และได้รับรายได้มากขึ้น!`,
    info26: `เริ่มต้นเชิญเพื่อนของคุณและเพลิดเพลินกับสิ่งที่มากับการเป็นส่วนหนึ่งของทีม Node NFT ร่วมเข้าร่วมกับเราและเริ่มรับรางวัลที่ดีกว่าวันนี้!`,
    minted: 'มิ้นต์',
    soldOut: 'ขายหมด',
  },
  leaderNft: {
    title: 'โปรแกรม LEADER NFT รุ่นหนึ่ง',
    mintNow: 'Mint Now',
    mintLeader: 'MINT LEADER NFT',
    mintLeaderNft: 'MINT LEADER NFT',
    leaderNftDes1: 'มีความเป็นเลิศด้วย Leader NFT',
    leaderNftDes2: 'เข้าร่วมและทำเครื่องหมายของคุณในชุมชน LYNK',
    info11: `ยินดีต้อนรับสู่โปรแกรม Leader NFT, LYNK ครอบครัว!`,
    info12: `โปรแกรม Leader NFT คือโปรแกรมหลักของเราที่เฉพาะกับผู้สนับสนุนตั้งแต่ต้นแรกที่ได้แสดงให้เห็นถึงความกระตือรือร้นในการเติบโตของชุมชน LYNK อย่างแข็งขัน รุ่นที่ 1 ของโปรแกรมจะเป็นการรับรู้ผู้นำเช่นคุณด้วย NFT ชั้นนำที่ไม่ซ้ำกันซึ่งจะแทนตัวตนและสถานะของคุณเป็นผู้นำของชุมชน Web3 ที่น่าตื่นเต้นที่สุด`,
    info13: `โปรแกรมนี้จะให้คุณมีโอกาสสร้าง Leader NFT ที่ไม่ซ้ำกันได้ในสามระดับ: Common, Special, หรือ Rare ต่อไปนี้คือสิ่งที่คุณจะได้รับในแต่ละระดับ:`,
    infoCommon: `Common Leader NFT: ซื้อสำหรับ 500 USDT เพื่อรับ 500 CA, นอกจากนี้ยังสามารถเลื่อนขั้นไปยังระดับ EPIC โดยตรงได้เลยค่ะ.`,
    infoSpecial: `Special Leader NFT: Mint โดยจ่ายเงิน 1000 ดอลลาร์สหรัฐฯ และได้รับ 1,000 CA, นอกจากนี้คุณยังจะได้รับการเลื่อนระดับโดยตรงไปเป็นอันดับ MASTER ด้วย.`,
    infoRare: `Rare Leader NFT: Mint ด้วยราคา 3000 USDT เพื่อรับ 3,000 CA, โดยที่คุณยังจะได้รับการเลื่อนขั้นไปเป็นตำแหน่ง LEGENDARY โดยตรงด้วยค่ะ.`,
    info21: `เป็นสมาชิกระดับตำแหน่งตำแหน่งแห่งความสำเร็จ คุณจะได้รับสิทธิประโยชน์เพิ่มเติมสำหรับการแนะนำเพื่อน! เมื่อเพื่อนของคุณซื้อ Leader NFT คุณจะได้รับสิทธิ์ประโยชน์ที่สัมพันธ์กับระดับที่พวกเขาซื้.`,
    info22: `เมื่อผู้แนะนำของคุณซื้อ Rare Leader NFT คุณจะได้รับเพิ่มเติมอีก 3,000 CA, 120 AP และ 240 LRT โทเค็นในรางวัลที่สะสมไว้!`,
    info23: `เมื่อบุคคลที่คุณแนะนำซื้อ Special Leader NFT คุณจะได้รับสิทธิพิเศษในการรับรางวัล CA สะสมเพิ่มเติมอีก 1,000 หน่วย รวมถึง AP 40 หน่วยและ LRT 80 หน่วย!`,
    info24: `เมื่อผู้แนะนำของคุณซื้อ Common Leader NFT คุณจะได้รับเงินรางวัลเพิ่มเติมอย่างรวดเร็ว 500 CA, 20 AP และ 40 LRT tokens ที่สะสมไว้!`,
    info25: `NFT ของโนดถูกออกแบบมาเพื่อช่วยให้คุณได้ก้าวหน้าในการสร้างเครือข่ายของคุณในชุมชน Web3 ที่หลากหลายและมีชีวิตชีวา โดยการขยายเครือข่ายของคุณภายในชุมชน LYNK มากขึ้น คุณจะปลดล็อคประโยชน์มากขึ้น และได้รับรายได้มากขึ้น!`,
    info26: `เริ่มต้นเชิญเพื่อนของคุณและเพลิดเพลินกับสิ่งที่มากับการเป็นส่วนหนึ่งของทีม Leader NFT ร่วมเข้าร่วมกับเราและเริ่มรับรางวัลที่ดีกว่าวันนี้!`,
    minted: 'มิ้นต์',
    soldOut: 'ขายหมด',
  },
  marketPlace: {
    title: 'ตลาด',
    totalVolume: 'ปริมาณรวม',
    floorPrice: 'ราคาพื้นฐาน',
    higherPrice: 'ราคาที่สูงขึ้น',
    owner: 'เจ้าของ',
    tradingFee: 'ค่าธรรมเนียมการซื้อขาย',
    faction: 'ฝ่าย',
    type0: 'Finder',
    type1: 'วิญญาณ',
    type2: 'Exo',
    type3: 'การต่อสู้',
    type4: 'โมฆะ',
    gender: 'เพศ',
    boy: 'ชาย',
    girl: 'หญิง',
    level: 'ระดับ',
    level0: 'ระดับที่ 2-3',
    level1: 'ระดับที่ 4-6',
    level2: 'ระดับที่ 7-9',
    level3: 'ระดับที่ 10-12',
    level4: 'ระดับที่ 13',
    priceDesc: 'ราคา: สูงไปต่ํา',
    priceAsc: 'ราคา: ต่ําไปสูง',
    priceHint: {
      empty: 'โปรดป้อนช่วงราคา',
      numberError: 'ราคาสูงสุดต้องไม่ต่ํากว่าราคาต่ําสุด',
    },
    priceUsdt: 'ราคา USDT',
    to: 'ถึง',
    all: 'ทั้งหมด',
    buy: 'ซื้อ',
    rare: 'หายาก',
    price: 'ราคา',
    balance: 'สมดุล',
  },
};
