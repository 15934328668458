import React, { Suspense } from 'react';
import { ThemeProvider } from '@mui/material/styles';
import { BaseLayout } from 'layouts';
import { Routes, Route, Navigate } from 'react-router-dom';
import theme from 'theme';
import Finders from 'pages/Finders';
import NodeNft from 'pages/NodeNft';
import LeaderNft from 'pages/LeaderNft';
import History from 'pages/History';
import Invitatin from 'pages/Invitation';
import MarketPlace from 'pages/MarketPlace';
import Nfts from 'pages/Nfts';
import Detail from 'pages/Detail';
import Rank from 'pages/Rank';
import Stake from 'pages/Stake';
import { langData } from 'locales';
import { createI18n, I18nProvider } from 'react-simple-i18n';
import BlockNumber from 'components/BlockNumber';
import TransactionsUpdater from 'components/TransactionsUpdater';
import MulticallUpdater from 'components/MulticallUpdater';
import Web3ReactManager from 'components/Web3ReactManager';
import TransactionModal from 'components/TransactionModal';
import TipModal from 'components/TipModal';
import RegisterModal from 'components/RegisterModal';
import ErrorBoundary from 'components/ErrorBoundary';
import TransactionPopup from 'components/TransactionPopup';
import { useAppSelector } from 'hooks/redux';
import { useAppDispatch } from 'hooks/redux';
import { updateInviter } from 'store/features/applicationSlice';

function App() {
  const { lang } = useAppSelector((state) => state.user);
  const inviter = window.location.href?.split('?referrer=')?.[1];
  const dispatch = useAppDispatch();

  React.useEffect(() => {
    if (inviter) {
      dispatch(updateInviter({ inviter: inviter }));
    } else {
      dispatch(updateInviter({ inviter: '' }));
    }
  }, [dispatch, inviter]);

  return (
    <ErrorBoundary>
      <Suspense>
        <I18nProvider i18n={createI18n(langData, { lang: lang })}>
          <ThemeProvider theme={theme}>
            <BlockNumber />
            <TransactionsUpdater />
            <TransactionModal />
            <TipModal />
            <RegisterModal />
            <MulticallUpdater />
            <TransactionPopup />
            <Web3ReactManager>
              <Routes>
                <Route path="/" element={<BaseLayout />}>
                  <Route
                    index
                    element={
                      <Navigate
                        to={
                          window.location.href?.split('?referrer=')?.[1]
                            ? `/mint/leader-nft?referrer=${inviter}`
                            : '/mint/leader-nft'
                        }
                      />
                    }
                  />
                  <Route path="profile">
                    <Route index element={<Navigate to={'/profile/nfts'} />} />
                    <Route path="nfts" element={<Nfts />}>
                      <Route path=":tokenId" element={<Detail />} />
                    </Route>
                    <Route path="stake" element={<Stake />}>
                      <Route path=":tokenId" element={<Detail />} />
                    </Route>
                    <Route path="invitation" element={<Invitatin />} />
                    <Route path="history" element={<History />} />
                  </Route>
                  <Route path="mint">
                    <Route
                      index
                      element={
                        <Navigate
                          to={
                            window.location.href?.split('?referrer=')?.[1]
                              ? `/mint/leader-nft?referrer=${inviter}`
                              : '/mint/leader-nft'
                          }
                        />
                      }
                    />
                    <Route path="finders" element={<Finders />} />
                    <Route path="node-nft" element={<NodeNft />} />
                    <Route path="leader-nft" element={<LeaderNft />} />
                  </Route>
                  <Route path="market-place" element={<MarketPlace />}>
                    <Route path=":tokenId" element={<Detail />} />
                  </Route>
                  <Route path="rank" element={<Rank />}>
                    <Route path=":tokenId" element={<Detail />} />
                  </Route>
                  <Route
                    path="*"
                    element={
                      <Navigate
                        to={
                          window.location.href?.split('?referrer=')?.[1]
                            ? `/mint/leader-nft?referrer=${inviter}`
                            : '/mint/leader-nft'
                        }
                      />
                    }
                  />
                </Route>
              </Routes>
            </Web3ReactManager>
          </ThemeProvider>
        </I18nProvider>
      </Suspense>
    </ErrorBoundary>
  );
}

export default App;
