import React, { useCallback } from 'react';
import { useI18n } from 'react-simple-i18n';
import { useAppDispatch } from 'hooks/redux';
import BaseModal from 'components/BaseModal';
import { Stack, Typography, styled, Box, keyframes } from '@mui/material';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import { StepIconProps } from '@mui/material/StepIcon';
import { ReactComponent as StepIcon } from 'assets/img/finder/stepIcon.svg';
import { ReactComponent as StepCheckIcon } from 'assets/img/finder/stepCheckIcon.svg';
import { ReactComponent as BaseLoading } from 'assets/svg/loading.svg';
import finderEgg from 'assets/img/finder/finderEgg.png';
import { ReactComponent as UsdtIcon } from 'assets/img/finder/usdtIcon.svg';
import { BASE_IMAGE_URL, USDT, LYNKNFT_ADDRESS } from 'constants/index';
import { useTokenBalance } from 'hooks/useWallet';
import { useActiveWeb3React } from 'hooks';
import { ApprovalState, useApproveCallback } from 'hooks/useApproveCallback';
import { tryParseAmount } from 'utils/parseAmount';
import { useUserHasSubmitted } from 'hooks/useTransaction';
import { useUser } from 'hooks/useUser';
import { useFledgling } from 'hooks/useFledgling';
import {
  showTransactionConfirmModal,
  showTransactionPendingModal,
  showTransactionErrorModal,
} from 'store/features/componentSlice';

const EggImage = styled(Box)(({ theme }) => ({
  height: theme.spacing(22),
  width: theme.spacing(22),
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundImage: `url(${finderEgg})`,
  backgroundPosition: 'center',
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
  position: 'relative',
}));

const EarlyBirdImage = styled('img')(({ theme }) => ({
  height: theme.spacing(28),
  width: theme.spacing(28),
  marginTop: theme.spacing(2),
  border: '1px solid #000000',
}));

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const Loading = styled(BaseLoading)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  width: 160px;
  height: 120px;
  animation: ${rotate} 2s linear infinite;
  transform: translate3d(0, 0, 0);
`;

export default function MintFinderModal({
  open,
  handleClose,
}: {
  open: boolean;
  handleClose: () => void;
}): JSX.Element {
  const { t } = useI18n();
  const { account } = useActiveWeb3React();
  const dispatch = useAppDispatch();
  const { earlyBirdMinted, earlyBirdId } = useUser();
  const balance = useTokenBalance(account ?? undefined, USDT);
  const payAmount = tryParseAmount('5000', USDT);
  const { mint } = useFledgling();
  const enoughAsset = balance && payAmount && !balance.lessThan(payAmount);
  const [approvalState, approveCallback] = useApproveCallback(payAmount, LYNKNFT_ADDRESS);
  const { submitted: minting } = useUserHasSubmitted(`${account}_fledgling_mint` ?? undefined);

  const steps = [t('global.approve'), t('nftDetail.mint'), t('global.finish')];

  const mintCallback = useCallback(async () => {
    // if (earlyBirdMinted) {
    //   push('/profile/nfts')
    //   return
    // }
    dispatch(showTransactionPendingModal());
    mint()
      .then(() => {
        dispatch(showTransactionConfirmModal());
      })
      .catch((err: any) => {
        dispatch(
          showTransactionErrorModal(
            err.error && err.error.message
              ? err.error.message
              : err?.data?.message
              ? err?.data?.message
              : err.message ?? t('global.networkError')
          )
        );
        console.error(err);
      });
  }, [dispatch, mint, t]);

  const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
      top: 10,
    },
    [`&.${stepConnectorClasses.active}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        background: '#0167BC',
      },
    },
    [`&.${stepConnectorClasses.completed}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        background: '#0167BC',
      },
    },
    [`& .${stepConnectorClasses.line}`]: {
      height: 3,
      border: 0,
      backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
      borderRadius: 1,
    },
  }));
  const ColorlibStepIconRoot = styled('div')<{
    ownerState: { completed?: boolean; active?: boolean };
  }>(({ ownerState }) => ({
    backgroundColor: '#fff',
    zIndex: 1,
    color: '#fff',
    width: 22,
    height: 22,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
    ...(ownerState.active && {
      background: '#0167BC',
    }),
    ...(ownerState.completed && {
      background: '#0167BC',
    }),
  }));

  function ColorlibStepIcon(props: StepIconProps) {
    const { active, completed, className } = props;

    return (
      <ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
        {active || completed ? <StepCheckIcon /> : <StepIcon />}
      </ColorlibStepIconRoot>
    );
  }

  return (
    <BaseModal
      open={open}
      contentWidth={560}
      contentMobileWidth={308}
      confirmText={
        earlyBirdMinted
          ? t('global.finish')
          : !enoughAsset
          ? t('global.insufficient')
          : approvalState === ApprovalState.NOT_APPROVED
          ? t('global.approve')
          : approvalState === ApprovalState.PENDING
          ? t('global.approving')
          : minting
          ? t('global.minting')
          : t('nftDetail.mint')
      }
      confirm={
        earlyBirdMinted ? handleClose : approvalState === ApprovalState.NOT_APPROVED ? approveCallback : mintCallback
      }
      confirmDisabled={!enoughAsset && !earlyBirdMinted}
      confirmLoading={minting || approvalState === ApprovalState.PENDING}
      title={t('nftDetail.mintAction')}
      handleClose={handleClose}
    >
      <Stack
        sx={(theme) => ({
          justifyContent: 'space-between',
          [theme.breakpoints.down('sm')]: {
            zoom: 0.8,
          },
        })}
        alignItems="center"
      >
        <Stepper
          sx={{ width: '90%' }}
          alternativeLabel
          activeStep={earlyBirdId ? 2 : approvalState === ApprovalState.APPROVED ? 1 : 0}
          connector={<ColorlibConnector />}
        >
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel StepIconComponent={ColorlibStepIcon}>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        {earlyBirdId ? (
          <EarlyBirdImage src={`${BASE_IMAGE_URL}${earlyBirdId}.png`} />
        ) : (
          <>
            <EggImage>{(minting || approvalState === ApprovalState.PENDING) && <Loading />}</EggImage>
            <Stack direction="row" height={24} spacing={1}>
              <Typography>{`${t('nftDetail.price')} 5000`}</Typography>
              <UsdtIcon />
            </Stack>
            <Typography fontWeight={700} color={'#2765B7'}>
              {`${t('global.balance')}: ${balance ? balance?.toFixed(2, { groupSeparator: ',' }) : '--'}`}
            </Typography>
          </>
        )}
      </Stack>
    </BaseModal>
  );
}
