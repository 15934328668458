import { createSlice } from '@reduxjs/toolkit';
import type { RootState } from 'store';

interface ComponentState {
  walletModalOpen: boolean;
  transactionModalOpen: boolean;
  transactionModalPending: boolean;
  transactionModalConfirm: boolean;
  transactionModalError: boolean;
  transactionModalErrorText: string;
  tipModalOpen: boolean;
  tipValue: string;
  registerModalOpen: boolean;
}

const initialState: ComponentState = {
  walletModalOpen: false,
  transactionModalOpen: false,
  transactionModalPending: false,
  transactionModalConfirm: false,
  transactionModalError: false,
  transactionModalErrorText: '',
  tipModalOpen: false,
  tipValue: '',
  registerModalOpen: false,
};

export const componentSlice = createSlice({
  name: 'component',
  initialState,
  reducers: {
    showWalletModal: (state) => {
      state.walletModalOpen = true;
    },
    closeWalletModal: (state) => {
      state.walletModalOpen = false;
    },
    showTransactionPendingModal: (state) => {
      state.transactionModalOpen = true;
      state.transactionModalPending = true;
      state.transactionModalConfirm = false;
      state.transactionModalError = false;
    },
    showTransactionConfirmModal: (state) => {
      state.transactionModalOpen = true;
      state.transactionModalPending = false;
      state.transactionModalConfirm = true;
      state.transactionModalError = false;
    },
    showTransactionErrorModal: (state, { payload }) => {
      state.transactionModalOpen = true;
      state.transactionModalPending = false;
      state.transactionModalConfirm = false;
      state.transactionModalError = true;
      state.transactionModalErrorText = payload;
    },
    closeTransactionModal: (state) => {
      state.transactionModalPending = false;
      state.transactionModalConfirm = false;
      state.transactionModalError = false;
      state.transactionModalErrorText = '';
      state.transactionModalOpen = false;
    },
    showTipModal: (state, { payload }) => {
      if (payload) {
        state.tipValue = payload;
        state.tipModalOpen = true;
      }
    },
    closeTipModal: (state) => {
      state.tipValue = '';
      state.tipModalOpen = false;
    },
    showRegisterModal: (state) => {
      state.registerModalOpen = true;
    },
    closeRegisterModal: (state) => {
      state.registerModalOpen = false;
    },
  },
});

export const {
  showWalletModal,
  closeWalletModal,
  showTransactionPendingModal,
  showTransactionConfirmModal,
  showTransactionErrorModal,
  closeTransactionModal,
  showTipModal,
  closeTipModal,
  showRegisterModal,
  closeRegisterModal,
} = componentSlice.actions;

export const componentState = (state: RootState) => state.component;

export default componentSlice.reducer;
