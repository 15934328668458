import { useState, useEffect, useRef } from 'react';
import moment from 'moment';

interface IntervalFrameProps {
  fn?: () => void;
  delay: number;
  startTime?: number;
  startTimeOut?: () => void;
  endTime?: number;
  endTimeOut?: () => void;
}
const useIntervalFrame = ({ fn, delay, startTime, startTimeOut, endTime, endTimeOut }: IntervalFrameProps) => {
  const [diff, setDiff] = useState(moment().diff(moment(), 'seconds'));
  const [timeInfo, setTimeInfo] = useState({ days: 0, hours: 0, minutes: 0, seconds: 0 });
  const requestRef = useRef(0);
  const previousTimeRef = useRef(0);
  const fnRef = useRef<() => void>();
  const startTimeOutRef = useRef<() => void>();
  const endTimeOutRef = useRef<() => void>();
  fnRef.current = fn;
  startTimeOutRef.current = startTimeOut;
  endTimeOutRef.current = endTimeOut;

  const clearIntervalFrame = () => {
    cancelAnimationFrame(requestRef.current);
  };

  useEffect(() => {
    const durationTime = moment.duration(diff, 'seconds');
    const days = isNaN(durationTime.days()) ? 0 : durationTime.days();
    const hours = isNaN(durationTime.hours()) ? 0 : durationTime.hours();
    const minutes = isNaN(durationTime.minutes()) ? 0 : durationTime.minutes();
    const seconds = isNaN(durationTime.seconds()) ? 0 : durationTime.seconds();
    setTimeInfo({ days, hours, minutes, seconds });
  }, [diff]);

  useEffect(() => {
    if (delay <= 0 || delay === undefined || delay === null) {
      return;
    }
    const animate = () => {
      if (!previousTimeRef.current) {
        previousTimeRef.current = moment().valueOf();
      }
      if (moment().valueOf() - previousTimeRef.current > delay) {
        previousTimeRef.current = previousTimeRef.current + delay;
        fnRef.current?.();
        if (startTime && endTime) {
          if (moment().isBefore(moment(startTime))) {
            setDiff(moment(startTime).diff(moment(), 'seconds'));
          } else if (moment().isBetween(moment(startTime), moment(endTime))) {
            startTimeOutRef.current?.();
            setDiff(moment(endTime).diff(moment(), 'seconds'));
          } else if (moment().isAfter(moment(endTime))) {
            endTimeOutRef.current?.();
            setDiff(0);
            cancelAnimationFrame(requestRef.current);
            return;
          }
        } else if (endTime) {
          if (moment().isAfter(moment(endTime))) {
            endTimeOutRef.current?.();
            setDiff(0);
            cancelAnimationFrame(requestRef.current);
            return;
          } else {
            setDiff(moment(endTime).diff(moment(), 'seconds'));
          }
        }
      }
      requestRef.current = requestAnimationFrame(animate);
    };
    requestRef.current = requestAnimationFrame(animate);
    return () => cancelAnimationFrame(requestRef.current);
  }, [delay, endTime, startTime]);

  return { clearIntervalFrame, timeInfo };
};

export default useIntervalFrame;
