import React from 'react';
import { useI18n } from 'react-simple-i18n';
import { useAppSelector, useAppDispatch } from 'hooks/redux';
import BaseModal from 'components/BaseModal';
import { Stack, Typography, InputBase, styled } from '@mui/material';
import { isAddress } from 'utils';
import { useUser, useValidAddress } from 'hooks/useUser';
import {
  showTransactionConfirmModal,
  showTransactionPendingModal,
  showTransactionErrorModal,
  closeRegisterModal,
} from 'store/features/componentSlice';
import { updateInviter } from 'store/features/applicationSlice';
import { useLocation, useNavigate } from 'react-router-dom';

const NameInput = styled(InputBase)(({ theme }) => ({
  borderRadius: 7,
  height: theme.spacing(4),
  width: '80%',
  background: '#F0F0F0',
  padding: theme.spacing(0, 2),
  '&.usdtNumber': {
    padding: theme.spacing(0, 0, 0, 2),
  },
}));

export default function RegisterModal(): JSX.Element {
  const { t } = useI18n();
  const dispatch = useAppDispatch();
  const { registerModalOpen } = useAppSelector((state) => state.component);
  const { inviter } = useAppSelector((state) => state.application);
  const [code, setCode] = React.useState('');
  const [error, setError] = React.useState(false);
  const [address, setAddress] = React.useState<undefined | string>();
  const validAddress = useValidAddress(address);
  const validInviter = useValidAddress(window.atob(inviter));
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { register } = useUser();

  const registerCallback = React.useCallback(async () => {
    dispatch(showTransactionPendingModal());
    register(inviter ? window.atob(inviter) : address ?? '')
      .then(() => {
        dispatch(updateInviter({ inviter: '' }));
        navigate(pathname);
        dispatch(showTransactionConfirmModal());
        dispatch(closeRegisterModal());
      })
      .catch((err: any) => {
        dispatch(
          showTransactionErrorModal(
            err.error && err.error.message
              ? err.error.message
              : err?.data?.message
              ? err?.data?.message
              : err.message ?? t('global.networkError')
          )
        );
        console.error(err);
      });
  }, [dispatch, register, inviter, address, navigate, pathname, t]);

  React.useEffect(() => {
    if (!inviter) {
      try {
        const formatAddress = window.atob(code);
        if (isAddress(formatAddress)) {
          setError(false);
          setAddress(formatAddress);
        } else {
          setAddress(undefined);
          setError(true);
        }
        setAddress(window.atob(code));
      } catch (e) {
        setError(true);
      }
    }
  }, [inviter, code]);

  return (
    <BaseModal
      open={registerModalOpen}
      contentWidth={460}
      contentMobileWidth={308}
      confirm={registerCallback}
      confirmText={
        inviter
          ? validInviter
            ? t('global.register')
            : ''
          : (!inviter && !validAddress) || error
          ? t('global.unValidCode')
          : t('global.register')
      }
      confirmDisabled={inviter ? !validInviter : !validAddress || error}
      title={t(inviter ? 'global.tip' : 'global.registerTitle')}
      handleClose={() => dispatch(closeRegisterModal())}
    >
      {inviter ? (
        validInviter ? (
          <Stack
            sx={(theme) => ({
              justifyContent: 'space-between',
              [theme.breakpoints.down('sm')]: {
                height: theme.spacing(8),
                justifyContent: 'center',
              },
            })}
            alignItems="center"
          >
            <Typography
              sx={(theme) => ({
                lineHeight: theme.spacing(4),
                width: '80%',
                textAlign: 'center',
                fontWeight: 500,
                [theme.breakpoints.down('sm')]: {
                  lineHeight: theme.spacing(2),
                  justifyContent: 'center',
                },
              })}
            >
              {t('global.registerInviterDes')}
            </Typography>
          </Stack>
        ) : (
          <Stack
            sx={(theme) => ({
              justifyContent: 'space-between',
              [theme.breakpoints.down('sm')]: {
                height: theme.spacing(10),
                justifyContent: 'center',
              },
            })}
            alignItems="center"
          >
            <Typography
              sx={(theme) => ({
                lineHeight: theme.spacing(10),
                width: '80%',
                textAlign: 'center',
                fontWeight: 500,
                [theme.breakpoints.down('sm')]: {
                  lineHeight: theme.spacing(2),
                  justifyContent: 'center',
                },
              })}
            >
              {t('global.unValidCode')}
            </Typography>
          </Stack>
        )
      ) : (
        <Stack
          sx={(theme) => ({
            justifyContent: 'space-between',
            [theme.breakpoints.down('sm')]: {
              height: theme.spacing(10),
              justifyContent: 'center',
            },
          })}
          alignItems="center"
        >
          <NameInput
            value={code}
            placeholder={t('global.registerPlh')}
            onChange={(e) => {
              setCode(e.target.value);
            }}
          />
          <Typography
            color="#454545"
            sx={(theme) => ({
              lineHeight: theme.spacing(4),
              width: '80%',
              zoom: 0.8,
              // textAlign: 'center',
              [theme.breakpoints.down('sm')]: {
                lineHeight: theme.spacing(2),
              },
            })}
          >
            {t('global.registerDes')}
          </Typography>
        </Stack>
      )}
    </BaseModal>
  );
}
