import { useDBContract, useLYNKNFTContract, useSLYNKNFTContract, useUserContract } from 'hooks/useContract';
import { useCallback } from 'react';
import { useActiveWeb3React } from 'hooks';
import { calculateGasMargin, isAddress } from 'utils';
import { TransactionResponse } from '@ethersproject/providers';
import { useTransactionAdder } from 'hooks/useTransaction';
import { useSingleCallResult, useSingleContractMultipleData } from 'hooks/useRequest';
import { ROOT } from '../constants';

export function useUser() {
  const contract = useUserContract();
  const LYNKNFTContract = useLYNKNFTContract();
  const { account } = useActiveWeb3React();
  const addTransaction = useTransactionAdder();

  const balance = useSingleCallResult(LYNKNFTContract, 'balanceOf', [account ?? undefined])?.result?.[0];
  const earlyBirdMinted = useSingleCallResult(LYNKNFTContract, 'earlyBirdMintedOf', [account ?? undefined]);
  const earlyBirdId = useSingleCallResult(
    LYNKNFTContract,
    'tokenOfOwnerByIndex',
    earlyBirdMinted?.result?.[0] && balance && account
      ? [account, (balance - 1 > 0 ? balance - 1 : 0).toString()]
      : [undefined]
  )?.result?.[0];

  const register = useCallback(
    async (address: string) => {
      if (!account) throw new Error('none account');
      if (!contract) throw new Error('none contract');
      const args = [address];
      const method = 'register';
      return contract.estimateGas[method](...args, { from: account }).then((estimatedGasLimit) => {
        return contract[method](...args, {
          gasLimit: calculateGasMargin(estimatedGasLimit),
          // gasLimit: '3500000',
          from: account,
        }).then((response: TransactionResponse) => {
          addTransaction(response, {
            summary: '$%global.register$%',
            claim: { recipient: `${account}_register` },
          });
          return response.hash;
        });
      });
    },
    [account, addTransaction, contract]
  );

  return {
    register,
    earlyBirdId,
    userLoading: earlyBirdMinted.loading,
    earlyBirdMinted: earlyBirdMinted?.result?.[0] as boolean | undefined,
  };
}

export function useValidAddress(address: string | undefined) {
  const contract = useUserContract();
  const arg = isAddress(address);
  const valid = useSingleCallResult(contract, 'isValidUser', [arg ? address : undefined]);
  return (arg && arg.toLowerCase() === ROOT.toLowerCase()) || !!valid.result?.[0];
}

export function useUserInfo() {
  const { account } = useActiveWeb3React();
  const addTransaction = useTransactionAdder();
  const DBContract = useDBContract();
  const slynkNFTContract = useSLYNKNFTContract();
  const userContract = useUserContract();
  const userInfo = useSingleCallResult(userContract, 'userInfoOf', [account ?? undefined])?.result;
  const level = userInfo?.level;
  const achievementRewardAmounts = useSingleCallResult(DBContract, 'achievementRewardAmounts', [level])?.result;
  const communityRewardRatesNumByLevel = useSingleCallResult(DBContract, 'communityRewardRatesNumByLevel', [
    level,
  ])?.result;
  const socialRewardRates = useSingleCallResult(DBContract, 'socialRewardRates', [level])?.result;
  const contributionOf = useSingleCallResult(userContract, 'contributionOf', [account ?? undefined])?.result;
  const achievementCounter = useSingleCallResult(userContract, 'achievementCounter', [account ?? undefined])?.result;
  const stakedCount = useSingleCallResult(slynkNFTContract, 'balanceOf', [account ?? undefined])?.result;
  const levelArgs = account
    ? [
        [account, '0'],
        [account, '1'],
        [account, '2'],
        [account, '3'],
        [account, '4'],
        [account, '5'],
      ]
    : [undefined];

  const inviterOfLevel = useSingleContractMultipleData(account ? userContract : null, 'refCounterOf', levelArgs);
  const inviterMaxOfLevel = useSingleContractMultipleData(DBContract, 'directRequirements', [
    ['0'],
    ['1'],
    ['2'],
    ['3'],
    ['4'],
  ]);
  const caMaxOfLevel = useSingleContractMultipleData(DBContract, 'performanceRequirements', [
    ['0'],
    ['1'],
    ['2'],
    ['3'],
    ['4'],
  ]);

  const upgrade = useCallback(async () => {
    if (!account) throw new Error('none account');
    if (!userContract) throw new Error('none contract');
    const args = [account];
    const method = 'auditLevel';
    return userContract.estimateGas[method](...args, { from: account }).then((estimatedGasLimit) => {
      return userContract[method](...args, {
        gasLimit: calculateGasMargin(estimatedGasLimit),
        // gasLimit: '3500000',
        from: account,
      }).then((response: TransactionResponse) => {
        addTransaction(response, {
          summary: '$%nftDetail.upgrade$%',
        });
        return response.hash;
      });
    });
  }, [account, addTransaction, userContract]);

  return {
    upgrade,
    level,
    socialRev: userInfo?.socialRev,
    communityRev: userInfo?.communityRev,
    contributionRev: userInfo?.contributionRev,
    achievementRev: userInfo?.achievementRev,
    achievementCounter: achievementCounter?.[0],
    stakeRev: userInfo?.stakeRev,
    socialRewardRates: socialRewardRates?.[0],
    contributionOf: contributionOf?.[0],
    achievementRewardAmounts: achievementRewardAmounts?.[0],
    communityRewardRatesNumByLevel: communityRewardRatesNumByLevel?.[0],
    stakedCount: stakedCount?.[0],
    inviterOfLevel,
    inviterMaxOfLevel,
    currenCaValue: userInfo?.performance ?? 0,
    caMaxOfLevel,
  };
}
