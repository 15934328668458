import { useDBContract, useLYNKNFTContract } from './useContract';
import { useSingleCallResult } from 'hooks/useRequest';
import { useCallback } from 'react';
import { calculateGasMargin, isAddress } from '../utils';
import { TransactionResponse } from '@ethersproject/providers';
import { useTransactionAdder } from 'hooks/useTransaction';
import { useActiveWeb3React } from 'hooks';

export function useIsWhitelistInFledgling(address: string | undefined) {
  const contract = useDBContract();
  const account = isAddress(address);
  const isWhitelist = useSingleCallResult(contract, 'earlyBirdMintWlOf', [account ? account : undefined]);

  return {
    loading: isWhitelist?.loading,
    isWhitelist: isWhitelist?.result ? isWhitelist?.result?.[0] : false,
  };
}

export function useFledgling() {
  const { account } = useActiveWeb3React();
  const addTransaction = useTransactionAdder();
  const contract = useLYNKNFTContract();
  const mintInfo = useSingleCallResult(contract, 'earlyMintInfo')?.result;
  const mint = useCallback(async () => {
    if (!account) throw new Error('none account');
    if (!contract) throw new Error('none contract');
    const method = 'earlyBirdMint';
    return contract.estimateGas[method]({ from: account }).then((estimatedGasLimit) => {
      return contract[method]({
        gasLimit: calculateGasMargin(estimatedGasLimit),
        // gasLimit: '3500000',
        from: account,
      }).then((response: TransactionResponse) => {
        addTransaction(response, {
          summary: '$%nftDetail.mintAction$%',
          claim: { recipient: `${account}_fledgling_mint` },
        });
        return response.hash;
      });
    });
  }, [account, addTransaction, contract]);

  return {
    remainNum: mintInfo ? mintInfo?._remainNum : undefined,
    totalNum: mintInfo ? mintInfo?._totalNum : undefined,
    nextId: mintInfo ? mintInfo._nextId.toString() : '',
    mint,
  };
}
