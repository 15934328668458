import React from 'react';
import { styled } from '@mui/material';
import AppBar from 'components/AppBar';
import NodeNftContent from 'components/NodeNftContent';
import { useI18n } from 'react-simple-i18n';
import nodeNftBg from 'assets/img/nodeNft/nodeNftBg.png';
import nodeNftMobileBg from 'assets/img/nodeNft/nodeNftMobileBg.png';

const MainContainer = styled('div')(({ theme }) => ({
  display: 'grid',
  position: 'relative',
  height: 'calc(100%)',
  width: `calc(100% - ${theme.spacing(14)})`,
  padding: theme.spacing(0, 7),
  gridTemplateRows: `${theme.spacing(10.5)} 1fr`,
  backgroundImage: `url(${nodeNftBg})`,
  backgroundPosition: 'right bottom',
  backgroundSize: '645.6px 766.8px',
  backgroundRepeat: 'no-repeat',
  [theme.breakpoints.down('sm')]: {
    gridTemplateRows: `${theme.spacing(9)} 1fr`,
    width: `calc(100% - ${theme.spacing(6)})`,
    padding: theme.spacing(0, 3),
    backgroundImage: 'none',
    backgroundSize: '271px 348px',
    overflow: 'overlay',
  },
  gridTemplateColumns: '1fr',
  gridTemplateAreas: `
    'nodeNftT'
    'nodeNftB'
  `,
  overflow: 'hidden',
}));

const Header = styled('div')({
  gridArea: 'nodeNftT',
  position: 'relative',
});

const Bg = styled('img')(({ theme }) => ({
  position: 'fixed',
  right: 0,
  bottom: 0,
  width: '271px',
  height: '348px',
  opacity: 0.3,
  zIndex: -1,
  [theme.breakpoints.up('sm')]: {
    display: 'none',
  },
}));

const NodeNft = () => {
  const { t } = useI18n();

  return (
    <MainContainer>
      <Header>
        <AppBar title={t('nodeNft.title')}></AppBar>
      </Header>
      <Bg src={nodeNftMobileBg} />
      <NodeNftContent />
    </MainContainer>
  );
};

export default NodeNft;
