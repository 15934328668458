import React, { useState, useCallback, useEffect } from 'react';
import { styled, Typography, Button, Stack, Paper, Box, Chip } from '@mui/material';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import { useI18n } from 'react-simple-i18n';
import { useActiveWeb3React } from 'hooks';
import { useAppDispatch } from 'hooks/redux';
import {
  showTransactionConfirmModal,
  showTransactionPendingModal,
  showTransactionErrorModal,
} from 'store/features/componentSlice';
import header from 'assets/img/invitation/header.png';
import mobileHeader from 'assets/img/invitation/mobileHeader.png';
import rewardsBorder from 'assets/img/invitation/rewardsBorder.svg';
import myLevelBorder from 'assets/img/invitation/myLevelBorder.svg';
import BaseTooltip from 'components/BaseTooltip';
import { useUserInfo, useValidAddress } from 'hooks/useUser';
import { CurrencyAmount } from 'constants/token';
import JSBI from 'jsbi';
import { showRegisterModal } from 'store/features/componentSlice';
import InvitationLinkModal from 'components/InvitationLinkModal';
import { showWalletModal } from 'store/features/componentSlice';
import { useNodeMinted } from 'hooks/useNFT';
import low from 'assets/img/invitation/low.png';
import middle from 'assets/img/invitation/middle.png';
import high from 'assets/img/invitation/high.png';
import { getSumcaCountReward } from 'utils/fetch/graph';
import _ from 'lodash';
import moment from 'moment';
import CONFIG from 'config';

const COUNTREWARDAPI = CONFIG.sumcaCountRewardApi;

const InvitationContentBox = styled('div')(({ theme }) => ({
  gridArea: 'invitationB',
  display: 'grid',
  paddingTop: theme.spacing(2),
  gridTemplateRows: `${theme.spacing(32.5)} ${theme.spacing(21)} 1fr ${theme.spacing(5)}`,
  gridTemplateColumns: `1fr`,
  gridTemplateAreas: `
    'invitationDes'
    'invitationRewards'
    'myLevel'
    'invitationBottom'
  `,
  [theme.breakpoints.between('sm', 'xl')]: {
    zoom: 0.8,
  },
  [theme.breakpoints.down('sm')]: {
    '&::-webkit-scrollbar': {
      width: 0,
    },
    paddingTop: theme.spacing(0),
    gridTemplateRows: `${theme.spacing(4)} ${theme.spacing(33.5)} auto 1fr ${theme.spacing(4)}`,
    gridTemplateColumns: `1fr`,
    gridTemplateAreas: `
      'invitationTitle'
      'invitationDes'
      'invitationRewards'
      'myLevel'
      'invitationBottom'
    `,
  },
}));

const StakeTitle = styled('div')(({ theme }) => ({
  gridArea: 'invitationTitle',
  [theme.breakpoints.up('sm')]: {
    display: 'none',
  },
}));

const InvitationDes = styled('div')(({ theme }) => ({
  gridArea: 'invitationDes',
  backgroundImage: `url(${header})`,
  backgroundPosition: 'center',
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
  borderRadius: 25,
  padding: theme.spacing(5),
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  gap: theme.spacing(3),
  [theme.breakpoints.down('sm')]: {
    marginTop: theme.spacing(2),
    padding: theme.spacing(4),
    justifyContent: 'flex-end',
    gap: theme.spacing(2),
    with: `calc(100% - ${theme.spacing(2)})`,
    backgroundImage: `url(${mobileHeader})`,
  },
}));

const InvitationRewards = styled('div')(({ theme }) => ({
  gridArea: 'invitationRewards',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  [theme.breakpoints.down('sm')]: {
    gap: theme.spacing(1),
  },
}));

const MyLevel = styled('div')(() => ({
  gridArea: 'myLevel',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
}));

const NodeNftLevel = styled('img')(({ theme }) => ({
  width: theme.spacing(5),
  height: theme.spacing(5),
  [theme.breakpoints.down('sm')]: {
    width: theme.spacing(4),
    height: theme.spacing(4),
  },
}));

const LevelPaper = styled(Paper)(({ theme }) => ({
  width: `calc(100% - ${theme.spacing(8)} - 4px)`,
  height: '100%',
  alignSelf: 'center',
  position: 'relative',
  padding: theme.spacing(1, 4),
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(2),
    width: `calc(100% - ${theme.spacing(4)} - 4px)`,
  },
}));

const MyLevelBorder = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: '-3px',
  right: '-3px',
  width: theme.spacing(18.5),
  height: theme.spacing(21.5),
  backgroundImage: `url(${myLevelBorder})`,
  backgroundPosition: 'center',
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
}));

const MyLevelBottomBorder = styled(Box)(({ theme }) => ({
  position: 'absolute',
  bottom: '-3px',
  left: '-3px',
  width: theme.spacing(18.5),
  height: theme.spacing(21.5),
  backgroundImage: `url(${myLevelBorder})`,
  transform: 'rotate(180deg)',
  backgroundPosition: 'center',
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
}));

const TypographyDes = styled(Typography)(({ theme }) => ({
  fontSize: 32,
  fontWeight: 700,
  [theme.breakpoints.down('sm')]: {
    fontSize: 20,
    lineHeight: theme.spacing(6),
  },
}));

const InviteButton = styled(Button)(({ theme }) => ({
  height: theme.spacing(4),
  width: 'fit-content',
  fontSize: 13,
  fontWeight: 700,
}));

const RewardsStack = styled(Stack)(({ theme }) => ({
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  gap: theme.spacing(2),
  [theme.breakpoints.down('sm')]: {
    flexWrap: 'wrap',
    padding: '2px',
    gap: theme.spacing(2),
  },
}));

const RewardsBox = styled(Box)(({ theme }) => ({
  justifyContent: 'center',
  alignItems: 'center',
  display: 'flex',
  width: theme.spacing(26),
  height: theme.spacing(14.5),
  position: 'relative',
  border: '3px solid #78dbe040',
  borderRadius: theme.spacing(1),
  [theme.breakpoints.down('sm')]: {
    width: '45%',
  },
}));

const RewardsBorder = styled(Box)(({ theme }) => ({
  position: 'absolute',
  width: theme.spacing(2.5),
  height: theme.spacing(2.5),
  backgroundImage: `url(${rewardsBorder})`,
  transform: 'rotate(270deg)',
  backgroundPosition: 'center',
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
}));

const RewardsPaper = styled(Paper)(({ theme }) => ({
  width: theme.spacing(25),
  height: theme.spacing(13.5),
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  [theme.breakpoints.down('sm')]: {
    width: `calc(100% - ${theme.spacing(1)})`,
  },
}));

const RewardsPaperStack = styled(Stack)(({ theme }) => ({
  width: theme.spacing(23.5),
  height: theme.spacing(11),
  justifyContent: 'space-between',
  alignItems: 'center',
  // [theme.breakpoints.down('sm')]: {
  //   // width: '100%',
  //   zoom: 0.85,
  // },
}));

const RewardsTitle = styled(Typography)<{ longTitle: boolean }>(({ theme, longTitle }) => ({
  fontSize: 13,
  lineHeight: theme.spacing(4),
  display: 'flex',
  alignItems: 'center',
  borderBottom: '1.5px solid rgba(139, 139, 139, 0.5)',
  zoom: longTitle ? 0.7 : 1,
  whiteSpace: 'nowrap',
  fontWeight: longTitle ? 900 : 700,
  [theme.breakpoints.down('sm')]: {
    fontSize: 12,
    zoom: 0.5,
  },
}));

const TypographyHelp = styled(Typography)({
  height: 10,
  width: 10,
  borderRadius: 5,
  border: '0.5px solid #000',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  cursor: 'default',
  '& span': {
    zoom: 0.8,
  },
});

const RewardTypographyDes = styled(Typography)<{ longDes: boolean; isRU: boolean; isJP: boolean }>(
  ({ theme, longDes, isRU, isJP }) => ({
    fontWeight: 500,
    color: '#000000',
    whiteSpace: 'nowrap',
    zoom: isRU ? 0.6 : isJP ? 0.65 : longDes ? 0.8 : 1,
    [theme.breakpoints.down('sm')]: {
      zoom: isRU ? 0.4 : isJP ? 0.45 : longDes ? 0.55 : 0.7,
    },
  })
);

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 3,
  width: '100%',
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 3,
    backgroundColor: '#72BCDE',
  },
}));

const CountRewards = styled(Box)<{ longlang: boolean }>(({ theme, longlang }) => ({
  height: theme.spacing(3.5),
  padding: theme.spacing(0, 1),
  borderRadius: '7px',
  textTransform: 'unset',
  border: '1px solid #64B6DC',
  color: '#000000de',
  display: 'flex',
  alignItems: 'center',
  fontSize: 18,
  gap: theme.spacing(1),
  [theme.breakpoints.down('sm')]: {
    zoom: longlang ? 0.6 : 0.9,
    width: '100%',
    justifyContent: 'space-between',
    '& .count-rewards-value': {
      flex: 1,
      justifyContent: 'space-between',
    },
  },
}));

interface CardItemProps {
  title: string;
  describe: string;
  leftTitle: string;
  leftNumber: string;
  rightTitle: string;
  rightNumber: string;
}

const CardItem = (props: CardItemProps) => {
  const { title, describe, leftTitle, leftNumber, rightTitle, rightNumber } = props;
  const { i18n } = useI18n();

  return (
    <RewardsBox>
      <RewardsBorder sx={{ top: '-2px', left: '-2px' }} />
      <RewardsBorder sx={{ top: '-2px', right: '-2px', transform: 'rotate(0deg)' }} />
      <RewardsBorder sx={{ bottom: '-2px', left: '-2px', transform: 'rotate(180deg)' }} />
      <RewardsBorder sx={{ bottom: '-2px', right: '-2px', transform: 'rotate(90deg)' }} />
      <RewardsPaper elevation={3}>
        <RewardsPaperStack>
          <Stack direction="row">
            <RewardsTitle longTitle={_.includes(['frFR', 'esES', 'ruRU'], i18n.getLang())}>{title}</RewardsTitle>
            <BaseTooltip title={describe}>
              <TypographyHelp alignSelf="flex-start">
                <Typography component="span" variant="caption">
                  {'?'}
                </Typography>
              </TypographyHelp>
            </BaseTooltip>
          </Stack>
          <Stack direction="row" width={'100%'} justifyContent="space-between">
            <Stack height={40} width={'50%'} alignItems="center" justifyContent="space-between">
              <Typography fontWeight={700} color={'#78BEDF'}>
                {leftNumber}
              </Typography>
              <RewardTypographyDes
                longDes={_.includes(['frFR', 'esES', 'jaJP'], i18n.getLang())}
                isRU={i18n.getLang() === 'ruRU'}
                isJP={i18n.getLang() === 'jaJP'}
              >
                {leftTitle}
              </RewardTypographyDes>
            </Stack>
            <Stack height={40} width={'50%'} alignItems="center" justifyContent="space-between">
              <Typography fontWeight={700} color={'#78BEDF'}>
                {rightNumber}
              </Typography>
              <RewardTypographyDes
                longDes={_.includes(['frFR', 'esES', 'jaJP'], i18n.getLang())}
                isRU={i18n.getLang() === 'ruRU'}
                isJP={i18n.getLang() === 'jaJP'}
              >
                {rightTitle}
              </RewardTypographyDes>
            </Stack>
          </Stack>
        </RewardsPaperStack>
      </RewardsPaper>
    </RewardsBox>
  );
};

const InvitationContent = () => {
  const { account } = useActiveWeb3React();
  const dispatch = useAppDispatch();
  const { t, i18n } = useI18n();
  const userInfo = useUserInfo();
  const [selectedLevel, setSelectedLevel] = useState(userInfo?.level ? Number(userInfo?.level) : 0);
  const userNodeGrade = useNodeMinted(account || '');
  const registered = useValidAddress(account ?? undefined);
  const [openInvitationLink, setOpenInvitationLink] = useState(false);
  const [incrAcaToday, setIncrAcaToday] = useState('');
  const [incrAcaLastday, setIncrAcaLastday] = useState('');
  const [incrAcaWeek, setIncrAcaWeek] = useState('');
  const [incrAcaLastweek, setIncrAcaLastweek] = useState('');
  const [incrAcaMonth, setIncrAcaMonth] = useState('');
  const [incrAcaLastmonth, setIncrAcaLastmonth] = useState('');
  const [acaCountRewardList, setAcaCountRewardList] = useState([
    {
      title: 'invitation.daily',
      pastTitle: 'invitation.yesterday',
      nowTitle: 'invitation.today',
      pastValue: '--',
      nowValue: '--',
      growthValue: '--',
      percentage: '--',
      growthRate: false,
    },
    {
      title: 'invitation.weekly',
      pastTitle: 'invitation.lastWeek',
      nowTitle: 'invitation.thisWeek',
      pastValue: '--',
      nowValue: '--',
      growthValue: '--',
      percentage: '--',
      growthRate: false,
    },
    {
      title: 'invitation.monthly',
      pastTitle: 'invitation.lastMonth',
      nowTitle: 'invitation.thisMonth',
      pastValue: '--',
      nowValue: '--',
      growthValue: '--',
      percentage: '--',
      growthRate: false,
    },
  ]);
  const dayStart = moment().startOf('day').unix();
  const dayEnd = moment().endOf('day').unix();
  const lastDayStart = moment().startOf('day').subtract(1, 'day').unix();
  const lastDayEnd = moment().endOf('day').subtract(1, 'day').unix();
  const weekStart = moment().subtract(1, 'day').startOf('week').add(1, 'day').unix();
  const weekEnd = moment().subtract(1, 'day').endOf('week').add(1, 'day').unix();
  const lastWeekStart = moment().subtract(1, 'day').startOf('week').subtract(1, 'week').add(1, 'day').unix();
  const lastWeekEnd = moment().subtract(1, 'day').endOf('week').subtract(1, 'week').add(1, 'day').unix();
  const monthStart = moment().startOf('month').unix();
  const monthEnd = moment().endOf('month').unix();
  const lastMonthStart = moment().startOf('month').subtract(1, 'month').unix();
  const lastMonthEnd = moment().endOf('month').subtract(1, 'month').unix();

  useEffect(() => {
    if (userInfo?.level) {
      setSelectedLevel(Number(userInfo?.level));
    }
  }, [userInfo?.level]);

  useEffect(() => {
    getSumcaCountReward(account || '').then((res: any) => {
      if (res) {
        setIncrAcaToday(res?.ca1?.toString() || '');
        setIncrAcaLastday(res?.ca2?.toString() || '');
        setIncrAcaWeek(res?.ca3?.toString() || '');
        setIncrAcaLastweek(res?.ca4?.toString() || '');
        setIncrAcaMonth(res?.ca5?.toString() || '');
        setIncrAcaLastmonth(res?.ca6?.toString() || '');
      }
    });
    // getAcaCountReward(account || '', dayStart, dayEnd).then((res) => {
    //   setIncrAcaToday(res);
    // });
    // getAcaCountReward(account || '', lastDayStart, lastDayEnd).then((res) => {
    //   setIncrAcaLastday(res);
    // });
    // getAcaCountReward(account || '', weekStart, weekEnd).then((res) => {
    //   setIncrAcaWeek(res);
    // });
    // getAcaCountReward(account || '', lastWeekStart, lastWeekEnd).then((res) => {
    //   setIncrAcaLastweek(res);
    // });
    // getAcaCountReward(account || '', monthStart, monthEnd).then((res) => {
    //   setIncrAcaMonth(res);
    // });
    // getAcaCountReward(account || '', lastMonthStart, lastMonthEnd).then((res) => {
    //   setIncrAcaLastmonth(res);
    // });
  }, [
    account,
    dayEnd,
    dayStart,
    lastDayEnd,
    lastDayStart,
    lastMonthEnd,
    lastMonthStart,
    lastWeekEnd,
    lastWeekStart,
    monthEnd,
    monthStart,
    weekEnd,
    weekStart,
  ]);

  useEffect(() => {
    setAcaCountRewardList([
      {
        title: 'invitation.daily',
        pastTitle: 'invitation.yesterday',
        nowTitle: 'invitation.today',
        pastValue: incrAcaLastday,
        nowValue: incrAcaToday,
        growthValue:
          incrAcaLastday && incrAcaToday ? Math.abs(Number(incrAcaToday) - Number(incrAcaLastday)).toString() : '--',
        percentage:
          incrAcaLastday && incrAcaToday && incrAcaLastday !== '0'
            ? `${((Math.abs(Number(incrAcaToday) - Number(incrAcaLastday)) * 100) / Number(incrAcaLastday)).toFixed(
                2
              )}%`
            : '--',
        growthRate: incrAcaLastday && incrAcaToday ? Number(incrAcaToday) - Number(incrAcaLastday) > 0 : false,
      },
      {
        title: 'invitation.weekly',
        pastTitle: 'invitation.lastWeek',
        nowTitle: 'invitation.thisWeek',
        pastValue: incrAcaLastweek,
        nowValue: incrAcaWeek,
        growthValue:
          incrAcaLastweek && incrAcaWeek ? Math.abs(Number(incrAcaWeek) - Number(incrAcaLastweek)).toString() : '--',
        percentage:
          incrAcaLastweek && incrAcaWeek && incrAcaLastweek !== '0'
            ? `${((Math.abs(Number(incrAcaWeek) - Number(incrAcaLastweek)) * 100) / Number(incrAcaLastweek)).toFixed(
                2
              )}%`
            : '--',
        growthRate: incrAcaLastweek && incrAcaWeek ? Number(incrAcaWeek) - Number(incrAcaLastweek) > 0 : false,
      },
      {
        title: 'invitation.monthly',
        pastTitle: 'invitation.lastMonth',
        nowTitle: 'invitation.thisMonth',
        // pastValue: incrAcaLastmonth,
        pastValue: '0',
        nowValue: incrAcaMonth,
        growthValue:
          incrAcaLastmonth && incrAcaMonth
            ? Math.abs(Number(incrAcaMonth) - Number(incrAcaLastmonth)).toString()
            : '--',
        percentage:
          incrAcaLastmonth && incrAcaMonth && incrAcaLastmonth !== '0'
            ? `${((Math.abs(Number(incrAcaMonth) - Number(incrAcaLastmonth)) * 100) / Number(incrAcaLastmonth)).toFixed(
                2
              )}%`
            : '--',
        growthRate: incrAcaLastmonth && incrAcaMonth ? Number(incrAcaMonth) - Number(incrAcaLastmonth) > 0 : false,
      },
    ]);
  }, [incrAcaToday, incrAcaLastday, incrAcaWeek, incrAcaLastweek, incrAcaMonth, incrAcaLastmonth]);

  const levelList = [
    { title: 'invitation.elite', rate: '5%', level: '1', rewardAP: '1', stakeAP: '1', invitationMax: 0, caMax: 0 },
    {
      title: 'invitation.epic',
      rate: '6%',
      level: '3',
      rewardAP: '2',
      stakeAP: '2',
      invitationMax: 1,
      caMax: 10000,
    },
    {
      title: 'invitation.master',
      rate: '7%',
      level: '5',
      rewardAP: '3',
      stakeAP: '4',
      invitationMax: 1,
      caMax: 50000,
    },
    {
      title: 'invitation.legendary',
      rate: '8%',
      level: '10',
      rewardAP: '4',
      stakeAP: '6',
      invitationMax: 1,
      caMax: 100000,
    },
    {
      title: 'invitation.mythic',
      rate: '9%',
      level: '15',
      rewardAP: '5',
      stakeAP: '8',
      invitationMax: 1,
      caMax: 300000,
    },
    {
      title: 'invitation.divine',
      rate: '10%',
      level: '20',
      rewardAP: '6',
      stakeAP: '10',
      invitationMax: 1,
      caMax: 1000000,
    },
  ];
  const currenInviterNum =
    selectedLevel === 0
      ? 0
      : userInfo.inviterOfLevel[selectedLevel - 1]?.result
      ? userInfo.inviterOfLevel[selectedLevel - 1]?.result?.[0]
      : 0;

  const currenInviterMax =
    selectedLevel === 0
      ? 0
      : userInfo.inviterMaxOfLevel[selectedLevel - 1]?.result
      ? userInfo.inviterMaxOfLevel[selectedLevel - 1]?.result?.[0]
      : 0;

  const currenCaMax =
    selectedLevel === 0
      ? 0
      : userInfo.caMaxOfLevel[selectedLevel - 1]?.result
      ? userInfo.caMaxOfLevel[selectedLevel - 1]?.result?.[0]
      : 0;

  const upgradeCallback = useCallback(async () => {
    if (!userInfo) return;
    dispatch(showTransactionPendingModal());
    userInfo
      .upgrade()
      .then(() => {
        dispatch(showTransactionConfirmModal());
      })
      .catch((err: any) => {
        dispatch(
          showTransactionErrorModal(
            err.error && err.error.message
              ? err.error.message
              : err?.data?.message
              ? err?.data?.message
              : err.message ?? t('global.networkError')
          )
        );
        console.error(err);
      });
  }, [dispatch, t, userInfo]);

  return (
    <InvitationContentBox>
      <InvitationLinkModal open={openInvitationLink} handleClose={() => setOpenInvitationLink(false)} />
      <StakeTitle>
        <TypographyDes color={'#000000'}>{t('invitation.title')}</TypographyDes>
      </StakeTitle>
      <InvitationDes>
        <TypographyDes
          sx={(theme) => ({ [theme.breakpoints.down('sm')]: { width: theme.spacing(25) } })}
          color={'#fff'}
        >
          {t('invitation.invitationDes')}
        </TypographyDes>
        <InviteButton
          variant="contained"
          color="info"
          onClick={() => {
            if (!account) {
              dispatch(showWalletModal());
              return;
            }
            if (!registered) {
              dispatch(showRegisterModal());
            } else {
              setOpenInvitationLink(true);
            }
          }}
        >
          {t('invitation.inviteFriends')}
        </InviteButton>
      </InvitationDes>
      <InvitationRewards>
        <TypographyDes color={'#000000'}>{t('invitation.rewardSummary')}</TypographyDes>
        <RewardsStack>
          <CardItem
            title={t('invitation.socialRewardUp')}
            describe={t('invitation.socialRewardDes')}
            leftTitle={t('invitation.bonusRatio')}
            leftNumber={`${
              userInfo?.socialRewardRates
                ? CurrencyAmount.ether(
                    JSBI.multiply(JSBI.BigInt(userInfo.socialRewardRates.toString()), JSBI.BigInt(100))
                  ).toFixed(2, { groupSeparator: ',' })
                : '--'
            } %`}
            rightTitle={t('invitation.totalLRT')}
            rightNumber={`${
              userInfo?.socialRev ? CurrencyAmount.ether(userInfo.socialRev).toFixed(2, { groupSeparator: ',' }) : '--'
            }`}
          />
          <CardItem
            title={t('invitation.communityReward')}
            describe={t('invitation.communityRewardDes')}
            leftTitle={t('invitation.qualified')}
            leftNumber={`${userInfo?.communityRewardRatesNumByLevel?.toString() ?? '--'}`}
            rightTitle={t('invitation.totalLRT')}
            rightNumber={`${
              userInfo?.communityRev
                ? CurrencyAmount.ether(userInfo.communityRev).toFixed(2, { groupSeparator: ',' })
                : '--'
            }`}
          />
          <CardItem
            title={t('invitation.contributionRewardUp')}
            describe={t('invitation.contributionRewardDes')}
            leftTitle={t('invitation.cumulateCA')}
            leftNumber={userInfo?.contributionOf?.toString() ?? '--'}
            rightTitle={t('invitation.totalAP')}
            rightNumber={`${
              userInfo?.contributionRev
                ? CurrencyAmount.ether(userInfo.contributionRev).toFixed(2, { groupSeparator: ',' })
                : '--'
            } `}
          />
          <CardItem
            title={t('invitation.AchievementReward')}
            describe={t('invitation.AchievementRewardDes')}
            leftTitle={t('invitation.cycles')}
            leftNumber={userInfo?.achievementCounter?.toString() ?? '--'}
            rightTitle={t('invitation.totalAP')}
            rightNumber={`${
              userInfo?.achievementRev
                ? CurrencyAmount.ether(userInfo.achievementRev).toFixed(2, { groupSeparator: ',' })
                : '--'
            }`}
          />
          <CardItem
            title={t('invitation.StakingReward')}
            describe={t('invitation.StakingRewardDes')}
            leftTitle={t('invitation.stakingNFT')}
            leftNumber={userInfo?.stakedCount?.toString() ?? '--'}
            rightTitle={t('invitation.totalLRT')}
            rightNumber={`${
              userInfo?.stakeRev ? CurrencyAmount.ether(userInfo.stakeRev).toFixed(2, { groupSeparator: ',' }) : '--'
            }`}
          />
        </RewardsStack>
      </InvitationRewards>
      <MyLevel>
        <Stack direction="row" alignItems="center" gap={2}>
          <TypographyDes color={'#000000'}>{t('invitation.myLevel')}</TypographyDes>
          {userNodeGrade && _.includes(['1', '2', '3'], userNodeGrade?.toString()) && (
            <NodeNftLevel
              src={userNodeGrade?.toString() === '1' ? low : userNodeGrade?.toString() === '2' ? middle : high}
            />
          )}
        </Stack>
        <Stack
          direction="row"
          mb={1}
          sx={(theme) => ({ gap: theme.spacing(1), [theme.breakpoints.down('sm')]: { flexWrap: 'wrap' } })}
        >
          {levelList.map(({ title }, index) => {
            return (
              <Button
                variant="outlined"
                onClick={() => setSelectedLevel(index)}
                key={title}
                sx={(theme) => ({
                  height: theme.spacing(3.5),
                  padding: theme.spacing(0, 4),
                  borderRadius: '7px',
                  textTransform: 'unset',
                  '&.levelActive': {
                    background: '#72bcde66',
                  },
                  [theme.breakpoints.down('sm')]: { padding: theme.spacing(0, 1) },
                })}
                className={selectedLevel === index ? 'levelActive' : ''}
              >
                {t(title)}
              </Button>
            );
          })}
        </Stack>
        <LevelPaper elevation={3}>
          <MyLevelBorder />
          <MyLevelBottomBorder />
          <Stack alignItems="flex-end">
            <Stack width="100%" justifyContent="space-between" direction="row">
              <Typography color={'#121425'} fontWeight={700}>
                {selectedLevel === 0
                  ? `${t('invitation.nourishInviter')}`
                  : `${t('invitation.nourishInviter')} ${currenInviterMax} ${t(levelList[selectedLevel - 1].title)}`}
              </Typography>
              <Typography color={'#121425'} fontWeight={700}>
                {`${currenInviterNum || 0}/${currenInviterMax}`}
              </Typography>
            </Stack>
            <BorderLinearProgress
              variant="determinate"
              value={
                ((currenInviterNum || 0) / currenInviterMax) * 100 > 100 || currenInviterMax === 0
                  ? 100
                  : ((currenInviterNum || 0) / currenInviterMax) * 100
              }
            />
          </Stack>
          <Stack alignItems="flex-end">
            <Stack width="100%" justifyContent="space-between" direction="row">
              <Typography color={'#121425'} fontWeight={700}>
                {selectedLevel === 0
                  ? `${t('invitation.teamGrade')} CA`
                  : `${t('invitation.teamGrade')} => ${currenCaMax} CA`}
              </Typography>
              <Typography color={'#121425'} fontWeight={700}>
                {`${userInfo.currenCaValue || 0}/${currenCaMax}`}
              </Typography>
            </Stack>
            <BorderLinearProgress
              variant="determinate"
              value={
                ((userInfo.currenCaValue || 0) / currenCaMax) * 100 > 100 || currenCaMax === 0
                  ? 100
                  : ((userInfo.currenCaValue || 0) / currenCaMax) * 100
              }
            />
          </Stack>
          <Typography color={'#121425'} fontWeight={700} lineHeight={2}>
            {t('invitation.levelPrivilege')}
          </Typography>
          <Stack sx={(theme) => ({ width: '50%', [theme.breakpoints.down('sm')]: { width: '100%', zoom: 0.8 } })}>
            <Stack direction="row" alignItems="center" justifyContent="space-between">
              <Typography color={'#8B8B8B'} fontWeight={600}>
                {t('invitation.socialReward')}
              </Typography>
              <Typography color={'#121425'} fontWeight={500}>
                {levelList[selectedLevel].rate}
              </Typography>
            </Stack>
            <Stack direction="row" alignItems="center" justifyContent="space-between">
              <Typography color={'#8B8B8B'} fontWeight={600}>
                {t('invitation.communityReward1')}
              </Typography>
              <Typography color={'#121425'} fontWeight={500}>
                {`${levelList[selectedLevel].level}${t('invitation.generation')}`}
              </Typography>
            </Stack>
            <Stack direction="row" alignItems="center" justifyContent="space-between">
              <Typography color={'#8B8B8B'} fontWeight={600}>
                {t('invitation.contributionReward')}
              </Typography>
              <Typography color={'#121425'} fontWeight={500}>
                {`${t('invitation.inviteRule')} ${levelList[selectedLevel].rewardAP} AP`}
              </Typography>
            </Stack>
            <Stack direction="row" alignItems="center" justifyContent="space-between">
              <Typography color={'#8B8B8B'} fontWeight={600}>
                {t('invitation.achievementReward')}
              </Typography>
              <Typography color={'#121425'} fontWeight={500}>
                {`${t('invitation.NFTStakingRule')} ${levelList[selectedLevel].stakeAP} AP`}
              </Typography>
            </Stack>
          </Stack>
          {!registered && (
            <Button sx={{ height: 28, marginTop: 1 }} disabled variant="contained">
              {t('invitation.inactivated')}
            </Button>
          )}
          {userInfo &&
            registered &&
            (!(Number(userInfo.level) < selectedLevel) || (selectedLevel === 0 && Number(userInfo.level) === 0)) && (
              <Button sx={{ height: 28, marginTop: 1 }} disabled variant="contained">
                {t('invitation.activated')}
              </Button>
            )}
          {userInfo && Number(userInfo.level) < selectedLevel && (
            <>
              {!userInfo ||
              Number(currenInviterNum) < Number(currenInviterMax) ||
              Number(userInfo.currenCaValue) < Number(currenCaMax) ? (
                <Button sx={{ height: 28, marginTop: 1 }} disabled variant="contained">
                  {t('invitation.inactivated')}
                </Button>
              ) : (
                <Button onClick={upgradeCallback} sx={{ height: 28, marginTop: 1 }} variant="contained">
                  {t('invitation.activate')}
                </Button>
              )}
            </>
          )}
        </LevelPaper>
        {COUNTREWARDAPI && <TypographyDes color={'#000000'}>{t('invitation.AcaStastistics')}</TypographyDes>}
        {COUNTREWARDAPI && (
          <Stack
            direction="row"
            sx={(theme) => ({
              gap: theme.spacing(1),
              [theme.breakpoints.down('sm')]: { flexWrap: 'wrap', gap: theme.spacing(2) },
            })}
          >
            {acaCountRewardList.map(({ title, pastTitle, nowTitle, pastValue, nowValue }) => {
              return (
                <CountRewards key={title} longlang={_.includes(['frFR', 'esES', 'ruRU', 'deDE'], i18n.getLang())}>
                  <Typography
                    sx={(theme) => ({
                      [theme.breakpoints.down('sm')]: {
                        width: _.includes(['frFR', 'esES', 'ruRU', 'deDE'], i18n.getLang())
                          ? theme.spacing(12)
                          : theme.spacing(10),
                      },
                    })}
                    color={'#121425'}
                    fontWeight={700}
                    fontSize={14}
                  >
                    {`${t(title)}:`}
                  </Typography>
                  <Stack className="count-rewards-value" direction={'row'} gap={0.4} alignItems={'center'}>
                    <Typography color={'#121425'} fontWeight={500} fontSize={14}>
                      {t(pastTitle)}
                    </Typography>
                    <Typography color={'#72BCDE'} fontWeight={700} fontSize={14}>
                      {pastValue}
                    </Typography>
                  </Stack>
                  <Stack className="count-rewards-value" direction={'row'} gap={0.4} alignItems={'center'}>
                    <Typography color={'#121425'} fontWeight={500} fontSize={14}>
                      {t(nowTitle)}
                    </Typography>
                    <Typography color={'#72BCDE'} fontWeight={700} fontSize={14}>
                      {nowValue}
                    </Typography>
                  </Stack>
                  {/* <Chip
                    sx={{ height: 20, borderRadius: '4px', width: '96px', '&.MuiChip-label': { padding: '0, 4px' } }}
                    label={`${growthRate ? '＋' : '—'}${percentage}`}
                    color={growthRate ? 'success' : 'error'}
                  /> */}
                </CountRewards>
              );
            })}
          </Stack>
        )}
      </MyLevel>
    </InvitationContentBox>
  );
};

export default InvitationContent;
