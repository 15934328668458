import { createSlice, nanoid } from '@reduxjs/toolkit';
import type { RootState } from 'store';

export type PopupContent = {
  txn: {
    hash: string;
    success: boolean;
    summary?: string;
  };
};

type PopupList = Array<{ key: string; show: boolean; content: PopupContent; removeAfterMs: number | null }>;
export interface ApplicationState {
  readonly blockNumber: { readonly [chainId: number]: number };
  readonly popupList: PopupList;
  readonly inviter: string;
}

const initialState: ApplicationState = {
  blockNumber: {},
  popupList: [],
  inviter: '',
};

export const applicationSlice = createSlice({
  name: 'application',
  initialState,
  reducers: {
    updateBlockNumber: (state, action) => {
      const { chainId, blockNumber }: { chainId: number; blockNumber: number } = action.payload;
      if (typeof state.blockNumber[chainId] !== 'number') {
        state.blockNumber[chainId] = blockNumber;
      } else {
        state.blockNumber[chainId] = Math.max(blockNumber, state.blockNumber[chainId]);
      }
    },
    updateInviter: (state, action) => {
      const { inviter }: { inviter: string } = action.payload;
      state.inviter = inviter;
    },
    addPopup: (state, { payload: { content, key, removeAfterMs = 15000 } }) => {
      state.popupList = (key ? state.popupList.filter((popup) => popup.key !== key) : state.popupList).concat([
        {
          key: key || nanoid(),
          show: true,
          content,
          removeAfterMs,
        },
      ]);
    },
    removePopup: (state, { payload: { key } }) => {
      state.popupList.forEach((p) => {
        if (p.key === key) {
          p.show = false;
        }
      });
    },
  },
});

export const { updateBlockNumber, updateInviter, addPopup, removePopup } = applicationSlice.actions;

export const applicationState = (state: RootState) => state.application;

export default applicationSlice.reducer;
