import BaseModal from 'components/BaseModal';
import { MenuItem, Stack, Typography, InputAdornment, InputBase, styled } from '@mui/material';
import { LYNKNFT_ADDRESS, MARKET_ADDRESS, SUPPORTED_TOKEN_LIST, SUPPORTED_TOKEN, TOKEN, USDT } from 'constants/index';
import React, { useCallback, useState, useMemo } from 'react';
import { tryParseAmount } from 'utils/parseAmount';
import { useI18n } from 'react-simple-i18n';
import { TokenAmount } from 'constants/token';
import { ApprovalState } from 'hooks/useApproveCallback';
import { useAppDispatch } from 'hooks/redux';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  showTransactionConfirmModal,
  showTransactionPendingModal,
  showTransactionErrorModal,
} from 'store/features/componentSlice';
import Select from '@mui/material/Select';
import { useMarket } from 'hooks/useMarket';
import { useERC721ApproveAllCallback } from 'hooks/useERC721ApproveAllCallback';

const NameInput = styled(InputBase)(({ theme }) => ({
  borderRadius: 13,
  height: theme.spacing(4),
  width: theme.spacing(37),
  background: '#F0F0F0',
  border: '1px solid #00000099',
  padding: theme.spacing(0, 2),
  '&.usdtNumber': {
    padding: theme.spacing(0, 0, 0, 2),
  },
  [theme.breakpoints.down('sm')]: {
    width: theme.spacing(27),
  },
}));

export default function MakeOrderModal({
  tokenId,
  handleClose,
  estimatePrice,
  open,
}: {
  tokenId: string;
  open: boolean;
  handleClose: () => void;
  estimatePrice: string;
}) {
  const { t } = useI18n();
  const dispatch = useAppDispatch();
  const [token, setToken] = useState(USDT);
  const [amount, setAmount] = useState('');

  const saleAmount = useMemo(() => {
    return token && amount ? (tryParseAmount(amount, token) as TokenAmount) : undefined;
  }, [amount, token]);

  const { make } = useMarket();

  const [approvalState, approveCallback] = useERC721ApproveAllCallback(LYNKNFT_ADDRESS, MARKET_ADDRESS);

  const makeCallback = useCallback(async () => {
    if (!saleAmount) return;
    dispatch(showTransactionPendingModal());
    make(tokenId, saleAmount)
      .then(() => {
        dispatch(showTransactionConfirmModal());
        handleClose();
      })
      .catch((err: any) => {
        dispatch(
          showTransactionErrorModal(
            err.error && err.error.message
              ? err.error.message
              : err?.data?.message
              ? err?.data?.message
              : err.message ?? t('global.networkError')
          )
        );
        console.error(err);
      });
  }, [saleAmount, dispatch, make, tokenId, handleClose, t]);

  return (
    <BaseModal
      contentWidth={434}
      open={open}
      confirmLoading={approvalState === ApprovalState.PENDING}
      confirmText={approvalState === ApprovalState.NOT_APPROVED ? t('global.approve') : t('global.confirm')}
      confirm={approvalState === ApprovalState.NOT_APPROVED ? approveCallback : makeCallback}
      handleClose={handleClose}
      title={t('nftDetail.sell')}
    >
      <Stack justifyContent="center" alignItems="center" spacing={0.5}>
        <NameInput
          value={amount}
          onChange={(e) => {
            const valueFirst = e.target.value.replace(/[^\d.]/g, '');
            // eslint-disable-next-line no-useless-escape
            const value = valueFirst.replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3');
            setAmount(Number(value) > Number(estimatePrice) ? Number(estimatePrice).toFixed(2).toString() : value);
          }}
          className="usdtNumber"
          placeholder={'0'}
          endAdornment={
            <InputAdornment position="end">
              <Typography
                onClick={() => setAmount(Number(estimatePrice).toFixed(2).toString())}
                sx={(theme) => ({ padding: theme.spacing(0, 1), fontWeight: 700, cursor: 'pointer' })}
              >
                {t('nftDetail.max')}
              </Typography>
              <Select
                value={token.symbol}
                onChange={(e) => {
                  setToken(SUPPORTED_TOKEN[e.target.value as TOKEN]);
                }}
                displayEmpty
                IconComponent={ExpandMoreIcon}
                sx={{
                  color: '#000',
                  fontWeight: 700,
                  height: 32,
                  '& .MuiOutlinedInput-notchedOutline': {
                    border: 'none',
                    borderRadius: 0,
                    borderLeft: '1px solid #00000099',
                  },
                  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                    borderLeft: '1px solid #00000099',
                  },
                }}
                inputProps={{ 'aria-label': 'Without label' }}
              >
                {SUPPORTED_TOKEN_LIST.map((token) => {
                  return (
                    <MenuItem key={token.address} value={token.symbol}>
                      <Typography>{token.symbol}</Typography>
                    </MenuItem>
                  );
                })}
              </Select>
            </InputAdornment>
          }
        />
        <Typography
          variant="body2"
          sx={(theme) => ({
            width: theme.spacing(37),
            textAlign: 'start',
            [theme.breakpoints.down('sm')]: {
              width: theme.spacing(27),
            },
          })}
        >
          {`${t('nftDetail.referencePrice')}: ${estimatePrice}`}
        </Typography>
      </Stack>
    </BaseModal>
  );
}
