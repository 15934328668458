import { Contract } from '@ethersproject/contracts';
import { getAddress } from '@ethersproject/address';
import { AddressZero } from '@ethersproject/constants';
import { JsonRpcSigner, Web3Provider } from '@ethersproject/providers';
import { BigNumber } from '@ethersproject/bignumber';
import { CurrencyAmount, Percent } from 'constants/token';
import JSBI from 'jsbi';
import { ChainId } from 'constants/chain';
import CONFIG from 'config';

// returns the checksummed address if the address is valid, otherwise returns false
export function isAddress(value: any): string | false {
  try {
    return getAddress(value);
  } catch {
    return false;
  }
}

const explorers = {
  etherscan: (link: string, data: string, type: 'transaction' | 'token' | 'address' | 'block') => {
    switch (type) {
      case 'transaction':
        return `${link}/tx/${data}`;
      default:
        return `${link}/${type}/${data}`;
    }
  },

  blockscout: (link: string, data: string, type: 'transaction' | 'token' | 'address' | 'block') => {
    switch (type) {
      case 'transaction':
        return `${link}/tx/${data}`;
      case 'token':
        return `${link}/tokens/${data}`;
      default:
        return `${link}/${type}/${data}`;
    }
  },

  harmony: (link: string, data: string, type: 'transaction' | 'token' | 'address' | 'block') => {
    switch (type) {
      case 'transaction':
        return `${link}/tx/${data}`;
      case 'token':
        return `${link}/address/${data}`;
      default:
        return `${link}/${type}/${data}`;
    }
  },

  okex: (link: string, data: string, type: 'transaction' | 'token' | 'address' | 'block') => {
    switch (type) {
      case 'transaction':
        return `${link}/tx/${data}`;
      case 'token':
        return `${link}/tokenAddr/${data}`;
      default:
        return `${link}/${type}/${data}`;
    }
  },
};

interface ChainObject {
  [chainId: number]: {
    link: string;
    builder: (chainName: string, data: string, type: 'transaction' | 'token' | 'address' | 'block') => string;
  };
}

const chains: ChainObject = {
  [ChainId.MAINNET]: {
    link: `${CONFIG.blockExplorerUrls}/`,
    builder: explorers.etherscan,
  },
};

export function getEtherscanLink(
  chainId: ChainId,
  data: string,
  type: 'transaction' | 'token' | 'address' | 'block'
): string {
  const chain = chains[chainId];
  return chain.builder(chain.link, data, type);
}

// shorten the checksummed version of the input address to have 0x + 4 characters at start and end
export function shortenAddress(address: string, chars = 4): string {
  const parsed = isAddress(address);
  if (!parsed) {
    throw Error(`Invalid 'address' parameter '${address}'.`);
  }
  return `${parsed.substring(0, chars + 2)}...${parsed.substring(42 - chars)}`;
}

export function shortenString(str: string): string {
  if (str.length < 8) return str;
  return `${str.substring(0, 6)}...${str.substring(str.length - 4)}`;
}

// add 10%
export function calculateGasMargin(value: BigNumber): BigNumber {
  return value.mul(BigNumber.from(10000).add(BigNumber.from(1000))).div(BigNumber.from(10000));
}

export function calculateGasPriceMargin(value: string): string {
  return JSBI.add(JSBI.BigInt(value), JSBI.divide(JSBI.BigInt(value), JSBI.BigInt(10))).toString();
}

// converts a basis points value to a sdk percent
export function basisPointsToPercent(num: number): Percent {
  return new Percent(JSBI.BigInt(num), JSBI.BigInt(10000));
}

export function calculateSlippageAmount(value: CurrencyAmount, slippage: number): [JSBI, JSBI] {
  if (slippage < 0 || slippage > 10000) {
    throw Error(`Unexpected slippage value: ${slippage}`);
  }
  return [
    JSBI.divide(JSBI.multiply(value.raw, JSBI.BigInt(10000 - slippage)), JSBI.BigInt(10000)),
    JSBI.divide(JSBI.multiply(value.raw, JSBI.BigInt(10000 + slippage)), JSBI.BigInt(10000)),
  ];
}

// account is not optional
export function getSigner(library: Web3Provider, account: string): JsonRpcSigner {
  return library.getSigner(account).connectUnchecked();
}

// account is optional
export function getProviderOrSigner(library: Web3Provider, account?: string): Web3Provider | JsonRpcSigner {
  return account ? getSigner(library, account) : library;
}

// account is optional
export function getContract(address: string, ABI: any, library: Web3Provider, account?: string): Contract {
  if (!isAddress(address) || address === AddressZero) {
    throw Error(`Invalid 'address' parameter '${address}'.`);
  }

  return new Contract(address, ABI, getProviderOrSigner(library, account) as any);
}

export function escapeRegExp(string: string): string {
  return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
}

export function lastStr(path: string) {
  const index = path.lastIndexOf('/');
  path = path.substring(index + 1, path.length);
  return path;
}

export const registerToken = async (
  tokenAddress: string,
  tokenSymbol: string,
  tokenDecimals: number,
  tokenImage: string
) => {
  const tokenAdded =
    (await window.ethereum) &&
    window?.ethereum?.request &&
    window.ethereum.request({
      method: 'wallet_watchAsset',
      params: {
        type: 'ERC20',
        options: {
          address: tokenAddress,
          symbol: tokenSymbol,
          decimals: tokenDecimals,
          image: tokenImage,
        },
      },
    });

  return tokenAdded;
};

export function getNftMintPrice(tokenId: string) {
  const tokenIdNum = Number(tokenId);
  if (tokenIdNum > 0 && tokenIdNum < 100001) {
    return 0;
  } else if (tokenIdNum > 100000 && tokenIdNum < 180001) {
    return 10;
  } else if (tokenIdNum > 200000 && tokenIdNum < 280001) {
    return 100;
  } else if (tokenIdNum > 300000 && tokenIdNum < 380001) {
    return 300;
  }
}

export function getRole(tokenId: string) {
  const tokenIdNum = Number(tokenId);
  if (tokenIdNum > 0 && tokenIdNum < 100001) {
    return 'Finder';
  } else if (tokenIdNum > 100000 && tokenIdNum < 180001) {
    return 'Spirits';
  } else if (tokenIdNum > 200000 && tokenIdNum < 280001) {
    return 'Exos';
  } else if (tokenIdNum > 300000 && tokenIdNum < 380001) {
    return 'Martials';
  } else {
    return 'Voids';
  }
}
