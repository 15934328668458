export default {
  global: {
    connectWallet: '连接钱包',
    swapLynk: '交换 $LYNK',
    connectToWallet: '连接钱包',
    metamask: 'METAMASK 钱包',
    walletConnect: 'WALLET CONNECT 钱包',
    coinbaseWallet: 'COINBASE 钱包',
    walletDes: '*连接钱包即表示您同意 Lynkverse 的服务条款和隐私政策。',
    connecting: '连接中...',
    connectedWallet: '已连接 %s% 钱包',
    wrongNetwork: '网络错误',
    error: '错误',
    myWallet: '我的钱包',
    myAddress: '我的地址',
    addToken: '添加代币',
    crossChain: '跨链',
    logout: '退出',
    change: '更改',
    recentTransactions: '最近交易',
    clearAll: '清除所有交易记录',
    confirm: '确认',
    confirmed: '确认',
    balance: '账户余额',
    confirmTransaction: '请在您的钱包里确认这笔交易',
    viewOnChain: '在 Arbiscan 上查看',
    invitationLink: '邀请链接',
    invitationCode: '邀请码',
    copy: '复制',
    insufficient: '余额不足',
    approve: '批准',
    tip: '提示',
    drop: '降低',
    swap: '交换',
    register: '登记',
    networkError: '网络错误',
    initializing: '正在初始化...',
    errorConnecting: '错误连接',
    errorConnectingDes: '连接错误。 尝试刷新页面.',
    binanceSmartChain: '币安智能链 ',
    connectTip: '请连接到',
    ethereumMainnet: '以太坊主网',
    connect: '连接到',
    tryAgain: '再试一次',
    changeWallet: '换钱包',
    exchangeRate: '汇率',
    approving: '批准中',
    swapTitle: '交换LYNK',
    registerTitle: '输入您的邀请码',
    registerPlh: '请在此处输入您的代码',
    registerDes: 'TIP: 你可以去 Lynk 的 Discord 找回你的邀请码.',
    unValidCode: '注册码无效',
    registerInviterDes: '您还没有注册，请先注册',
    transactionsDes: '您的交易将显示在这里...',
    copied: '已复制',
    finish: '结束',
    minting: '铸造',
    comingSoon: '即将推出',
  },
  navMenu: {
    myDashboard: '我的个人主页',
    nfts: 'NFTs',
    stake: '质押',
    invitation: '邀请',
    history: '历史记录',
    activitiesCampaigns: '活动',
    nodeNft: '节点NFT计划',
    leaderNft: '领袖NFT计划',
    finders: '锻造Finders NFT',
    marketPlace: '商城',
    rank: '排名',
    more: '更多',
    chatRoom: '聊天室',
    lynkAlliance: 'LYNK 联盟',
    domainServer: 'LYNK 域名服务器',
    feed: '动态',
  },
  nfts: {
    title: '用户 NFT',
    mintKeeper: '铸造 KEEPER',
    buyKeeper: '购买 KEEPER',
    freeMint: '免费锻造',
    owned: '我的 NFT',
    staked: '质押 NFT',
    sell: '出售',
    stake: '质押',
    mintKeeperNft: '铸造 KEEPER NFT',
    nftName: 'NFT 名称',
    selectGender: '选择性别',
    male: '男性',
    female: '女性',
    selectFaction: '选择派系',
    spirits: '五行派',
    martials: '战士派',
    exos: '赛博朋克派',
    voids: '暗黑派',
    selectNft: '选择 NFT',
    common: '普通',
    special: '特殊',
    rare: '稀有',
    reSelect: '重新选择',
    mint: '铸造',
    proceed: '继续',
    enterName: '输入名字',
    nameUsed: '名字被占用',
    cancel: '取消',
    claim: '领取',
    freeMintEnd: '销售结束于',
    days: '天',
    hours: '小時',
    minutes: '分钟',
    seconds: '秒',
  },
  nftDetail: {
    back: '返回',
    attributes: '属性',
    leveltooltip: '加速您的 NFT 属性以升级并获得更多质押奖励！',
    purchaseAp: '购买属性点',
    userStats: '用户数据',
    nfts: 'NFTs',
    charisma: '魅力',
    dexterity: '敏捷',
    intellect: '智力',
    vitality: '活力',
    accelerate: '加速',
    details: '详情',
    currentHolder: '当前持有者',
    mintTime: '铸造时间',
    mintAddress: '铸造地址',
    estimatedPrice: '预估价格',
    endPrice: '最终价格',
    role: '角色',
    contractAddress: '合约地址',
    tokenID: '代币 ID',
    tokenStandard: '代币标准',
    chain: '链',
    background: '背景',
    effect: '效果',
    body: '身体',
    clothing: '衣物',
    head: '头部',
    weapon: '武器',
    bodyAttachment: '配件',
    artifact: '神器',
    purchaseAP: '购买属性点',
    balance: '账户余额',
    amount: '总额',
    price: '价格',
    buy: '购买',
    caUpgrade: '升级魅力属性',
    vaUpgrade: '升级活力属性',
    inUpgrade: '升级智力属性',
    dxUpgrade: '升级敏捷属性',
    max: '最大',
    unStake: '停止质押',
    claimAp: '领取属性点',
    apReward: 'AP奖励',
    apRewardtooltip:
      '只有 2 级及以上的 NFT 才有资格获得成就奖励。 会员需要每天领取奖励 AP 以恢复下一个计数周期。\n否则将导致 AP 保持在 1，尽管质押了 2 天或更长时间。',
    stakingTime: '累计质押时间',
    claimApHelpDes:
      '会员必须每天领取AP奖励，以继续下一个AP周期。即使你已经将NFT质押100天甚至更长的时间，每天未能及时领取的AP奖励将会始终保持在1个点数。',
    claimProfit: '您可以领取收益',
    claim: '领取',
    sell: '出售',
    referencePrice: '参考价格',
    onSale: '出售中',
    unListing: '未上架',
    unList: '未上架',
    insufficientLynk: 'Lynk不足',
    fullLevel: '满级',
    upLimit: '必须高于',
    oneDayMaxVA: 'VA今天完成升级了',
    enterPoint: '输入点数',
    noReward: '没有可领取的奖励',
    mustHigherThanLevel2: '你必须高于2级',
    mintAction: '锻造 Finder NFT',
    mint: '锻造',
    unstake: '取消质押',
    upgrade: '升级',
    loading: '加载中',
  },
  stake: {
    title: '质押我的 NFTs',
    redeem: '停止质押',
    claim: '领取',
    accumulatedEarned: '累积获得的 $LRT',
    available: '可用的 $LRT',
    claiming: '领取中',
  },
  invitation: {
    title: '邀请版块',
    invitationDes: '邀请朋友一起获得奖励',
    inviteFriends: '邀请朋友',
    rewardSummary: '奖励总结',
    socialRewardUp: '社交奖励',
    socialRewardDes:
      '通过邀请您的朋友加入Lynk社区来建立您的团队和Keeper网络。您的Keeper等级越高，获得的社交奖励就越高。',
    communityReward: '社区奖励',
    communityReward1: '社区奖励',
    communityRewardDes: '通过与圣域的成员联系，您可建立新的联盟，或扩大现有的联盟，以获得更高的奖励。',
    contributionRewardUp: '贡献奖励',
    contributionRewardDes:
      '除了获得 $LRT 奖励，完成某些任务也能获得 AP 奖励。招募新成员的用户能从旗下的成员每积累的 100 CA 获得 1-6 个 AP (立即入账) 。',
    AchievementReward: '成就奖励',
    AchievementRewardDes:
      '除了获得 $LRT 奖励作为收入外，完成某些任务也能获得 AP 奖励。质押一个 2 级或以上的 NFT，每天都可以获得 AP 奖励! AP 奖励的数量将由您的 NFT 的等级决定。',
    StakingReward: '质押奖励',
    StakingRewardDes: '一个用户可以同时拥有多个NFT。只要NFT(s)符合质押要求，用户便可每日获得质押收益。',
    bonusRatio: '奖金比例',
    totalLRT: '总计 $LRT',
    qualified: '符合条件',
    cumulateCA: '累积 CA',
    totalAP: '总计 AP',
    cycles: '周期',
    stakingNFT: '质押 NFT',
    elite: '精英',
    epic: '史诗',
    master: '大师',
    legendary: '传奇',
    mythic: '神话',
    divine: '神圣',
    nourishInviter: '直推邀请',
    teamGrade: '累积',
    levelPrivilege: '权限等级',
    socialReward: '社交奖励',
    contributionReward: '贡献奖励',
    achievementReward: '成就奖励',
    inactive: '未激活',
    activated: '已激活',
    myLevel: '我的等级',
    generation: '代级',
    inviteRule: '介绍每一个新的100CA，得到',
    NFTStakingRule: '2级以上NFT质押1天，获得',
    inactivated: '未激活',
    activate: '已激活',
    AcaStastistics: 'SUMCA 统计数据',
    daily: '每日',
    yesterday: '昨天',
    today: '今天',
    weekly: '每周',
    lastWeek: '上星期',
    thisWeek: '本星期',
    monthly: '每月',
    lastMonth: '上个月',
    thisMonth: '这个月',
  },
  history: {
    title: '历史记录',
    stakingRewardRecords: '质押奖励记录',
    mintingRecords: 'NFT 铸造记录',
    transactionsRecords: 'NFT 交易记录',
    recommendedRecords: '推荐用户记录',
    referralRewardRecords: '推荐奖励记录',
    contributionRewardRecords: '贡献奖励记录',
    achievementRewardRecords: '成就奖励记录',
    communityRewardRecords: '社区奖励记录',
    time: '时间',
    reward: '奖励',
    tx: '交易',
    name: '名字',
    amount: '数量',
    buyerAddress: '买家地址',
    sellerAddress: '卖家地址',
    address: '地址',
  },
  rank: {
    title: '用户排行榜',
    top: '最高',
    ranking: '级别',
    levelUp: '升级',
    charisma: '魅力',
    nft: 'NFT',
    level: '等级',
    tx: '交易',
  },
  finders: {
    title: 'FINDERS',
    findersDes1: 'Finders是一群来自Nexus的独特生物，',
    findersDes2: '一个坐落于时空坐标的中心点',
    mint: '铸造',
    soldOut: '售罄',
    info1:
      'Finders是一群来自Nexus的独特生物，一个坐落于时空坐标交汇处。这些生物早在已知的全能宇宙开始就存在，并见证了无数世界的诞生。他们收集了来自所有元宇宙的知识和数据，通过COHESION连接并同步他们的思想来保存资料。，一天，一个非同寻常的元宇宙不时地出现，它具有超越其他元宇宙极限的潜力，引起Finders的关注。他们便决定采取行动。通过时空裂缝进入LYNKVERSE，Finders开始执行他们的任务—— 找出被选中之人来指导其他居民，并确保元宇宙充分发挥其潜力。',
    info2:
      '每个Finder都创造了一颗蕴含他们部分力量的灵魂之种，并将其赠予被选中之人。通过吸收灵魂之种的力量，一个新的种族也诞生了。来吧，披上名为希望的披风，成为新时代的领导者！',
    totalAmount: '总数量:',
    totalBalance: '剩余数量:',
    minted: '已锻造',
  },
  nodeNft: {
    title: '首批节点NFT计划',
    mintNow: '即刻锻造',
    mintNode: '锻造节点 NFT',
    mintNodeNft: '锻造节点 NFT',
    nodeNftDes1: '节点NFT，让您享受非凡待遇',
    nodeNftDes2: '以最具代表性的身份和地位，携手共创LYNK社区发展',
    info11: `亲爱的LYNK家族成员，欢迎来到我们的全新节点NFT计划！`,
    info12: `Lynk精英之作——全新节点NFT计划，专为早期积极推动Lynk社区发展，有着杰出贡献的支持者量身打造。首批核心会员将能拥有独一无二的传奇级别Keeper NFT，彰显社区先锋者的特殊身份和地位。`,
    info13: `此特别计划为您提供绝无仅有，锻造节点NFT的机会，共分为三个等级：普通、特殊和稀有。在每个等级中，您将获得非一般的福利：`,
    infoCommon: `普通节点NFT：以1000 USDT的价格锻造，不仅可以获得1,200 魅力（CA）、110 属性点（AP）和普通NFT徽章，即可直接晋升为传奇级别。`,
    infoSpecial: `特殊节点NFT：以3000 USDT的价格锻造，不仅可以获得4,000 魅力（CA）、450 属性点（AP）和特殊NFT徽章，即可直接晋升为传奇级别。`,
    infoRare: `稀有节点NFT：以5000 USDT的价格锻造，不仅可以获得7,000 魅力（CA）、1,200 属性点（AP）和稀有NFT徽章，即可直接晋升为传奇级别。`,
    info21: `作为我们的传奇级别会员，您也将享受非凡的特权！每成功推荐一位新会员，您将获得与他们购买的节点NFT等级对应的比例奖励。以下为每个推荐等级能获得的奖励：`,
    info22: `每成功推荐一位新会员购买稀有节点 NFT 时，您将额外获得 5,000 累积 CA、280 AP 和 560 LRT 代币的奖励！`,
    info23: `每成功推荐一位新会员购买殊节节点 NFT 时，您将额外获得 3,000 累积 CA、160 AP 和 320 LRT 代币的奖励！`,
    info24: `每成功推荐一位新会员购买普通节点 NFT 时，您将额外获得 1,000 累积 CA、48 AP 和 96 LRT 代币的奖励！`,
    info25: `节点NFT是您在我们Lynk Web3社区中扩大人脉关系的秘密武器，帮您解锁更多的福利和收益！`,
    info26: `热烈欢迎您和朋友们共同加入节点NFT团队，共享不同奖励及特别待遇。即刻加入我们，开启无限的收益之旅！`,
    minted: '已锻造',
    soldOut: '售罄',
  },
  leaderNft: {
    title: '首批领袖NFT计划',
    mintNow: '即刻锻造',
    mintLeader: '锻造领袖 NFT',
    mintLeaderNft: '锻造领袖 NFT',
    leaderNftDes1: '领袖NFT，让您享受非凡待遇',
    leaderNftDes2: '以最具代表性的身份和地位，携手共创LYNK社区发展',
    info11: `亲爱的LYNK家族成员，欢迎来到我们的全新领袖NFT计划！`,
    info12: `Lynk精英之作——全新领袖NFT计划，专为早期积极推动Lynk社区发展，有着杰出贡献的支持者量身打造。首批核心会员将能拥有独一无二的传奇级别Keeper NFT，彰显社区先锋者的特殊身份和地位。`,
    info13: `此特别计划为您提供绝无仅有，锻造领袖NFT的机会，共分为三个等级：普通、特殊和稀有。在每个等级中，您将获得非一般的福利：`,
    infoCommon: `普通领袖NFT：以500 USDT的价格锻造，不仅可以获得500 魅力（CA），即可直接晋升为史诗级别。`,
    infoSpecial: `特殊领袖NFT：以1000 USDT的价格锻造，不仅可以获得1,000 魅力（CA），即可直接晋升为大师级别。`,
    infoRare: `稀有领袖NFT：以3000 USDT的价格锻造，不仅可以获得3,000 魅力（CA），即可直接晋升为传奇级别。`,
    info21: `作为我们的传奇级别会员，您也将享受非凡的特权！每成功推荐一位新会员，您将获得与他们购买的领袖NFT等级对应的比例奖励。以下为每个推荐等级能获得的奖励：`,
    info22: `每成功推荐一位新会员购买稀有领袖 NFT 时，您将额外获得 3,000 累积 CA、120 AP 和 240 LRT 代币的奖励！`,
    info23: `每成功推荐一位新会员购买殊节领袖 NFT 时，您将额外获得 1,000 累积 CA、40 AP 和 80 LRT 代币的奖励！`,
    info24: `每成功推荐一位新会员购买普通领袖 NFT 时，您将额外获得 500 累积 CA、20 AP 和 40 LRT 代币的奖励！`,
    info25: `领袖NFT是您在我们Lynk Web3社区中扩大人脉关系的秘密武器，帮您解锁更多的福利和收益！`,
    info26: `热烈欢迎您和朋友们共同加入领袖NFT团队，共享不同奖励及特别待遇。即刻加入我们，开启无限的收益之旅！`,
    minted: '已锻造',
    soldOut: '售罄',
  },
  marketPlace: {
    title: '市场',
    totalVolume: '总交易量',
    floorPrice: '最低入手价格',
    higherPrice: '最高价格',
    owner: '持有者',
    tradingFee: '交易手续费',
    faction: '派系',
    type0: 'Finder',
    type1: '五行派',
    type2: '赛博朋克派',
    type3: '战士派',
    type4: '暗黑派',
    gender: '性别',
    boy: '男性',
    girl: '女性',
    level: '等级',
    level0: '等级 2-3',
    level1: '等级 4-6',
    level2: '等级 7-9',
    level3: '等级 10-12',
    level4: '等级 13',
    priceDesc: '价格：由高至低',
    priceAsc: '价格：由低至高',
    priceHint: {
      empty: '请输入价格范围',
      numberError: '最大价格不能低于最小价格',
    },
    priceUsdt: 'USDT 价格',
    to: '至',
    all: '全部',
    buy: '购买',
    rare: '稀有',
    price: '价格',
    balance: '余额',
  },
};
