import { NetworkConnector } from './NetworkConnector';
import { Web3Provider } from '@ethersproject/providers';
import { ChainId } from '../constants/chain';

const NETWORK_URL = process?.env?.REACT_APP_NETWORK_URL;

export function getRpcUrl(chainId: ChainId) {
  switch (chainId) {
    case ChainId.MAINNET:
      return NETWORK_URL ?? '`https://mainnet.infura.io/v3/169a2f10743f4afdaa0a17e148552867`';
    // return `https://mainnet.infura.io/v3/169a2f10743f4afdaa0a17e148552867`
    default:
      return '';
  }
}

export function getOtherNetworkLibrary(chainId: ChainId) {
  const rpc = getRpcUrl(chainId);
  if (!rpc) return undefined;
  return new Web3Provider(
    new NetworkConnector({
      urls: {
        [chainId]: getRpcUrl(chainId),
      },
    }).provider as any
  );
}
