import { Attr, SUPPORT_ATTR } from 'utils/attr';
import BaseModal from 'components/BaseModal';
import { MenuItem, Stack, Typography, InputAdornment, InputBase, styled } from '@mui/material';
import { LYNKNFT_ADDRESS, SUPPORTED_TOKEN, TOKEN } from 'constants/index';
import React, { useCallback, useEffect, useState } from 'react';
import { useTokenBalance } from 'hooks/useWallet';
import { tryParseAmount } from 'utils/parseAmount';
import { useI18n } from 'react-simple-i18n';
import { useNFT, useVAInfo } from 'hooks/useNFT';
import { useActiveWeb3React } from 'hooks';
import { Token } from 'constants/token';
import { ApprovalState, useApproveCallback } from 'hooks/useApproveCallback';
import { useAppDispatch } from 'hooks/redux';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  showTransactionConfirmModal,
  showTransactionPendingModal,
  showTransactionErrorModal,
} from 'store/features/componentSlice';
import Select, { SelectChangeEvent } from '@mui/material/Select';

const NameInput = styled(InputBase)(({ theme }) => ({
  borderRadius: 13,
  height: theme.spacing(4),
  width: theme.spacing(37),
  background: '#F0F0F0',
  border: '1px solid #00000099',
  padding: theme.spacing(0, 2),
  '&.usdtNumber': {
    padding: theme.spacing(0, 0, 0, 2),
  },
  [theme.breakpoints.down('sm')]: {
    width: theme.spacing(27),
  },
}));

export default function UpgradeModal({
  tokenId,
  tokenName,
  attr,
  title,
  tokens,
  isOpen,
  handleClose,
  levelMax,
  value,
}: {
  tokenId: string;
  tokenName: string;
  attr: Attr;
  title: string;
  tokens: Token[];
  max: string;
  isOpen: boolean;
  handleClose: () => void;
  levelMax: number;
  value: number;
}) {
  const { t } = useI18n();
  const dispatch = useAppDispatch();
  const { account } = useActiveWeb3React();
  const [tokenTag, setTokenTag] = useState(tokens[0].symbol);
  const paymentToken = SUPPORTED_TOKEN[tokenTag as TOKEN];
  const [typed, setTyped] = useState('');
  const balance = useTokenBalance(account ?? undefined, paymentToken);
  const payAmount = tryParseAmount(typed, paymentToken);
  const enoughAsset = balance && payAmount && !balance.lessThan(payAmount);
  const [approvalState, approveCallback] = useApproveCallback(payAmount, LYNKNFT_ADDRESS);
  const { upgrade } = useNFT();
  const { loading, maxValue } = useVAInfo(tokenId || '');
  const maxValueNum = Number(maxValue.toString());

  const onChangeCurrencyType = (event: SelectChangeEvent) => {
    setTokenTag(event.target.value as TOKEN);
  };

  useEffect(() => {
    setTokenTag(tokens[0].symbol);
  }, [tokens]);
  const upgradeCallback = useCallback(async () => {
    dispatch(showTransactionPendingModal());
    upgrade(SUPPORT_ATTR[attr].attr, tokenId, tokenName, typed, paymentToken.address)
      .then(() => {
        dispatch(showTransactionConfirmModal());
        handleClose();
      })
      .catch((err: any) => {
        dispatch(
          showTransactionErrorModal(
            err.error && err.error.message
              ? err.error.message
              : err?.data?.message
              ? err?.data?.message
              : err.message ?? t('global.networkError')
          )
        );
        console.error(err);
      });
  }, [dispatch, upgrade, attr, tokenId, tokenName, typed, paymentToken.address, handleClose, t]);
  return (
    <BaseModal
      contentWidth={434}
      open={isOpen}
      confirmDisabled={!enoughAsset}
      confirmLoading={!balance || approvalState === ApprovalState.PENDING || loading}
      confirmText={
        !payAmount
          ? t('nftDetail.enterPoint')
          : !enoughAsset
          ? t('global.insufficient')
          : approvalState === ApprovalState.NOT_APPROVED
          ? t('global.approve')
          : t('global.confirm')
      }
      confirm={approvalState === ApprovalState.NOT_APPROVED ? approveCallback : upgradeCallback}
      handleClose={handleClose}
      title={title}
    >
      <Stack justifyContent="center" alignItems="center" spacing={0.5}>
        <NameInput
          value={typed}
          onChange={(e) => {
            const eventValue = e.target.value.replace(/[^0-9]/g, '').replace(/\b(0+)/gi, '');
            setTyped(() =>
              attr === 0
                ? eventValue
                : attr === 1
                ? levelMax - value > maxValueNum
                  ? Number(eventValue) > maxValueNum
                    ? maxValueNum.toString()
                    : eventValue
                  : Number(eventValue) > levelMax - value
                  ? (levelMax - value).toString()
                  : eventValue
                : Number(eventValue) + value - levelMax > 0
                ? (levelMax - value).toString()
                : eventValue
            );
          }}
          className="usdtNumber"
          placeholder={'0'}
          endAdornment={
            <InputAdornment position="end">
              <Typography
                onClick={() =>
                  setTyped(
                    levelMax - value > 0
                      ? (attr === 1 && levelMax - value > maxValueNum ? maxValueNum : levelMax - value).toString()
                      : '0'
                  )
                }
                sx={(theme) => ({ padding: theme.spacing(0, 1), fontWeight: 700, cursor: 'pointer' })}
              >
                {t('nftDetail.max')}
              </Typography>
              <Select
                value={tokenTag}
                onChange={onChangeCurrencyType}
                displayEmpty
                IconComponent={ExpandMoreIcon}
                sx={{
                  color: '#000',
                  fontWeight: 700,
                  height: 32,
                  '& .MuiOutlinedInput-notchedOutline': {
                    border: 'none',
                    borderRadius: 0,
                    borderLeft: '1px solid #00000099',
                  },
                  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                    borderLeft: '1px solid #00000099',
                  },
                }}
                inputProps={{ 'aria-label': 'Without label' }}
              >
                {tokens.map(({ symbol }) => {
                  return (
                    <MenuItem key={symbol} value={symbol}>
                      <Typography>{symbol}</Typography>
                    </MenuItem>
                  );
                })}
              </Select>
            </InputAdornment>
          }
        />
        <Typography
          variant="body2"
          sx={(theme) => ({
            width: theme.spacing(37),
            textAlign: 'start',
            [theme.breakpoints.down('sm')]: {
              width: theme.spacing(27),
            },
          })}
        >
          {`${t('global.balance')}: ${balance ? balance.toFixed(2, { groupSeparator: ',' }) : '--'}`}
        </Typography>
      </Stack>
    </BaseModal>
  );
}
