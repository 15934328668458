import React from 'react';
import { Select, MenuItem } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Check from '@mui/icons-material/Check';
import Checkbox from '@mui/material/Checkbox';
import { useI18n } from 'react-simple-i18n';

interface BaseSelectProps {
  selectItemIndex?: number;
  selectItemIndexs?: number[];
  MenuList: any[];
  selectWidth: number;
  mobileSelectWidth: number | string;
  onChange: (e: any) => void;
  label?: string;
}

const BaseSelect = (props: BaseSelectProps) => {
  const { t } = useI18n();
  const { selectItemIndex, MenuList, selectWidth = 160, onChange, label, selectItemIndexs, mobileSelectWidth } = props;

  return (
    <Select
      sx={(theme) => ({
        background: '#F0F0F0',
        width: selectWidth,
        height: 30,
        borderRadius: '7px',
        alignSelf: 'center',
        overflow: 'hidden',
        '& .MuiOutlinedInput-notchedOutline': { border: 'none' },
        [theme.breakpoints.down('sm')]: {
          width: mobileSelectWidth || '100%',
          height: 24,
          '& .MuiSelect-select': {
            zoom: 0.8,
          },
        },
      })}
      displayEmpty
      multiple={selectItemIndexs ? true : false}
      value={selectItemIndexs || selectItemIndex}
      renderValue={(selected) => {
        if (selectItemIndexs && Array.isArray(selected)) {
          const selectItems = selected.map((i) => {
            return label ? MenuList[i][label] : MenuList[i];
          });
          return selected.length === MenuList.length ? t('marketPlace.all') : selectItems.join(', ');
        } else if (typeof selected === 'number') {
          return label ? MenuList[selected][label] : MenuList[selected];
        }
      }}
      IconComponent={ExpandMoreIcon}
      inputProps={{
        sx: {
          border: 'none',
          paddingY: 0,
        },
      }}
      MenuProps={{
        sx: {
          marginTop: 1,
          '& .MuiPaper-root': {
            borderRadius: '10px',
            boxShadow: 'unset',
          },
          '& .MuiList-root': { padding: '0 12px', background: '#F0F0F0' },
        },
      }}
      onChange={onChange}
    >
      {MenuList.map((item, index) => (
        <MenuItem
          disableRipple
          divider={index !== MenuList.length - 1}
          key={`${label ? item[label] : item}${index}`}
          sx={(theme) => ({
            height: 38,
            background: '#F0F0F0',
            justifyContent: 'space-between',
            padding: '2px 0',
            '&:hover': { backgroundColor: '#F0F0F0' },
            '&.Mui-selected': { backgroundColor: '#F0F0F0 !important' },
            '&.Mui-selected:hover': { backgroundColor: '#F0F0F0' },
            [theme.breakpoints.down('sm')]: { minHeight: 'unset', zoom: 0.8 },
          })}
          value={index}
        >
          {selectItemIndexs && (
            <Checkbox
              disableRipple
              sx={(theme) => ({
                padding: theme.spacing(0, 2, 0, 0),
                [theme.breakpoints.down('sm')]: { padding: theme.spacing(0, 1, 0, 0) },
              })}
              checked={selectItemIndexs.indexOf(index) > -1}
            />
          )}
          <ListItemText
            primary={label ? item[label] : item}
            // sx={(theme) => ({ [theme.breakpoints.down('sm')]: { zoom: 0.8 } })}
          />
          {selectItemIndex === index ? (
            <ListItemIcon sx={{ justifyContent: 'flex-end' }}>
              <Check />
            </ListItemIcon>
          ) : null}
        </MenuItem>
      ))}
    </Select>
  );
};

export default BaseSelect;
