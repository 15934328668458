import { configureStore } from '@reduxjs/toolkit';
import { save, load } from 'redux-localstorage-simple';
import applicationReducer from './features/applicationSlice';
import componentReducer from './features/componentSlice';
import multicallReducer from './features/multicallSlice';
import userReducer from './features/userSlice';
import transactionsReducer from './features/transactionsSlice';

const PERSISTED_KEYS: string[] = ['user', 'transactions'];

const store = configureStore({
  reducer: {
    component: componentReducer,
    multicall: multicallReducer,
    application: applicationReducer,
    user: userReducer,
    transactions: transactionsReducer,
  },
  middleware: [save({ states: PERSISTED_KEYS, disableWarnings: true })],
  preloadedState: load({ states: PERSISTED_KEYS, disableWarnings: true }),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export default store;
