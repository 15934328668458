import React from 'react';
import { styled } from '@mui/material';
import DrawerMenu from 'components/DrawerMenu';
import { Outlet } from 'react-router-dom';
import '@fontsource/chakra-petch';

const drawerWidth = 220;

const Container = styled('div')(({ theme }) => ({
  display: 'flex',
  height: '100vh',
  width: '100vw',
  [theme.breakpoints.down('sm')]: {
    height: 'calc(100%)',
    width: 'calc(100%)',
  },
}));

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })<{
  open?: boolean;
}>(({ theme, open }) => ({
  position: 'relative',
  flex: 1,
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: `-${drawerWidth}px`,
  ...(open && {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  }),
}));

const BaseLayout = () => {
  const [open] = React.useState(true);

  return (
    <Container>
      <DrawerMenu open={open} drawerWidth={drawerWidth} />
      <Main open={open}>
        <Outlet />
      </Main>
    </Container>
  );
};

export default BaseLayout;
