import React, { useCallback } from 'react';
import { styled, Box, Typography, Button, Stack } from '@mui/material';
import { useAppDispatch } from 'hooks/redux';
import {
  showTransactionConfirmModal,
  showTransactionPendingModal,
  showTransactionErrorModal,
} from 'store/features/componentSlice';
import BaseModal from 'components/BaseModal';
import useBreakpoint from 'hooks/useBreakpoint';
import { LYNK, AP } from 'constants/index';
import { ApprovalState, useApproveCallback } from 'hooks/useApproveCallback';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import Carousel from 'react-material-ui-carousel';
import { useI18n } from 'react-simple-i18n';
import { CurrencyAmount, TokenAmount } from 'constants/token';
import { useTokenBalance } from 'hooks/useWallet';
import { useActiveWeb3React } from 'hooks';
import { usePackage } from 'hooks/usePackage';
import JSBI from 'jsbi';
import { ReactComponent as AP1 } from 'assets/img/nfts/AP1.svg';
import { ReactComponent as AP2 } from 'assets/img/nfts/AP2.svg';
import { ReactComponent as AP3 } from 'assets/img/nfts/AP3.svg';
import { ReactComponent as AP4 } from 'assets/img/nfts/AP4.svg';

const MintModalBox = styled(Box)(({ theme }) => ({
  padding: theme.spacing(1, 4, 4),
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(0, 1.5),
    marginBottom: theme.spacing(2),
  },
}));

const PurchaseApBox = styled(Box)(({ theme }) => ({
  width: theme.spacing(16),
  height: theme.spacing(18),
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: theme.spacing(1, 0),
  justifyContent: 'space-between',
  margin: 0,
  [theme.breakpoints.down('sm')]: {
    width: 'auto',
    maxWidth: theme.spacing(14),
    height: theme.spacing(18),
    padding: theme.spacing(1),
    gap: theme.spacing(0.5),
    background: '#E9E9E9',
  },
}));

const TypographyLable = styled(Typography)<{ component?: string }>(({ theme }) => ({
  fontSize: 13,
  fontWeight: 500,
  textAlign: 'center',
  [theme.breakpoints.down('sm')]: {
    zoom: 0.8,
    textAlign: 'center',
  },
}));

const BuyButton = styled(Button)(({ theme, disabled }) => ({
  width: 'fit-content',
  height: theme.spacing(3.5),
  background: disabled ? '#C4C4C4' : '#85D1E8',
  fontSize: 12,
  fontWeight: 700,
  color: '#FFFFF8',
  borderRadius: 7,
  padding: theme.spacing(0.5),
  '&:hover': {
    background: disabled ? '#C4C4C4' : '#85D1E8',
  },
  [theme.breakpoints.down('sm')]: {
    zoom: 0.7,
    borderRadius: 4,
  },
}));

const PurchaseApModal = ({ open, handleClose }: { open: boolean; handleClose: () => void }): JSX.Element => {
  const { t } = useI18n();
  const dispatch = useAppDispatch();
  const { mint, packages } = usePackage();
  const { account } = useActiveWeb3React();
  const alyxBalance = useTokenBalance(account ?? undefined, LYNK);
  const balance = account ? alyxBalance : undefined;
  const isMobile = useBreakpoint();
  const payAmount = new TokenAmount(LYNK, JSBI.BigInt(1));
  const [approvalState, approveCallback] = useApproveCallback(payAmount, AP.address);

  const mintCallback = useCallback(
    async (index: string, amount: string) => {
      dispatch(showTransactionPendingModal());
      mint(index, amount)
        .then(() => {
          dispatch(showTransactionConfirmModal());
        })
        .catch((err: any) => {
          dispatch(
            showTransactionErrorModal(
              err.error && err.error.message
                ? err.error.message
                : err?.data?.message
                ? err?.data?.message
                : err.message ?? t('global.networkError')
            )
          );
          console.error(err);
        });
    },
    [dispatch, mint, t]
  );

  return (
    <BaseModal
      open={open}
      title={t('nftDetail.purchaseAP')}
      contentWidth={600}
      contentMobileWidth={'80vw'}
      handleClose={handleClose}
    >
      <MintModalBox>
        <Typography lineHeight={3}>{`${t('global.balance')}: ${
          alyxBalance ? alyxBalance?.toFixed(2, { groupSeparator: ',' }) : '--'
        } LYNK`}</Typography>
        {isMobile ? (
          <Stack direction="row" justifyContent="center" alignItems="center" spacing={1}>
            <Carousel
              autoPlay={false}
              indicators={false}
              animation="slide"
              cycleNavigation={false}
              navButtonsAlwaysVisible={true}
              PrevIcon={<ArrowBackIosNewIcon fontSize="large" />}
              NextIcon={<ArrowForwardIosIcon fontSize="large" />}
              navButtonsProps={{
                style: {
                  backgroundColor: 'transparent',
                  color: '#121425',
                },
              }}
              sx={(theme) => ({ width: theme.spacing(14), borderRadius: '10px', position: 'unset' })}
            >
              {packages?.map((item, index) => {
                return (
                  <PurchaseApBox key={index}>
                    {index === 0 ? <AP1 /> : index === 1 ? <AP2 /> : index === 2 ? <AP3 /> : <AP4 />}
                    <Stack spacing={0}>
                      <TypographyLable color={'#8B8B8B'}>
                        {`${t('nftDetail.amount')}: `}
                        <TypographyLable component="span" color={'#4F4F4F'}>{`${
                          item.result?.[0]?.[2]
                            ? CurrencyAmount.ether(item.result?.[0]?.[2].toString()).toFixed(2, { groupSeparator: ',' })
                            : '--'
                        } AP`}</TypographyLable>
                      </TypographyLable>
                      <TypographyLable color={'#8B8B8B'} whiteSpace="nowrap">
                        {`${t('nftDetail.price')}: `}
                        <TypographyLable component="span" color={'#4F4F4F'}>{`${
                          item.result?.[0]?.[1]
                            ? CurrencyAmount.ether(item.result?.[0]?.[1].toString()).toFixed(2, { groupSeparator: ',' })
                            : '--'
                        } LYNK`}</TypographyLable>
                      </TypographyLable>
                    </Stack>
                    <BuyButton
                      disabled={
                        balance &&
                        item.result?.[0]?.[1] &&
                        JSBI.greaterThan(JSBI.BigInt(item.result?.[0]?.[1]), JSBI.BigInt(balance?.raw?.toString()))
                      }
                      onClick={() =>
                        approvalState === ApprovalState.NOT_APPROVED
                          ? approveCallback()
                          : mintCallback(index.toString(), item.result?.[0]?.[1])
                      }
                    >
                      {approvalState === ApprovalState.NOT_APPROVED
                        ? t('global.approve')
                        : balance &&
                          item.result?.[0]?.[1] &&
                          JSBI.greaterThan(JSBI.BigInt(item.result?.[0]?.[1]), JSBI.BigInt(balance?.raw?.toString()))
                        ? t('nftDetail.insufficientLynk')
                        : t('nftDetail.buy')}
                    </BuyButton>
                  </PurchaseApBox>
                );
              })}
            </Carousel>
          </Stack>
        ) : (
          <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={1}>
            {packages?.map((item, index) => {
              return (
                <PurchaseApBox key={index}>
                  {index === 0 ? <AP1 /> : index === 1 ? <AP2 /> : index === 2 ? <AP3 /> : <AP4 />}
                  <Stack spacing={0}>
                    <TypographyLable color={'#8B8B8B'}>
                      {`${t('nftDetail.amount')}: `}
                      <TypographyLable component="span" color={'#4F4F4F'}>{`${
                        item.result?.[0]?.[2]
                          ? CurrencyAmount.ether(item.result?.[0]?.[2].toString()).toFixed(2, { groupSeparator: ',' })
                          : '--'
                      } AP`}</TypographyLable>
                    </TypographyLable>
                    <TypographyLable color={'#8B8B8B'} whiteSpace="nowrap">
                      {`${t('nftDetail.price')}: `}
                      <TypographyLable component="span" color={'#4F4F4F'}>{`${
                        item.result?.[0]?.[1]
                          ? CurrencyAmount.ether(item.result?.[0]?.[1].toString()).toFixed(2, { groupSeparator: ',' })
                          : '--'
                      } LYNK`}</TypographyLable>
                    </TypographyLable>
                  </Stack>
                  <BuyButton
                    disabled={
                      balance &&
                      item.result?.[0]?.[1] &&
                      JSBI.greaterThan(JSBI.BigInt(item.result?.[0]?.[1]), JSBI.BigInt(balance?.raw?.toString()))
                    }
                    onClick={() =>
                      approvalState === ApprovalState.NOT_APPROVED
                        ? approveCallback()
                        : mintCallback(index.toString(), item.result?.[0]?.[1])
                    }
                  >
                    {approvalState === ApprovalState.NOT_APPROVED
                      ? t('global.approve')
                      : balance &&
                        item.result?.[0]?.[1] &&
                        JSBI.greaterThan(JSBI.BigInt(item.result?.[0]?.[1]), JSBI.BigInt(balance?.raw?.toString()))
                      ? t('nftDetail.insufficientLynk')
                      : t('nftDetail.buy')}
                  </BuyButton>
                </PurchaseApBox>
              );
            })}
          </Stack>
        )}
      </MintModalBox>
    </BaseModal>
  );
};

export default PurchaseApModal;
