import { Web3Provider } from '@ethersproject/providers';
import { InjectedConnector } from '@web3-react/injected-connector';
import { BscConnector } from '@binance-chain/bsc-connector';
import { WalletConnectConnector } from '@web3-react/walletconnect-connector';
import { WalletLinkConnector } from '@web3-react/walletlink-connector';
import { PortisConnector } from '@web3-react/portis-connector';

import { FortmaticConnector } from './Fortmatic';
import { NetworkConnector } from './NetworkConnector';
import { SUPPORT_NETWORK_CHAIN_IDS, NETWORK_CHAIN_ID } from 'constants/chain';
import { getRpcUrl } from './MultiNetworkConnector';

const NETWORK_URL = process?.env?.REACT_APP_NETWORK_URL;
const FORMATIC_KEY = process?.env?.REACT_APP_FORTMATIC_KEY;
const PORTIS_ID = process?.env?.REACT_APP_PORTIS_ID;

if (typeof NETWORK_URL === 'undefined') {
  throw new Error(`REACT_APP_NETWORK_URL must be a defined environment variable`);
}

export const network = new NetworkConnector({
  urls: { [NETWORK_CHAIN_ID]: NETWORK_URL },
});

let networkLibrary: Web3Provider | undefined;
export function getNetworkLibrary(): Web3Provider {
  return (networkLibrary = networkLibrary ?? new Web3Provider(network.provider as any));
}

export const injected = new InjectedConnector({
  supportedChainIds: SUPPORT_NETWORK_CHAIN_IDS,
});

// binance only
export const binance = new BscConnector({ supportedChainIds: [56] });

const walletConnectRpc: { [key in number]: string } = {};
for (const id of SUPPORT_NETWORK_CHAIN_IDS) {
  walletConnectRpc[id] = getRpcUrl(id);
}

// mainnet only
export const walletconnect = new WalletConnectConnector({
  rpc: walletConnectRpc,
  supportedChainIds: SUPPORT_NETWORK_CHAIN_IDS,
  bridge: 'https://bridge.walletconnect.org',
  chainId: NETWORK_CHAIN_ID || undefined,
  qrcode: true,
});

// mainnet only
export const fortmatic = new FortmaticConnector({
  apiKey: FORMATIC_KEY ?? '',
  chainId: 1,
});

// mainnet only
export const portis = new PortisConnector({
  dAppId: PORTIS_ID ?? '',
  networks: [1],
});

// mainnet only
export const walletlink = new WalletLinkConnector({
  url: NETWORK_URL,
  appName: 'dualinvest',
  appLogoUrl:
    'https://mpng.pngfly.com/20181202/bex/kisspng-emoji-domain-unicorn-pin-badges-sticker-unicorn-tumblr-emoji-unicorn-iphoneemoji-5c046729264a77.5671679315437924251569.jpg',
});
