import { useAPContract, useDBContract } from './useContract';
import { useSingleContractMultipleData } from 'hooks/useRequest';
import { useCallback } from 'react';
import { calculateGasMargin } from 'utils';
import { TransactionResponse } from '@ethersproject/providers';
import { useActiveWeb3React } from 'hooks';
import { useTransactionAdder } from 'hooks/useTransaction';

export function usePackage() {
  const DBContract = useDBContract();
  const contract = useAPContract();
  const { account } = useActiveWeb3React();
  const addTransaction = useTransactionAdder();
  const packages = useSingleContractMultipleData(DBContract, 'packageByIndex', [['0'], ['1'], ['2'], ['3']]);

  const mint = useCallback(
    async (index: string, amount: string) => {
      console.log(amount);
      if (!account) throw new Error('none account');
      if (!contract) throw new Error('none contract');
      const args = [index];
      const method = 'mint(uint256)';
      return contract.estimateGas[method](...args, { from: account }).then((estimatedGasLimit) => {
        return contract[method](...args, {
          gasLimit: calculateGasMargin(estimatedGasLimit),
          // gasLimit: '3500000',
          from: account,
        }).then((response: TransactionResponse) => {
          addTransaction(response, {
            summary: '$%nftDetail.purchaseAP$%',
          });
          return response.hash;
        });
      });
    },
    [account, addTransaction, contract]
  );

  return { mint, packages };
}
