import Discord from 'assets/img/drawer/discord.svg';
import Medium from 'assets/img/drawer/medium.svg';
import Gitbook from 'assets/img/drawer/gitbook.svg';
import Twitter from 'assets/img/drawer/twitter.svg';

export const drawerFooter = [
  { name: 'paperPlane', Icon: Gitbook, path: 'https://lynksanctuary.gitbook.io' },
  { name: 'discord', Icon: Discord, path: 'https://discord.gg/6JcgvUzdPj' },
  { name: 'twitter', Icon: Twitter, path: 'https://twitter.com/lynksanctuary' },
  { name: 'medium', Icon: Medium, path: 'https://medium.com/@lynksanctuary' },
];
