import BaseModal from 'components/BaseModal';
import { Button, Stack, Typography } from '@mui/material';
import { MARKET_ADDRESS } from 'constants/index';
import React, { useCallback } from 'react';
import { useI18n } from 'react-simple-i18n';
import { TokenAmount } from 'constants/token';
import { useAppDispatch } from 'hooks/redux';
import {
  showTransactionConfirmModal,
  showTransactionPendingModal,
  showTransactionErrorModal,
} from 'store/features/componentSlice';
import { useMarket } from 'hooks/useMarket';
import { BuyNftCard } from 'components/Card';
import { useActiveWeb3React } from 'hooks';
import { ApprovalState, useApproveCallback } from 'hooks/useApproveCallback';
import { useTokenBalance } from 'hooks/useWallet';

export default function BuyNftModal({
  tokenId,
  handleClose,
  name,
  open,
  level,
  price,
}: {
  tokenId: string;
  open: boolean;
  name: string;
  level: string;
  price: TokenAmount | undefined;
  handleClose: () => void;
}) {
  const { t } = useI18n();
  const dispatch = useAppDispatch();
  const { account } = useActiveWeb3React();
  const { take } = useMarket();
  const [approvalState, approveCallback] = useApproveCallback(price, MARKET_ADDRESS);
  const balance = useTokenBalance(account ?? undefined, price?.token);
  const enoughAsset = balance && price && price.lessThan(balance);

  const takeCallback = useCallback(async () => {
    dispatch(showTransactionPendingModal());
    take(tokenId)
      .then(() => {
        dispatch(showTransactionConfirmModal());
        handleClose();
      })
      .catch((err: any) => {
        dispatch(
          showTransactionErrorModal(
            err.error && err.error.message
              ? err.error.message
              : err?.data?.message
              ? err?.data?.message
              : err.message ?? t('global.networkError')
          )
        );
        console.error(err);
      });
  }, [dispatch, handleClose, t, take, tokenId]);

  return (
    <BaseModal contentWidth={486} open={open} handleClose={handleClose} title={t('marketPlace.buy')}>
      <Stack
        direction="row"
        alignItems="center"
        sx={(theme) => ({
          padding: '0 32px 20px 12px',
          gap: '16px',
          '& .MuiPaper-root': {
            background: '#15253B',
            margin: 0,
          },
          [theme.breakpoints.down('sm')]: { padding: theme.spacing(0, 0, 3), flexDirection: 'column' },
        })}
      >
        <BuyNftCard
          tokenId={tokenId}
          name={name}
          level={level}
          price={price ? price?.toFixed(2, { groupSeparator: ',' }) : '--'}
        />
        <Stack
          alignItems="center"
          sx={(theme) => ({
            gap: theme.spacing(4),
            [theme.breakpoints.down('sm')]: { gap: theme.spacing(1) },
          })}
        >
          <Stack direction="row" alignItems="center" spacing={0.5}>
            <Typography fontWeight={700}>{t('marketPlace.price')}</Typography>
            <Typography fontSize={32} fontWeight={700}>
              {price ? price?.toFixed(2, { groupSeparator: ',' }) : '--'}
            </Typography>
            <Typography fontWeight={700}>{'USDT'}</Typography>
          </Stack>
          <Stack direction="row" alignItems="center" spacing={0.5}>
            <Typography>{`${t('marketPlace.balance')}: `}</Typography>
            <Typography>{balance ? balance?.toFixed(2, { groupSeparator: ',' }) : '--'}</Typography>
          </Stack>
          <Button
            variant="contained"
            disabled={!enoughAsset || approvalState === ApprovalState.PENDING}
            onClick={() => (approvalState === ApprovalState.NOT_APPROVED ? approveCallback() : takeCallback())}
          >
            {!enoughAsset
              ? t('global.insufficient')
              : approvalState === ApprovalState.NOT_APPROVED
              ? t('global.approve')
              : approvalState === ApprovalState.PENDING
              ? t('global.approving')
              : t('global.confirm')}
          </Button>
        </Stack>
      </Stack>
    </BaseModal>
  );
}
