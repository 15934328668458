import React, { useMemo, useCallback } from 'react';
import { styled, Typography, Stack, Paper, Box } from '@mui/material';
import { useI18n } from 'react-simple-i18n';
import { shortenAddress } from 'utils';
import { BASE_IMAGE_URL } from 'constants/index';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import IconButton from '@mui/material/IconButton';
import _ from 'lodash';
import { getEtherscanLink } from 'utils';
import { ChainId } from 'constants/chain';
import { useAppSelector } from 'hooks/redux';
import { useNavigate, createSearchParams } from 'react-router-dom';
import { useActiveWeb3React } from 'hooks';
import useAsyncMemo from 'hooks/useAsyncMemo';
import { getAccountNFT, NFT } from 'utils/fetch/graph';
import { useBlockNumber } from 'hooks/useStoreState';

const RankContentBox = styled('div')(({ theme }) => ({
  gridArea: 'rankB',
  display: 'grid',
  paddingTop: theme.spacing(5),
  gridTemplateRows: `1fr ${theme.spacing(3)}`,
  gridTemplateColumns: `1fr`,
  gridTemplateAreas: `
    'rankTable'
    'rankBottom'
  `,
  [theme.breakpoints.down('sm')]: {
    paddingTop: theme.spacing(0),
    '&::-webkit-scrollbar': {
      width: 0,
    },
    overflowY: 'overlay',
    gridTemplateRows: `${theme.spacing(4)} ${theme.spacing(8)} 1fr ${theme.spacing(2)}`,
    gridTemplateColumns: `1fr`,
    gridTemplateAreas: `
      'rankTitle'
      'rankSelect'
      'rankTable'
      'rankBottom'
    `,
  },
  overflow: 'hidden',
}));

const RankTitle = styled('div')(({ theme }) => ({
  gridArea: 'rankTitle',
  [theme.breakpoints.up('sm')]: {
    display: 'none',
  },
}));

const RankSelect = styled('div')(({ theme }) => ({
  gridArea: 'rankSelect',
  display: 'flex',
  alignItems: 'center',
  [theme.breakpoints.up('sm')]: {
    display: 'none',
  },
}));

const RankTable = styled('div')(({ theme }) => ({
  gridArea: 'rankTable',
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1.5),
  width: '100%',
  // [theme.breakpoints.up('sm')]: {
  //   display: 'none',
  // },
}));

const TypographyDes = styled(Typography)(({ theme }) => ({
  fontSize: 32,
  fontWeight: 700,
  [theme.breakpoints.down('sm')]: {
    fontSize: 20,
  },
}));

const HeadPaper = styled(Paper)(({ theme }) => ({
  height: theme.spacing(4.5),
  color: '#8B8B8B',
  fontSize: 13,
  fontWeight: 700,
  background: 'rgba(126, 192, 224, 0.5)',
  borderRadius: '20px',
  display: 'flex',
  alignItems: 'center',
}));

const BodyPaper = styled(Paper)<{ showBorder?: boolean }>(({ theme, showBorder = true }) => ({
  height: theme.spacing(4.5),
  border: showBorder ? '1px solid rgba(144, 144, 144, 0.2)' : 0,
  color: '#8B8B8B',
  fontSize: 13,
  fontWeight: 700,
  background: '#fff',
  borderRadius: '8px',
  display: 'flex',
  alignItems: 'center',
}));

const TopColor = styled(Box)<{ bgColor?: string }>(({ theme, bgColor }) => ({
  height: theme.spacing(4.5),
  background: bgColor,
  borderRadius: '8px 0 0 8px',
}));

const RowStack = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  gap: theme.spacing(2),
  overflow: 'hidden',
  [theme.breakpoints.down('sm')]: {
    gap: theme.spacing(1),
    marginRight: 5,
  },
}));

const Image = styled('img')(() => ({
  height: '26px',
  weight: '26px',
}));

const RankContent = ({
  rankingList,
  page,
  curRankingType,
  children,
}: {
  rankingList: any[] | undefined;
  page: any;
  curRankingType: number;
  children: React.ReactElement;
}) => {
  const { t } = useI18n();
  const { inviter } = useAppSelector((state) => state.application);
  const { account } = useActiveWeb3React();
  const blockNumber = useBlockNumber();
  const navigate = useNavigate();

  const getMyNFT = useCallback(async () => {
    if (!account) return [];
    return await getAccountNFT(account ?? '');
  }, [account]);

  const NFTList: NFT[] = useAsyncMemo(getMyNFT, [], [blockNumber]);
  console.log(rankingList);

  const headers = useMemo(() => {
    switch (curRankingType) {
      case 0:
        return [
          { title: '', width: '15%' },
          { title: 'rank.level', width: '35%' },
          { title: 'history.tx', width: '50%' },
        ];
      case 1:
        return [
          { title: '', width: '15%' },
          { title: 'NFT', width: '30%' },
          { title: 'rank.level', width: '15%' },
          { title: 'history.address', width: '40%' },
        ];
      default:
        return [
          { title: '', width: '15%' },
          { title: 'NFT', width: '30%' },
          { title: 'CA', width: '15%' },
          { title: 'history.address', width: '40%' },
        ];
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rankingList]);

  const rankList = useMemo(() => {
    switch (curRankingType) {
      case 0: {
        const newList = rankingList?.map((item, index) => {
          return [
            {
              title: (page.currentPage - 1) * page.pageSize + index + 1,
              width: '15%',
              imageId: '',
              tokenId: '',
              nftName: '',
            },
            { title: item.level, width: '35%', imageId: '', tokenId: '', nftName: '' },
            { title: item.address, width: '50%', imageId: '', tokenId: '', nftName: '' },
          ];
        });
        return newList;
      }
      case 1: {
        const newList = rankingList?.map((item, index) => {
          return [
            {
              title: (page.currentPage - 1) * page.pageSize + index + 1,
              width: '15%',
              imageId: '',
              tokenId: '',
              nftName: '',
            },
            { title: item.name, width: '30%', imageId: item.id, tokenId: '', nftName: '' },
            { title: item.level, width: '15%', imageId: '', tokenId: '', nftName: '' },
            { title: item.owner, width: '40%', imageId: '', tokenId: item.id, nftName: item.name },
          ];
        });
        return newList;
      }
      default: {
        const newList = rankingList?.map((item, index) => {
          return [
            {
              title: (page.currentPage - 1) * page.pageSize + index + 1,
              width: '15%',
              imageId: '',
              tokenId: '',
              nftName: '',
            },
            { title: item.name, width: '30%', imageId: item.id, tokenId: '', nftName: '' },
            { title: item.charisma, width: '15%', imageId: '', tokenId: '', nftName: '' },
            { title: item.owner, width: '40%', imageId: '', tokenId: item.id, nftName: item.name },
          ];
        });
        return newList;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rankingList]);

  const jumpEtherscan = (tx: string, tokenId: string, nftName: string) => {
    if (curRankingType === 0) {
      const href = tx ? getEtherscanLink(ChainId.MAINNET, tx, 'address') : '';
      window.open(href);
    } else {
      navigate(
        {
          pathname: `/rank/${tokenId}`,
          search:
            inviter &&
            createSearchParams({
              referrer: inviter,
            }).toString(),
        },
        {
          state: {
            name: nftName,
            ownNftNum: NFTList?.length || 0,
            stakeNftNum: _.filter(NFTList, (o: NFT) => o.isStaking)?.length || 0,
          },
        }
      );
    }
  };

  return (
    <RankContentBox>
      <RankTitle>
        <TypographyDes color={'#000000'}>{t('history.title')}</TypographyDes>
      </RankTitle>
      <RankSelect>{React.cloneElement(children)}</RankSelect>
      <Paper
        elevation={0}
        sx={(theme) => ({
          padding: theme.spacing(2),
          borderRadius: theme.spacing(3),
          height: 'fit-content',
          border: '2px solid #79BFE0',
          [theme.breakpoints.down('sm')]: {
            border: 0,
            padding: theme.spacing(0, 0.2),
            zoom: 0.8,
          },
        })}
      >
        <RankTable>
          <HeadPaper elevation={0}>
            {headers.map((item, index) => (
              <RowStack key={item.title + index} width={item.width}>
                {index < 1 && (
                  <Stack width={12}>
                    <TopColor bgColor={'transparent'}></TopColor>
                  </Stack>
                )}
                {item.title ? t(item.title) : item.title}
              </RowStack>
            ))}
          </HeadPaper>
          {rankList?.map((row, index) => (
            <BodyPaper key={`rankListRow${index}`} showBorder={row[0].title > 3} elevation={row[0].title > 3 ? 0 : 3}>
              {row?.map((rowInfo, rowInfoIndex) => (
                <RowStack key={`rowInfo${rowInfoIndex}`} width={rowInfo.width}>
                  {rowInfoIndex < 1 && (
                    <Stack width={12}>
                      <TopColor
                        bgColor={index < 3 && row[0].title < 4 && rowInfoIndex === 0 ? '#78DBE0' : '#fff'}
                      ></TopColor>
                    </Stack>
                  )}
                  {rowInfo?.imageId && <Image src={`${BASE_IMAGE_URL}${rowInfo.imageId}.png`} />}
                  <Typography
                    color={'#8B8B8B'}
                    sx={{
                      textDecoration: row.length - 1 === rowInfoIndex ? 'underline' : 'unset',
                      cursor: row.length - 1 === rowInfoIndex ? 'pointer' : 'default',
                    }}
                    onClick={() => jumpEtherscan(rowInfo.title, rowInfo.tokenId, rowInfo.nftName)}
                  >
                    {rowInfo.title
                      ? row.length - 1 === rowInfoIndex
                        ? shortenAddress(rowInfo.title, 6)
                        : rowInfo.title
                      : '--'}
                  </Typography>
                </RowStack>
              ))}
            </BodyPaper>
          ))}
          <Stack direction="row" justifyContent="flex-end">
            <IconButton
              onClick={_.debounce(() => page.setCurrentPage(page.currentPage - 1), 300)}
              disableRipple
              disabled={!page.hasPrev}
            >
              <ArrowBackIcon fontSize="small" />
            </IconButton>
            <IconButton disableRipple>{page.currentPage}</IconButton>
            <IconButton
              disabled={!page.hasNext}
              disableRipple
              onClick={_.debounce(() => page.setCurrentPage(page.currentPage + 1), 300)}
            >
              <ArrowForwardIcon fontSize="small" />
            </IconButton>
          </Stack>
        </RankTable>
      </Paper>
    </RankContentBox>
  );
};

export default RankContent;
