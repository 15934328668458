import { Contract } from '@ethersproject/contracts';
import { useMemo } from 'react';
import ENS_PUBLIC_RESOLVER_ABI from '../constants/abis/ens-public-resolver.json';
import ENS_ABI from '../constants/abis/ens-registrar.json';
import { ERC20_BYTES32_ABI } from '../constants/abis/erc20';
import ERC20_ABI from '../constants/abis/erc20.json';
import { MIGRATOR_ABI, MIGRATOR_ADDRESS } from '../constants/abis/migrator';
import UNISOCKS_ABI from '../constants/abis/unisocks.json';
import { MULTICALL_ABI, MULTICALL_NETWORKS } from '../constants/multicall';
import { getContract } from '../utils';
import { useActiveWeb3React } from './index';
import { ChainId } from '../constants/chain';
import { getOtherNetworkLibrary } from 'connectors/MultiNetworkConnector';
import ERC721_ABI from '../constants/abis/erc721.json';
import {
  AP,
  DB_ADDRESS,
  I_LYNK_NFT_ADDRESS,
  LYNKNFT_ADDRESS,
  MARKET_ADDRESS,
  S_LYNK_NFT_ADDRESS,
  STAKING_ADDRESS,
  SWAP_ADDRESS,
  USER_ADDRESS,
} from '../constants';
import CONFIG from '../config';

const { AP_ABI, MARKET_ABI, USER_ABI, LYNKNFT_ABI, DB_ABI, STAKING_ABI, SLYNKNFT_ABI, ILYNKNFT_ABI, SWAP_ABI } =
  CONFIG.abis;

// returns null on errors
function useContract(
  address: string | undefined,
  ABI: any,
  withSignerIfPossible = true,
  queryChainId?: ChainId
): Contract | null {
  const { library, account, chainId } = useActiveWeb3React();

  return useMemo(() => {
    if (!address || !ABI) return null;
    if (!queryChainId && !chainId) return null;

    if (queryChainId && chainId !== queryChainId) {
      const web3Library = getOtherNetworkLibrary(queryChainId);
      if (!web3Library) return null;
      try {
        return getContract(address, ABI, web3Library, undefined);
      } catch (error) {
        console.error('Failed to get contract', error);
        return null;
      }
    }
    if (chainId && library) {
      try {
        return getContract(address, ABI, library, withSignerIfPossible && account ? account : undefined);
      } catch (error) {
        console.error('Failed to get contract', error);
        return null;
      }
    }
    return null;
  }, [ABI, account, address, chainId, library, queryChainId, withSignerIfPossible]);
}

export function useV2MigratorContract(): Contract | null {
  return useContract(MIGRATOR_ADDRESS, MIGRATOR_ABI, true);
}

export function useTokenContract(tokenAddress?: string, withSignerIfPossible?: boolean): Contract | null {
  return useContract(tokenAddress, ERC20_ABI, withSignerIfPossible);
}

export function useENSRegistrarContract(withSignerIfPossible?: boolean): Contract | null {
  const { chainId } = useActiveWeb3React();
  let address: string | undefined;
  if (chainId) {
    switch (chainId) {
      case ChainId.MAINNET:
        address = '0x00000000000C2E074eC69A0dFb2997BA6C7d2e1e';
        break;
    }
  }
  return useContract(address, ENS_ABI, withSignerIfPossible);
}

export function useENSResolverContract(address: string | undefined, withSignerIfPossible?: boolean): Contract | null {
  return useContract(address, ENS_PUBLIC_RESOLVER_ABI, withSignerIfPossible);
}

export function useBytes32TokenContract(tokenAddress?: string, withSignerIfPossible?: boolean): Contract | null {
  return useContract(tokenAddress, ERC20_BYTES32_ABI, withSignerIfPossible);
}

export function useMulticallContract(queryChainId?: ChainId): Contract | null {
  const { chainId } = useActiveWeb3React();
  return useContract(
    queryChainId || chainId ? MULTICALL_NETWORKS[(queryChainId || chainId) as ChainId] : undefined,
    MULTICALL_ABI,
    undefined,
    queryChainId
  );
}

export function useSocksController(): Contract | null {
  const { chainId } = useActiveWeb3React();
  return useContract(
    chainId === ChainId.MAINNET ? '0x65770b5283117639760beA3F867b69b3697a91dd' : undefined,
    UNISOCKS_ABI,
    false
  );
}

export function useNFTContract(address: string | undefined): Contract | null {
  return useContract(address, ERC721_ABI, true);
}

export function useMarketContract(): Contract | null {
  return useContract(MARKET_ADDRESS, MARKET_ABI, true);
}

export function useUserContract(): Contract | null {
  return useContract(USER_ADDRESS, USER_ABI, true);
}

export function useLYNKNFTContract(): Contract | null {
  return useContract(LYNKNFT_ADDRESS, LYNKNFT_ABI, true);
}

export function useDBContract(): Contract | null {
  return useContract(DB_ADDRESS, DB_ABI, true);
}

export function useStakingContract(): Contract | null {
  return useContract(STAKING_ADDRESS, STAKING_ABI, true);
}

export function useSLYNKNFTContract(): Contract | null {
  return useContract(S_LYNK_NFT_ADDRESS, SLYNKNFT_ABI, true);
}

export function useILYNKNFTContract(): Contract | null {
  return useContract(I_LYNK_NFT_ADDRESS, ILYNKNFT_ABI, true);
}

export function useAPContract(): Contract | null {
  return useContract(AP.address, AP_ABI, true);
}

export function useSwapContract(): Contract | null {
  return useContract(SWAP_ADDRESS, SWAP_ABI, true);
}
