export const FACTION_LIST = [
  { value: 0, name: 'Spirits', color: '#C5C4D6', price: '10', title: 'nfts.spirits' },
  { value: 1, name: 'Exos', color: '#614DF6', price: '30', title: 'nfts.exos' },
  { value: 2, name: 'Martials', color: '#CF5EFF', price: '100', title: 'nfts.martials' },
  { value: 3, name: 'Voids', color: '#E6A844', price: '300', title: 'nfts.voids' },
];

export function getGrade(tokenId: number) {
  if (0 <= tokenId && tokenId < 100000) {
    return 'Ultra Rare';
  }
  if (100000 <= tokenId && tokenId < 200000) {
    return 'Common';
  }
  if (200000 <= tokenId && tokenId < 300000) {
    return 'Special';
  }
  if (300000 <= tokenId && tokenId < 400000) {
    return 'Rare';
  }
  return '';
}

export function getFaction(tokenId: number) {
  if (0 <= tokenId && tokenId < 100000) {
    return 'Finder';
  }
  const num = tokenId % 100000;
  if (0 <= num && num < 20000) {
    return 'Spirits';
  }
  if (20000 <= num && num < 40000) {
    return 'Exos';
  }
  if (40000 <= num && num < 60000) {
    return 'Martials';
  }
  if (60000 <= num && num < 80000) {
    return 'Voids';
  }
  return '';
}
