export default {
  global: {
    connectWallet: 'ウォレットを接続',
    swapLynk: '$LYNKを交換',
    connectToWallet: 'ウォレットへ接続',
    metamask: 'METAMASK',
    walletConnect: 'ウォレット接続',
    coinbaseWallet: 'COINBASEウォレット',
    walletDes: '*ウォレットを接続したユーザーは、Lynkverseの利用規約およびプライバシーポリシーに同意するものとします。',
    connecting: '接続中...',
    connectedWallet: '%s%と接続済み',
    wrongNetwork: 'ネットワークに問題があります',
    error: 'エラー',
    myWallet: 'マイウォレット',
    myAddress: 'アドレス',
    addToken: 'トークンを追加',
    crossChain: 'クロスチェーン',
    logout: 'ログアウト',
    change: '変更',
    recentTransactions: '直近のトランザクション',
    clearAll: '全てクリア',
    confirm: '確認する',
    confirmed: '確認済み',
    balance: '残高',
    confirmTransaction: 'この取引をウォレットで確認してください。',
    viewOnChain: '%s%で閲覧',
    invitationLink: '招待リンク',
    invitationCode: '招待コード',
    copy: 'コピー',
    insufficient: '残高不足',
    approve: '承認',
    tip: 'ヒント',
    drop: '落とす',
    swap: 'スワップ',
    register: '登録',
    networkError: 'ネットワークエラー',
    initializing: '初期化中...',
    errorConnecting: '接続エラー',
    errorConnectingDes: '接続エラー。 ページを更新してみてください.',
    binanceSmartChain: 'Binance スマートチェーン',
    connectTip: 'に接続してください',
    ethereumMainnet: 'イーサリアムメインネット',
    connect: 'に接続します',
    tryAgain: '再試行する',
    changeWallet: 'ウォレットの変更',
    exchangeRate: '為替レート',
    approving: '承認中',
    swapTitle: 'スワップ LYNK',
    registerTitle: '招待コードを挿入',
    registerPlh: '招待コードを入力してください',
    registerDes: 'TIP: Lynk の Discord にアクセスして、招待コードを取得することができます.',
    unValidCode: '無効な登録コード',
    registerInviterDes: 'まだ登録していません。登録してください',
    transactionsDes: 'あなたの取引はここに表示されます...',
    copied: 'コピーした',
    finish: '終了',
    minting: '鋳造',
    comingSoon: '近日公開',
  },
  navMenu: {
    myDashboard: 'ダッシュボード',
    nfts: 'NFT',
    stake: 'ステーキング',
    invitation: '招待',
    history: '履歴',
    activitiesCampaigns: 'アクティビティとキャンペーン',
    nodeNft: 'Node NFTプログラム',
    leaderNft: 'LEADER NFTプログラム',
    finders: 'ファインダーズNFTミント',
    marketPlace: 'マーケットプレイス',
    rank: 'ランク',
    more: '詳細',
    chatRoom: 'チャットルーム',
    lynkAlliance: 'リンクアライアンス',
    domainServer: 'LYNK ドメインネームサーバー',
    feed: '動的',
  },
  nfts: {
    title: 'ユーザーのNFT',
    mintKeeper: '鍛造 KEEPER',
    buyKeeper: '買う KEEPER',
    freeMint: '自由鍛造',
    owned: '所有済み',
    staked: 'ステーキング済み',
    sell: '売る',
    stake: 'ステーキング',
    mintKeeperNft: 'キーパーNFTを生成',
    nftName: 'NFTの名前',
    selectGender: '性別を選ぶ',
    male: '男性',
    female: '女性',
    selectFaction: '種類を選択',
    spirits: 'スピリッツ',
    martials: 'マーシャルズ',
    exos: 'エクソス',
    voids: 'ヴォイズ',
    selectNft: 'NFTを選択',
    common: '通常',
    special: '特別',
    rare: '希少',
    reSelect: '選択し直す',
    mint: '生成',
    proceed: '続行',
    enterName: '名前を入力',
    nameUsed: '名前占有',
    cancel: 'キャンセル',
    claim: 'クレーム',
    freeMintEnd: '販売終了日',
    days: '日々',
    hours: '時間',
    minutes: 'ミンス',
    seconds: '秒',
  },
  nftDetail: {
    back: '戻る',
    attributes: '属性',
    leveltooltip: 'NFT属性を加速してレベルアップし、より多くのステー',
    purchaseAp: 'APを購入',
    userStats: 'ユーザーの統計',
    nfts: 'NFT',
    charisma: 'カリスマ性',
    dexterity: '器用さ',
    intellect: '知性',
    vitality: '活力',
    accelerate: 'レベルアップを加速',
    details: '詳細',
    currentHolder: '現在の所有者',
    mintTime: 'Mint時間',
    mintAddress: 'Mintアドレス',
    estimatedPrice: '推定価格',
    endPrice: '最終価格',
    role: 'ロール',
    contractAddress: 'コントラクトアドレス',
    tokenID: 'トークンID',
    tokenStandard: 'トークン規格',
    chain: 'チェーン',
    background: '背景',
    effect: 'エフェクト',
    body: 'ボディ',
    clothing: '衣類',
    head: '頭部',
    weapon: '武器',
    bodyAttachment: 'アタッチメント',
    artifact: 'アーティファクト',
    purchaseAP: 'APを購入',
    balance: '残高',
    amount: '数量',
    price: '価格',
    buy: '購入',
    caUpgrade: 'CAのアップグレード',
    vaUpgrade: 'VAのアップグレード',
    inUpgrade: 'INのアップグレード',
    dxUpgrade: 'DXのアップグレード',
    max: '最大',
    unStake: '引き出す',
    claimAp: 'APを受けとる',
    apReward: 'AP報酬',
    apRewardtooltip:
      'レベル 2 以上の NFT のみが達成報酬を受け取る資格があります。 メンバーは、次のカウント サイクルを再開するために、毎日の報酬 AP を要求する必要があります。\nそうしないと、2 日以上ステーキングしても AP が 1 のままになります。',
    stakingTime: '累積ステーキング時間',
    claimApHelpDes:
      'ユーザーが次のAPサイクルを再開するためには、各日のAP報酬を受け取る必要があります。受け取りを行わなかった場合、たとえ100日以上ステーキングしたとしても、AP報酬は1のままとなります。',
    claimProfit: '収益を受け取ることができます。',
    claim: '受け取る',
    sell: '売る',
    referencePrice: '参考価格',
    onSale: '販売中',
    unListing: '出品中止中',
    unList: '出品中止',
    insufficientLynk: '不十分 Lynk',
    fullLevel: 'フルレベル',
    upLimit: 'より高い必要があります',
    oneDayMaxVA: 'VAは今日アップグレードされました',
    enterPoint: 'ポイントを入力してください',
    noReward: '報酬なし',
    mustHigherThanLevel2: 'レベル2以上である必要があります',
    mintAction: '鍛造 Finder NFT',
    mint: '鍛造',
    unstake: 'アンステーク',
    upgrade: 'アップグレード',
    loading: '読み込み中',
  },
  stake: {
    title: 'NFTステーキング',
    redeem: '引き出す',
    claim: '受け取る',
    accumulatedEarned: '獲得した$LRTの累積',
    available: '利用可能な$LRT',
    claiming: '請求する',
  },
  invitation: {
    title: '招待ボード',
    invitationDes: '友人を招待して一緒に報酬を獲得',
    inviteFriends: '友人を招待',
    rewardSummary: '報酬の概要',
    socialRewardUp: 'ソーシャルリワード',
    socialRewardDes:
      '友人をLYNKコミュニティに招待して、自分のチームとキーパーのネットワークを築きましょう。キーパーのランクが高いほど、得られるソーシャルリワードも高くなります。',
    communityReward: 'コミュニティリワード',
    communityReward1: 'コミュニティリワード',
    communityRewardDes:
      'サンクチュアリにいるメンバーとつながることで、プレイヤーは新規、または既存の同盟を築き、より高い報酬を得ることができるようになります。',
    contributionRewardUp: '貢献度報酬',
    contributionRewardDes:
      'LRTを収益として受け取るだけでなく、特定のタスクを完了することでAPが付与されます。新メンバーを招待したユーザーは、フォロワーが貯めた100CAごとに1～6APを受け取ることができます（即時加算されます）。',
    AchievementReward: '達成度報酬',
    AchievementRewardDes:
      'LRTを収益として受け取ることができるだけでなく、特定のタスクを完了することでAPが付与されます。レベル2以上のNFTをステーキングすると、毎日AP報酬を受け取ることができます。AP報酬の金額はNFTのレベルに応じて決まります。',
    StakingReward: 'ステーキング報酬',
    StakingRewardDes:
      'ユーザーは同時に複数のNFTを所有することができます。NFTがステーキングの条件を満たしている限り、ユーザーは毎日利益を得ることができます。',
    bonusRatio: 'ボーナスのパーセンテージ',
    totalLRT: '合計LRT',
    qualified: '認証済み',
    cumulateCA: 'CAを累積',
    totalAP: '合計AP',
    cycles: 'サイクル',
    stakingNFT: 'NFTステーキング',
    elite: 'エリート',
    epic: 'エピック',
    master: 'マスター',
    legendary: 'レジェンダリー',
    mythic: 'ミシック',
    divine: 'ディヴァイン',
    nourishInviter: 'ダイレクトスポンサー',
    teamGrade: '蓄積',
    levelPrivilege: 'レベルの特権',
    socialReward: 'ソーシャルリワード',
    contributionReward: '貢献度報酬',
    achievementReward: '達成度報酬',
    inactive: '非アクティブ',
    activated: 'アクティブ',
    myLevel: '私のレベル',
    generation: '代',
    inviteRule: '新しい 100CA ごとに参照、取得',
    NFTStakingRule: 'レベル 2 以上の NFT ステーキングを 1 日行うと、',
    inactivated: '不活化',
    activate: 'アクティブ化',
    AcaStastistics: 'SUMCA 統計',
    daily: '毎日',
    yesterday: '昨日',
    today: '今日',
    weekly: '毎週',
    lastWeek: '先週',
    thisWeek: '今週',
    monthly: '毎月',
    lastMonth: '先月',
    thisMonth: '今月',
  },
  history: {
    title: '履歴',
    stakingRewardRecords: 'ステーキング報酬の記録',
    mintingRecords: 'NFT Mintの記録',
    transactionsRecords: 'NFTトランザクションの記録',
    recommendedRecords: 'おすすめの記録',
    referralRewardRecords: '紹介報酬の記録',
    contributionRewardRecords: '貢献度報酬の記録',
    achievementRewardRecords: '達成度報酬の記録',
    communityRewardRecords: 'コミュニティリワードの記録',
    time: '時間',
    reward: '報酬',
    tx: 'TX',
    name: '名前',
    amount: '額',
    buyerAddress: 'バイヤーのウォレットアドレス',
    sellerAddress: '売り手のウォレットアドレス',
    address: 'ウォレットアドレス',
  },
  rank: {
    title: 'トップユーザーをソート',
    top: 'トップ',
    ranking: 'ランキング',
    levelUp: 'レベル',
    charisma: 'カリスマ性',
    nft: 'NFT',
    level: 'レベル',
    tx: 'TX',
  },
  finders: {
    title: 'ファインダー',
    findersDes1: 'ファインダーは、すべての時空座標の中心点である',
    findersDes2: 'ネクサスからやってきた。',
    mint: 'MINT',
    soldOut: '完売',
    info1:
      'ファインダーは、すべての時空座標の中心点であるネクサスからやってきました。この生命体は、既知のオムニバースが誕生した時から存在し、無数の世界の誕生を観察してきました。彼らはすべてのメタヴァースから知識とデータを収集し、精神を同期させることでそれを保存しています。他のメタヴァースを超える可能性を秘めた特別なメタヴァースが出現すると、ファインダーたちは動き出します。裂け目を通してLYNKVERSEに足を踏み入れたファインダーは、その使命を遂行するために出発しました。メタバースがその潜在能力を最大限に引き出すことのできるよう、他の住人を導く選ばれし者たちを指名するのです。',
    info2:
      'ファインダーは、その力の一部を込めた「ソウルシード」を作り、選ばれし者に授けます。「ソウルシード」と同化することで、新たな種族が誕生します。希望のマントをまとい、新しい時代のリーダーになろう！',
    totalAmount: 'ファインダーの合計数：',
    totalBalance: '利用可能なファインダーの残高合計：',
    minted: '鍛造する',
  },
  nodeNft: {
    title: 'ノードNFTプログラム第1世代',
    mintNow: '今すぐ作成',
    mintNode: 'NODE NFT 作成',
    mintNodeNft: 'NODE NFT 作成',
    nodeNftDes1: 'NODE NFT で優位性を獲得しましょう',
    nodeNftDes2: 'LYNK コミュニティに参加して、自分自身を示しましょう',
    info11: `LYNK ファミリーの皆様、Node NFT プログラムへようこそ！`,
    info12: `Node NFT Program」は、LYNKコミュニティの成長に強い意欲を示した初期サポーターに対して、当社が提供する最高のプログラムです。第1世代のプログラムは、あなたのようなチャンピオンを認識し、あなたたちを最もエキサイティングなWeb3コミュニティのリーダーとして表すユニークなKeeper NFTを提供します。`,
    info13: `このエキサイティングなプログラムでは、3つの階層（コモン、スペシャル、レア）のうちの1つで、ユニークなNode NFTを作成するチャンスがあります。各階層で受け取ることができるものは次のとおりです：`,
    infoCommon: `コモン Node NFT：1000 USDTでミントし、1,200 CA、110 AP、そしてコモン Node NFTバッジを受け取りましょう。さらに、あなたはLEGENDARYランクに直接アドバンスします。`,
    infoSpecial: `スペシャル Node NFT：3000 USDTでMintし、4,000 CA、450 AP、そしてスペシャル Node NFTバッジを受け取ります。また、直ちにLEGENDARYランクに昇格します。`,
    infoRare: `レア Node NFT：5000 USDTでMintし、7,000 CA、1,200 AP、そしてレアなノードNFTバッジを受け取ります。さらに、あなたは直ちに伝説のランクに昇格します。`,
    info21: `レジェンドランクメンバーの方は、成功した紹介ごとに追加の報酬を受け取ることができます！お友達がNode NFTを購入するたびに、彼らが購入したティアに比例した報酬があなたに支払われます。`,
    info22: `あなたの紹介によりレア Node NFTを購入された場合、累積されたCAトークン5,000、APトークン280、LRTトークン560が報酬として支払われます。`,
    info23: `あなたの紹介者がスペシャル Node NFTを購入すると、あなたは累積されたCA 3,000、AP 160、およびLRTトークン320の追加報酬を受け取ります！`,
    info24: `あなたの紹介先がコモン Node NFTを購入した場合、あなたは累積されたCA 1,000、AP 48、およびLRT 96トークンを追加で報酬として受け取ることができます！`,
    info25: `Node NFTは、私たちの活気あるWeb3コミュニティー内でネットワークを構築するためのお手伝いをするように設計されています。LYNKコミュニティ内でネットワークを拡大するほど、より多くの特典を手に入れ、収益を増やすことができます！`,
    info26: `友達を招待して、Node NFTクルーの一員になり、特典を楽しんでください。今すぐ参加して、今日からもっと稼ぎましょう！`,
    minted: '鍛造する',
    soldOut: '完売',
  },
  leaderNft: {
    title: 'リーダーNFTプログラム第1世代',
    mintNow: '今すぐ作成',
    mintLeader: 'LEADER NFT 作成',
    mintLeaderNft: 'LEADER NFT 作成',
    leaderNftDes1: 'LEADER NFT で優位性を獲得しましょう',
    leaderNftDes2: 'LYNK コミュニティに参加して、自分自身を示しましょう',
    info11: `LYNK ファミリーの皆様、Leader NFT プログラムへようこそ！`,
    info12: `Leader NFT Program」は、LYNKコミュニティの成長に強い意欲を示した初期サポーターに対して、当社が提供する最高のプログラムです。第1世代のプログラムは、あなたのようなチャンピオンを認識し、あなたたちを最もエキサイティングなWeb3コミュニティのリーダーとして表すユニークなKeeper NFTを提供します。`,
    info13: `このエキサイティングなプログラムでは、3つの階層（コモン、スペシャル、レア）のうちの1つで、ユニークなLeader NFTを作成するチャンスがあります。各階層で受け取ることができるものは次のとおりです：`,
    infoCommon: `コモン Leader NFT：500 USDTでミントし、500 CA、さらに、あなたはEPICランクに直接アドバンスします。`,
    infoSpecial: `スペシャル Leader NFT：1000 USDTでMintし、1,000 CA、さらに、あなたはMASTERランクに直接アドバンスします。`,
    infoRare: `レア Leader NFT：3000 USDTでMintし、3,000 CA、さらに、あなたはLEGENDARYランクに直接アドバンスします。`,
    info21: `レジェンドランクメンバーの方は、成功した紹介ごとに追加の報酬を受け取ることができます！お友達がLeader NFTを購入するたびに、彼らが購入したティアに比例した報酬があなたに支払われます。`,
    info22: `あなたの紹介によりレア Leader NFTを購入された場合、累積されたCAトークン3,000、APトークン120、LRTトークン240が報酬として支払われます。`,
    info23: `あなたの紹介者がスペシャル Leader NFTを購入すると、あなたは累積されたCA 1,000、AP 40、およびLRTトークン80の追加報酬を受け取ります！`,
    info24: `あなたの紹介先がコモン Leader NFTを購入した場合、あなたは累積されたCA 500、AP 20、およびLRT 40トークンを追加で報酬として受け取ることができます！`,
    info25: `Leader NFTは、私たちの活気あるWeb3コミュニティー内でネットワークを構築するためのお手伝いをするように設計されています。LYNKコミュニティ内でネットワークを拡大するほど、より多くの特典を手に入れ、収益を増やすことができます！`,
    info26: `友達を招待して、Leader NFTクルーの一員になり、特典を楽しんでください。今すぐ参加して、今日からもっと稼ぎましょう！`,
    minted: '鍛造する',
    soldOut: '完売',
  },
  marketPlace: {
    title: 'マーケットプレイス',
    totalVolume: '総量',
    floorPrice: '最低価格',
    higherPrice: '最高価格',
    owner: '所有者',
    tradingFee: '取引手数料',
    faction: '種類',
    type0: 'ファインダー',
    type1: 'スピリッツ',
    type2: 'エクソス',
    type3: 'マーシャルズ',
    type4: 'ヴォイズ',
    gender: '性別',
    boy: '男性',
    girl: '女性',
    level: 'レベル',
    level0: 'レベル2-3',
    level1: 'レベル4-6',
    level2: 'レベル7-9',
    level3: 'レベル10-12',
    level4: 'レベル13',
    priceDesc: '価格:降順',
    priceAsc: '価格:昇順',
    priceHint: {
      empty: '価格帯を入力してください',
      numberError: '最大価格は最小価格を下回ることができません。',
    },
    priceUsdt: '価格（USDT）',
    to: 'まで',
    all: '全て',
    buy: '購入',
    rare: '希少',
    price: '価格',
    balance: '残高',
  },
};
