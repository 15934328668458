export default {
  global: {
    connectWallet: 'Portefeuille Connect',
    swapLynk: 'SWAP $LYNK',
    connectToWallet: 'Se connecter au portefeuille',
    metamask: 'METAMASK',
    walletConnect: 'CONNEXION AU PORTEFEUILLE',
    coinbaseWallet: 'PORTEFEUILLE COINBASE',
    walletDes: `*Vous acceptez les conditions d'utilisation et la politique de confidentialité de Lynkverse en connectant un portefeuille.`,
    connecting: 'Connexion...',
    connectedWallet: 'Connecté avec %s%',
    wrongNetwork: 'Erreur de réseau',
    error: 'Erreur',
    myWallet: 'MON PORTEFEUILLE',
    myAddress: 'Mon Adresse',
    addToken: 'ajouter un jeton',
    crossChain: 'traverser la chaîne',
    logout: 'SE DÉCONNECTER',
    change: 'CHANGER',
    recentTransactions: 'Transactions récentes',
    clearAll: 'Effacer tout',
    confirm: 'CONFIRMER',
    confirmed: 'Confirmé',
    balance: 'Solde',
    confirmTransaction: 'Veuillez confirmer cette transaction dans votre portefeuille',
    viewOnChain: 'Afficher sur %s%',
    invitationLink: `Lien d'invitation`,
    invitationCode: `Code d'invitation`,
    copy: 'COPIER',
    insufficient: 'Solde insuffisant',
    approve: 'Approuver',
    tip: 'Conseil',
    drop: 'Goutte',
    swap: 'Échanger',
    register: 'Enregistrer',
    networkError: 'Erreur réseau',
    initializing: 'Initialisation...',
    errorConnecting: 'Erreur de connexion',
    errorConnectingDes: `Erreur de connexion. Essayez d'actualiser la page.`,
    binanceSmartChain: 'Chaîne intelligente Binance',
    connectTip: 'Veuillez vous connecter au',
    ethereumMainnet: 'Réseau principal Ethereum',
    connect: 'Se connecter à',
    tryAgain: 'Essayer à nouveau',
    changeWallet: 'Changer de portefeuille',
    exchangeRate: 'Taux de change',
    approving: 'Approuvant',
    swapTitle: 'ÉCHANGER LYNK',
    registerTitle: `INSÉREZ VOTRE CODE D'INVITATION`,
    registerPlh: 'Veuillez entrer votre code ici',
    registerDes: `TIP: Vous pouvez vous rendre sur le Discord de Lynk pour récupérer votre code d'invitation.`,
    unValidCode: `Code d'enregistrement invalide`,
    registerInviterDes: `Vous n'êtes pas encore inscrit, veuillez vous inscrire`,
    transactionsDes: 'Vos transactions apparaîtront ici...',
    copied: 'COPIÉ',
    finish: 'Finir',
    minting: 'Frappe',
    comingSoon: 'À venir',
  },
  navMenu: {
    myDashboard: 'MON TABLEAU DE BORD',
    nfts: 'NFTs',
    stake: 'STAKE',
    invitation: 'INVITATION',
    history: 'HISTOIRE',
    activitiesCampaigns: 'ACTIVITÉS ET CAMPAGNES',
    nodeNft: 'Programme Node NFT',
    leaderNft: 'Programme LEADER NFT',
    finders: 'Mint Finders NFT',
    marketPlace: 'MARCHÉ',
    rank: 'CLASSEMENT',
    more: 'PLUS',
    chatRoom: 'TCHAT',
    lynkAlliance: 'ALLIANCE LYNK',
    domainServer: 'Serveur de noms de domaine LYNK',
    feed: 'Dynamique',
  },
  nfts: {
    title: `NFT de l'utilisateur`,
    mintKeeper: 'Forger KEEPER',
    buyKeeper: 'ACHETER KEEPER',
    freeMint: 'Forgeage gratuit',
    owned: 'possédé',
    staked: 'staked',
    sell: 'Vendre',
    stake: 'Stake',
    mintKeeperNft: 'MINT KEEPER NFT',
    nftName: 'Nom de NFT',
    selectGender: 'Choisir le sexe',
    male: 'Masculin',
    female: 'Féminin',
    selectFaction: 'Choisir la faction',
    spirits: 'Esprits',
    martials: 'Martials',
    exos: 'Exos',
    voids: 'Voids',
    selectNft: 'CHOISIR LE NFT',
    common: 'COMMUN',
    special: 'SPÉCIAL',
    rare: 'RARE',
    reSelect: 'Re-sélectionner',
    mint: 'MINT',
    proceed: 'PROCÉDER',
    enterName: 'Entrez le nom',
    nameUsed: 'Nom occupé',
    cancel: 'Annuler',
    claim: 'Réclamation',
    freeMintEnd: 'FIN DES TEMPS',
    days: 'DAYS',
    hours: 'HRS',
    minutes: 'MINS',
    seconds: 'SECS',
  },
  nftDetail: {
    back: 'RETOUR',
    attributes: 'ATTRIBUTS',
    leveltooltip: 'Accélérez vos attributs NFT pour passer au niveau supérieur et gagner plus de récompenses de mise !',
    purchaseAp: 'ACHETER DES PA',
    userStats: 'STATS UTILISATEUR',
    nfts: 'NFTs',
    charisma: 'Charisme',
    dexterity: 'Dextérité',
    intellect: 'Intelligence',
    vitality: 'Vitalité',
    accelerate: 'ACCÉLÉRER',
    details: 'DÉTAILS',
    currentHolder: 'Détenteur Actuel',
    mintTime: 'Heure du Mint',
    mintAddress: 'Adresse du Mint',
    estimatedPrice: 'Prix Estimé',
    endPrice: 'Prix Final',
    role: 'Rôle',
    contractAddress: 'Adresse du Contrat',
    tokenID: 'ID du token',
    tokenStandard: 'Norme du token',
    chain: 'Chaîne',
    background: 'Contexte',
    effect: 'Effet',
    body: 'Corps',
    clothing: 'Vêtements',
    head: 'Tête',
    weapon: 'Arme',
    bodyAttachment: 'Accessoire pour le corps',
    artifact: 'Artéfact',
    purchaseAP: 'Acheter des PA',
    balance: 'Équilibre',
    amount: 'Montant',
    price: 'Prix',
    buy: 'Achat',
    caUpgrade: 'AMÉLIORATION CA',
    vaUpgrade: 'AMÉLIORATION VA',
    inUpgrade: 'AMÉLIORATION IN',
    dxUpgrade: 'AMÉLIORATION DX ',
    max: 'Max',
    unStake: 'UNSTAKE',
    claimAp: 'RÉCLAMER PA',
    apReward: 'Récompense en PAS',
    apRewardtooltip: `Seuls les NFT de niveau 2 et supérieur ont le droit de recevoir une récompense de réussite. Les membres sont tenus de réclamer l'AP récompensé pour chaque jour pour reprendre le prochain cycle de comptage.\nSi vous ne le faites pas, l'AP restera à 1, malgré le jalonnement pendant 2 jours ou plus.`,
    stakingTime: 'Temps de Staking Accumulé',
    claimApHelpDes: `Les membres doivent réclamer les PA récompensés pour chaque jour afin de reprendre le cycle de PA suivant. Si cela n'est pas fait, la récompense en PA restera à 1. Même si vous avez staké pendant 100 jours ou plus.', '     Members are required to claim the rewarded AP for each day to resume the next AP cycle.Failure to do so will result to the AP reward to remain at 1. Despite staking if for 100 days or more.`,
    claimProfit: 'Vous pouvez réclamer des bénéfices',
    claim: 'RÉCLAMER',
    sell: 'VENDRE',
    referencePrice: 'Prix de référence',
    onSale: 'EN VENTE',
    unListing: 'ANNULER',
    unList: 'DÉSINSCRIRE',
    insufficientLynk: 'Lynk insuffisant',
    fullLevel: 'Niveau complet',
    upLimit: 'Doit être supérieur à',
    oneDayMaxVA: `VA a été mis à jour aujourd'hui`,
    enterPoint: 'Entrer le point',
    noReward: 'Aucune récompense disponible',
    mustHigherThanLevel2: 'Vous devez être supérieur au niveau 2',
    mintAction: 'Monnaie Finder NFT',
    mint: 'menthe',
    unstake: 'Décoller',
    upgrade: 'Améliorer',
    loading: 'Chargement',
  },
  stake: {
    title: 'ÉTABLIR MON NFT',
    redeem: 'Rembourser',
    claim: 'Réclamer',
    accumulatedEarned: '$LRT Accumulé Gagné ',
    available: '$LRT Disponible ',
    claiming: 'Affirmant',
  },
  invitation: {
    title: `TABLEAU D'INVITATION`,
    invitationDes: 'Invitez vos amis à gagner des récompenses ensemble',
    inviteFriends: 'INVITER DES AMIS',
    rewardSummary: 'RÉSUMÉ DES RÉCOMPENSES',
    socialRewardUp: 'RÉCOMPENSE SOCIALE',
    socialRewardDes:
      'Constituez votre équipe et votre réseau de Keepers en invitant vos amis à rejoindre la communauté LYNK. Plus le rang de votre Keepers est élevé, plus les récompenses sociales sont importantes.',
    communityReward: 'RÉCOMPENSE DE LA COMMUNAUTÉ',
    communityReward1: 'Récompense De La Communauté',
    communityRewardDes: `En se connectant avec les membres du Sanctuaire, les joueurs peuvent créer de nouvelles alliances ou en créer d'autres afin d'obtenir de meilleures récompenses.`,
    contributionRewardUp: 'RÉCOMPENSE DE LA CONTRIBUTION',
    contributionRewardDes: `En plus de toucher des LRT en guise de gains, des PA seront également attribués pour l'accomplissement de certaines tâches. Un utilisateur qui recrute un nouveau membre recevra de 1 à 6 PA pour chaque tranche de 100 CA accumulés par ses suiveurs (crédités immédiatement).`,
    AchievementReward: `RÉCOMPENSE DE L'ACCOMPLISSEMENT`,
    AchievementRewardDes: `En plus de toucher des LRT en guise de gains, des PA seront également attribués pour l'accomplissement de certaines tâches. Mettez en place un NFT de niveau 2 ou supérieur pour recevoir des récompenses en PA tous les jours ! Le montant des récompenses en PA sera déterminé par le niveau de votre NFT.`,
    StakingReward: `RÉCOMPENSE DE L'EFFORT`,
    StakingRewardDes: `Un utilisateur peut détenir plusieurs NFTs en même temps. Les utilisateurs ont droit à des bénéfices quotidiens tant que leur(s) NFTs respecte(nt) les conditions de staking.`,
    bonusRatio: 'Ratio de primes',
    totalLRT: 'Total LRT',
    qualified: 'Qualifié',
    cumulateCA: 'Cumul CA',
    totalAP: 'Total PA',
    cycles: 'Cycles',
    stakingNFT: 'Staking NFT',
    elite: 'Elite',
    epic: 'Epic',
    master: 'Maître',
    legendary: 'Légendaire',
    mythic: 'Mythique',
    divine: 'Divin',
    nourishInviter: 'Commanditaire direct',
    teamGrade: 'Accumulé',
    levelPrivilege: 'PRIVILÈGE DE NIVEAU',
    socialReward: 'Récompense Sociale',
    contributionReward: 'Récompense de Contribution',
    achievementReward: `Récompense d'Accomplissement`,
    inactive: 'INACTIF',
    activated: 'ACTIF',
    myLevel: 'MON NIVEAU',
    generation: 'Génération',
    inviteRule: 'Référez-vous chaque nouveau 100CA, obtenez',
    NFTStakingRule: 'Staking NFT de niveau 2 ou supérieur pendant 1 jour, et obtenez',
    inactivated: 'INACTIVÉE',
    activate: 'ACTIVER',
    AcaStastistics: 'STATISTIQUES SUMCA',
    daily: 'Quotidien',
    yesterday: 'Hier',
    today: `Aujourd'hui`,
    weekly: 'Hebdomadaire',
    lastWeek: 'La semaine dernière',
    thisWeek: 'Cette semaine',
    monthly: 'Mensuel',
    lastMonth: 'Le mois dernier',
    thisMonth: 'Ce mois-ci',
  },
  history: {
    title: 'HISTOIRE',
    stakingRewardRecords: 'Registres des Récompenses de Staking',
    mintingRecords: 'Registres des Mints du NFT',
    transactionsRecords: 'Registres des Transactions NFT',
    recommendedRecords: 'Registres Recommandés',
    referralRewardRecords: 'Registres de Récompenses de Recommandations',
    contributionRewardRecords: 'Registres de Récompenses de Contribution',
    achievementRewardRecords: `Registres de Récompense d'accomplissement`,
    communityRewardRecords: 'Registres de Récompenses de communauté',
    time: 'Temps',
    reward: 'Récompense',
    tx: 'TX',
    name: 'Nom',
    amount: 'Montante',
    buyerAddress: `Adresse de l'acheteur`,
    sellerAddress: 'Adresse du vendeur',
    address: 'Adresse',
  },
  rank: {
    title: 'TOP UTILISATEURS PAR',
    top: 'TOP',
    ranking: 'CLASSEMENT',
    levelUp: 'NIVEAU',
    charisma: 'CHARISME',
    nft: 'NFT',
    level: 'Niveau',
    tx: 'TX',
  },
  finders: {
    title: 'FINDERS',
    findersDes1: 'Les Finders viennent du nexus,',
    findersDes2: 'un point central de toutes les coordonnées spatio-temporelles.',
    mint: 'MINT',
    soldOut: 'ÉPUISÉ',
    info1: `TLes Finders viennent du Nexus, point central de toutes les coordonnées spatio-temporelles. Ces êtres existent depuis le début de l'omnivers connu et ont observé la naissance d'innombrables mondes. Ils rassemblent toutes les informations et les données de toutes les Métavers et les conservent en synchronisant leurs esprits dans la cohésion. De temps à autre, un Métavers extraordinaire ayant le potentiel de progresser au-delà des limites des autres Métavers apparaît, incitant les Finders à agir. En entrant dans le LYNKVERSE par la brèche, les Finders entreprennent de mener à bien leur mission : sélectionner quelques élus pour guider les autres habitants et s'assurer que le Métavers développe tout son potentiel.`,
    info2: `EChaque Finder crée des âmes-souches renfermant une partie de ses pouvoirs et les transmet à ceux qu'il a choisis. En s'assimilant à l'âme, une nouvelle race naît. Venez porter le manteau de l'espoir et soyez les leaders de la nouvelle ère.`,
    totalAmount: 'TOTAL AMOUNT OF FINDERS',
    totalBalance: 'TOTAL BALANCE OF FINDERS AVAILABLE',
    minted: 'FRAPPE',
  },
  nodeNft: {
    title: 'Programme NODE NFT Génération 1 ',
    mintNow: 'Minter maintenant',
    mintNode: 'MINTER NODE NFT',
    mintNodeNft: 'MINTER NODE NFT',
    nodeNftDes1: 'Gagnez un avantage avec un Node NFT',
    nodeNftDes2: 'Rejoignez les rangs et faites votre marque dans la communauté LYNK',
    info11: `Bienvenue dans le programme Node NFT, famille LYNK !`,
    info12: `Le programme Node NFT est notre premier programme exclusivement destiné aux premiers soutiens qui ont fait preuve d'une forte initiative dans la croissance de la communauté LYNK. La génération I du programme sert à reconnaître les champions comme vous, avec des NFT uniques qui représenteront votre identité et votre statut en tant que leaders de la communauté Web3 la plus excitante.`,
    info13: `Ce programme passionnant vous offre la possibilité de frapper un Node NFT unique à l'un des trois niveaux : Commun, Spécial ou Rare. Voici ce que vous obtiendrez à chaque niveau :`,
    infoCommon: `Nœud commun NFT : Monnaie pour 1000 USDT et recevez 1,200 CA, 110 AP et un badge NFT nœud commun. Vous passerez également directement au rang de LÉGENDAIRE.`,
    infoSpecial: `Nœud spécial NFT : Monnaie pour 3000 USDT et recevez 4,000 CA, 450 AP et un badge Nœud spécial NFT. Vous passerez également directement au rang de LÉGENDAIRE.`,
    infoRare: `Nœud rare NFT : Monnaie pour 5000 USDT et recevez 7,000 CA, 1200 AP et un badge Nœud rare NFT. Vous passerez également directement au rang de LÉGENDAIRE.`,
    info21: `En tant que membre du rang Légende, obtenez des récompenses supplémentaires pour chaque parrainage réussi ! Lorsque vos amis achètent un Node NFT, vous recevez des récompenses proportionnelles au niveau qu'ils achètent.`,
    info22: `Lorsque votre filleul achète un nœud rare NFT, vous recevez 5,000 jetons CA, 280 jetons AP et 560 jetons TLR supplémentaires en récompense!`,
    info23: `Lorsque votre filleul achète un nœud spécial NFT, vous recevez en récompense 3,000 jetons CA, 160 jetons AP et 320 jetons SLR supplémentaires!`,
    info24: `Lorsque votre filleul achète un nœud commun NFT, vous recevez en récompense 1,000 jetons CA, 48 jetons AP et 96 jetons SLR supplémentaires!`,
    info25: `Les NFTs sont conçus pour vous aider à avancer dans votre quête de construction de votre réseau au sein de notre vibrante communauté Web3. Plus vous développez votre réseau au sein de la communauté LYNK, plus vous débloquerez d'avantages et plus vous gagnerez !`,
    info26: `Alors, commencez à inviter vos amis et profitez des avantages qui viennent avec le fait de faire partie de l'équipe de Node NFT. Rejoignez-nous maintenant et commencez à gagner plus dès aujourd'hui!`,
    minted: 'FRAPPE',
    soldOut: 'ÉPUISÉ',
  },
  leaderNft: {
    title: 'Programme LEADER NFT Génération 1 ',
    mintNow: 'Minter maintenant',
    mintLeader: 'MINTER LEADER NFT',
    mintLeaderNft: 'MINTER LEADER NFT',
    leaderNftDes1: 'Gagnez un avantage avec un Leader NFT',
    leaderNftDes2: 'Rejoignez les rangs et faites votre marque dans la communauté LYNK',
    info11: `Bienvenue dans le programme Leader NFT, famille LYNK !`,
    info12: `Le programme Leader NFT est notre premier programme exclusivement destiné aux premiers soutiens qui ont fait preuve d'une forte initiative dans la croissance de la communauté LYNK. La génération I du programme sert à reconnaître les champions comme vous, avec des NFT uniques qui représenteront votre identité et votre statut en tant que leaders de la communauté Web3 la plus excitante.`,
    info13: `Ce programme passionnant vous offre la possibilité de frapper un Leader NFT unique à l'un des trois niveaux : Commun, Spécial ou Rare. Voici ce que vous obtiendrez à chaque niveau :`,
    infoCommon: `Chef commun NFT : Monnaie pour 500 USDT et recevez 500 CA, Vous passerez également directement au rang de ÉPIQUE.`,
    infoSpecial: `Chef spécial NFT : Monnaie pour 1000 USDT et recevez 1,000 CA, Vous passerez également directement au rang de MAÎTRE.`,
    infoRare: `Chef rare NFT : Monnaie pour 3000 USDT et recevez 3,000 CA, Vous passerez également directement au rang de LÉGENDAIRE.`,
    info21: `En tant que membre du rang Légende, obtenez des récompenses supplémentaires pour chaque parrainage réussi ! Lorsque vos amis achètent un Leader NFT, vous recevez des récompenses proportionnelles au niveau qu'ils achètent.`,
    info22: `Lorsque votre filleul achète un Chef rare NFT, vous recevez 3,000 jetons CA, 120 jetons AP et 240 jetons TLR supplémentaires en récompense!`,
    info23: `Lorsque votre filleul achète un Chef spécial NFT, vous recevez en récompense 1,000 jetons CA, 40 jetons AP et 80 jetons SLR supplémentaires!`,
    info24: `Lorsque votre filleul achète un Chef commun NFT, vous recevez en récompense 500 jetons CA, 20 jetons AP et 40 jetons SLR supplémentaires!`,
    info25: `Les NFTs sont conçus pour vous aider à avancer dans votre quête de construction de votre réseau au sein de notre vibrante communauté Web3. Plus vous développez votre réseau au sein de la communauté LYNK, plus vous débloquerez d'avantages et plus vous gagnerez !`,
    info26: `Alors, commencez à inviter vos amis et profitez des avantages qui viennent avec le fait de faire partie de l'équipe de Leader NFT. Rejoignez-nous maintenant et commencez à gagner plus dès aujourd'hui!`,
    minted: 'FRAPPE',
    soldOut: 'ÉPUISÉ',
  },
  marketPlace: {
    title: 'MARCHÉ',
    totalVolume: 'Volume Total',
    floorPrice: 'Prix Plancher ',
    higherPrice: 'Prix Plafond',
    owner: 'Propriétaire',
    tradingFee: 'Frais de Négociation',
    faction: 'FACTION',
    type0: 'Chercheur',
    type1: 'Esprits',
    type2: 'Exos',
    type3: 'Martiaals',
    type4: 'Voids',
    gender: 'GENRE',
    boy: 'Homme',
    girl: 'Féminin',
    level: 'NIVEAU',
    level0: 'Niveau 2-3',
    level1: 'Niveau 4-6',
    level2: 'Niveau 7-9',
    level3: 'Niveau 10-12',
    level4: 'Niveau 13',
    priceDesc: 'Prix',
    priceAsc: 'Prix',
    priceHint: {
      empty: 'Veuillez saisir une fourchette de prix',
      numberError: 'Le prix maximum ne peut pas être inférieur au prix minimum.',
    },
    priceUsdt: 'PRIX USDT',
    to: 'A',
    all: 'TOUT',
    buy: 'Acheter',
    rare: 'RARE',
    price: 'Prix',
    balance: 'Solde',
  },
};
