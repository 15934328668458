import React, { useState, useCallback, useEffect, useMemo } from 'react';
import { styled, Typography, Stack, InputBase } from '@mui/material';
import { useI18n } from 'react-simple-i18n';
import { useNavigate, createSearchParams } from 'react-router-dom';
import { useValidAddress } from 'hooks/useUser';
import { useActiveWeb3React } from 'hooks';
import { MarketNftCard } from 'components/Card';
import useAsyncMemo from 'hooks/useAsyncMemo';
import { getAccountNFT, NFT } from 'utils/fetch/graph';
import { useBlockNumber } from 'hooks/useStoreState';
import _ from 'lodash';
import BaseSelect from 'components/BaseSelect';
import { getOrderList, Order } from 'utils/fetch/graph';
import { tryParseAmount } from 'utils/parseAmount';
import { USDT } from 'constants/index';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import IconButton from '@mui/material/IconButton';
import { TokenAmount } from 'constants/token';
import { escapeRegExp } from 'utils';
import BuyNftModal from 'components/BuyNftModal';
import { useAppDispatch } from 'hooks/redux';
import { showWalletModal, showRegisterModal } from 'store/features/componentSlice';
import { useAppSelector } from 'hooks/redux';

const inputRegex = RegExp(`^\\d*(?:\\\\[.])?\\d*$`);

const MarketPlaceContentBox = styled('div')(({ theme }) => ({
  gridArea: 'marketPlaceB',
  display: 'grid',
  gridTemplateRows: `${theme.spacing(14)} 1fr ${theme.spacing(5.5)} ${theme.spacing(3)}`,
  gridTemplateColumns: `1fr`,
  gridTemplateAreas: `
    'marketPlaceFilter'
    'marketPlaceList'
    'marketPagination'
    'nftsNumbernone'
  `,
  overflow: 'hidden',
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(0, 3),
    width: `calc(100% - ${theme.spacing(6)})`,
    gridTemplateRows: `${theme.spacing(23)} 1fr ${theme.spacing(5.5)} ${theme.spacing(2)}`,
    gridTemplateColumns: `1fr`,
    gridTemplateAreas: `
      'marketPlaceFilter'
      'marketPlaceList'
      'marketPagination'
      'nftsNumbernone'
    `,
    overflow: 'unset',
  },
}));

const MarketPlaceFilter = styled('div')(({ theme }) => ({
  gridArea: 'marketPlaceFilter',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'flex-end',
  padding: theme.spacing(0, 0, 4, 0),
  [theme.breakpoints.down('sm')]: {
    justifyContent: 'center',
    alignItems: 'flex-end',
    padding: theme.spacing(0, 0, 2, 0),
  },
}));

const MarketPlaceList = styled('div')(({ theme }) => ({
  gridArea: 'marketPlaceList',
  display: 'flex',
  alignContent: 'flex-start',
  gap: theme.spacing(4),
  flexWrap: 'wrap',
  [theme.breakpoints.down('sm')]: {
    gap: theme.spacing(1),
  },
  [theme.breakpoints.up('sm')]: {
    overflow: 'overlay',
  },
}));

const MarketPagination = styled('div')(({ theme }) => ({
  gridArea: 'marketPagination',
  display: 'flex',
  alignContent: 'center',
  justifyContent: 'flex-end',
  [theme.breakpoints.down('sm')]: {
    gap: theme.spacing(1),
  },
}));

const InputPrice = styled(InputBase)(({ theme }) => ({
  width: 57,
  background: '#fff',
  borderRadius: '7px',
  height: 22,
  padding: theme.spacing(0, 1),
}));

const MarketPlaceContent = () => {
  const { t } = useI18n();
  const { inviter } = useAppSelector((state) => state.application);
  const { account } = useActiveWeb3React();
  const blockNumber = useBlockNumber();
  const dispatch = useAppDispatch();
  const registered = useValidAddress(account ?? undefined);
  const navigate = useNavigate();

  const getMyNFT = useCallback(async () => {
    if (!account) return [];
    return await getAccountNFT(account ?? '');
  }, [account]);

  const NFTList: NFT[] = useAsyncMemo(getMyNFT, [], [blockNumber]);

  const [factionsSelectIndexs, setFactionsSelectIndexs] = useState([0, 1, 2, 3, 4]);
  const [sexesIndexs, setSexesIndexs] = useState([0, 1]);
  const [levelIndexs, setLevelIndexs] = useState([0, 1, 2, 3, 4]);
  const [filterIndex, setFilterIndex] = useState(0);
  const [page, setPage] = useState(1);
  const [order, setOrder] = useState('');
  const [priceRange, setPriceRange] = useState([0, 0]);
  const [minPrice, setMinPrice] = useState(0);
  const [maxPrice, setMaxPrice] = useState(0);
  const [open, setOpen] = useState(false);
  const [selectedData, setSelectedData] = useState<
    undefined | { tokenId: string; price: TokenAmount; level: string; name: string }
  >(undefined);

  const factionsList = [
    { value: 0, label: t('marketPlace.type0') },
    { value: 1, label: t('marketPlace.type1') },
    { value: 2, label: t('marketPlace.type2') },
    { value: 3, label: t('marketPlace.type3') },
    { value: 4, label: t('marketPlace.type4') },
  ];
  const sexesList = [
    { value: 1, label: t('marketPlace.boy') },
    { value: 2, label: t('marketPlace.girl') },
  ];
  const levelList = [
    { value: [1, 2, 3], label: t('marketPlace.level0') },
    { value: [4, 5, 6], label: t('marketPlace.level1') },
    { value: [7, 8, 9], label: t('marketPlace.level2') },
    { value: [10, 11, 12], label: t('marketPlace.level3') },
    { value: [13], label: t('marketPlace.level4') },
  ];
  const filterItemList = [
    { value: [0], content: t('marketPlace.all'), type: '' },
    { value: [1, 2, 3], content: t('marketPlace.level0'), type: 'level23' },
    { value: [4, 5, 6], content: t('marketPlace.level1'), type: 'level46' },
    { value: [7, 8, 9], content: t('marketPlace.level2'), type: 'level79' },
    { value: [10, 11, 12], content: t('marketPlace.level3'), type: 'level12' },
    { value: [13], content: t('marketPlace.level4'), type: 'level13' },
    { value: [111], content: t('marketPlace.priceDesc'), type: 'order' }, //'desc'
    { value: [222], content: t('marketPlace.priceAsc'), type: 'order' }, //'asc'
  ];

  const sexes = useMemo(() => {
    const newSexes = sexesIndexs.map((item) => item + 1);
    return newSexes;
  }, [sexesIndexs]);

  const level = useMemo(() => {
    const newLevel = levelIndexs.map((item) => levelList[item].value);
    return _.flattenDeep(newLevel);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [levelIndexs]);

  useEffect(() => {
    const item = filterItemList[filterIndex];
    switch (item.type) {
      case '': {
        setLevelIndexs([0, 1, 2, 3, 4]);
        setOrder('');
        break;
      }
      case 'level23': {
        setLevelIndexs([0]);
        break;
      }
      case 'level46': {
        setLevelIndexs([1]);
        break;
      }
      case 'level79': {
        setLevelIndexs([2]);
        break;
      }
      case 'level12': {
        setLevelIndexs([3]);
        break;
      }
      case 'level13': {
        setLevelIndexs([4]);
        break;
      }
      case 'order': {
        if (item.value[0] == 111) {
          setOrder('desc');
        } else {
          setOrder('asc');
        }
        break;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterIndex]);

  useEffect(() => {
    setPage(1);
  }, [factionsSelectIndexs, level, order, priceRange, sexes]);

  const priceRangeAmount = useMemo(() => {
    return [
      priceRange[0] ? tryParseAmount(priceRange[0].toString(), USDT) : undefined,
      priceRange[1] ? tryParseAmount(priceRange[1].toString(), USDT) : undefined,
    ];
  }, [priceRange]);

  const getOrder = useCallback(async () => {
    return await getOrderList([...factionsSelectIndexs], [...sexes], priceRangeAmount, [...level], order, 12, page);
  }, [factionsSelectIndexs, level, order, page, priceRangeAmount, sexes]);

  const orderList: Order[] = useAsyncMemo(getOrder, [], [blockNumber]);

  const getNextOrder = useCallback(async () => {
    return await getOrderList([...factionsSelectIndexs], [...sexes], priceRangeAmount, [...level], order, 12, page + 1);
  }, [factionsSelectIndexs, level, order, page, priceRangeAmount, sexes]);

  const nextOrderList: Order[] = useAsyncMemo(getNextOrder, [], [blockNumber]);

  const pageInfo = useMemo(() => {
    return {
      hasPrev: page > 1,
      hasNext: orderList?.length === 12 && nextOrderList.length,
    };
  }, [orderList?.length, page, nextOrderList]);

  const enforcer = (nextUserInput: string) => {
    const fixed = nextUserInput.replace(/,/g, '.');
    if (fixed === '' || inputRegex.test(escapeRegExp(fixed))) {
      return fixed;
    }
    return null;
  };

  const takeOrderCallback = useCallback((tokenId: string, price: TokenAmount, name: string, level: string) => {
    setSelectedData({ tokenId, price, level, name });
    setOpen(true);
  }, []);

  return (
    <MarketPlaceContentBox>
      <BuyNftModal
        handleClose={() => setOpen(false)}
        open={open}
        name={selectedData?.name ?? ''}
        level={selectedData?.level ?? ''}
        tokenId={selectedData?.tokenId ?? ''}
        price={selectedData?.price}
      />
      <MarketPlaceFilter>
        <Stack
          sx={(theme) => ({
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'flex-end',
            gap: theme.spacing(2),
            [theme.breakpoints.down('sm')]: { flexWrap: 'wrap', justifyContent: 'space-between', gapRow: 0 },
          })}
        >
          <BaseSelect
            selectItemIndexs={factionsSelectIndexs}
            label="label"
            MenuList={factionsList}
            selectWidth={160}
            mobileSelectWidth={'30%'}
            onChange={(e) => {
              setFactionsSelectIndexs(e.target.value);
            }}
          />
          <BaseSelect
            selectItemIndexs={sexesIndexs}
            label="label"
            MenuList={sexesList}
            selectWidth={160}
            mobileSelectWidth={'28%'}
            onChange={(e) => {
              setSexesIndexs(e.target.value);
            }}
          />
          <BaseSelect
            selectItemIndexs={levelIndexs}
            label="label"
            MenuList={levelList}
            selectWidth={160}
            mobileSelectWidth={'32%'}
            onChange={(e) => {
              setLevelIndexs(e.target.value);
            }}
          />
          <Stack
            sx={(theme) => ({
              background: '#F0F0F0',
              width: '244px',
              height: '30px',
              borderRadius: '7px',
              [theme.breakpoints.down('sm')]: { zoom: 0.8 },
            })}
            direction="row"
            alignItems="center"
            justifyContent="center"
            spacing={2}
          >
            <Typography>{t('marketPlace.priceUsdt')}</Typography>
            <InputPrice
              value={minPrice}
              onBlur={() => {
                if (minPrice >= 0 && maxPrice > minPrice) {
                  setPriceRange([minPrice, maxPrice]);
                }
              }}
              onChange={(event) => {
                const formatted = enforcer(event.target.value);
                if (formatted) {
                  setMinPrice(Number(formatted));
                }
              }}
            />
            <InputPrice
              value={maxPrice}
              onBlur={() => {
                if (minPrice >= 0 && maxPrice > minPrice) {
                  setPriceRange([minPrice, maxPrice]);
                }
              }}
              onChange={(event) => {
                const formatted = enforcer(event.target.value);
                if (formatted) {
                  setMaxPrice(Number(formatted));
                }
              }}
            />
          </Stack>
          <Stack
            sx={(theme) => ({ display: 'none', [theme.breakpoints.down('sm')]: { display: 'flex', width: '35%' } })}
            alignItems="flex-end"
          >
            <BaseSelect
              selectItemIndex={filterIndex}
              label="content"
              MenuList={filterItemList}
              selectWidth={160}
              mobileSelectWidth={'100%'}
              onChange={(e) => {
                setFilterIndex(e.target.value);
              }}
            />
          </Stack>
        </Stack>
        <Stack
          alignItems="flex-end"
          sx={(theme) => ({ display: 'flex', [theme.breakpoints.down('sm')]: { display: 'none' } })}
        >
          <BaseSelect
            selectItemIndex={filterIndex}
            label="content"
            MenuList={filterItemList}
            selectWidth={160}
            mobileSelectWidth={'30%'}
            onChange={(e) => {
              setFilterIndex(e.target.value);
            }}
          />
        </Stack>
      </MarketPlaceFilter>
      <MarketPlaceList>
        {orderList.map((nft) => {
          return (
            <MarketNftCard
              goDetail={() =>
                navigate(
                  {
                    pathname: `/market-place/${nft.id}`,
                    search:
                      inviter &&
                      createSearchParams({
                        referrer: inviter,
                      }).toString(),
                  },
                  {
                    state: {
                      name: nft?.name,
                      ownNftNum: NFTList?.length || 0,
                      stakeNftNum: _.filter(NFTList, (o: NFT) => o.isStaking)?.length || 0,
                    },
                  }
                )
              }
              nftPrice={
                nft?.priceInAcceptToken
                  ? new TokenAmount(USDT, nft?.priceInAcceptToken).toFixed(2, { groupSeparator: ',' })
                  : 0
              }
              rightText={t('marketPlace.buy')}
              rightButton={(event) => {
                event.stopPropagation();
                if (!account) {
                  dispatch(showWalletModal());
                  return;
                }
                if (!registered) {
                  dispatch(showRegisterModal());
                  return;
                }
                takeOrderCallback(nft.id, new TokenAmount(USDT, nft.priceInAcceptToken), nft.name, nft.level);
              }}
              item={nft}
              key={nft.id}
            />
          );
        })}
      </MarketPlaceList>
      <MarketPagination>
        <Stack direction="row" justifyContent="flex-end">
          <IconButton
            onClick={_.debounce(() => pageInfo.hasPrev && setPage(page - 1), 300)}
            disableRipple
            disabled={!pageInfo.hasPrev}
          >
            <ArrowBackIcon fontSize="small" />
          </IconButton>
          <IconButton disableRipple>{page}</IconButton>
          <IconButton
            disabled={!pageInfo.hasNext}
            disableRipple
            onClick={_.debounce(() => pageInfo.hasNext && setPage(page + 1), 300)}
          >
            <ArrowForwardIcon fontSize="small" />
          </IconButton>
        </Stack>
      </MarketPagination>
    </MarketPlaceContentBox>
  );
};

export default MarketPlaceContent;
