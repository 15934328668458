import React from 'react';
import { useI18n } from 'react-simple-i18n';
import { useAppSelector } from 'hooks/redux';
import { Stack, Typography } from '@mui/material';
import { ReactComponent as Confirmed } from 'assets/svg/confirmed.svg';
import { ReactComponent as Error } from 'assets/svg/error.svg';
import Snackbar from '@mui/material/Snackbar';
import { useActiveWeb3React } from 'hooks';
import CONFIG from 'config';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { getEtherscanLink } from 'utils';
import _ from 'lodash';

export default function TransactionPopup(): JSX.Element {
  const { t } = useI18n();
  const { chainId } = useActiveWeb3React();
  const [open, setOpen] = React.useState(false);
  const [content, setContent] = React.useState<{
    hash?: string;
    success?: boolean;
    summary?: string;
  }>({ hash: '', success: false, summary: '' });
  const { popupList } = useAppSelector((state) => state.application);

  React.useEffect(() => {
    const list = popupList.filter((item) => item.show);
    list.forEach((element) => {
      if ('txn' in element.content) {
        const {
          txn: { hash, success, summary },
        } = element.content;
        setContent({ hash, success, summary });
        setOpen(true);
      }
    });
  }, [popupList]);

  return (
    <Snackbar
      autoHideDuration={6000}
      onClose={(event: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
          return;
        }
        setOpen(false);
      }}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={open}
      sx={(theme) => ({
        width: theme.spacing(28),
        height: theme.spacing(13),
        [theme.breakpoints.down('sm')]: {
          left: 'unset',
        },
        '& .MuiPaper-root': {
          height: '100%',
          width: '100%',
          bgcolor: '#E9E9E9',
          padding: 0,
          '& .MuiSnackbarContent-message': {
            height: '80%',
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            paddingLeft: theme.spacing(2.5),
          },
        },
      })}
      message={
        <Stack direction="row" alignItems="center" spacing={2}>
          <IconButton onClick={() => setOpen(false)} sx={{ position: 'absolute', top: 0, right: 0 }} disableRipple>
            <CloseIcon />
          </IconButton>
          {content.success ? <Confirmed /> : <Error />}
          <Stack alignItems="center">
            <Typography color="#000">
              {content?.summary
                ? _.split(content.summary, '$%')
                    .map((item) => (item ? t(item) : ''))
                    .join('')
                : content?.hash
                ? 'Hash: ' + content.hash.slice(0, 8) + '...' + content.hash.slice(58, 65)
                : '-'}
            </Typography>
            {chainId && content.hash && (
              <Typography
                onClick={(event) => {
                  const href = getEtherscanLink(chainId ? chainId : 1, content.hash || '', 'transaction');
                  event.preventDefault();
                  window.open(href);
                }}
                sx={{ cursor: 'pointer' }}
                color="#454545"
                fontSize={12}
              >
                {t('global.viewOnChain', CONFIG.chain)}
              </Typography>
            )}
          </Stack>
        </Stack>
      }
    />
  );
}
