import { useMarketContract, useDBContract } from 'hooks/useContract';
import { useCallback, useEffect, useState } from 'react';
import { useActiveWeb3React } from 'hooks';
import { calculateGasMargin } from 'utils';
import { TransactionResponse } from '@ethersproject/providers';
import { useTransactionAdder } from 'hooks/useTransaction';
import { useSingleCallResult } from 'hooks/useRequest';
import { TokenAmount } from 'constants/token';
import { getFloorPrice, getHighestPrice, getMarketOverview } from 'utils/fetch/graph';
import { USDT } from 'constants/index';

export function useMarket() {
  const contract = useMarketContract();
  const { account } = useActiveWeb3React();
  const addTransaction = useTransactionAdder();

  const take = useCallback(
    async (tokenId: string) => {
      if (!account) throw new Error('none account');
      if (!contract) throw new Error('none contract');
      const args = [tokenId];
      const method = 'takeNFT';
      return contract.estimateGas[method](...args, { from: account }).then((estimatedGasLimit) => {
        return contract[method](...args, {
          gasLimit: calculateGasMargin(estimatedGasLimit),
          // gasLimit: '3500000',
          from: account,
        }).then((response: TransactionResponse) => {
          addTransaction(response, {
            summary: '$%nftDetail.buy$%',
            claim: { recipient: `${account}_selling_${tokenId}` },
          });
          return response.hash;
        });
      });
    },
    [account, addTransaction, contract]
  );

  const make = useCallback(
    async (tokenId: string, amount: TokenAmount) => {
      if (!account) throw new Error('none account');
      if (!contract) throw new Error('none contract');
      const args = [tokenId, amount.token.address, amount.raw.toString()];
      const method = 'listNFT';
      return contract.estimateGas[method](...args, { from: account }).then((estimatedGasLimit) => {
        return contract[method](...args, {
          gasLimit: calculateGasMargin(estimatedGasLimit),
          // gasLimit: '3500000',
          from: account,
        }).then((response: TransactionResponse) => {
          addTransaction(response, {
            summary: '$%nfts.sell$%',
            claim: { recipient: `${account}_selling_${tokenId}` },
          });
          return response.hash;
        });
      });
    },
    [account, addTransaction, contract]
  );

  const cancel = useCallback(
    async (tokenId: string) => {
      if (!account) throw new Error('none account');
      if (!contract) throw new Error('none contract');
      const args = [tokenId];
      const method = 'cancelList';
      return contract.estimateGas[method](...args, { from: account }).then((estimatedGasLimit) => {
        return contract[method](...args, {
          gasLimit: calculateGasMargin(estimatedGasLimit),
          // gasLimit: '3500000',
          from: account,
        }).then((response: TransactionResponse) => {
          addTransaction(response, {
            summary: '$%global.drop$%',
            claim: { recipient: `${account}_cancel_${tokenId}` },
          });
          return response.hash;
        });
      });
    },
    [account, addTransaction, contract]
  );

  return { take, make, cancel };
}

export function useOrder(tokenId: string) {
  const contract = useMarketContract();
  const index = useSingleCallResult(contract, 'listIndexByTokenId', [tokenId]).result;
  const order = useSingleCallResult(contract, 'listNFTs', [index ? index?.[0].toString() : undefined]);
  return { seller: order?.result?.seller };
}

export function useTradingFee() {
  const dbContract = useDBContract();
  const tradingFee = useSingleCallResult(dbContract, 'tradingFee')?.result?.[0];
  return tradingFee;
}

export function useHoldersNum() {
  const contract = useMarketContract();
  const holdersNum = useSingleCallResult(contract, 'onSellNum')?.result?.[0];
  return holdersNum?.toString();
}

export function useMarketOverview() {
  const [loading, setLoading] = useState<boolean>(false);
  const [result, setResult] = useState<{
    holdersNum: number;
    highestPrice: TokenAmount;
    lowestPrice: TokenAmount;
    tradeAmount: TokenAmount;
  }>();

  useEffect(() => {
    (async () => {
      setLoading(true);
      try {
        const res = await getMarketOverview();
        setLoading(false);
        setResult({
          holdersNum: res.holdersNum,
          highestPrice: new TokenAmount(USDT, res.highestPrice),
          lowestPrice: new TokenAmount(USDT, res.lowestPrice),
          tradeAmount: new TokenAmount(USDT, res.tradeAmount),
        });
      } catch (error) {
        setResult(undefined);
        setLoading(false);
        console.error('useMarketOverview', error);
      }
    })();
  }, []);

  return {
    loading,
    result,
  };
}

export function useFloorPrice() {
  const [floorPrice, setFloorPrice] = useState<string | undefined>();

  useEffect(() => {
    (async () => {
      try {
        const res = await getFloorPrice();
        setFloorPrice(res);
      } catch (error) {
        setFloorPrice(undefined);
        console.error('useMarketOverview', error);
      }
    })();
  }, []);

  return floorPrice;
}

export function useHighestPrice() {
  const [floorPrice, setFloorPrice] = useState<string | undefined>();

  useEffect(() => {
    (async () => {
      try {
        const res = await getHighestPrice();
        setFloorPrice(res);
      } catch (error) {
        setFloorPrice(undefined);
        console.error('useMarketOverview', error);
      }
    })();
  }, []);

  return floorPrice;
}
