import React, { useState } from 'react';
import { styled, Typography, Button, Stack } from '@mui/material';
import { useI18n } from 'react-simple-i18n';
import { useValidAddress } from 'hooks/useUser';
import { useActiveWeb3React } from 'hooks';
import { useUserHasSubmitted } from 'hooks/useTransaction';
import { useAppDispatch } from 'hooks/redux';
import { showRegisterModal } from 'store/features/componentSlice';
import KeeperMintModal from 'components/KeeperMintModal';
import { showWalletModal } from 'store/features/componentSlice';
import { useLeaderMinted, useNftMintEnable, useNodeMinted } from 'hooks/useNFT';

const LeaderNftContentBox = styled('div')(({ theme }) => ({
  gridArea: 'nodeNftB',
  display: 'grid',
  paddingTop: theme.spacing(5),
  gridTemplateRows: `0px auto ${theme.spacing(8)} 1fr`,
  gridTemplateColumns: `1fr ${theme.spacing(6)} ${theme.spacing(65)}`,
  [theme.breakpoints.down('sm')]: {
    paddingTop: theme.spacing(0),
    gridTemplateRows: `${theme.spacing(4)} auto ${theme.spacing(10)} 1fr`,
    gridTemplateColumns: `1fr 0px 0px`,
  },
  gridTemplateAreas: `
    'nodeNftTitle nodeNftTitle nodeNftTitle'
    'nodeNftDes nodeNftDes nodeNftDes'
    'nodeNftMint nodeNftBg nodeNftBg'
    'nodeNftInfo nodeNftBg nodeNftBg'
  `,
  [theme.breakpoints.up('sm')]: {
    overflow: 'hidden',
  },
}));

const LeaderNftTitle = styled('div')(({ theme }) => ({
  gridArea: 'nodeNftTitle',
  [theme.breakpoints.up('sm')]: {
    display: 'none',
  },
}));

const LeaderNftDes = styled('div')({
  gridArea: 'nodeNftDes',
});

const LeaderNftMint = styled('div')({
  gridArea: 'nodeNftMint',
});

const LeaderNftInfo = styled('div')(({ theme }) => ({
  gridArea: 'nodeNftInfo',
  [theme.breakpoints.up('sm')]: {
    overflow: 'overlay',
  },
}));

const TypographyDes = styled(Typography)(({ theme }) => ({
  fontSize: 32,
  fontWeight: 700,
  [theme.breakpoints.down('sm')]: {
    fontSize: 20,
  },
}));

const TypographyInfo = styled(Typography)(({ theme }) => ({
  lineHeight: theme.spacing(2.5),
  whiteSpace: 'pre-wrap',
  '& ul': {
    paddingInlineStart: theme.spacing(2),
    marginBlockStart: 0,
    marginBlockEnd: 0,
  },
  [theme.breakpoints.down('sm')]: {
    lineHeight: theme.spacing(3),
  },
}));

const MintButton = styled(Button)(({ theme, disabled }) => ({
  fontSize: 13,
  fontWeight: 700,
  width: disabled ? 200 : 160,
  background: disabled ? 'linear-gradient(90deg, #C4C4C4 -4.11%, #666666 113.36%)' : '',
  height: theme.spacing(4.1),
  marginTop: theme.spacing(2),
}));

const LeaderNftContent = () => {
  const { t } = useI18n();
  const { account } = useActiveWeb3React();
  const { submitted: registering } = useUserHasSubmitted(`${account}_register` ?? undefined);
  const registered = useValidAddress(account ?? undefined);
  const dispatch = useAppDispatch();
  const [openLeaderNftMintModal, setOpenLeaderNftMintModal] = useState(false);
  const leaderMinted = useLeaderMinted(account || '');
  const nodeMinted = useNodeMinted(account || '');
  const nftMintEnable = useNftMintEnable();

  return (
    <LeaderNftContentBox>
      {openLeaderNftMintModal ? (
        <KeeperMintModal
          startId={0}
          isActivitingMint={false}
          endId={0}
          mintPrice={0}
          open={openLeaderNftMintModal}
          isLeaderNftMint={true}
          handleClose={() => setOpenLeaderNftMintModal(false)}
        />
      ) : null}
      <LeaderNftTitle>
        <TypographyDes color={'#000000'}>{t('leaderNft.title')}</TypographyDes>
      </LeaderNftTitle>
      <LeaderNftDes>
        <TypographyDes color={'#121425'}>{t('leaderNft.leaderNftDes1')}</TypographyDes>
        <TypographyDes color={'#72BCDE'}>{t('leaderNft.leaderNftDes2')}</TypographyDes>
      </LeaderNftDes>
      <LeaderNftMint>
        <MintButton
          onClick={() => {
            if (!account) {
              dispatch(showWalletModal());
              return;
            }
            if (!registered) {
              dispatch(showRegisterModal());
            } else {
              leaderMinted?.toString() === '0' && setOpenLeaderNftMintModal(true);
            }
          }}
          variant="contained"
          disabled={
            registering ||
            (leaderMinted && leaderMinted?.toString() !== '0') ||
            (nodeMinted && nodeMinted?.toString() !== '0') ||
            nftMintEnable === false
          }
        >
          {nftMintEnable === false
            ? t('leaderNft.soldOut')
            : (leaderMinted && leaderMinted?.toString() !== '0') || (nodeMinted && nodeMinted?.toString() !== '0')
            ? t('leaderNft.minted')
            : t('leaderNft.mintNow')}
        </MintButton>
      </LeaderNftMint>
      <LeaderNftInfo>
        <Stack spacing={1.8}>
          <TypographyInfo variant="body2">{t('leaderNft.info11')}</TypographyInfo>
          <TypographyInfo variant="body2">{t('leaderNft.info12')}</TypographyInfo>
          <TypographyInfo variant="body2">
            {t('leaderNft.info13')}
            <ul>
              <li>{t('leaderNft.infoCommon')}</li>
              <li>{t('leaderNft.infoSpecial')}</li>
              <li>{t('leaderNft.infoRare')}</li>
            </ul>
          </TypographyInfo>
          <TypographyInfo variant="body2">{t('leaderNft.info21')}</TypographyInfo>
          <TypographyInfo variant="body2">
            <ul>
              <li>{t('leaderNft.info22')}</li>
              <li>{t('leaderNft.info23')}</li>
              <li>{t('leaderNft.info24')}</li>
            </ul>
          </TypographyInfo>
          <TypographyInfo variant="body2">{t('leaderNft.info25')}</TypographyInfo>
          <TypographyInfo variant="body2">{t('leaderNft.info26')}</TypographyInfo>
        </Stack>
      </LeaderNftInfo>
      {/* <LeaderNftTotal>
        <Stack spacing={0}>
          <TypographyTotal variant="body1">
            {t('finders.totalAmount')}
            {totalNum ? totalNum.toString() : '--'}
          </TypographyTotal>
          <TypographyTotal variant="body1">
            {t('finders.totalBalance')}
            {totalNum ? remainNum.toString() : '--'}
          </TypographyTotal>
        </Stack>
      </LeaderNftTotal>
      <LeaderNftTime>
        <Stack direction={'row'} spacing={1}>
          {[days, hours, minutes, seconds].map((item, index) => {
            return index === 0 && item === 0 ? null : <TimeBox key={index}>{item}</TimeBox>;
          })}
        </Stack>
      </LeaderNftTime> */}
    </LeaderNftContentBox>
  );
};

export default LeaderNftContent;
