import React from 'react';
import { useI18n } from 'react-simple-i18n';
import { useAppSelector, useAppDispatch } from 'hooks/redux';
import { closeTransactionModal } from 'store/features/componentSlice';
import BaseModal from 'components/BaseModal';
import { Stack, Typography } from '@mui/material';
import { ReactComponent as Pending } from 'assets/svg/pending.svg';
import { ReactComponent as Confirmed } from 'assets/svg/confirmed.svg';
import { ReactComponent as Error } from 'assets/svg/error.svg';

export default function TransactionModal(): JSX.Element {
  const { t } = useI18n();
  const dispatch = useAppDispatch();
  const { transactionModalPending, transactionModalConfirm, transactionModalError, transactionModalErrorText } =
    useAppSelector((state) => state.component);

  return (
    <BaseModal
      open={transactionModalPending || transactionModalConfirm || transactionModalError}
      contentWidth={434}
      title={''}
      headerHeight={25}
      handleClose={() => dispatch(closeTransactionModal())}
    >
      <Stack
        sx={(theme) => ({
          height: transactionModalError ? 'auto' : theme.spacing(18),
          padding: transactionModalError ? theme.spacing(2) : theme.spacing(0),
          justifyContent: 'space-between',
          gap: theme.spacing(2),
          [theme.breakpoints.down('sm')]: {
            height: transactionModalError ? 'auto' : theme.spacing(22.5),
            justifyContent: 'center',
            gap: theme.spacing(4),
          },
        })}
        alignItems="center"
      >
        {transactionModalPending ? (
          <Pending />
        ) : transactionModalError ? (
          <Error />
        ) : transactionModalConfirm ? (
          <Confirmed />
        ) : null}
        <Typography
          sx={(theme) => ({
            minHeight: theme.spacing(9),
            wordBreak: 'break-all',
            width: transactionModalError ? 'calc(100% - 20px)' : 'auto',
            textAlign: 'center',
            [theme.breakpoints.down('sm')]: {
              lineHeight: 'unset',
              width: theme.spacing(20),
            },
          })}
        >
          {transactionModalErrorText
            ? transactionModalErrorText
            : transactionModalPending
            ? t('global.confirmTransaction')
            : transactionModalConfirm
            ? t('global.confirmed')
            : ''}
        </Typography>
      </Stack>
    </BaseModal>
  );
}
