import { AbstractConnector } from '@web3-react/abstract-connector';
import { Token } from './token';
import { injected, walletconnect, walletlink } from '../connectors';
import JSBI from 'jsbi';
import { ChainId } from './chain';
import CONFIG from '../config';

// used to ensure the user doesn't send so much ETH so they end up with <.01
export const MIN_ETH: JSBI = JSBI.exponentiate(JSBI.BigInt(10), JSBI.BigInt(16)); // .01 ETH

export const BAST_TOKEN: { [chainId in ChainId]: Token } = {
  [ChainId.MAINNET]: new Token(ChainId.MAINNET, '0x7a250d5630B4cF539739dF2C5dAcb4c659F2488D', 18, '', ''),
};

export const ZERO_ADDRESS = '0x0000000000000000000000000000000000000000';

export interface WalletInfo {
  connector?: AbstractConnector;
  name: string;
  iconName: string;
  description: string;
  href: string | null;
  color: string;
  primary?: true;
  mobile?: true;
  mobileOnly?: true;
}

export const SUPPORTED_WALLETS: { [key: string]: WalletInfo } = {
  INJECTED: {
    connector: injected,
    name: 'Injected',
    iconName: 'arrow-right.svg',
    description: 'Injected web3 provider.',
    href: null,
    color: '#010101',
    primary: true,
  },
  METAMASK: {
    connector: injected,
    name: 'MetaMask',
    iconName: 'metaMask.svg',
    description: 'Easy-to-use browser extension.',
    href: null,
    color: '#E8831D',
  },
  WALLET_CONNECT: {
    connector: walletconnect,
    name: 'WalletConnect',
    iconName: 'walletConnect.svg',
    description: 'Connect to Trust Wallet, Rainbow Wallet and more...',
    href: null,
    color: '#4196FC',
    mobile: true,
  },
  WALLET_LINK: {
    connector: walletlink,
    name: 'Coinbase Wallet',
    iconName: 'coinbaseWallet.svg',
    description: 'Use Coinbase Wallet app on mobile device',
    href: null,
    color: '#315CF5',
  },
};

export const NetworkContextName = 'NETWORK';

// default allowed slippage, in bips
export const INITIAL_ALLOWED_SLIPPAGE = 50;
// 20 minutes, denominated in seconds
export const DEFAULT_DEADLINE_FROM_NOW = 60 * 20;

// SDN OFAC addresses
export const BLOCKED_ADDRESSES: string[] = [
  '0x7F367cC41522cE07553e823bf3be79A889DEbe1B',
  '0xd882cFc20F52f2599D84b8e8D58C7FB62cfE344b',
  '0x901bb9583b24D97e995513C6778dc6888AB6870e',
  '0xA7e5d5A720f06526557c513402f2e6B5fA20b008',
  '0x8576aCC5C05D6Ce88f4e49bf65BdF0C62F91353C',
];

export const MAX_INT = '0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff';

export const USDT = new Token(ChainId.MAINNET, CONFIG.address.usdt, 6, 'USDT', 'USDT');

export const LRT = new Token(ChainId.MAINNET, CONFIG.address.lrt, 18, 'LRT', 'LRT');

export const LYNK = new Token(ChainId.MAINNET, CONFIG.address.lynk, 18, 'LYNK', 'LYNK');

export const AP = new Token(ChainId.MAINNET, CONFIG.address.ap, 18, 'AP', 'AP');

export const ROOT = '0x000000000000000000000000000000000000dEaD';

export const MARKET_ADDRESS = CONFIG.address.market;

export const USER_ADDRESS = CONFIG.address.user;

export const LYNKNFT_ADDRESS = CONFIG.address.lynkNFT;

export const DB_ADDRESS = CONFIG.address.db;

export const STAKING_ADDRESS = CONFIG.address.staking;

export const S_LYNK_NFT_ADDRESS = CONFIG.address.sLynkNFT;

export const I_LYNK_NFT_ADDRESS = CONFIG.address.iLynkNFT;

export const SWAP_ADDRESS = CONFIG.address.swap;

export const BASE_IMAGE_URL = CONFIG.baseImageUrl;

export enum TOKEN {
  USDT = 'USDT',
  LRT = 'LRT',
  AP = 'AP',
}

export const SUPPORTED_TOKEN: { [token in TOKEN]: Token } = {
  [TOKEN.USDT]: USDT,
  [TOKEN.LRT]: LRT,
  [TOKEN.AP]: AP,
};

export const SUPPORTED_TOKEN_LIST: Token[] = [USDT];

export enum PageModel {
  DEFAULT = 'detail',
  ORDER = 'order',
  SALE = 'sale',
  STAKING = 'staking',
}

export enum Faction {
  Spirits,
  Exos,
  Martials,
  Voids,
}

export const FACTION: { [faction in Faction]: string } = {
  [Faction.Spirits]: 'Spirits',
  [Faction.Exos]: 'Exos',
  [Faction.Martials]: 'Martials',
  [Faction.Voids]: 'Voids',
};
