import React, { useState } from 'react';
import { styled, Typography, Button, Stack, Box } from '@mui/material';
import { useI18n } from 'react-simple-i18n';
import useIntervalFrame from 'hooks/useIntervalFrame';
import { useFledgling, useIsWhitelistInFledgling } from 'hooks/useFledgling';
import moment from 'moment';
import config from 'config';
import { useUser, useValidAddress } from 'hooks/useUser';
import { useActiveWeb3React } from 'hooks';
import { useUserHasSubmitted } from 'hooks/useTransaction';
import { useAppDispatch } from 'hooks/redux';
import { showRegisterModal } from 'store/features/componentSlice';
import MintFinderModal from 'components/MintFinderModal';
import { showWalletModal } from 'store/features/componentSlice';

const FinderContentBox = styled('div')(({ theme }) => ({
  gridArea: 'finderB',
  display: 'grid',
  paddingTop: theme.spacing(5),
  gridTemplateRows: `0px auto ${theme.spacing(12)} 1fr ${theme.spacing(13)} ${theme.spacing(14)}`,
  gridTemplateColumns: `1fr ${theme.spacing(75)}`,
  [theme.breakpoints.down('sm')]: {
    paddingTop: theme.spacing(0),
    gridTemplateRows: `${theme.spacing(4)} auto ${theme.spacing(10)} 1fr ${theme.spacing(10)} ${theme.spacing(14)}`,
    gridTemplateColumns: `1fr 0px`,
  },
  gridTemplateAreas: `
    'findersTitle findersBg'
    'findersDes findersBg'
    'findersMint findersBg'
    'findersInfo findersBg'
    'findersTotal findersBg'
    'findersTime findersBg'
  `,
  // overflow: 'hidden',
}));

const FindersTitle = styled('div')(({ theme }) => ({
  gridArea: 'findersTitle',
  [theme.breakpoints.up('sm')]: {
    display: 'none',
  },
}));

const FindersDes = styled('div')({
  gridArea: 'findersDes',
});

const FindersMint = styled('div')({
  gridArea: 'findersMint',
});

const FindersInfo = styled('div')({
  gridArea: 'findersInfo',
  // overflowY: 'auto',
});

const FindersTotal = styled('div')({
  gridArea: 'findersTotal',
});

const FindersTime = styled('div')({
  gridArea: 'findersTime',
});

const TypographyDes = styled(Typography)(({ theme }) => ({
  fontSize: 32,
  fontWeight: 700,
  [theme.breakpoints.down('sm')]: {
    fontSize: 20,
  },
}));

const TypographyInfo = styled(Typography)(({ theme }) => ({
  lineHeight: theme.spacing(4),
  [theme.breakpoints.down('sm')]: {
    lineHeight: theme.spacing(3),
  },
}));

const TypographyTotal = styled(Typography)(({ theme }) => ({
  lineHeight: theme.spacing(6.5),
  [theme.breakpoints.down('sm')]: {
    lineHeight: theme.spacing(5),
  },
}));

const MintButton = styled(Button)(({ theme, disabled }) => ({
  fontSize: 13,
  fontWeight: 700,
  width: disabled ? 200 : 150,
  background: disabled ? 'linear-gradient(90deg, #C4C4C4 -4.11%, #666666 113.36%)' : '',
  height: theme.spacing(4.1),
  marginTop: theme.spacing(2),
}));

const TimeBox = styled(Box)({
  width: 60,
  height: 60,
  backgroundColor: '#72bcde99',
  borderRadius: 5,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  fontSize: 30,
  fontWeight: 600,
});

const FinderContent = () => {
  const { t } = useI18n();
  const { account } = useActiveWeb3React();
  const { mintStartTime, mintEndTime } = config;
  const [mintTimeEnd, setMintTimeEnd] = useState(moment().isAfter(moment(mintEndTime)));
  const [startMint, setStartMint] = useState(moment().isBetween(moment(mintStartTime), moment(mintEndTime)));
  const {
    timeInfo: { days, hours, minutes, seconds },
  } = useIntervalFrame({
    delay: 1000,
    startTime: mintStartTime,
    startTimeOut: () => setStartMint(true),
    endTime: mintEndTime,
    endTimeOut: () => setMintTimeEnd(true),
  });
  const { remainNum, totalNum } = useFledgling();
  const { earlyBirdMinted } = useUser();
  const { loading } = useIsWhitelistInFledgling(account ?? undefined);
  const { submitted: registering } = useUserHasSubmitted(`${account}_register` ?? undefined);
  const registered = useValidAddress(account ?? undefined);
  const dispatch = useAppDispatch();
  const [mintModalOpen, setMintModalOpen] = useState(false);

  return (
    <FinderContentBox>
      <MintFinderModal open={mintModalOpen} handleClose={() => setMintModalOpen(false)} />
      <FindersTitle>
        <TypographyDes color={'#000000'}>{t('finders.title')}</TypographyDes>
      </FindersTitle>
      <FindersDes>
        <TypographyDes color={'#121425'}>{t('finders.findersDes1')}</TypographyDes>
        <TypographyDes color={'#72BCDE'}>{t('finders.findersDes2')}</TypographyDes>
      </FindersDes>
      <FindersMint>
        <MintButton
          onClick={() => {
            if (!account) {
              dispatch(showWalletModal());
              return;
            }
            if (!registered) {
              dispatch(showRegisterModal());
            } else {
              setMintModalOpen(true);
            }
          }}
          variant="contained"
          disabled={earlyBirdMinted || loading || registering || mintTimeEnd}
        >
          {earlyBirdMinted
            ? t('finders.minted')
            : startMint
            ? t('finders.mint')
            : (mintTimeEnd || remainNum?.toString() === '0') && totalNum
            ? `${t('finders.mint')}(${t('finders.soldOut')})`
            : t('finders.mint')}
        </MintButton>
      </FindersMint>
      <FindersInfo>
        <Stack spacing={6}>
          <TypographyInfo variant="body2">{t('finders.info1')}</TypographyInfo>
          <TypographyInfo variant="body2">{t('finders.info2')}</TypographyInfo>
        </Stack>
      </FindersInfo>
      <FindersTotal>
        <Stack spacing={0}>
          <TypographyTotal variant="body1">
            {t('finders.totalAmount')}
            {totalNum ? totalNum.toString() : '--'}
          </TypographyTotal>
          <TypographyTotal variant="body1">
            {t('finders.totalBalance')}
            {totalNum ? remainNum.toString() : '--'}
          </TypographyTotal>
        </Stack>
      </FindersTotal>
      <FindersTime>
        <Stack direction={'row'} spacing={1}>
          {[days, hours, minutes, seconds].map((item, index) => {
            return index === 0 && item === 0 ? null : <TimeBox key={index}>{item}</TimeBox>;
          })}
        </Stack>
      </FindersTime>
    </FinderContentBox>
  );
};

export default FinderContent;
