import React, { useState } from 'react';
import { styled, Box, Typography, Button, Tooltip, IconButton, Stack, useTheme } from '@mui/material';
import { useAppDispatch } from 'hooks/redux';
import { showWalletModal } from 'store/features/componentSlice';
import { UnsupportedChainIdError, useWeb3React } from '@web3-react/core';
import { shortenAddress } from 'utils';
import MyWallet from 'components/MyWallet';
import BaseModal from 'components/BaseModal';
import MobileMenu from 'components/MobileMenu';
import useBreakpoint from 'hooks/useBreakpoint';
import { ReactComponent as DropdownBar } from 'assets/img/header/dropdownBar.svg';
import { ReactComponent as Logo } from 'assets/img/header/lynkLogo.svg';
import WalletModal from 'components/WalletModal';
import Fade from '@mui/material/Fade';
import { useI18n } from 'react-simple-i18n';
import SwapLinkModal from 'components/SwapLinkModal';

const Bar = styled(Box)<{ marketplace: string }>(({ theme, marketplace }) => ({
  display: 'flex',
  alignItems: 'flex-end',
  justifyContent: 'space-between',
  height: theme.spacing(10.5),
  position: 'absolute',
  padding: marketplace ? theme.spacing(0, 3.5) : 'unset',
  width: marketplace ? `calc(100% - ${theme.spacing(7)})` : 'calc(100%)',
  [theme.breakpoints.down('sm')]: {
    height: theme.spacing(9),
    padding: theme.spacing(0, 3),
    width: `calc(100% - ${theme.spacing(6)})`,
    background: marketplace ? 'transparent' : '#fff',
    alignItems: 'center',
    position: 'fixed',
    zIndex: 999,
  },
  top: 0,
  left: 0,
}));

const BarLeft = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(3),
  [theme.breakpoints.down('sm')]: {
    display: 'none',
  },
}));

const LogoBar = styled(IconButton)(({ theme }) => ({
  [theme.breakpoints.up('sm')]: {
    display: 'none',
  },
}));

const BarRight = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(3),
  [theme.breakpoints.down('sm')]: {
    display: 'none',
  },
}));

const MenuBar = styled(IconButton)(({ theme }) => ({
  [theme.breakpoints.up('sm')]: {
    display: 'none',
  },
  padding: 0,
}));

const ConnectButton = styled(Button)(({ theme }) => ({
  height: theme.spacing(3.5),
  padding: theme.spacing(0, 2),
}));

const MenuConnectButton = styled(Button)(({ theme }) => ({
  height: theme.spacing(3.5),
  padding: theme.spacing(0, 2),
}));

const AppBar = ({
  title,
  children,
  ismarketplace = false,
}: {
  title: string;
  children?: React.ReactNode;
  ismarketplace?: boolean;
}): JSX.Element => {
  const dispatch = useAppDispatch();
  const { account, error } = useWeb3React();
  const [myWalletModalOpen, setMyWalletModalOpen] = useState(false);
  const [openMobileMenu, setOpenMobileMenu] = useState(false);
  const isMobile = useBreakpoint();
  const theme = useTheme();
  const { t } = useI18n();
  const [openSwapModal, setOpenSwapModal] = useState(false);

  const showModal = () => {
    if (account) {
      if (isMobile) {
        setMyWalletModalOpen(true);
      }
    } else {
      dispatch(showWalletModal());
    }
  };

  const closeMenu = () => {
    setMyWalletModalOpen(false);
    setOpenMobileMenu(false);
  };

  return (
    <Bar marketplace={ismarketplace ? 'ismarketplace' : ''}>
      <SwapLinkModal open={openSwapModal} handleClose={() => setOpenSwapModal(false)} />
      <BarLeft>
        <Typography sx={{ fontSize: 32, fontWeight: 700, color: ismarketplace ? '#fff' : 'unset' }}>{title}</Typography>
        {children && children}
      </BarLeft>
      <LogoBar onClick={() => window.open('https://lynk.im/')} disableRipple>
        <Logo />
      </LogoBar>
      <BarRight>
        <ConnectButton
          sx={{ background: '#fff', '&:hover': { background: '#fff' } }}
          size="small"
          onClick={() => setOpenSwapModal(true)}
          color="secondary"
          variant="outlined"
        >
          {t('global.swapLynk')}
        </ConnectButton>

        <Tooltip
          title={account ? <MyWallet /> : null}
          TransitionComponent={Fade}
          PopperProps={{
            sx: {
              '& .MuiTooltip-tooltip': {
                bgcolor: '#fff',
                padding: 0,
                margin: 0,
              },
            },
          }}
          placement="bottom-end"
        >
          <ConnectButton onClick={showModal} size="small" color="secondary" variant="contained">
            {account
              ? shortenAddress(account, 8)
              : error
              ? error instanceof UnsupportedChainIdError
                ? t('global.wrongNetwork')
                : t('global.error')
              : t('global.connectWallet')}
          </ConnectButton>
        </Tooltip>
      </BarRight>
      <MenuBar onClick={() => setOpenMobileMenu(true)}>
        <DropdownBar />
      </MenuBar>
      <WalletModal />
      <BaseModal
        open={myWalletModalOpen}
        showSlideModal={true}
        title={t('global.myWallet')}
        bgColor={theme.bgColor.bg2}
        handleClose={() => setMyWalletModalOpen(false)}
      >
        <MyWallet showTitle={false} closeMenu={closeMenu} />
      </BaseModal>
      <MobileMenu open={openMobileMenu} handleClose={() => setOpenMobileMenu(false)}>
        <Stack spacing={3} padding={'0 48px'}>
          <MenuConnectButton
            size="small"
            onClick={() => setOpenSwapModal(true)}
            sx={{ background: '#fff', color: '#1B1D2D', '&:hover': { background: '#fff' } }}
            variant="outlined"
          >
            {t('global.swapLynk')}
          </MenuConnectButton>
          <MenuConnectButton
            onClick={showModal}
            size="small"
            sx={{ color: '#fff' }}
            color="primary"
            variant="contained"
          >
            {account
              ? shortenAddress(account, 8)
              : error
              ? error instanceof UnsupportedChainIdError
                ? t('global.wrongNetwork')
                : t('global.error')
              : t('global.connectWallet')}
          </MenuConnectButton>
        </Stack>
      </MobileMenu>
    </Bar>
  );
};

export default AppBar;
