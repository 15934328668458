export default {
  global: {
    connectWallet: '지갑 연결하기',
    swapLynk: '$LYNK 교환',
    connectToWallet: '지갑으로 연결하기',
    metamask: '메타마스크',
    walletConnect: '지갑 연결',
    coinbaseWallet: '코인베이스 지갑',
    walletDes: '*지갑을 연결하여 링크버스(Lynkverse) 서비스 이용 약관 및 개인정보 보호정책에 동의합니다.',
    connecting: '연결 중입니다...',
    connectedWallet: '%s%에 연결되었습니다',
    wrongNetwork: '잘못된 네트워크',
    error: '오류',
    myWallet: '나의 지갑',
    myAddress: '나의 주소',
    addToken: '토큰 추가',
    crossChain: '교차 체인',
    logout: '로그아웃',
    change: '변경',
    recentTransactions: '최근 거래',
    clearAll: '모두 지우기',
    confirm: '확인',
    confirmed: '확인되었습니다.',
    balance: '잔액',
    confirmTransaction: '회원님의 지갑에서 이 거래를 확인해 주시기 바랍니다.',
    viewOnChain: '아비스캔(%s%)에서 보기',
    invitationLink: '초대 링크',
    invitationCode: '초대 코드',
    copy: '복사',
    insufficient: '잔액 불충분',
    approve: '승인하다',
    tip: '팁',
    drop: '떨어지다',
    swap: '교환',
    register: '등록하다',
    networkError: '네트워크 오류',
    initializing: '초기화 중...',
    errorConnecting: '연결 오류',
    errorConnectingDes: '연결하는 동안 오류가 발생했습니다. 페이지를 새로고침해 보세요..',
    binanceSmartChain: '바이낸스 스마트 체인',
    connectTip: '에 접속해주세요',
    ethereumMainnet: '이더리움 메인넷',
    connect: '연결',
    tryAgain: '다시 시도하십시오',
    changeWallet: '지갑 변경',
    exchangeRate: '환율',
    approving: '승인',
    swapTitle: '교환 LYNK',
    registerTitle: '초대 코드를 입력하세요',
    registerPlh: '여기에 코드를 입력하세요',
    registerDes: 'TIP: Lynk의 Discord로 이동하여 초대 코드를 검색할 수 있습니다..',
    unValidCode: '잘못된 등록 코드',
    registerInviterDes: '아직 등록하지 않았습니다. 등록하십시오',
    transactionsDes: '거래 내역이 여기에 표시됩니다....',
    copied: '복사됨',
    finish: '마치다',
    minting: '주조',
    comingSoon: '곧 출시',
  },
  navMenu: {
    myDashboard: '나의 대시보드',
    nfts: 'NFT',
    stake: '스테이킹',
    invitation: '초대',
    history: '이력',
    activitiesCampaigns: '활동 및 캠페인',
    nodeNft: 'Node NFT 프로그램',
    leaderNft: 'LEADER NFT 프로그램',
    finders: '파인더 NFT 발행',
    marketPlace: '시장',
    rank: '순위',
    more: '더 보기',
    chatRoom: '대화방',
    lynkAlliance: '링크 얼라이언스',
    domainServer: 'LYNK 도메인 이름 서버',
    feed: '동적',
  },
  nfts: {
    title: '사용자 NFT',
    mintKeeper: '단조 KEEPER',
    buyKeeper: '구입하다 KEEPER',
    freeMint: '자유 단조',
    owned: '소유 중입니다.',
    staked: '스테이킹 되었습니다.',
    sell: '판매',
    stake: '스테이킹',
    mintKeeperNft: '키퍼 NFT 민팅',
    nftName: 'NFT 이름',
    selectGender: '성별 선택',
    male: '남성',
    female: '여성',
    selectFaction: '파벌 선택',
    spirits: '정신',
    martials: '마샬',
    exos: '엑소',
    voids: '보이드',
    selectNft: 'NFT 선택',
    common: '일반',
    special: '특별',
    rare: '레어',
    reSelect: '재선택',
    mint: '민팅',
    proceed: '진행하다',
    enterName: '이름을 입력하시오',
    nameUsed: '점유된 이름',
    cancel: '취소',
    claim: '주장하다',
    freeMintEnd: '다음으로 끝나는 판매',
    days: '날',
    hours: '시간',
    minutes: '분',
    seconds: '초',
  },
  nftDetail: {
    back: '뒤로',
    attributes: '속성',
    leveltooltip: 'NFT 속성을 가속화하여 레벨을 올리고 더 많은 스테이킹 보상을 받으세요!',
    purchaseAp: 'AP 구매',
    userStats: '사용자 능력치',
    nfts: 'NFT',
    charisma: '카리스마',
    dexterity: '재주',
    intellect: '지능',
    vitality: '활력',
    accelerate: '가속화',
    details: '상세',
    currentHolder: '현재 소유자',
    mintTime: '민팅 시간',
    mintAddress: '민팅 주소',
    estimatedPrice: '예상 가격',
    endPrice: '종료 가격',
    role: '역할',
    contractAddress: '컨트랙트 주소',
    tokenID: '토큰 ID',
    tokenStandard: '토큰 기준',
    chain: '체인',
    background: '배경',
    effect: '효과',
    body: '신체',
    clothing: '옷',
    head: '머리',
    weapon: '무기',
    bodyAttachment: '신체 부착물',
    artifact: '유물',
    purchaseAP: 'AP 구매',
    balance: '잔액',
    amount: '금액',
    price: '가격',
    buy: '구매',
    caUpgrade: 'CA 업그레이드',
    vaUpgrade: 'VA 업그레이드',
    inUpgrade: 'IN 업그레이드',
    dxUpgrade: 'DX 업그레이드',
    max: '최대',
    unStake: '스테이크 해제',
    claimAp: 'AP 요청',
    apReward: 'AP 보상',
    apRewardtooltip:
      '레벨 2 이상의 NFT만 성취 보상을 받을 수 있습니다. 회원은 다음 집계 주기를 재개하기 위해 매일 보상된 AP를 청구해야 합니다.\n그렇게 하지 않으면 2일 이상 스테이킹했음에도 불구하고 AP가 1로 유지됩니다.',
    stakingTime: '누적된 스테이킹 시간',
    claimApHelpDes:
      '멤버들은 다음 AP 주기를 다시 시작하기 위해 매일 보상 받은 AP를 받도록 요구됩니다. 이를 시행하지 않을 경우 AP 보상이 1로 머물러 있는 결과를 야기할 것입니다. 100일 이상 동안 스테이킹을 함에도 불구하고 말이죠.',
    claimProfit: '수익을 받을 수 있습니다. ',
    claim: '받기',
    sell: '팔기',
    referencePrice: '레퍼런스 가격',
    onSale: '판매 중',
    unListing: '수신 목록에서 제거 중입니다.',
    unList: '수신 목록에서 제거',
    insufficientLynk: '불충분하다 Lynk',
    fullLevel: '풀 레벨',
    upLimit: '보다 높아야 함',
    oneDayMaxVA: 'VA가 오늘 업그레이드되었습니다.',
    enterPoint: '엔터포인트',
    noReward: '사용 가능한 보상 없음',
    mustHigherThanLevel2: '레벨 2 이상이어야 합니다.',
    mintAction: '민트 파인더 NFT',
    mint: '민트',
    unstake: '언스테이크',
    upgrade: '업그레이드',
    loading: '로드 중',
  },
  stake: {
    title: '나의 NFT 스테이킹하기',
    redeem: '사용',
    claim: '받기',
    accumulatedEarned: '누적된 수익 $LRT',
    available: '사용 가능한 $LRT',
    claiming: '청구',
  },
  invitation: {
    title: '초대 보드',
    invitationDes: '친구를 초대 하여 함께 보상을 받아 보세요.',
    inviteFriends: '친구 초대',
    rewardSummary: '보상 개요',
    socialRewardUp: '사회적 보상',
    socialRewardDes:
      '여러분의 친구들이 링크(LYNK) 커뮤니티에 가입할 수 있도록 하여 여러분의 팀 및 키퍼로 이루어진 네트워크를 구축하세요. 여러분의 키퍼의 순위가 높을수록 받게 되는 사회적 보상이 높아집니다.',
    communityReward: '커뮤니티 보상',
    communityReward1: '커뮤니티 보상',
    communityRewardDes:
      '보호 구역에 있는 멤버들과 연결함으로써 플레이어들은 더 높은 보상을 받기 위해 신규 동맹 또는 기존의 동맹을 맺을 수 있습니다.',
    contributionRewardUp: '공헌 보상',
    contributionRewardDes:
      'LRT를 수익으로서 받는 것뿐만 아니라 특정 임무를 수행하는 것에 대해 AP가 주어질 부여될 수 있습니다. 새로운 멤버를 모집하는 사용자는 그들의 팔로우에 의해 (즉시 지급됨) 누적된 금액에서 100 CA마다 1-6 AP를 받게 될 것입니다.',
    AchievementReward: '성취 보상',
    AchievementRewardDes:
      ' LRT를 수익으로서 받는 것뿐만 아니라 특정 임무를 수행하는 것에 대해 AP가 주어질 부여될 수 있습니다. 매일 AP 보상을 받기 위해 레벨 2 이상의 NFT를 스테이킹 하세요! AP 보상의 금액은 회원님의 NFT 레벨에 의해 결정될 것입니다.',
    StakingReward: '스테이킹 보상',
    StakingRewardDes:
      '저희 사용자는 동시에 여러 NFT를 소유할 수 있습니다. 사용자들은 본인의 NFT가 스테이킹 요건만 만족시킨다면 일일 보상을 받을 대상이 됩니다.',
    bonusRatio: '보너스 비율',
    totalLRT: '총 LRT',
    qualified: '자격 부여됨',
    cumulateCA: 'CA 누적',
    totalAP: '총 AP',
    cycles: '주기',
    stakingNFT: 'NFT 스테이킹',
    elite: '엘리트',
    epic: '에픽',
    master: '마스터',
    legendary: '레전더리',
    mythic: '신화적',
    divine: '신성함',
    nourishInviter: '직접 후원자',
    teamGrade: '누적',
    levelPrivilege: '레벨 특권',
    socialReward: '사회적 보상',
    contributionReward: '공헌 보상',
    achievementReward: '성취 보상',
    inactive: '비활성',
    activated: '활성되었습니다.',
    myLevel: '내 레벨',
    generation: '세대',
    inviteRule: '모든 새로운 100CA를 참조하십시오.',
    NFTStakingRule: '레벨 2 이상 NFT 1일 스테이킹하고 획득',
    inactivated: '비활성화됨',
    activate: '활성화',
    AcaStastistics: 'SUMCA 통계',
    daily: '일일',
    yesterday: '어제',
    today: '오늘',
    weekly: '주간',
    lastWeek: '지난주',
    thisWeek: '이번 주',
    monthly: '월간 간행물',
    lastMonth: '지난 달',
    thisMonth: '이번 달',
  },
  history: {
    title: '이력',
    stakingRewardRecords: '스테이킹 보상 기록',
    mintingRecords: 'NFT 민팅 기록',
    transactionsRecords: 'NFT 거래 기록',
    recommendedRecords: '추천된 기록',
    referralRewardRecords: '추천인 보상 기록',
    contributionRewardRecords: '공헌 보상 기록',
    achievementRewardRecords: '성취 보상 기록',
    communityRewardRecords: '커뮤니티 보상 기록',
    time: '시간',
    reward: '보상',
    tx: 'TX',
    name: '이름',
    amount: '양',
    buyerAddress: '구매자 지갑 주소',
    sellerAddress: '판매자 지갑 주소',
    address: '지갑 주소',
  },
  rank: {
    title: '상위 사용자 정렬',
    top: '상위',
    ranking: '순위',
    levelUp: '레벨',
    charisma: '카리스마',
    nft: 'NFT',
    level: '레벨',
    tx: 'TX',
  },
  finders: {
    title: '파인더',
    findersDes1: '파인더는 넥서스로부터 왔습니다. ',
    findersDes2: '모든 시공 좌표의 중심 말이죠. ',
    mint: '민팅',
    soldOut: '매진',
    info1:
      '파인더들은 모든 시공 좌표의 중심인 넥서스로부터 왔습니다. 이들은 이미 알려진 우주의 시작부터 존재해 왔으며 무수한 세계의 탄생을 지켜봐 왔습니다. 이들은 모든 메타버스들로부터 모든 지식과 데이터를 수집합니다. 그들의 마음을 조화롭게 동기화하며 이를 지키면서 말이죠. 때때로 다른 메타버스들의 한계를 뛰어넘어 전진할 수 있는 잠재력을 가진 비범한 메타버스가 나타나 파인더들이 행동하도록 유발합니다. 파인더들은 틈을 통해 링크버스(LYNKVERSE)로 들어서서 그들의 미션 수행에 착수했습니다. 다른 주민들을 안내하기 위해 선택된 몇몇 파인더들을 고르고 이 메타버스가 최대 잠재력으로 성장할 수 있도록 하기 위한 미션 말이죠.',
    info2:
      '모든 파인더들은 그들의 힘의 일부를 포함하고 있는 영혼의 씨앗을 만들며 선택된 파인더들에게 이를 수여합니다. 영혼의 씨앗과 융합하여 새로운 종이 탄생합니다. 희망의 역할을 수행하고 새시대의 리더가 되어 보세요.',
    totalAmount: '총 파인더의 수:',
    totalBalance: '가능한 파인더의 총 합계:',
    minted: '발행됨',
  },
  nodeNft: {
    title: '노드 NFT 프로그램 1세대',
    mintNow: '발행 지금',
    mintNode: '노드 NFT발행',
    mintNodeNft: '노드 NFT 발행',
    nodeNftDes1: '노드 NFT로 경쟁 우위 확보',
    nodeNftDes2: 'LYNK 커뮤니티에서 당신의 자리를 잡으세요.',
    info11: `LYNK 패밀리 여러분, 노드 NFT 프로그램에 환영합니다!`,
    info12: `Node NFT 프로그램은 LYNK 커뮤니티 성장에 강한 이니셔티브를 보인 일차 지원자들을 위한 우수한 프로그램입니다. 프로그램의 제1 세대는 귀하와 같은 챔피언들을 인정하며, 고유한 Keeper NFT를 제공하여 가장 흥미로운 Web3 커뮤니티의 리더로서의 정체성과 지위를 대표합니다.`,
    info13: `이 흥미로운 프로그램은 고유한 노드 NFT를 공통, 특별 또는 희귀 세 가지 티어 중 하나에서 만들 수 있는 기회를 제공합니다. 각 티어에서 제공되는 것은 다음과 같습니다:`,
    infoCommon: `커먼 노드 NFT: 1000 USDT로 민트하면 1,200 CA, 110 AP 및 커먼 노드 NFT 배지를 받을 수 있습니다. 또한 레전더리 랭크로 직접 진급하게 됩니다.`,
    infoSpecial: `스페셜 노드 NFT: 3000 USDT로 민트하면, 4,000 CA와 450 AP를 받을 수 있으며, 스페셜 노드 NFT 배지도 지급됩니다. 또한, 당신은 레전더리 랭크로 직접 승급합니다.`,
    infoRare: `희귀 노드 NFT: 5000 USDT로 Mint하면 7,000 CA, 1,200 AP 및 Rare Node NFT 배지를 받을 수 있습니다. 또한 바로 전설적인 랭크로 승격됩니다.`,
    info21: `전설 등급 회원으로서, 성공적인 추천마다 추가적인 보상을 받으세요! 당신의 친구가 노드 NFT를 구매할 때, 그들이 구매한 등급에 해당하는 비례적인 보상을 받게 됩니다.`,
    info22: `당신의 추천인이 희귀 노드 Node NFT를 구매하면, 당신은 추가 보상으로 누적 CA 5,000, AP 280 및 LRT 560 토큰을 받게 됩니다!`,
    info23: `당신의 추천인이 스페셜 노드 NFT를 구매하면, 당신은 추가적으로 누적된 CA 3,000, AP 160 및 LRT 320 토큰의 보상을 받게 됩니다!`,
    info24: `당신의 추천인이 커먼 노드 Node NFT를 구매하면, 당신은 추가로 1,000 CA, 48 AP 및 96 LRT 토큰의 보상을 받게 됩니다!`,
    info25: `Node NFT는 활기찬 Web3 커뮤니티에서 네트워크를 구축하는 데 도움이 되도록 설계되었습니다. LYNK 커뮤니티 내에서 네트워크를 확장할수록 더 많은 혜택을 누리고 더 많은 수익을 얻게됩니다!`,
    info26: `그래서 친구들을 초대하고 Node NFT 크루에 참여하여 혜택을 누리세요. 지금 가입하고 오늘부터 더 많은 수익을 얻으세요!`,
    minted: '발행됨',
    soldOut: '매진',
  },
  leaderNft: {
    title: '지도자 NFT 프로그램 1세대',
    mintNow: '발행 지금',
    mintLeader: '지도자 NFT발행',
    mintLeaderNft: '지도자 NFT 발행',
    leaderNftDes1: '지도자 NFT로 경쟁 우위 확보',
    leaderNftDes2: 'LYNK 커뮤니티에서 당신의 자리를 잡으세요.',
    info11: `LYNK 패밀리 여러분, 지도자 NFT 프로그램에 환영합니다!`,
    info12: `Leader NFT 프로그램은 LYNK 커뮤니티 성장에 강한 이니셔티브를 보인 일차 지원자들을 위한 우수한 프로그램입니다. 프로그램의 제1 세대는 귀하와 같은 챔피언들을 인정하며, 고유한 Keeper NFT를 제공하여 가장 흥미로운 Web3 커뮤니티의 리더로서의 정체성과 지위를 대표합니다.`,
    info13: `이 흥미로운 프로그램은 고유한 지도자 NFT를 공통, 특별 또는 희귀 세 가지 티어 중 하나에서 만들 수 있는 기회를 제공합니다. 각 티어에서 제공되는 것은 다음과 같습니다:`,
    infoCommon: `커먼 지도자 NFT: 500 USDT로 민트하면 500 CA, 또한 서사시 랭크로 직접 진급하게 됩니다.`,
    infoSpecial: `스페셜 지도자 NFT: 1000 USDT로 민트하면, 1,000 CA, 또한, 당신은 주인 랭크로 직접 승급합니다.`,
    infoRare: `희귀 지도자 NFT: 3000 USDT로 Mint하면 3,000 CA, 또한 바로 전설적인 랭크로 승격됩니다.`,
    info21: `전설 등급 회원으로서, 성공적인 추천마다 추가적인 보상을 받으세요! 당신의 친구가 지도자 NFT를 구매할 때, 그들이 구매한 등급에 해당하는 비례적인 보상을 받게 됩니다.`,
    info22: `당신의 추천인이 희귀 지도자 Leader NFT를 구매하면, 당신은 추가 보상으로 누적 CA 3,000, AP 120 및 LRT 240 토큰을 받게 됩니다!`,
    info23: `당신의 추천인이 스페셜 지도자 NFT를 구매하면, 당신은 추가적으로 누적된 CA 1,000, AP 40 및 LRT 80 토큰의 보상을 받게 됩니다!`,
    info24: `당신의 추천인이 커먼 지도자 Leader NFT를 구매하면, 당신은 추가로 500 CA, 20 AP 및 40 LRT 토큰의 보상을 받게 됩니다!`,
    info25: `Leader NFT는 활기찬 Web3 커뮤니티에서 네트워크를 구축하는 데 도움이 되도록 설계되었습니다. LYNK 커뮤니티 내에서 네트워크를 확장할수록 더 많은 혜택을 누리고 더 많은 수익을 얻게됩니다!`,
    info26: `그래서 친구들을 초대하고 Leader NFT 크루에 참여하여 혜택을 누리세요. 지금 가입하고 오늘부터 더 많은 수익을 얻으세요!`,
    minted: '발행됨',
    soldOut: '매진',
  },
  marketPlace: {
    title: '시장',
    totalVolume: '총 양',
    floorPrice: '최저 가격',
    higherPrice: '더 높은 가격',
    owner: '소유주',
    tradingFee: '거래 수수료',
    faction: '파벌',
    type0: '파인더',
    type1: '정신',
    type2: '엑소',
    type3: '마샬',
    type4: '보이드',
    gender: '성별',
    boy: '남성',
    girl: '여성',
    level: '레벨',
    level0: '레벨 2-3',
    level1: '레벨 4-6',
    level2: '레벨 7-9',
    level3: '레벨 10-12',
    level4: '레벨13',
    priceDesc: '가격: 높은 순으로',
    priceAsc: '가격: 낮은 순으로',
    priceHint: {
      empty: '가격 범위를 입력해 주시기 바랍니다. ',
      numberError: '최대 가격은 최저 가격보다 낮을 수 없습니다.',
    },
    priceUsdt: '가격 USDT',
    to: '까지',
    all: '모두',
    buy: '구매',
    rare: '레어',
    price: '가격',
    balance: '잔액',
  },
};
