import React, { useState, useEffect, useRef } from 'react';
import { styled, Drawer, List, Box, IconButton, ListItemButton, ListItemText, Collapse } from '@mui/material';
import { ReactComponent as Logo } from 'assets/img/drawer/logo.svg';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { drawerFooter } from './config/drawerFooter';
import { router } from './config/router';
import { languageList } from './config/language';
import { useNavigate, useLocation, createSearchParams } from 'react-router-dom';
import { useAppSelector, useAppDispatch } from 'hooks/redux';
import { updateLanguage } from 'store/features/userSlice';
import { useClickAway } from 'ahooks';
import arrow from 'assets/img/drawer/arrow.svg';
import { useI18n } from 'react-simple-i18n';
import { showTipModal } from 'store/features/componentSlice';

const LeftDrawer = styled(Drawer)<{
  drawerwidth: number;
}>(({ drawerwidth, theme }) => ({
  width: drawerwidth,
  [theme.breakpoints.down('sm')]: {
    display: 'none',
  },
  flexShrink: 0,
  backgroundColor: 'transparent',
  '& .MuiDrawer-paper': {
    width: drawerwidth,
    boxSizing: 'border-box',
    borderTopRightRadius: 30,
    borderBottomRightRadius: 30,
    background: `${theme.bgColor.bg1}`,
    border: 'none',
    boxShadow: '0px 0 30px 5px rgb(0 0 0 / 45%)',
  },
}));

const DrawerHeader = styled('div')({
  display: 'flex',
  height: 130,
  alignItems: 'center',
  justifyContent: 'center',
  cursor: 'pointer',
});

const DrawerFooter = styled('div')({
  display: 'flex',
  height: 70,
  width: '100%',
  alignItems: 'center',
  justifyContent: 'center',
});

const ItemText = styled(ListItemText)<{ path: string; checkpath: string }>(({ path, checkpath, theme }) => ({
  '& > span > span': {
    // paddingLeft: 8,
    paddingRight: 20,
    fontSize: 13,
    fontWeight: 700,
    // whiteSpace: 'nowrap',
    color: path === checkpath ? `${theme.textColor.text3}` : `${theme.textColor.text1}`,
    background:
      path === checkpath
        ? `linear-gradient(75deg, ${theme.bgColor.bg3} 0, ${theme.bgColor.bg3} 92%, transparent 50%)`
        : 'transparent',
  },
}));

const LanguageBox = styled(Box)(({ theme }) => ({
  background: '#203044',
  width: theme.spacing(13.5),
  border: '1px solid #fff',
  borderRadius: 7,
  display: 'grid',
  gridTemplateRows: '1fr',
  marginLeft: theme.spacing(2),
  marginTop: theme.spacing(5),
  '& .languageList': {
    width: '100%',
    background: '#ffffff33',
    maxHeight: theme.spacing(30),
    overflowY: 'auto',
  },
}));

const LanguageButton = styled(ListItemButton)(({ theme }) => ({
  width: '100%',
  height: theme.spacing(3.5),
  padding: theme.spacing(0, 0.5, 0, 1),
  '& .MuiTypography-root': {
    fontSize: 13,
    fontWeight: 700,
    color: '#fff',
  },
  '& .MuiSvgIcon-root': {
    color: '#fff',
  },
  '& .arrowDown': {
    marginRight: theme.spacing(1),
  },
  '& .arrow': {
    marginRight: theme.spacing(1),
    transform: 'rotate(180deg)',
  },
}));

const DrawerMenu = ({ open, drawerWidth }: { open: boolean; drawerWidth: number }): JSX.Element => {
  const navigate = useNavigate();
  const { t } = useI18n();
  const dispatch = useAppDispatch();
  const { inviter } = useAppSelector((state) => state.application);
  const { lang } = useAppSelector((state) => state.user);
  const { pathname } = useLocation();
  const [checkPath, setpCheckPath] = useState('');
  const [collapsePath, setpCollapsePath] = useState('');
  const [openSelect, setOpenSelect] = useState(false);
  const ref = useRef<any>(null);
  const ref1 = useRef<any>(null);
  const ref2 = useRef<any>(null);
  useClickAway(() => {
    setOpenSelect(false);
  }, [ref, ref1]);

  useEffect(() => {
    setpCheckPath(pathname);
  }, [pathname]);

  const openLink = (url: string) => {
    window.open(url, '_blank');
  };

  const selectPath = (path: string, children: Array<{ title: string; path: string }>) => {
    if (children.length) {
      setpCollapsePath(path === collapsePath ? '' : path);
    } else {
      if (!path.includes(collapsePath)) {
        setpCollapsePath('');
      }
      if (path.includes('/more')) {
        dispatch(showTipModal(t('global.comingSoon')));
        return;
      }
      navigate({
        pathname: path,
        search:
          inviter &&
          createSearchParams({
            referrer: inviter,
          }).toString(),
      });
    }
  };

  return (
    <LeftDrawer variant="persistent" anchor="left" open={open} drawerwidth={drawerWidth}>
      <DrawerHeader onClick={() => window.open('https://lynk.im/')}>
        <Logo />
      </DrawerHeader>
      <List
        component="nav"
        sx={{
          width: '100%',
          maxWidth: 360,
          bgcolor: 'background.bg1',
          height: 'calc(100% - 200px)',
          overflowY: 'auto',
        }}
        aria-labelledby="nested-list-subheader"
      >
        {router.map(({ title, path, children }) => {
          return (
            <Box
              key={title}
              sx={{
                bgcolor: path === collapsePath ? 'background.paper' : 'bgColor.bg1',
                borderTopRightRadius: 15,
                borderBottomRightRadius: 15,
              }}
            >
              <ListItemButton onClick={() => selectPath(path, children)} disableRipple>
                <ListItemText
                  primary={t(title)}
                  sx={{
                    '& .MuiTypography-root': {
                      fontSize: 13,
                      fontWeight: 700,
                    },
                    color: path === collapsePath || checkPath.includes(path) ? 'text.text3' : 'background.paper',
                  }}
                />
                {!children?.length ? null : path === collapsePath ? (
                  <ExpandLess sx={{ color: 'text.text3' }} />
                ) : (
                  <ExpandMore
                    sx={{
                      color: checkPath.includes(path) ? 'text.text3' : 'background.paper',
                    }}
                  />
                )}
              </ListItemButton>
              {children.length ? (
                <Collapse in={path === collapsePath} timeout="auto" unmountOnExit>
                  <List
                    component="div"
                    disablePadding
                    sx={{
                      width: '100%',
                      maxWidth: 360,
                    }}
                  >
                    {children.map((el) => (
                      <ListItemButton
                        disableRipple
                        key={el.title}
                        sx={{ pl: 4 }}
                        onClick={() => selectPath(el.path, [])}
                      >
                        <ItemText checkpath={checkPath} path={el.path}>
                          <span>{t(el.title)}</span>
                        </ItemText>
                      </ListItemButton>
                    ))}
                  </List>
                </Collapse>
              ) : null}
            </Box>
          );
        })}
        <LanguageBox ref={ref}>
          <LanguageButton onClick={() => setOpenSelect(!openSelect)} disableRipple>
            <ListItemText primary={languageList.filter((el) => el.value === lang)[0]?.title} />
            {openSelect ? (
              <img ref={ref1} className="arrow" src={arrow} />
            ) : (
              <img ref={ref2} className="arrowDown" src={arrow} />
            )}
          </LanguageButton>
          <Collapse in={openSelect} timeout="auto" unmountOnExit>
            <List component="div" className="languageList" disablePadding>
              {languageList.map((item) => (
                <LanguageButton
                  key={item.value}
                  onClick={() => {
                    dispatch(updateLanguage(item.value));
                    setOpenSelect(false);
                  }}
                  disableRipple
                >
                  <ListItemText primary={item.title} />
                </LanguageButton>
              ))}
            </List>
          </Collapse>
        </LanguageBox>
      </List>
      <DrawerFooter>
        {drawerFooter.map(({ name, Icon, path }) => {
          return (
            <IconButton aria-label={name} key={name} onClick={() => openLink(path)}>
              <Icon />
            </IconButton>
          );
        })}
      </DrawerFooter>
    </LeftDrawer>
  );
};

export default DrawerMenu;
