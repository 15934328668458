import React, { useState, useEffect } from 'react';
import { styled, Typography, Button, Stack, Box } from '@mui/material';
import CircularProgress, { circularProgressClasses } from '@mui/material/CircularProgress';
import { useI18n } from 'react-simple-i18n';
import progress from 'assets/img/detail/progress.png';

const AccelerateBox = styled(Box)(({ theme }) => ({
  width: theme.spacing(17),
  height: theme.spacing(21),
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  alignItems: 'center',
  // transform: 'rotate(36.24deg)',
  // [theme.breakpoints.down('sm')]: {
  //   fontSize: 20,
  // },
}));

const ProgressBox = styled(Box)(({ theme }) => ({
  width: theme.spacing(17),
  height: theme.spacing(17),
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'flex-start',
  backgroundImage: `url(${progress})`,
  backgroundPosition: 'center',
  backgroundSize: 'contain',
  backgroundRepeat: 'no-repeat',
  paddingLeft: '0.7px',
  paddingTop: '1px',
  position: 'relative',
}));

const ProgressInfoBox = styled(Box)({
  top: 0,
  left: 0,
  bottom: 0,
  right: 0,
  position: 'absolute',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

const ProgressTypography = styled(Typography)({
  fontSize: 15,
  fontWeight: 500,
  lineHeight: '15px',
});

const InfoTypography = styled(Typography)<{ isLong: boolean }>(({ isLong }) => ({
  fontSize: 13,
  fontWeight: 700,
  lineHeight: '15px',
  zoom: isLong ? 0.7 : 1,
}));

interface AccelerateProgressProps {
  title: string;
  current: number;
  max: number;
  upgradeBle: boolean;
  onUpgrade: () => void;
  levelMax: number;
  upgrading: boolean;
}

const AccelerateProgress = (props: AccelerateProgressProps) => {
  const { t, i18n } = useI18n();
  const { title, current, max, upgradeBle, onUpgrade, levelMax, upgrading } = props;
  const [progressValue, setProgressValue] = useState(0);

  useEffect(() => {
    if (!Number.isNaN(current) && levelMax?.toString() && Number(levelMax?.toString())) {
      const value = (Number(current) / Number(levelMax?.toString())) * 100;
      setProgressValue(value > 100 ? 100 : value);
    } else {
      setProgressValue(0);
    }
  }, [current, levelMax]);

  return (
    <AccelerateBox>
      <ProgressBox>
        <svg width="0" height="0">
          <defs>
            <linearGradient id="my_gradient" x1="0%" y1="0%" x2="0%" y2="100%">
              <stop offset="0%" stopColor="#6BB9DD" />
              <stop offset="100%" stopColor="#78DBE0" />
            </linearGradient>
          </defs>
        </svg>
        <CircularProgress
          variant="determinate"
          sx={{
            '&.MuiCircularProgress-root': {
              transform: 'rotate(25deg) !important',
            },
            'svg circle': { stroke: 'url(#my_gradient)' },
            [`& .${circularProgressClasses.circle}`]: {
              strokeLinecap: 'round',
            },
          }}
          thickness={4.5}
          size={'129.12px'}
          value={progressValue}
        />
        <ProgressInfoBox>
          <Stack alignItems="center">
            <ProgressTypography>
              {Number.isNaN(current) ? '' : current.toString()}
              {!Number.isNaN(current) && levelMax?.toString() ? '/' : ''}
              {levelMax?.toString() ?? ''}
            </ProgressTypography>
            <InfoTypography isLong={i18n.getLang() === 'ruRU'}>{t(`nftDetail.${title}`)}</InfoTypography>
          </Stack>
        </ProgressInfoBox>
      </ProgressBox>
      {upgradeBle && (
        <Button
          disabled={(current === max && (title === 'intellect' || title === 'dexterity')) || upgrading}
          onClick={onUpgrade}
          size="small"
          sx={{ color: '#fff', height: 20, padding: '0 10px', borderRadius: 8 }}
          color="secondary"
          variant="contained"
        >
          {current === max && (title === 'intellect' || title === 'dexterity') ? null : upgrading ? (
            <CircularProgress sx={{ 'svg circle': { stroke: '#00000042' } }} size={12} />
          ) : null}
          <Typography fontSize={12} sx={{ zoom: i18n.getLang() === 'jaJP' ? 0.8 : 1 }}>
            {current === max && (title === 'intellect' || title === 'dexterity')
              ? t('nftDetail.fullLevel')
              : t('nftDetail.accelerate')}
          </Typography>
        </Button>
      )}
    </AccelerateBox>
  );
};

export default AccelerateProgress;
