import BaseModal from 'components/BaseModal';
import { MenuItem, Stack, Typography, InputAdornment, InputBase, styled } from '@mui/material';
import React, { useCallback, useMemo, useState } from 'react';
import { useTokenBalance } from 'hooks/useWallet';
import { tryParseAmount } from 'utils/parseAmount';
import { useI18n } from 'react-simple-i18n';
import { useActiveWeb3React } from 'hooks';
import { ApprovalState, useApproveCallback } from 'hooks/useApproveCallback';
import { useAppDispatch } from 'hooks/redux';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  showTransactionConfirmModal,
  showTransactionPendingModal,
  showTransactionErrorModal,
} from 'store/features/componentSlice';
import Select from '@mui/material/Select';
import { useSwap } from 'hooks/useSwap';
import { LYNK, LRT, SWAP_ADDRESS } from 'constants/index';
import { TokenAmount } from 'constants/token';
import { ReactComponent as SwapIcon } from 'assets/svg/swapIcon.svg';
import { useCurrencyBalance } from 'hooks/useWallet';
import useBreakpoint from 'hooks/useBreakpoint';

const NameInput = styled(InputBase)(({ theme }) => ({
  borderRadius: 13,
  height: theme.spacing(4),
  width: theme.spacing(37),
  background: '#F0F0F0',
  border: '1px solid #00000099',
  padding: theme.spacing(0, 2),
  '&.usdtNumber': {
    padding: theme.spacing(0, 0, 0, 2),
  },
  [theme.breakpoints.down('sm')]: {
    width: '80%',
  },
}));

const StartTypography = styled(Typography)(({ theme }) => ({
  width: theme.spacing(37),
  textAlign: 'start',
  [theme.breakpoints.down('sm')]: {
    width: '80%',
  },
}));

export default function SwapLinkModal({ open, handleClose }: { open: boolean; handleClose: () => void }) {
  const { t } = useI18n();
  const dispatch = useAppDispatch();
  const { account } = useActiveWeb3React();
  const [typed, setTyped] = useState('');
  const { swap, price } = useSwap();
  const isMobile = useBreakpoint();

  const swapAmount = useMemo(() => {
    return typed ? (tryParseAmount(typed, LRT) as TokenAmount) : undefined;
  }, [typed]);

  const lynkBalance = useTokenBalance(account ?? undefined, LRT);

  const enoughAsset = swapAmount && lynkBalance && !lynkBalance.lessThan(swapAmount);

  const [approvalState, approveCallback] = useApproveCallback(swapAmount, SWAP_ADDRESS);
  const holdLRtBalance = useCurrencyBalance(account ?? undefined, LRT);
  const holdLYNKBalance = useCurrencyBalance(account ?? undefined, LYNK);

  const makeCallback = useCallback(async () => {
    if (!swapAmount) return;
    dispatch(showTransactionPendingModal());
    swap(swapAmount)
      .then(() => {
        dispatch(showTransactionConfirmModal());
        handleClose();
      })
      .catch((err: any) => {
        dispatch(
          showTransactionErrorModal(
            err.error && err.error.message
              ? err.error.message
              : err?.data?.message
              ? err?.data?.message
              : err.message ?? t('global.networkError')
          )
        );
        console.error(err);
      });
  }, [swapAmount, dispatch, swap, handleClose, t]);

  return (
    <BaseModal
      contentWidth={434}
      open={open}
      confirmDisabled={!swapAmount || !lynkBalance || !enoughAsset}
      confirmLoading={approvalState === ApprovalState.PENDING}
      confirmText={
        approvalState === ApprovalState.NOT_APPROVED
          ? t('global.approve')
          : approvalState === ApprovalState.PENDING
          ? t('global.approving')
          : swapAmount && lynkBalance && !enoughAsset
          ? t('global.insufficient')
          : t('global.swap')
      }
      showSlideModal={isMobile}
      confirm={approvalState === ApprovalState.NOT_APPROVED ? approveCallback : makeCallback}
      handleClose={handleClose}
      title={t('global.swapTitle')}
    >
      <Stack justifyContent="center" alignItems="center">
        <StartTypography>{t('nftDetail.amount')}</StartTypography>
        <NameInput
          value={typed}
          onChange={(e) => {
            const eventValue = e.target.value.replace(/[^0-9]/g, '').replace(/\b(0+)/gi, '');
            setTyped(() => eventValue);
          }}
          className="usdtNumber"
          placeholder={'0'}
          endAdornment={
            <InputAdornment position="end">
              <Typography
                onClick={() => setTyped(lynkBalance?.toSignificant().toString() ?? '')}
                sx={(theme) => ({ padding: theme.spacing(0, 1), fontWeight: 700, cursor: 'pointer' })}
              >
                {t('nftDetail.max')}
              </Typography>
              <Select
                value={'LRT'}
                displayEmpty
                IconComponent={ExpandMoreIcon}
                sx={{
                  color: '#000',
                  fontWeight: 700,
                  height: 32,
                  '& .MuiOutlinedInput-notchedOutline': {
                    border: 'none',
                    borderRadius: 0,
                    borderLeft: '1px solid #00000099',
                  },
                  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                    borderLeft: '1px solid #00000099',
                  },
                }}
                inputProps={{ 'aria-label': 'Without label' }}
              >
                <MenuItem key={'LRT'} value={'LRT'}>
                  <Typography>{'LRT'}</Typography>
                </MenuItem>
              </Select>
            </InputAdornment>
          }
        />
        <StartTypography>{`${t('nftDetail.balance')}: ${
          holdLRtBalance ? holdLRtBalance?.toFixed(2, { groupSeparator: ',' }) : '--'
        }`}</StartTypography>
        <SwapIcon />
        <StartTypography>{t('nftDetail.amount')}</StartTypography>
        <NameInput
          readOnly
          value={price && swapAmount ? swapAmount.multiply(price)?.toFixed(6, { groupSeparator: ',' }) : '--'}
          endAdornment={<InputAdornment position="end">Lynk</InputAdornment>}
        />
        <Stack width={isMobile ? '80%' : 296} direction="row" justifyContent="space-between">
          <Typography>{`${t('nftDetail.balance')}: ${
            holdLYNKBalance ? holdLYNKBalance?.toFixed(2, { groupSeparator: ',' }) : '--'
          }`}</Typography>
          <Stack>
            <Typography textAlign="end">{t('global.exchangeRate')}</Typography>
            <Typography>1 LRT = {price ? price.toSignificant() : '--'} LYNK</Typography>
          </Stack>
        </Stack>
      </Stack>
    </BaseModal>
  );
}
