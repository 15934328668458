import { isAddress, shortenAddress } from 'utils';
import { CurrencyAmount, TokenAmount } from 'constants/token';
import { USDT, LRT, AP } from 'constants/index';
import { getTxLink } from 'theme/components';
import moment from 'moment';
import CONFIG from 'config';

const SUBGRAPH = CONFIG.subgraph;
const COUNTREWARDAPI = CONFIG.countRewardApi;
const SUMCACOUNTREWARDAPI = CONFIG.sumcaCountRewardApi;

export type Order = {
  id: string;
  grade?: string;
  seller: string;
  index: string;
  name: string;
  level: string;
  priceInAcceptToken: string;
  acceptToken: string;
  charisma: string;
  charismaThreshold: string;
  vitality: string;
  vitalityThreshold: string;
  intellect: string;
  intellectThreshold: string;
  dexterity: string;
  dexterityThreshold: string;
};

export type BaseNFT = {
  id: string;
  name: string;
  level: string;
  price: string;
  grade?: string;
};

export type NFT = {
  id: string;
  createdTime: string;
  sex: string;
  grade: string;
  factions: string;
  owner: string;
  creator: string;
  seller: string;
  isList: boolean;
  isStaking: boolean;
  listIndex: string;
  name: string;
  level: string;
  charisma: string;
  priceInAcceptToken: string;
  lastTradePrice: string;
  acceptToken: string;
  charismaThreshold: string;
  vitality: string;
  vitalityThreshold: string;
  intellect: string;
  intellectThreshold: string;
  dexterity: string;
  dexterityThreshold: string;
};

export type History = {
  id: string;
  name: string;
  owner: string;
  eventType: string;
  eventTime: string;
  tokenId: string;
  rewardType: string;
  invitee: string;
  inviter: string;
  tx: string;
  buyer: string;
  seller: string;
  payment: string;
  priceInPayment: string;
  amount: string;
};

const postQuery = async (endpoint: string, query: string) => {
  const options = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ query }),
  };
  const response = await fetch(endpoint, options);
  const data = await response.json();
  if (data.errors) {
    throw new Error(data.errors[0].message);
  } else {
    return data;
  }
};

const postRequst = async (
  endpoint: string,
  body: {
    [propName: string]: any;
  }
) => {
  const options = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(body),
  };
  const response = await fetch(endpoint, options);
  const data = await response.json();
  if (data.errors) {
    throw new Error(data.errors[0].message);
  } else {
    return data;
  }
};

export async function getOrderList(
  fraction: number[],
  sex: number[],
  princeRange: (CurrencyAmount | undefined)[],
  level: number[],
  order: string,
  size = 10,
  page = 1
): Promise<Order[]> {
  const minPrice = princeRange[0] ? `priceInAcceptToken_gte: "${princeRange[0].raw.toString()}"` : '';
  const maxPrice = princeRange[1] ? `priceInAcceptToken_lte: "${princeRange[1].raw.toString()}"` : '';
  const level_in = level && level.length > 0 ? `level_in: [${level}]` : '';
  const sex_in = sex && sex.length > 0 ? `sex_in: [0,${sex}]` : 'sex_in: [0]';
  const factions_in = fraction && fraction.length > 0 ? `factions_in: [${fraction}]` : '';
  const orderStr = order && order != '' ? `orderBy: priceInAcceptToken,orderDirection: ${order}` : '';
  const query = `
  {
    marketGoodsEntities(
        first: ${size}, skip: ${size * (page - 1)}
        ${orderStr}
        where: {
           ${minPrice}
           ${maxPrice}
           ${level_in}
           ${sex_in}
           ${factions_in}
           onSale:true
        }
    ) {
        id
        level
        priceInAcceptToken
        charisma
        charismaThreshold
        vitality
        vitalityThreshold
        intellect
        intellectThreshold
        dexterity
        dexterityThreshold
        name
        sex
        grade
        factions
    }
}
  `;

  try {
    const response = await postQuery(SUBGRAPH, query);
    return response.data.marketGoodsEntities;
  } catch (error) {
    return [];
  }
}

export async function getAccountNFT(account: string, name?: string): Promise<NFT[]> {
  if (!isAddress(account)) return [];
  const query = `{ lynknftentities(where: {owner: "${account}", ${
    name ? `name_contains_nocase: "${name}"` : ''
  }}, orderBy: createdTime, orderDirection: desc) {  id  createdTime   owner   isList   name    listIndex  level   isStaking  charisma   charismaThreshold   vitality    vitalityThreshold    intellect    intellectThreshold    dexterity    dexterityThreshold  }  userEntities(subgraphError: deny) {    id   level  }}`;

  try {
    const response = await postQuery(SUBGRAPH, query);
    return response.data.lynknftentities;
  } catch (error) {
    return [];
  }
}

export async function getStakingList(account: string): Promise<NFT[]> {
  const query = `{ lynknftentities(where: {owner: "${account}", isStaking: true}) {  id    owner   isList   name    listIndex  level   isStaking  charisma   charismaThreshold   vitality    vitalityThreshold    intellect    intellectThreshold    dexterity    dexterityThreshold  }  userEntities(subgraphError: deny) {    id   level  }}`;
  try {
    const response = await postQuery(SUBGRAPH, query);
    return response.data.lynknftentities;
  } catch (error) {
    return [];
  }
}

export async function getSingleNFT(id: string): Promise<string | undefined> {
  const query = `{ lynknftentities(where: {id: "${id}"}) {  id }  userEntities(subgraphError: deny) {    id  }}`;
  try {
    const response = await postQuery(SUBGRAPH, query);
    return response.data.lynknftentities[0];
  } catch (error) {
    return undefined;
  }
}

export async function getNFT(id: string): Promise<NFT | undefined> {
  const marketQuery = `{ marketGoodsEntities(where: { id: ${id}  }) {id seller index name level priceInAcceptToken acceptToken charisma charismaThreshold vitality vitalityThreshold  intellect intellectThreshold dexterity dexterityThreshold }}`;
  const detailQuery = `{ lynknftentities(where: {id: "${id}"}) {  id createdTime creator sex grade factions  owner   isList   name    listIndex    isStaking  charisma lastTradePrice   charismaThreshold   vitality    vitalityThreshold    intellect    intellectThreshold    dexterity    dexterityThreshold  }  userEntities(subgraphError: deny) {    id   level  }}`;
  try {
    const marketResponse = await postQuery(SUBGRAPH, marketQuery);
    const detailResponse = await postQuery(SUBGRAPH, detailQuery);
    return { ...marketResponse.data.marketGoodsEntities[0], ...detailResponse.data.lynknftentities[0] };
  } catch (error) {
    return undefined;
  }
}

export async function getFloorPrice(): Promise<string | undefined> {
  const marketQuery = `{ marketGoodsEntities(first: 1, where: {onSale:true}, orderBy: priceInAcceptToken, orderDirection: asc) { priceInAcceptToken }}`;
  try {
    const marketResponse = await postQuery(SUBGRAPH, marketQuery);
    return marketResponse?.data?.marketGoodsEntities?.[0]?.priceInAcceptToken;
  } catch (error) {
    return undefined;
  }
}

// export async function getHighestPrice(): Promise<string | undefined> {
//   const marketQuery = `{ marketGoodsEntities(first: 1, where: {onSale:true}, orderBy: priceInAcceptToken, orderDirection: desc) { priceInAcceptToken }}`;
//   try {
//     const marketResponse = await postQuery(SUBGRAPH, marketQuery);
//     return marketResponse?.data?.marketGoodsEntities?.[0]?.priceInAcceptToken;
//   } catch (error) {
//     return undefined;
//   }
// }

export async function getHighestPrice(): Promise<string | undefined> {
  const marketQuery = `{ marketOverviews{
    tradeAmount
    highestPrice
    lowestPrice
    holdersNum
  }}`;
  try {
    const marketResponse = await postQuery(SUBGRAPH, marketQuery);
    return marketResponse?.data?.marketOverviews?.[0]?.highestPrice;
  } catch (error) {
    return undefined;
  }
}

export async function getNFTRanking(
  searchContent: string | undefined,
  sort: 'asc' | 'desc',
  orderBy: 'ranking' | 'level' | 'charisma',
  skip = 0,
  size = 10
): Promise<
  | NFT[]
  | {
      address: string;
      id: string;
      level: number;
    }[]
  | undefined
> {
  if (orderBy === 'ranking') {
    const query = `{userEntities(first: ${size}, skip: ${skip}, where: { ${
      searchContent ? `address: "${searchContent}" ` : ''
    }} orderBy: level, orderDirection: ${sort}) {    id    level  address  }}`;
    try {
      const detailResponse = await postQuery(SUBGRAPH, query);
      return detailResponse.data.userEntities;
    } catch (error) {
      return undefined;
    }
  } else {
    const query = `{ lynknftentities(first: ${size},skip:${skip}, where: {createdTime_gt:1682352000,${
      searchContent ? `name_contains: "${searchContent}" ` : ''
    }} orderBy: ${orderBy},orderDirection:${sort}){
    id
    vitality
    owner
    name
    listIndex
    level
    isStaking
    isList
    intellect
    dexterity
    charisma
  } }`;
    try {
      const detailResponse = await postQuery(SUBGRAPH, query);
      return detailResponse.data.lynknftentities;
    } catch (error) {
      return undefined;
    }
  }
}

export async function getRecordHistory(
  account: string | null | undefined,
  curTab: number,
  size: number,
  page: number,
  usdtAddr: string
): Promise<(string | JSX.Element)[][]> {
  if (!isAddress(account)) return [];
  let sqlQuery;
  const mintHistory = `
  {
    mintLogEntities(first: ${size}, orderBy: eventTime,orderDirection: desc, skip: ${
    size * (page - 1)
  } , where: {owner: "${account}"}) {
      id
      name
      owner
      eventTime
      tx
      payment
      amount
    }
  }
  `;
  const tradeHistory = `
  {
  tradeSearch(first: ${size}, orderBy: eventTime,orderDirection: desc, skip: ${
    size * (page - 1)
  }, text:"${account?.toLowerCase()}" )
      {
          id
          tx
          tokenId
          eventTime
          buyer
          seller
          payment
          priceInPayment
      }
  }
  `;
  const recommendHistory = `
  {
    registerLogEntities(first: ${size}, orderBy: eventTime,orderDirection: desc, skip: ${
    size * (page - 1)
  }  where: {inviter:"${account}"}) {
      id
      inviter
      eventTime
      tx
    }
  }
  `;
  const recoRewardHistory = `
  {
    rewardLogEntities(first: ${size}, orderBy: eventTime,orderDirection: desc, skip: ${
    size * (page - 1)
  }  where: {rewardType: "social",account:"${account}"}) {
      id
      rewardType
      account
      invitee
      eventTime
      tx
      amount
    }
  }
  `;
  const recoSocialHistory = `{
  rewardLogEntities(first: ${size}, orderBy: eventTime,orderDirection: desc, skip: ${
    size * (page - 1)
  }  where: {rewardType: "community",account:"${account}"})
    {
        id
        rewardType
        account
        invitee
        eventTime
        tx
        amount
    }
  }
`;
  const recoContributeHistory = `
  {
  rewardLogEntities(first: ${size}, orderBy: eventTime,orderDirection: desc, skip: ${
    size * (page - 1)
  }  where: {rewardType: "contribution",account:"${account}"})
    {
        id
        rewardType
        account
        invitee
        eventTime
        tx
        amount
    }
}
`;
  const achievement = `
  {
  achievementRewardLogEntities(first: ${size}, orderBy: eventTime,orderDirection: desc, skip: ${
    size * (page - 1)
  }  where: {account:"${account}"}) {
    id
    account
    tokenId
    eventTime
    tx
    amount
    }
  }`;
  const stakeHistory = `
  {
  stakingLogEntities(first: ${size}, orderBy: eventTime,orderDirection: desc, skip: ${
    size * (page - 1)
  }  where: {eventType: "claim", owner:"${account}"}) {
    id
    owner
    eventType
    eventTime
    tx
    amount
  }
}`;
  switch (curTab) {
    case 0: // NFT铸造记录
      sqlQuery = mintHistory;
      break;
    case 1: // NFT交易记录
      sqlQuery = tradeHistory;
      break;
    case 2: // 直推记录
      sqlQuery = recommendHistory;
      break;
    case 3: // 直推奖励记录
      sqlQuery = recoRewardHistory;
      break;
    case 4: // 社区奖励记录
      sqlQuery = recoSocialHistory;
      break;
    case 5: // 贡献奖励记录
      sqlQuery = recoContributeHistory;
      break;
    case 6: // 成就奖励记录
      sqlQuery = achievement;
      break;
    case 7: // 质押奖励记录
      sqlQuery = stakeHistory;
      break;
    default:
      sqlQuery = mintHistory;
  }
  try {
    const response = await postQuery(SUBGRAPH, sqlQuery);
    const resp: History[] =
      response.data.mintLogEntities ||
      response.data.tradeLogEntities ||
      response.data.registerLogEntities ||
      response.data.rewardLogEntities ||
      response.data.achievementRewardLogEntities ||
      response.data.stakingLogEntities ||
      response.data.tradeSearch;
    const result = resp.map((value) => {
      const txLink = getTxLink(value.tx);
      const time = moment(Number(value.eventTime) * 1000).format('YYYY/MM/DD HH:mm:ss');
      switch (curTab) {
        case 0: {
          // NFT铸造记录
          const suffix = value.payment.toLowerCase() == usdtAddr.toLowerCase() ? ' USDT' : ' LRT';
          return [
            time,
            shortenAddress(value.owner),
            new TokenAmount(USDT, value.amount).toFixed(2, { groupSeparator: ',' }) + suffix,
            txLink,
          ];
        }
        case 1: // NFT交易记录
          return [
            time,
            value.tokenId,
            new TokenAmount(USDT, value.priceInPayment).toFixed(2, { groupSeparator: ',' }) + ' USDT',
            shortenAddress(value.buyer),
            shortenAddress(value.seller),
            txLink,
          ];
        case 2: // 直推记录
          return [time, shortenAddress(value.id), txLink];
        case 3: // 直推奖励记录
          return [
            time,
            shortenAddress(value.invitee),
            new TokenAmount(LRT, value.amount).toFixed(2, { groupSeparator: ',' }) + ' LRT',
            txLink,
          ];
        case 4: // 社区奖励记录
          return [
            time,
            shortenAddress(value.invitee),
            new TokenAmount(LRT, value.amount).toFixed(2, { groupSeparator: ',' }) + ' LRT',
            txLink,
          ];
        case 5: // 贡献奖励记录
          return [
            time,
            shortenAddress(value.invitee),
            new TokenAmount(AP, value.amount).toFixed(2, { groupSeparator: ',' }) + ' AP',
            txLink,
          ];
        case 6: // 成就奖励记录
          return [
            time,
            value.tokenId,
            new TokenAmount(AP, value.amount).toFixed(2, { groupSeparator: ',' }) + ' AP',
            txLink,
          ];
        case 7: // 质押奖励记录
          return [time, new TokenAmount(LRT, value.amount).toFixed(2, { groupSeparator: ',' }) + ' LRT', txLink];
        default:
          return [];
      }
    });
    console.log(result);
    return result;
  } catch (error) {
    console.log(error);
    return [];
  }
}

export async function getMarketOverview() {
  const query = `{marketOverview(id:"0"){
    holdersNum
    highestPrice
    lowestPrice
    tradeAmount
  }}`;
  try {
    const res = await postQuery(SUBGRAPH, query);
    return res.data.marketOverview;
  } catch (error) {
    return undefined;
  }
}

export async function getAcaCountReward(account: string, startTime: number, endTime: number) {
  if (!isAddress(account) || !COUNTREWARDAPI) return '';
  const body = { address: account, startTime: startTime, endTime: endTime };
  try {
    const response = await postRequst(COUNTREWARDAPI, body);
    return response?.success ? response.data : '';
  } catch (error) {
    return '';
  }
}

export async function getSumcaCountReward(account: string) {
  return new Promise((resolve) => {
    if (!isAddress(account) || !SUMCACOUNTREWARDAPI) return resolve(null);
    fetch(`${SUMCACOUNTREWARDAPI}/api/v1/GetCharismaStatistics?address=${account}`)
      .then((response) => {
        if (!response.ok) {
          return resolve(null);
        }
        return response.json();
      })
      .then((data: any) => {
        return resolve(data.data);
      })
      .catch((err) => {
        console.log(err);
        return resolve(null);
      });
  });
}
