import React from 'react';
import { styled } from '@mui/material';
import AppBar from 'components/AppBar';
import FinderContent from 'components/FinderContent';
import { useI18n } from 'react-simple-i18n';
import finderBg from 'assets/img/finder/finderBg.png';
import finderMobileBg from 'assets/img/finder/finderMobileBg.png';

const MainContainer = styled('div')(({ theme }) => ({
  display: 'grid',
  height: 'calc(100%)',
  width: `calc(100% - ${theme.spacing(14)})`,
  padding: theme.spacing(0, 7),
  gridTemplateRows: `${theme.spacing(10.5)} 1fr`,
  backgroundImage: `url(${finderBg})`,
  backgroundPosition: 'right bottom',
  backgroundSize: 'contain',
  backgroundRepeat: 'no-repeat',
  [theme.breakpoints.down('sm')]: {
    gridTemplateRows: `${theme.spacing(9)} 1fr`,
    width: `calc(100% - ${theme.spacing(6)})`,
    padding: theme.spacing(0, 3),
    backgroundImage: `url(${finderMobileBg})`,
    overflow: 'overlay',
  },
  gridTemplateColumns: '1fr',
  gridTemplateAreas: `
    'finderT'
    'finderB'
  `,
  overflow: 'hidden',
}));

const Header = styled('div')({
  gridArea: 'finderT',
  position: 'relative',
});

const Finders = () => {
  const { t } = useI18n();

  return (
    <MainContainer>
      <Header>
        <AppBar title={t('finders.title')}></AppBar>
      </Header>
      <FinderContent />
    </MainContainer>
  );
};

export default Finders;
