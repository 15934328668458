export const router = [
  {
    title: 'navMenu.myDashboard',
    path: '/profile',
    children: [
      { title: 'navMenu.nfts', path: '/profile/nfts' },
      { title: 'navMenu.stake', path: '/profile/stake' },
      { title: 'navMenu.invitation', path: '/profile/invitation' },
      { title: 'navMenu.history', path: '/profile/history' },
    ],
  },
  {
    title: 'navMenu.activitiesCampaigns',
    path: '/mint',
    children: [
      { title: 'navMenu.finders', path: '/mint/finders', children: [] },
      { title: 'navMenu.nodeNft', path: '/mint/node-nft', children: [] },
      { title: 'navMenu.leaderNft', path: '/mint/leader-nft', children: [] },
    ],
  },
  { title: 'navMenu.marketPlace', path: '/market-place', children: [] },
  { title: 'navMenu.rank', path: '/rank', children: [] },
  {
    title: 'navMenu.more',
    path: '/more',
    children: [
      { title: 'navMenu.chatRoom', path: '/more/chat-room' },
      { title: 'navMenu.lynkAlliance', path: '/more/lynk-alliance' },
      { title: 'navMenu.domainServer', path: '/more/lynk-server' },
      { title: 'navMenu.feed', path: '/more/feed' },
    ],
  },
];
