import React from 'react';
import { styled } from '@mui/material';
import AppBar from 'components/AppBar';
import { useI18n } from 'react-simple-i18n';
import StakeContent from 'components/StakeContent';
import { Outlet } from 'react-router-dom';

const Container = styled('div')`
  height: 100%;
  width: 100%;
  overflow: hidden;
`;

const MainContainer = styled('div')(({ theme }) => ({
  display: 'grid',
  height: 'calc(100%)',
  width: `calc(100% - ${theme.spacing(14)})`,
  padding: theme.spacing(0, 7),
  gridTemplateRows: `${theme.spacing(10.5)} 1fr`,
  [theme.breakpoints.down('sm')]: {
    gridTemplateRows: `${theme.spacing(9)} 1fr`,
    padding: theme.spacing(0, 3),
    width: `calc(100% - ${theme.spacing(6)})`,
    overflow: 'overlay',
  },
  gridTemplateColumns: '1fr',
  gridTemplateAreas: `
    'stakeT'
    'stakeB'
  `,
}));

const Header = styled('div')({
  gridArea: 'stakeT',
  position: 'relative',
});

const Stake = () => {
  const { t } = useI18n();

  return (
    <Container>
      <Outlet />
      <MainContainer>
        <Header>
          <AppBar title={t('stake.title')}></AppBar>
        </Header>
        <StakeContent />
      </MainContainer>
    </Container>
  );
};

export default Stake;
