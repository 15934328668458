import React, { useState, useCallback, useEffect, useRef } from 'react';
import { styled, Typography, Button, Stack, Box, CircularProgress, Backdrop, Paper, Slide, Chip } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { getNFT, NFT } from 'utils/fetch/graph';
import useAsyncMemo from 'hooks/useAsyncMemo';
import { useI18n } from 'react-simple-i18n';
import { useBlockNumber } from 'hooks/useStoreState';
import moment from 'moment';
import _ from 'lodash';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { AP, BASE_IMAGE_URL, LRT, LYNKNFT_ADDRESS, STAKING_ADDRESS, USDT } from 'constants/index';
import AccelerateProgress from 'components/AccelerateProgress';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import PurchaseApModal from 'components/PurchaseApModal';
import { useAttr, useRecessiveAttrUri, useLevel, useNFTStatus, useVAInfo } from 'hooks/useNFT';
import { Attr } from 'utils/attr';
import { useUserHasSubmitted } from 'hooks/useTransaction';
import { useActiveWeb3React } from 'hooks';
import JSBI from 'jsbi';
import { useReward, useStaking } from 'hooks/useStaking';
import { showTipModal } from 'store/features/componentSlice';
import UpgradeModal from 'components/UpgradeModal';
import { ApprovalState } from 'hooks/useApproveCallback';
import { useERC721ApproveAllCallback } from 'hooks/useERC721ApproveAllCallback';
import { useAppDispatch } from 'hooks/redux';
import BaseTooltip from 'components/BaseTooltip';
import {
  showTransactionConfirmModal,
  showTransactionPendingModal,
  showTransactionErrorModal,
} from 'store/features/componentSlice';
import ClaimModal from 'components/ClaimModal';
import useIntervalFrame from 'hooks/useIntervalFrame';
import MakeOrderModal from 'components/MakeOrderModal';
import { useSwap } from 'hooks/useSwap';
import { getNftMintPrice, shortenAddress } from 'utils';
import { useMarket } from 'hooks/useMarket';
import { TokenAmount } from 'constants/token';
import CONFIG from 'config';
import { useIsNodeNft, useLeaderMinted } from 'hooks/useNFT';
import low from 'assets/img/invitation/low.png';
import middle from 'assets/img/invitation/middle.png';
import high from 'assets/img/invitation/high.png';

const NftDetailBox = styled('div')(({ theme }) => ({
  gridArea: 'detailB',
  display: 'grid',
  gridTemplateRows: `${theme.spacing(4)} ${theme.spacing(61.5)} ${theme.spacing(6)} 1fr`,
  gridTemplateColumns: `${theme.spacing(61.5)} 1fr ${theme.spacing(31)}`,
  gridTemplateAreas: `
    'detailBack detailBack detailBack'
    'detailImage attributes userStats'
    'detailText detailText detailText'
    'detailInfo detailInfo detailInfo'
  `,
  gridGap: '0 50px',
  overflow: 'overlay',
  [theme.breakpoints.between('sm', 'xl')]: {
    zoom: 0.8,
  },
  [theme.breakpoints.down('sm')]: {
    '&::-webkit-scrollbar': {
      width: 0,
    },
    padding: theme.spacing(0, 0, 2),
    overflow: 'unset',
    gridGap: 0,
    gridTemplateRows: `auto ${theme.spacing(10)} auto 1fr ${theme.spacing(55)}`,
    gridTemplateColumns: `1fr`,
    gridTemplateAreas: `
      'userStats'
      'detailBack'
      'detailImage'
      'detailInfo'
      'attributes'
    `,
  },
}));

const DetailBack = styled('div')(({ theme }) => ({
  gridArea: 'detailBack',
  '& .nftName': {
    display: 'none',
  },
  [theme.breakpoints.down('sm')]: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    '& .nftName': {
      display: 'flex',
      justifyContent: 'flex-start',
    },
  },
}));

const DetailImageBox = styled('div')(({ theme }) => ({
  gridArea: 'detailImage',
  position: 'relative',
  [theme.breakpoints.down('sm')]: {
    width: 'calc(100%)',
    height: 'calc(100%)',
  },
}));

const LevelChip = styled(Chip)(({ theme }) => ({
  position: 'absolute',
  top: 0,
  right: 12,
  width: theme.spacing(7),
  height: theme.spacing(3),
  background: '#43434399',
  color: '#D1D1D1',
  fontSize: 13,
  fontWeight: 700,
  [theme.breakpoints.down('sm')]: {
    width: theme.spacing(6),
    height: theme.spacing(2.5),
    fontSize: 12,
  },
}));

const NodeNftLevel = styled('img')(({ theme }) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  width: theme.spacing(5),
  height: theme.spacing(5),
}));

const RowStack = styled(Stack)({
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
});

const TypographyHelp = styled(Typography)({
  height: 14,
  width: 14,
  borderRadius: 7,
  border: '0.5px solid #000',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  cursor: 'default',
});

const DetailImage = styled('img')(() => ({
  width: '100%',
  height: '100%',
  borderRadius: 7,
  cursor: 'pointer',
}));

const PreviewImage = styled('img')(({ theme }) => ({
  height: '100%',
  [theme.breakpoints.down('sm')]: {
    width: '100%',
    objectFit: 'contain',
  },
}));

const AttributesArea = styled('div')(() => ({
  gridArea: 'attributes',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}));

const AttributesBox = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
  width: theme.spacing(45),
  height: theme.spacing(54.5),
  [theme.breakpoints.down('sm')]: {
    width: '100%',
  },
}));

const UserStatsArea = styled('div')(() => ({
  gridArea: 'userStats',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  // justifyContent: 'flex-end',
  // [theme.breakpoints.down('sm')]: {
  //   justifyContent: 'center',
  //   alignItems: 'center',
  // },
}));

const UserStatsBox = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  width: '100%',
  height: theme.spacing(54.5),
  gap: theme.spacing(3.5),
  [theme.breakpoints.down('sm')]: {
    gap: theme.spacing(2),
    height: 'auto',
  },
}));

const SellStakeButton = styled(Button)(({ theme }) => ({
  width: '100%',
  height: theme.spacing(4),
  background: 'linear-gradient(0deg, #72BCDE, #72BCDE)',
  fontSize: 13,
  fontWeight: 700,
  color: '#FFFFF8',
  borderRadius: 7,
  '&.outlined': {
    background: '#fff',
    border: '2px solid #79BFE0',
    color: '#202F44',
  },
  // justifyContent: 'flex-end',
  // [theme.breakpoints.down('sm')]: {
  //   justifyContent: 'center',
  //   alignItems: 'center',
  // },
}));

const TypographyTotal = styled(Typography)(({ theme }) => ({
  fontSize: 32,
  fontWeight: 700,
  color: '#6BB9DD',
  [theme.breakpoints.down('sm')]: {
    fontSize: 25,
  },
}));

const TypographyTotalDes = styled(Typography)(({ theme }) => ({
  fontSize: 13,
  fontWeight: 500,
  color: '#000000',
  [theme.breakpoints.down('sm')]: {
    fontSize: 12,
  },
}));

const PaperBox = styled(Paper)(({ theme }) => ({
  width: theme.spacing(13),
  height: theme.spacing(7.5),
  border: '2px solid #000000',
  borderRadius: 7,
  display: 'flex',
  alignItems: 'center',
  [theme.breakpoints.down('sm')]: {
    width: '45%',
    height: theme.spacing(5),
    '& .totalDes': {
      display: 'flex',
      gap: 4,
    },
  },
}));

const StakeStack = styled(Stack)(({ theme }) => ({
  width: '100%',
  height: theme.spacing(8.5),
  borderRadius: 18,
  background: '#E9E9E9',
}));

const TypographyStakeNum = styled(Typography)(({ theme }) => ({
  fontSize: 25,
  fontWeight: 700,
  lineHeight: theme.spacing(4),
  color: '#78BEDF',
  marginTop: '0px !important',
  [theme.breakpoints.down('sm')]: {
    fontSize: 25,
  },
}));

const TypographyStakeNumDes = styled(Typography)<{ isAComponent?: boolean }>(({ theme, isAComponent }) => ({
  fontSize: 13,
  fontWeight: 500,
  color: isAComponent ? `${theme.textColor.text3}` : '#8B8B8B',
  cursor: isAComponent ? 'pointer' : 'default',
  lineHeight: theme.spacing(3),
  marginTop: 0,
  '& span': {
    paddingRight: theme.spacing(1.5),
    background: 'linear-gradient(75deg, #7ff3f966 0, #7ff3f966 92%, #fff 50%)',
  },
  [theme.breakpoints.down('sm')]: {
    fontSize: 12,
    paddingRight: theme.spacing(1),
  },
}));

const DetailArea = styled('div')(({ theme }) => ({
  gridArea: 'detailText',
  display: 'flex',
  alignItems: 'center',
  fontSize: 30,
  fontWeight: 700,
  [theme.breakpoints.down('sm')]: {
    display: 'none',
  },
}));

const DetailInfoArea = styled('div')(({ theme }) => ({
  gridArea: 'detailInfo',
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(4),
  minHeight: theme.spacing(25),
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    minHeight: theme.spacing(21),
    marginBottom: 16,
    '& .moreDetail': {
      display: 'none',
    },
  },
}));

const DetailPaper = styled(Paper)(({ theme }) => ({
  width: theme.spacing(58.5),
  height: `calc(100% - ${theme.spacing(4)})`,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'space-between',
  border: '1px solid #7FF3F9',
  borderRadius: 10,
  padding: theme.spacing(0.5, 1.5),
  '&.moreDetail': {
    // paddingTop: theme.spacing(4),
    // gap: theme.spacing(1),
    justifyContent: 'start',
  },
  [theme.breakpoints.down('sm')]: {
    width: `calc(100% - ${theme.spacing(4)})`,
    borderTop: 'none',
    height: 'auto',
    borderRadius: '0 0 10px 10px',
  },
}));

const MoreDetailsTypography = styled(Typography)(({ theme }) => ({
  fontSize: 12,
  fontWeight: 700,
  color: '#78BEDF',
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  gap: 4,
  [theme.breakpoints.up('sm')]: {
    display: 'none',
  },
}));

const NftDetail = ({
  tokenId,
  ownNftNum,
  stakeNftNum,
}: {
  tokenId: string | undefined;
  ownNftNum: number;
  stakeNftNum: number;
}) => {
  const { t, i18n } = useI18n();
  const { stake, withdraw } = useStaking();
  const { account } = useActiveWeb3React();
  const blockNumber = useBlockNumber();
  const navigate = useNavigate();
  const { charisma, vitality, intellect, dexterity } = useAttr(tokenId || '');
  const level = useLevel(tokenId || '');
  const [openMore, setOpenMore] = useState(false);
  const [imagePreview, setImagePreview] = useState(false);
  const [showPurchaseApModal, setShowPurchaseApModal] = useState(false);
  const [openUpModal, setOpenUpModal] = useState(false);
  const [openClaimModal, setOpenClaimModal] = useState(false);
  const [upData, setUpData] = useState({
    max: '',
    tokens: [USDT],
    tokenId: '',
    attr: Attr.CA,
    title: '',
    levelMax: 0,
    value: 0,
  });
  const { isMine, staked, listed } = useNFTStatus(tokenId || '');
  const { reward, duration, lastUpdateTime } = useReward(tokenId || '');
  const upgradeBle = isMine && !staked && !listed;
  const { maxValue } = useVAInfo(tokenId || '');
  const maxValueNum = Number(maxValue.toString());
  const dispatch = useAppDispatch();
  const lastUpdateTimeRef = useRef<string>('');
  const [claimTime, setClaimTime] = useState(0);
  const [estimatePrice, setEstimatePrice] = useState('--');
  const [openMakeModal, setOpenMakeModal] = useState(false);
  const [approvalState, approveCallback] = useERC721ApproveAllCallback(LYNKNFT_ADDRESS, STAKING_ADDRESS);
  const {
    timeInfo: { hours, minutes, seconds },
  } = useIntervalFrame({
    delay: 1000,
    endTime: claimTime,
  });
  const { price } = useSwap();
  const { cancel } = useMarket();
  const isNodeNft = useIsNodeNft(tokenId || '');
  const isLeaderNft = useLeaderMinted(account || '');
  const recessiveAttrUri = useRecessiveAttrUri(tokenId || '');
  const [recessiveAttr, setRecessiveAttr] = useState<{ trait_type: string; value: string }[]>([]);

  const { submitted: staking } = useUserHasSubmitted(`${account}_staking_${tokenId}`);
  const { submitted: selling } = useUserHasSubmitted(`${account}_selling_${tokenId}`);
  const { submitted: withdrawing } = useUserHasSubmitted(`${account}_withdraw_${tokenId}`);
  const { submitted: canceling } = useUserHasSubmitted(`${account}_cancel_${tokenId}`);
  const { submitted: charismaUpgrade } = useUserHasSubmitted(`${account}_upgrade0_${tokenId}`);
  const { submitted: vitalityUpgrade } = useUserHasSubmitted(`${account}_upgrade1_${tokenId}`);
  const { submitted: intellectUpgrade } = useUserHasSubmitted(`${account}_upgrade2_${tokenId}`);
  const { submitted: dexterityUpgrade } = useUserHasSubmitted(`${account}_upgrade3_${tokenId}`);

  const func = useCallback(async () => {
    if (!tokenId) return undefined;
    return await getNFT(tokenId);
  }, [tokenId]);

  const nft: NFT | undefined = useAsyncMemo(func, undefined, [blockNumber]);

  const upgradeCallback = useCallback(
    (attr: Attr) => {
      if (attr === Attr.CA) {
        setUpData({
          max: JSBI.subtract(JSBI.BigInt(charisma.max), JSBI.BigInt(charisma.value)).toString(),
          tokens: [USDT, LRT],
          tokenId: tokenId || '',
          attr: Attr.CA,
          title: t('nftDetail.caUpgrade'),
          levelMax: charisma.levelMax,
          value: charisma.value,
        });
        setOpenUpModal(true);
      }
      if (attr === Attr.VA) {
        const { levelMax, value } = vitality;
        if (maxValueNum && levelMax - value > 0) {
          setUpData({
            max: JSBI.subtract(JSBI.BigInt(vitality.max), JSBI.BigInt(vitality.value)).toString(),
            tokens: [AP],
            tokenId: tokenId || '',
            attr: Attr.VA,
            title: t('nftDetail.vaUpgrade'),
            levelMax: vitality.levelMax,
            value: vitality.value,
          });
          setOpenUpModal(true);
        } else if (!maxValueNum) {
          dispatch(showTipModal(t('nftDetail.oneDayMaxVA')));
        } else {
          dispatch(showTipModal(`${t('rank.level')} ${t('nftDetail.upLimit')} ${Number(level.toString()) + 1}`));
        }
      }
      if (attr === Attr.IN) {
        if (charisma.levelMax > charisma.value) {
          dispatch(showTipModal(`CA ${t('nftDetail.upLimit')} ${charisma.levelMax}`));
        } else if (vitality.levelMax > vitality.value) {
          dispatch(showTipModal(`VA ${t('nftDetail.upLimit')} ${vitality.levelMax}`));
        } else if (intellect.value >= intellect.levelMax) {
          dispatch(showTipModal(`${t('rank.level')} ${t('nftDetail.upLimit')} ${Number(level.toString()) + 1}`));
        } else {
          setUpData({
            max: JSBI.subtract(JSBI.BigInt(intellect.max), JSBI.BigInt(intellect.value)).toString(),
            tokens: [AP],
            tokenId: tokenId || '',
            attr: Attr.IN,
            title: t('nftDetail.inUpgrade'),
            levelMax: intellect.levelMax,
            value: intellect.value,
          });
          setOpenUpModal(true);
        }
      }
      if (attr === Attr.DX) {
        if (intellect.levelMax > intellect.value) {
          dispatch(showTipModal(`IN ${t('nftDetail.upLimit')} ${intellect.levelMax}`));
        } else {
          setUpData({
            max: JSBI.subtract(JSBI.BigInt(dexterity.max), JSBI.BigInt(dexterity.value)).toString(),
            tokens: [AP],
            tokenId: tokenId || '',
            attr: Attr.DX,
            title: t('nftDetail.dxUpgrade'),
            levelMax: dexterity.levelMax,
            value: dexterity.value,
          });
          setOpenUpModal(true);
        }
      }
    },
    [
      charisma.levelMax,
      charisma.max,
      charisma.value,
      dexterity.levelMax,
      dexterity.max,
      dexterity.value,
      dispatch,
      intellect.levelMax,
      intellect.max,
      intellect.value,
      level,
      maxValueNum,
      t,
      tokenId,
      vitality,
    ]
  );

  const stakeCallback = useCallback(async () => {
    dispatch(showTransactionPendingModal());
    stake(tokenId || '')
      .then(() => {
        dispatch(showTransactionConfirmModal());
      })
      .catch((err: any) => {
        dispatch(
          showTransactionErrorModal(
            err.error && err.error.message
              ? err.error.message
              : err?.data?.message
              ? err?.data?.message
              : err.message ?? t('global.networkError')
          )
        );
        console.error(err);
      });
  }, [dispatch, stake, t, tokenId]);

  const withdrawCallback = useCallback(async () => {
    dispatch(showTransactionPendingModal());
    withdraw(tokenId || '')
      .then(() => {
        dispatch(showTransactionConfirmModal());
      })
      .catch((err: any) => {
        dispatch(
          showTransactionErrorModal(
            err.error && err.error.message
              ? err.error.message
              : err?.data?.message
              ? err?.data?.message
              : err.message ?? t('global.networkError')
          )
        );
        console.error(err);
      });
  }, [dispatch, withdraw, tokenId, t]);

  const cancelCallback = useCallback(async () => {
    dispatch(showTransactionPendingModal());
    cancel(tokenId || '')
      .then(() => {
        dispatch(showTransactionConfirmModal());
      })
      .catch((err: any) => {
        dispatch(
          showTransactionErrorModal(
            err.error && err.error.message
              ? err.error.message
              : err?.data?.message
              ? err?.data?.message
              : err.message ?? t('global.networkError')
          )
        );
      });
  }, [cancel, dispatch, t, tokenId]);

  const unstakeAndSell = () => {
    if (staked) {
      withdrawCallback();
    } else {
      if (!level || JSBI.lessThan(JSBI.BigInt(level), JSBI.BigInt(2))) {
        dispatch(showTipModal(t('nftDetail.mustHigherThanLevel2')));
        return;
      }
      setOpenMakeModal(true);
    }
  };

  const stakeAndClaimAp = () => {
    if (staked) {
      if (!reward || reward.equalTo('0')) {
        dispatch(showTipModal(t('nftDetail.noReward')));
        return;
      }
      setOpenClaimModal(true);
    } else {
      approvalState === ApprovalState.NOT_APPROVED ? approveCallback() : stakeCallback();
    }
  };

  useEffect(() => {
    if (
      duration?.toString() &&
      lastUpdateTime?.toString() &&
      lastUpdateTime?.toString() !== lastUpdateTimeRef?.current
    ) {
      lastUpdateTimeRef.current = lastUpdateTime?.toString();
      const timeLeft =
        moment(lastUpdateTime * 1000)
          .add(1, 'days')
          .valueOf() -
        duration * 1000;
      setClaimTime(timeLeft > 0 ? timeLeft : 0);
    }
  }, [duration, lastUpdateTime]);

  useEffect(() => {
    if (
      charisma.value.toString() &&
      vitality.value.toString() &&
      intellect.value.toString() &&
      dexterity.value.toString() &&
      price &&
      tokenId
    ) {
      if (isNodeNft && isLeaderNft && nft) {
        const dayDiff = moment().diff(moment(Number(nft.createdTime) * 1000), 'days');
        const dayDiffDiscount = dayDiff < 16 ? 0.8 : dayDiff < 31 ? 0.9 : 1;
        if (isNodeNft.toString() === '0' && isLeaderNft.toString() === '0') {
          const nftPrice = getNftMintPrice(tokenId);
          if (nftPrice?.toString()) {
            const etPrice = Number(
              Number(
                (charisma.value +
                  nftPrice +
                  (vitality.value + intellect.value + dexterity.value) * 30 * (1 / Number(price?.toSignificant()))) *
                  dayDiffDiscount
              ).toFixed(4)
            ).toString();
            setEstimatePrice(etPrice);
          } else {
            setEstimatePrice('--');
          }
        } else {
          const nodePrice =
            isNodeNft.toString() === '1'
              ? 200
              : isNodeNft.toString() === '2'
              ? 1000
              : isNodeNft.toString() === '3'
              ? 2000
              : 0;
          setEstimatePrice(((Number(charisma.value) - nodePrice) * dayDiffDiscount).toFixed(4).toString());
        }
      } else {
        setEstimatePrice('--');
      }
    } else {
      setEstimatePrice('--');
    }
  }, [charisma.value, dexterity.value, intellect.value, price, tokenId, vitality.value, nft, isNodeNft, isLeaderNft]);

  useEffect(() => {
    if (recessiveAttrUri) {
      fetch(recessiveAttrUri)
        .then((res) => res.clone().json())
        .then((json) => {
          const attributes = json?.attributes || [];
          setRecessiveAttr(attributes);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [recessiveAttrUri]);

  return (
    <NftDetailBox>
      <MakeOrderModal
        tokenId={tokenId || ''}
        estimatePrice={estimatePrice}
        open={openMakeModal}
        handleClose={() => setOpenMakeModal(false)}
      />
      <ClaimModal open={openClaimModal} tokenId={tokenId || ''} handleClose={() => setOpenClaimModal(false)} />
      <PurchaseApModal open={showPurchaseApModal} handleClose={() => setShowPurchaseApModal(false)} />
      {openUpModal && (
        <UpgradeModal
          isOpen={openUpModal}
          handleClose={() => setOpenUpModal(false)}
          max={upData.max}
          tokens={upData.tokens}
          tokenName={nft?.name || ''}
          tokenId={upData.tokenId}
          attr={upData.attr}
          title={upData.title}
          levelMax={upData.levelMax}
          value={upData.value}
        />
      )}
      <DetailBack>
        <Typography className="nftName" sx={{ fontSize: 20 }}>
          {nft?.name}
        </Typography>
        <Button
          variant="text"
          disableRipple
          sx={{ color: '#AEAEAE', '&:hover': { bgcolor: '#fff' } }}
          size="small"
          onClick={() => navigate(-1)}
          startIcon={<KeyboardBackspaceIcon />}
        >
          {t('nftDetail.back')}
        </Button>
      </DetailBack>
      <DetailImageBox onClick={() => setImagePreview(true)}>
        <DetailImage src={`${BASE_IMAGE_URL}${tokenId}.png`} />
        <LevelChip label={`LV ${level || '--'}`} size="small" />
        {isNodeNft && _.includes(['1', '2', '3'], isNodeNft?.toString()) && (
          <NodeNftLevel src={isNodeNft?.toString() === '1' ? low : isNodeNft?.toString() === '2' ? middle : high} />
        )}
      </DetailImageBox>
      <Backdrop
        sx={(theme) => ({
          padding: theme.spacing(5, 3),
          zIndex: theme.zIndex.drawer + 1,
          [theme.breakpoints.down('sm')]: { padding: theme.spacing(3) },
        })}
        open={imagePreview}
        onClick={() => setImagePreview(false)}
      >
        <PreviewImage onClick={() => setImagePreview(false)} src={`${BASE_IMAGE_URL}${tokenId}.png`} />
      </Backdrop>
      <AttributesArea>
        <AttributesBox>
          <RowStack height={40} width={'100%'}>
            <RowStack height={40}>
              <Typography sx={(theme) => ({ fontSize: 30, [theme.breakpoints.down('sm')]: { fontSize: 20 } })}>
                {t('nftDetail.attributes')}
              </Typography>
              <BaseTooltip title={t('nftDetail.leveltooltip')}>
                <TypographyHelp alignSelf="flex-start">{'?'}</TypographyHelp>
              </BaseTooltip>
            </RowStack>
            {isMine && (
              <Button
                onClick={() => setShowPurchaseApModal(true)}
                size="small"
                sx={{ color: '#fff', height: 26, padding: '0 20px', whiteSpace: 'nowrap' }}
                color="secondary"
                variant="contained"
              >
                {t('nftDetail.purchaseAp')}
              </Button>
            )}
          </RowStack>
          <RowStack width={'100%'}>
            <AccelerateProgress
              upgradeBle={upgradeBle}
              onUpgrade={() => upgradeCallback(Attr.CA)}
              title={'charisma'}
              current={charisma.value}
              max={charisma.max}
              levelMax={charisma.levelMax}
              upgrading={charismaUpgrade}
            />
            <AccelerateProgress
              upgradeBle={upgradeBle}
              onUpgrade={() => upgradeCallback(Attr.VA)}
              title={'vitality'}
              current={vitality.value}
              max={vitality.max}
              levelMax={vitality.levelMax}
              upgrading={vitalityUpgrade}
            />
          </RowStack>
          <RowStack width={'100%'}>
            <AccelerateProgress
              upgradeBle={upgradeBle}
              onUpgrade={() => upgradeCallback(Attr.IN)}
              title={'intellect'}
              current={intellect.value}
              max={intellect.max}
              levelMax={intellect.levelMax}
              upgrading={intellectUpgrade}
            />
            <AccelerateProgress
              upgradeBle={upgradeBle}
              onUpgrade={() => upgradeCallback(Attr.DX)}
              title={'dexterity'}
              current={dexterity.value}
              max={dexterity.max}
              levelMax={dexterity.levelMax}
              upgrading={dexterityUpgrade}
            />
          </RowStack>
        </AttributesBox>
      </AttributesArea>
      <UserStatsArea>
        <UserStatsBox>
          <Typography sx={(theme) => ({ fontSize: 30, [theme.breakpoints.down('sm')]: { fontSize: 20 } })}>
            {t('nftDetail.userStats')}
          </Typography>
          <RowStack width={'100%'}>
            <PaperBox>
              <Stack direction="row" padding={1} alignItems="center" spacing={1}>
                <TypographyTotal variant="body1">{ownNftNum || 0}</TypographyTotal>
                <Box className="totalDes">
                  <TypographyTotalDes variant="body1">{t('nftDetail.nfts')}</TypographyTotalDes>
                  <TypographyTotalDes variant="body1" sx={{ zoom: i18n.getLang() === 'ruRU' ? 0.8 : 1 }}>
                    {t('nfts.owned')}
                  </TypographyTotalDes>
                </Box>
              </Stack>
            </PaperBox>
            <PaperBox>
              <Stack direction="row" padding={1} alignItems="center" spacing={1}>
                <TypographyTotal variant="body1">{stakeNftNum || 0}</TypographyTotal>
                <Box className="totalDes">
                  <TypographyTotalDes variant="body1">{t('nftDetail.nfts')}</TypographyTotalDes>
                  <TypographyTotalDes variant="body1" sx={{ zoom: i18n.getLang() === 'ruRU' ? 0.8 : 1 }}>
                    {t('nfts.staked')}
                  </TypographyTotalDes>
                </Box>
              </Stack>
            </PaperBox>
          </RowStack>
          {isMine && listed && (
            <>
              <SellStakeButton className={'outlined'} onClick={cancelCallback}>
                {canceling ? (
                  <CircularProgress size={16} sx={{ color: staked ? '#00000042' : '#fff', marginRight: 1.5 }} />
                ) : null}
                {t(canceling ? 'nftDetail.unListing' : 'nftDetail.unList')}
              </SellStakeButton>
            </>
          )}
          {!listed && isMine && (
            <>
              <SellStakeButton className={staked ? 'outlined' : ''} onClick={unstakeAndSell}>
                {withdrawing || selling ? (
                  <CircularProgress size={16} sx={{ color: staked ? '#00000042' : '#fff', marginRight: 1.5 }} />
                ) : null}
                {t(staked ? 'nftDetail.unStake' : selling ? 'nftDetail.onSale' : 'nftDetail.sell')}
              </SellStakeButton>
              <SellStakeButton className={staked ? 'outlined' : ''} onClick={stakeAndClaimAp}>
                {approvalState === ApprovalState.PENDING || staking ? (
                  <CircularProgress size={16} sx={{ color: staked ? '#00000042' : '#fff', marginRight: 1.5 }} />
                ) : null}
                {t(staked ? 'nftDetail.claimAp' : 'navMenu.stake')}
              </SellStakeButton>
            </>
          )}
          {isMine && staked && (
            <>
              <StakeStack alignItems="center" justifyContent="center">
                <TypographyStakeNum>{reward ? reward.toFixed(2, { groupSeparator: ',' }) : '--'}</TypographyStakeNum>
                <TypographyStakeNumDes sx={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
                  {t('nftDetail.apReward')}
                  <BaseTooltip title={t('nftDetail.apRewardtooltip')}>
                    <TypographyHelp style={{ borderColor: '#E9E9E9' }} alignSelf="flex-start">
                      {<TypographyHelp sx={{ zoom: 0.8 }}>{'?'}</TypographyHelp>}
                    </TypographyHelp>
                  </BaseTooltip>
                </TypographyStakeNumDes>
              </StakeStack>
              <StakeStack alignItems="center" justifyContent="center">
                <TypographyStakeNum>{`${hours}h : ${minutes}m : ${seconds}s`}</TypographyStakeNum>
                <TypographyStakeNumDes>{t('nftDetail.stakingTime')}</TypographyStakeNumDes>
              </StakeStack>
            </>
          )}
        </UserStatsBox>
      </UserStatsArea>
      <DetailArea>{t('nftDetail.details')}</DetailArea>
      <DetailInfoArea>
        <DetailPaper elevation={3}>
          <RowStack width={'100%'}>
            <TypographyStakeNumDes>{'TokenId'}</TypographyStakeNumDes>
            <TypographyStakeNumDes>{tokenId}</TypographyStakeNumDes>
          </RowStack>
          <RowStack width={'100%'}>
            <TypographyStakeNumDes>{t('nftDetail.currentHolder')}</TypographyStakeNumDes>
            <TypographyStakeNumDes
              isAComponent={true}
              onClick={() => nft?.owner && window.open(`${CONFIG.blockExplorerUrls}/address/${nft?.owner}`)}
            >
              {nft?.owner ? shortenAddress(nft.owner) : '--'}
            </TypographyStakeNumDes>
          </RowStack>
          <RowStack width={'100%'}>
            <TypographyStakeNumDes>{t('nftDetail.mintTime')}</TypographyStakeNumDes>
            <TypographyStakeNumDes>
              {nft?.createdTime ? moment(Number(nft.createdTime) * 1000).format('YYYY/MM/DD HH:mm:ss') : '--'}
            </TypographyStakeNumDes>
          </RowStack>
          <RowStack width={'100%'}>
            <TypographyStakeNumDes>{t('nftDetail.mintAddress')}</TypographyStakeNumDes>
            <TypographyStakeNumDes
              isAComponent={true}
              onClick={() => nft?.creator && window.open(`${CONFIG.blockExplorerUrls}/address/${nft?.creator}`)}
            >
              {nft?.creator ? shortenAddress(nft.creator) : '--'}
            </TypographyStakeNumDes>
          </RowStack>
          <RowStack width={'100%'}>
            <TypographyStakeNumDes>{t('nftDetail.estimatedPrice')}</TypographyStakeNumDes>
            <TypographyStakeNumDes>{estimatePrice ? `${estimatePrice} U` : '--'}</TypographyStakeNumDes>
          </RowStack>
          <RowStack width={'100%'}>
            <TypographyStakeNumDes>{t('nftDetail.endPrice')}</TypographyStakeNumDes>
            <TypographyStakeNumDes>
              {nft?.lastTradePrice
                ? `${TokenAmount.ether(nft.lastTradePrice).toFixed(2, { groupSeparator: ',' })} U`
                : '--'}
            </TypographyStakeNumDes>
          </RowStack>
          <RowStack width={'100%'}>
            <TypographyStakeNumDes>{t('nftDetail.contractAddress')}</TypographyStakeNumDes>
            <TypographyStakeNumDes
              isAComponent={true}
              onClick={() => LYNKNFT_ADDRESS && window.open(`${CONFIG.blockExplorerUrls}/address/${LYNKNFT_ADDRESS}`)}
            >
              {LYNKNFT_ADDRESS ? shortenAddress(LYNKNFT_ADDRESS) : '--'}
            </TypographyStakeNumDes>
          </RowStack>
          {recessiveAttr.length > 0 && (
            <MoreDetailsTypography onClick={() => setOpenMore(!openMore)}>
              {`${t('navMenu.more')} ${t('nftDetail.details')} `}
              {openMore ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </MoreDetailsTypography>
          )}
          <Slide direction="down">
            {openMore ? (
              <>
                {recessiveAttr.map((item, index) => {
                  return (
                    <RowStack key={`${item?.trait_type}${index}`} width={'100%'}>
                      <TypographyStakeNumDes>
                        <span>{item.trait_type}</span>
                      </TypographyStakeNumDes>
                      <TypographyStakeNumDes>{item.value}</TypographyStakeNumDes>
                    </RowStack>
                  );
                })}
              </>
            ) : (
              <></>
            )}
          </Slide>
        </DetailPaper>
        {recessiveAttr.length > 0 && (
          <DetailPaper className="moreDetail" sx={{ border: 'none', width: 'fit-content' }} elevation={0}>
            {_.slice(recessiveAttr, 0, 7).map((item, index) => {
              return (
                <RowStack key={`${item?.trait_type}${index}`} sx={{ gap: '10px', width: '100%' }}>
                  <TypographyStakeNumDes>
                    <span>{item.trait_type}</span>
                  </TypographyStakeNumDes>
                  <TypographyStakeNumDes>{item.value}</TypographyStakeNumDes>
                </RowStack>
              );
            })}
          </DetailPaper>
        )}
        {recessiveAttr.length > 7 && (
          <DetailPaper className="moreDetail" sx={{ border: 'none', width: 'fit-content' }} elevation={0}>
            {_.slice(recessiveAttr, 7).map((item, index) => {
              return (
                <RowStack key={`${item?.trait_type}${index}`} sx={{ gap: '10px', width: '100%' }}>
                  <TypographyStakeNumDes>
                    <span>{item.trait_type}</span>
                  </TypographyStakeNumDes>
                  <TypographyStakeNumDes>{item.value}</TypographyStakeNumDes>
                </RowStack>
              );
            })}
          </DetailPaper>
        )}
      </DetailInfoArea>
    </NftDetailBox>
  );
};

export default NftDetail;
