export default {
  global: {
    connectWallet: 'Connect Wallet',
    swapLynk: 'SWAP $LYNK',
    connectToWallet: 'Connect to Wallet',
    metamask: 'METAMASK',
    walletConnect: 'WALLET CONNECT',
    coinbaseWallet: 'COINBASE WALLET',
    walletDes: '*You consent to the Terms of Service and Privacy Policy of Lynkverse by linking a wallet.',
    connecting: 'Connecting...',
    connectedWallet: 'Connected with %s%',
    wrongNetwork: 'Wrong Network',
    error: 'Error',
    myWallet: 'MY WALLET',
    myAddress: 'My Address',
    addToken: 'add token',
    crossChain: 'cross chain',
    logout: 'LOG OUT',
    change: 'CHANGE',
    recentTransactions: 'Recent Transactions',
    clearAll: 'Clear All',
    confirm: 'CONFIRM',
    confirmed: 'Confirmed',
    balance: 'Balance',
    confirmTransaction: 'Please confirm this transaction in your wallet',
    viewOnChain: 'View on %s%',
    invitationLink: 'Invitation Link',
    invitationCode: 'Invitation Code',
    copy: 'COPY',
    approve: 'Approve',
    insufficient: 'Insufficient balance',
    tip: 'Tip',
    drop: 'Drop',
    swap: 'Swap',
    register: 'Register',
    networkError: 'Network Error',
    initializing: 'Initializing...',
    errorConnecting: 'Error Connecting',
    errorConnectingDes: 'Error connecting. Try refreshing the page.',
    binanceSmartChain: 'Binance Smart Chain',
    connectTip: 'Please connect to the',
    ethereumMainnet: 'ethereumMainnet',
    connect: 'Connect to',
    tryAgain: 'Try Again',
    changeWallet: 'Change Wallet',
    exchangeRate: 'Exchange Rate',
    approving: 'Approving',
    swapTitle: 'SWAP LYNK',
    registerTitle: 'INSERT YOUR INVITATION CODE',
    registerPlh: 'Please enter your code here',
    registerDes: 'TIP: You may go to Lynk’s Discord to retrieve your invitation code.',
    unValidCode: 'Invalid registration code',
    registerInviterDes: 'You have not registered yet, pleases register',
    transactionsDes: 'Your transactions will appear here...',
    finish: 'Finish',
    minting: 'Minting',
    copied: 'COPIED',
    comingSoon: 'Coming Soon',
  },
  navMenu: {
    myDashboard: 'MY DASHBOARD',
    nfts: 'NFTs',
    stake: 'STAKE',
    invitation: 'INVITATION',
    history: 'HISTORY',
    activitiesCampaigns: 'ACTIVITIES & CAMPAIGNS',
    nodeNft: 'Node NFT Program',
    leaderNft: 'LEADER NFT Program',
    finders: 'Finders NFT Mint',
    marketPlace: 'MARKETPLACE',
    rank: 'RANK',
    more: 'MORE',
    chatRoom: 'CHAT ROOM',
    lynkAlliance: 'LYNK ALLIANCE',
    domainServer: 'LYNK Domain Name Server',
    feed: 'FEED',
  },
  nfts: {
    title: 'USER NFTs',
    mintKeeper: 'MINT KEEPER',
    buyKeeper: 'BUY KEEPER',
    freeMint: 'FREE MINT',
    owned: 'owned',
    staked: 'staked',
    sell: 'Sell',
    stake: 'Stake',
    mintKeeperNft: 'MINT KEEPER NFT',
    nftName: 'NFT name',
    selectGender: 'Select gender',
    male: 'Male',
    female: 'Female',
    selectFaction: 'Select faction',
    spirits: 'Spirits',
    martials: 'Martials',
    exos: 'Exos',
    voids: 'Voids',
    selectNft: 'SELECT NFT',
    common: 'COMMON',
    special: 'SPECIAL',
    rare: 'RARE',
    reSelect: 'Re-select',
    mint: 'MINT',
    proceed: 'PROCEED',
    enterName: 'Enter name',
    nameUsed: 'Name occupied',
    cancel: 'Cancel',
    claim: 'Claim',
    freeMintEnd: 'SALES ENDING IN',
    days: 'DAYS',
    hours: 'HRS',
    minutes: 'MINS',
    seconds: 'SECS',
  },
  nftDetail: {
    back: 'BACK',
    attributes: 'ATTRIBUTES',
    leveltooltip: 'Accelerate your NFT attributes to level up and earn more staking rewards!',
    purchaseAp: 'PURCHASE AP',
    userStats: 'USER STATS',
    nfts: 'NFTs',
    charisma: 'Charisma',
    dexterity: 'Dexterity',
    intellect: 'Intellect',
    vitality: 'Vitality',
    accelerate: 'ACCELERATE',
    details: 'DETAILS',
    currentHolder: 'Current Holder',
    mintTime: 'Mint Time',
    mintAddress: 'Mint Address',
    estimatedPrice: 'Estimated Price',
    endPrice: 'End Price',
    role: 'Role',
    contractAddress: 'Contract Address',
    tokenID: 'Token ID',
    tokenStandard: 'Token Standard',
    chain: 'Chain',
    background: 'Background',
    effect: 'Effect',
    body: 'Body',
    clothing: 'Clothing',
    head: 'Head',
    weapon: 'Weapon',
    bodyAttachment: 'Body Attachment',
    artifact: 'Artifact',
    purchaseAP: 'Purchase AP',
    balance: 'Balance',
    amount: 'Amount',
    price: 'Price',
    buy: 'Buy',
    caUpgrade: 'CA UPGRADE',
    vaUpgrade: 'VA UPGRADE',
    inUpgrade: 'IN UPGRADE',
    dxUpgrade: 'DX UPGRADE',
    max: 'Max',
    unStake: 'UNSTAKE',
    claimAp: 'CLAIM AP',
    apReward: 'AP Reward',
    apRewardtooltip:
      'Only NFTs Level 2 and above are entitled to receive Achievement Reward. Members are required to claim the rewarded AP for each day to resume the next counting cycle.\nFailure to do so will result to the AP to remain at 1, despite staking for 2 days or more.',
    stakingTime: 'Accumulated Staking Time',
    claimApHelpDes:
      'Members are required to claim the rewarded AP for each day to resume the next AP cycle.Failure to do so will result to the AP reward to remain at 1. Despite staking if for 100 days or more.',
    claimProfit: 'You can claim profit',
    claim: 'CLAIM',
    sell: 'SELL',
    referencePrice: 'Reference Price',
    onSale: 'ON SALE',
    unListing: 'UNLISTING',
    unList: 'UNLIST',
    insufficientLynk: 'Insufficient Lynk',
    fullLevel: 'Full Level',
    upLimit: 'Must be higher than',
    oneDayMaxVA: 'VA has been upgraded today',
    enterPoint: 'Enter point',
    noReward: 'No reward available',
    mustHigherThanLevel2: 'You must be higher than level 2',
    mintAction: 'Mint Finder NFT',
    mint: 'Mint',
    unstake: 'Unstake',
    upgrade: 'Upgrade',
    loading: 'Loading',
  },
  stake: {
    title: 'STAKE MY NFTS',
    redeem: 'Redeem',
    claim: 'Claim',
    claiming: 'Claiming',
    accumulatedEarned: 'Accumulated Earned $LRT',
    available: 'Available $LRT',
  },
  invitation: {
    title: 'INVITATION BOARD',
    invitationDes: 'Invite friends to earn rewards together',
    inviteFriends: 'INVITE FRIENDS',
    rewardSummary: 'REWARDS SUMMARY',
    socialRewardUp: 'SOCIAL REWARD',
    socialRewardDes:
      'Build up your team and network of Keepers by inviting your friends to join the LYNK community. The higher the rank of your Keeper, the higher the social rewards earned.',
    communityReward: 'COMMUNITY REWARD',
    communityReward1: 'Community Reward',
    communityRewardDes:
      'By connecting with members in the Sanctuary, players are able to build new or existing alliances to earn higher rewards.',
    contributionRewardUp: 'CONTRIBUTION REWARD',
    contributionRewardDes:
      'In addition to receiving LRT as earnings, AP will also be awarded for completing certain tasks. A user that recruits a new member will receive 1-6 AP for every 100 CA accumulated by their followers (credited immediately).',
    AchievementReward: 'ACHIEVEMENT REWARD',
    AchievementRewardDes:
      'In addition to receiving LRT as earnings, AP will also be awarded for completing certain tasks. Stake an NFT that is level 2 and above to receive AP rewards every day! The amount of AP rewards will be determined by your NFT’s level.',
    StakingReward: 'STAKING REWARD',
    StakingRewardDes:
      'One user can own multiple NFTs at the same time. Users are eligible for daily profits as long as your NFT(s) fulfil the staking requirements.',
    bonusRatio: 'Bonus Ratio',
    totalLRT: 'Total LRT',
    qualified: 'Qualified',
    cumulateCA: 'Cumulate CA',
    totalAP: 'Total AP',
    cycles: 'Cycles',
    stakingNFT: 'Staking NFT',
    elite: 'Elite',
    epic: 'Epic',
    master: 'Master',
    legendary: 'Legendary',
    mythic: 'Mythic',
    divine: 'Divine',
    nourishInviter: 'Direct Sponsor',
    teamGrade: 'Accumulated',
    levelPrivilege: 'LEVEL PRIVILEGE',
    socialReward: 'Social Reward',
    contributionReward: 'Contribution Reward',
    achievementReward: 'Achievement Reward',
    inactive: 'INACTIVE',
    activated: 'ACTIVATED',
    inactivated: 'INACTIVATED',
    activate: 'ACTIVATE',
    myLevel: 'MY LEVEL',
    generation: 'Generation',
    inviteRule: 'Refer every new 100CA，get',
    NFTStakingRule: 'Level 2 or above NFT staking for 1 day, and get',
    AcaStastistics: 'SUMCA STASTISTICS',
    daily: 'Daily',
    yesterday: 'Yesterday',
    today: 'Today',
    weekly: 'Weekly',
    lastWeek: 'Last week',
    thisWeek: 'This week',
    monthly: 'Monthly',
    lastMonth: 'Last month',
    thisMonth: 'This month',
  },
  history: {
    title: 'HISTORY',
    stakingRewardRecords: 'Staking Reward Records',
    mintingRecords: 'NFT Minting Records',
    transactionsRecords: 'NFT Transactions Records',
    recommendedRecords: 'Recommended Records',
    referralRewardRecords: 'Referral Reward Records',
    contributionRewardRecords: 'Contribution Reward Records',
    achievementRewardRecords: 'Achievement Reward Records',
    communityRewardRecords: 'Community Reward Records',
    time: 'Time',
    reward: 'Reward',
    name: 'Name',
    amount: 'Amount',
    buyerAddress: 'Buyer Address',
    sellerAddress: 'Seller Address',
    address: 'Address',
    tx: 'TX',
  },
  rank: {
    title: 'TOP USERS BY',
    top: 'TOP',
    ranking: 'RANKING',
    levelUp: 'LEVEL',
    charisma: 'CHARISMA',
    nft: 'NFT',
    level: 'Level',
    tx: 'TX',
  },
  finders: {
    title: 'FINDERS',
    findersDes1: 'The Finders come from the nexus,',
    findersDes2: 'a central point of all spacetime coordinates.',
    mint: 'MINT FINDER',
    soldOut: 'SOLD OUT',
    info1:
      'The Finders come from the Nexus, a central point of all spacetime coordinates. These beings have existed from the start of the known omniverse and have observed the birth of countless worlds. They collect all the knowledge and data from all Metaverses, preserving it by synchronizing their minds in cohesion. From time to time, an extraordinary Metaverse with the potential to advance beyond the limits of other Metaverses appear, prompting the Finders to act. Stepping into LYNKVERSE through the rift, the Finders set out to carry out their mission - to select a chosen few to guide other inhabitants and ensure the Metaverse grows into its full potential.',
    info2:
      'Every Finder creates soulseeds containing a portion of its powers and bestow them upon the chosen ones. By assimilating with the soulseed, a new race is born. Come carry the mantle of hope and become the leaders of the new age.',
    totalAmount: 'TOTAL AMOUNT OF FINDERS:',
    totalBalance: 'TOTAL BALANCE OF FINDERS AVAILABLE:',
    minted: 'MINTED',
  },
  nodeNft: {
    title: 'NODE NFT Program Generation I',
    mintNow: 'Mint Now',
    mintNode: 'MINT NODE',
    mintNodeNft: 'MINT NODE NFT',
    nodeNftDes1: 'Gain an edge with a Node NFT',
    nodeNftDes2: 'Join the ranks and make your mark in the LYNK Community',
    info11: `Welcome to the Node NFT Program, LYNK fam!`,
    info12: `The Node NFT Program is our premier program exclusively for early supporters that have demonstrated strong initiative in growing the LYNK community. Generation I of the program serves to recognize champions like you, with unique Keeper NFTs that will represent your identity and status as leaders of the most exciting Web3 community.`,
    info13: `This exciting program offers you the chance to mint a unique Node NFT at one of three tiers: Common, Special, or Rare. Here's what you will get at each tier:`,
    infoCommon: `Common Node NFT: Mint for 1000 USDT and receive 1,200 CA, 110 AP, and a Common Node NFT Badge. You'll also advance straight to LEGENDARY rank.`,
    infoSpecial: `Special Node NFT: Mint for 3000 USDT and receive 4,000 CA, 450 AP, and a Special Node NFT Badge. You'll also advance straight to LEGENDARY rank.`,
    infoRare: `Rare Node NFT: Mint for 5000 USDT and receive 7,000 CA, 1,200 AP, and a Rare Node NFT Badge. You'll also advance straight to LEGENDARY rank.`,
    info21: `As a Legendary rank member, get additional rewards for every successful referral! When your friends purchase a Node NFT, you will receive proportional rewards corresponding to the tier they purchase.`,
    info22: `When your referral purchases a Rare Node NFT, you will receive an additional 5,000 in accumulated CA, 280 AP and 560 LRT tokens in rewards!`,
    info23: `When your referral purchases a Special Node NFT, you will receive an additional 3,000 in accumulated CA, 160 AP and 320 LRT tokens in rewards!`,
    info24: `When your referral purchases a Common Node NFT, you will receive an additional 1,000 in accumulated CA, 48 AP and 96 LRT tokens in rewards! `,
    info25: `Node NFTs are designed to help you get ahead in your quest to build your network in our vibrant Web3 community. The more you expand your network within the LYNK community, the more benefits you will unlock, and the more you will earn!`,
    info26: `So, start inviting your friends and enjoy the perks that come with being part of the Node NFT crew. Join us now and start earning more today!`,
    minted: 'MINTED',
    soldOut: 'SOLD OUT',
  },
  leaderNft: {
    title: 'LEADER NFT Program Generation I',
    mintNow: 'Mint Now',
    mintLeader: 'MINT LEADER',
    mintLeaderNft: 'MINT LEADER NFT',
    leaderNftDes1: 'Gain an edge with a Leader NFT',
    leaderNftDes2: 'Join the ranks and make your mark in the LYNK Community',
    info11: `Welcome to the Leader NFT Program, LYNK fam!`,
    info12: `The Leader NFT Program is our premier program exclusively for early supporters that have demonstrated strong initiative in growing the LYNK community. Generation I of the program serves to recognize champions like you, with unique Keeper NFTs that will represent your identity and status as leaders of the most exciting Web3 community.`,
    info13: `This exciting program offers you the chance to mint a unique Leader NFT at one of three tiers: Common, Special, or Rare. Here's what you will get at each tier:`,
    infoCommon: `Common Leader NFT: Mint for 500 USDT and receive 500 CA, You'll also advance straight to EPIC rank.`,
    infoSpecial: `Special Leader NFT: Mint for 1000 USDT and receive 1,000 CA, You'll also advance straight to MASTER rank.`,
    infoRare: `Rare Leader NFT: Mint for 3000 USDT and receive 3,000 CA, You'll also advance straight to LEGENDARY rank.`,
    info21: `As a Legendary rank member, get additional rewards for every successful referral! When your friends purchase a Leader NFT, you will receive proportional rewards corresponding to the tier they purchase.`,
    info22: `When your referral purchases a Rare Leader NFT, you will receive an additional 3,000 in accumulated CA, 120 AP and 240 LRT tokens in rewards!`,
    info23: `When your referral purchases a Special Leader NFT, you will receive an additional 1,000 in accumulated CA, 40 AP and 80 LRT tokens in rewards!`,
    info24: `When your referral purchases a Common Leader NFT, you will receive an additional 500 in accumulated CA, 20 AP and 40 LRT tokens in rewards! `,
    info25: `Leader NFTs are designed to help you get ahead in your quest to build your network in our vibrant Web3 community. The more you expand your network within the LYNK community, the more benefits you will unlock, and the more you will earn!`,
    info26: `So, start inviting your friends and enjoy the perks that come with being part of the Leader NFT crew. Join us now and start earning more today!`,
    minted: 'MINTED',
    soldOut: 'SOLD OUT',
  },
  marketPlace: {
    title: 'MARKETPLACE',
    totalVolume: 'Total Volume',
    floorPrice: 'Floor Price',
    higherPrice: 'Higher Price',
    owner: 'Owner',
    tradingFee: 'Trading Fee',
    faction: 'FACTION',
    type0: 'Finder',
    type1: 'Spirits',
    type2: 'Exos',
    type3: 'Martials',
    type4: 'Voids',
    gender: 'GENDER',
    boy: 'Male',
    girl: 'Female',
    level: 'LEVEL',
    level0: 'Level 2-3',
    level1: 'Level 4-6',
    level2: 'Level 7-9',
    level3: 'Level 10-12',
    level4: 'Level 13',
    priceDesc: 'Price: high to low',
    priceAsc: 'Price: low to high',
    priceHint: {
      empty: 'Please enter price range',
      numberError: 'The max price could not lower than the min price',
    },
    priceUsdt: 'PRICE USDT',
    to: 'TO',
    all: 'ALL',
    buy: 'Buy',
    rare: 'RARE',
    price: 'Price',
    balance: 'Balance',
  },
};
