import React, { useState, useEffect } from 'react';
import { styled, Typography, Stack, Select, MenuItem } from '@mui/material';
import { useI18n } from 'react-simple-i18n';
import { useActiveWeb3React } from 'hooks';
import _ from 'lodash';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Check from '@mui/icons-material/Check';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableFooter from '@mui/material/TableFooter';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import IconButton from '@mui/material/IconButton';
import { useHistory } from 'hooks/useHistory';

const HistoryContentBox = styled('div')(({ theme }) => ({
  gridArea: 'historyB',
  display: 'grid',
  paddingTop: theme.spacing(5),
  gridTemplateRows: `${theme.spacing(4)} 1fr ${theme.spacing(3)}`,
  gridTemplateColumns: `1fr`,
  gridTemplateAreas: `
    'historySelect'
    'historyTable'
    'historyBottom'
  `,
  [theme.breakpoints.down('sm')]: {
    paddingTop: theme.spacing(0),
    '&::-webkit-scrollbar': {
      width: 0,
    },
    overflowY: 'overlay',
    gridTemplateRows: `${theme.spacing(4)} ${theme.spacing(4)} 1fr ${theme.spacing(2)}`,
    gridTemplateColumns: `1fr`,
    gridTemplateAreas: `
      'historyTitle'
      'historySelect'
      'historyTable'
      'historyBottom'
    `,
  },
  overflow: 'hidden',
}));

const HistoryTitle = styled('div')(({ theme }) => ({
  gridArea: 'historyTitle',
  [theme.breakpoints.up('sm')]: {
    display: 'none',
  },
}));

const HistorySelect = styled('div')({
  gridArea: 'historySelect',
});

const HistoryTable = styled('div')({
  gridArea: 'historyTable',
});

const TypographyDes = styled(Typography)(({ theme }) => ({
  fontSize: 32,
  fontWeight: 700,
  [theme.breakpoints.down('sm')]: {
    fontSize: 20,
  },
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    height: theme.spacing(7),
    border: 0,
    color: '#8B8B8B',
    fontSize: 13,
    fontWeight: 700,
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(0.5),
    },
  },
  [`&.${tableCellClasses.body}`]: {
    color: '#121425',
    fontSize: 13,
    fontWeight: 500,
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(0.5),
    },
  },
}));

const StyledTableRow = styled(TableRow)(() => ({
  '&:nth-of-type(odd)': {
    backgroundColor: 'rgba(217, 217, 217, 0.2)',
  },
  '& td, & th': {
    border: 0,
  },
}));

const HistoryContent = () => {
  const { t, i18n } = useI18n();
  const { account } = useActiveWeb3React();
  const [curTab, setCurTab] = useState(0);
  const [tableHeaders, setTableHeaders] = useState(['']);
  const { page, historyList } = useHistory(account, curTab, 10);

  const selectItems = [
    t('history.mintingRecords'),
    t('history.transactionsRecords'),
    t('history.recommendedRecords'),
    t('history.referralRewardRecords'),
    t('history.communityRewardRecords'),
    t('history.contributionRewardRecords'),
    t('history.achievementRewardRecords'),
    t('history.stakingRewardRecords'),
  ];

  useEffect(() => {
    let header = [''];
    switch (curTab) {
      case 0:
        header = [t('history.time'), t('history.name'), t('history.amount'), t('history.tx')];
        break;
      case 1:
        header = [
          t('history.time'),
          'NFT',
          t('price'),
          t('history.buyerAddress'),
          t('history.sellerAddress'),
          t('history.tx'),
        ];
        break;
      case 2:
        header = [t('history.time'), t('history.address'), t('history.tx')];
        break;
      case 3:
        header = [t('history.time'), t('history.address'), t('history.reward'), t('history.tx')];
        break;
      case 4:
        header = [t('history.time'), t('history.address'), t('history.reward'), t('history.tx')];
        break;
      case 5:
        header = [t('history.time'), t('history.address'), t('history.reward'), t('history.tx')];
        break;
      case 6:
        header = [t('history.time'), 'NFT', t('history.reward'), t('history.tx')];
        break;
      case 7:
        header = [t('history.time'), t('history.reward'), t('history.tx')];
        break;
    }
    setTableHeaders(header);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [historyList, t]);

  return (
    <HistoryContentBox>
      <HistoryTitle>
        <TypographyDes color={'#000000'}>{t('history.title')}</TypographyDes>
      </HistoryTitle>
      <HistorySelect>
        <Select
          sx={(theme) => ({
            background: '#F0F0F0',
            width: _.includes(['frFR', 'esES', 'ruRU', 'deDE'], i18n.getLang()) ? 360 : 240,
            height: 30,
            borderRadius: '10px',
            '& .MuiOutlinedInput-notchedOutline': { border: 'none' },
            [theme.breakpoints.down('sm')]: { zoom: 0.8 },
          })}
          displayEmpty
          value={curTab}
          IconComponent={ExpandMoreIcon}
          inputProps={{
            sx: { border: 'none', display: 'flex', '& .MuiListItemIcon-root': { svg: { display: 'none' } } },
          }}
          MenuProps={{
            sx: (theme) => ({
              marginTop: 1,
              '& .MuiPaper-root': {
                width: _.includes(['frFR', 'esES', 'ruRU', 'deDE'], i18n.getLang()) ? 400 : 272,
                marginLeft: _.includes(['frFR', 'esES', 'ruRU', 'deDE'], i18n.getLang()) ? 2.5 : 2,
                [theme.breakpoints.down('sm')]: {
                  marginLeft: _.includes(['frFR', 'esES', 'ruRU', 'deDE'], i18n.getLang()) ? 2 : 0,
                  width: _.includes(['frFR', 'esES', 'ruRU', 'deDE'], i18n.getLang()) ? 360 : 240,
                  zoom: 0.8,
                },
                borderRadius: '10px',
                boxShadow: 'unset',
              },
              '& .MuiList-root': { padding: '0 12px', background: '#F0F0F0' },
            }),
          }}
          onChange={(event) => {
            if (event.target.value === curTab) return;
            page.setCurrentPage(1);
            setCurTab(Number(event.target.value));
          }}
        >
          {selectItems.map((item, index) => (
            <MenuItem
              divider={index !== selectItems.length - 1}
              key={item}
              selected={false}
              sx={(theme) => ({
                height: 38,
                background: '#F0F0F0',
                justifyContent: 'space-between',
                padding: '2px 0',
                '&:hover': { backgroundColor: '#F0F0F0' },
                '&.Mui-selected': { backgroundColor: '#F0F0F0 !important' },
                '&.Mui-selected:hover': { backgroundColor: '#F0F0F0' },
                [theme.breakpoints.down('sm')]: { height: 38, minHeight: 'unset' },
              })}
              value={index}
            >
              <ListItemText primary={item} />
              {curTab === index ? (
                <ListItemIcon sx={{ justifyContent: 'flex-end' }}>
                  <Check />
                </ListItemIcon>
              ) : null}
            </MenuItem>
          ))}
        </Select>
      </HistorySelect>
      <HistoryTable>
        <TableContainer sx={(theme) => ({ [theme.breakpoints.down('sm')]: { zoom: 0.7 } })}>
          <Table size="small" aria-label="customized table">
            <TableHead>
              <TableRow>
                {tableHeaders.map((item) => (
                  <StyledTableCell key={item}>{item}</StyledTableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {historyList.map((row, index) => (
                <StyledTableRow key={`historyListRow${index}`}>
                  {row?.map((historyItem, historyItemIndex) => (
                    <StyledTableCell key={`historyItem${historyItemIndex}`}>{historyItem}</StyledTableCell>
                  ))}
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
          <TableFooter sx={{ display: 'flex', justifyContent: 'end', height: 64 }}>
            <Stack direction="row">
              <IconButton
                onClick={_.debounce(() => page.setCurrentPage(page.currentPage - 1), 300)}
                disableRipple
                disabled={!page.hasPrev}
              >
                <ArrowBackIcon fontSize="small" />
              </IconButton>
              <IconButton disableRipple>{page.currentPage}</IconButton>
              <IconButton
                disabled={!page.hasNext}
                disableRipple
                onClick={_.debounce(() => page.setCurrentPage(page.currentPage + 1), 300)}
              >
                <ArrowForwardIcon fontSize="small" />
              </IconButton>
            </Stack>
          </TableFooter>
        </TableContainer>
      </HistoryTable>
    </HistoryContentBox>
  );
};

export default HistoryContent;
