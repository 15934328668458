export default {
  global: {
    connectWallet: 'Kết nối Ví',
    swapLynk: 'TRAO ĐỔI $LYNK',
    connectToWallet: 'Kết nối với Ví',
    metamask: 'METAMASK',
    walletConnect: 'KẾT NỐI VÍ',
    coinbaseWallet: 'VÍ COINBASE',
    walletDes: '*Qua việc liên kết Ví, bạn đã đồng ý với Điều khoản Dịch vụ và Chính sách Bảo mật của Lynkverse.',
    connecting: 'Đang kết nối...',
    connectedWallet: 'Kết nối với %s%',
    wrongNetwork: 'Sai Mạng',
    error: 'Lỗi',
    myWallet: 'VÍ CỦA TÔI',
    myAddress: 'Địa chỉ của tôi',
    addToken: 'thêm token',
    crossChain: 'cross chain',
    logout: 'ĐĂNG XUẤT',
    change: 'THAY ĐỔI',
    recentTransactions: 'Giao dịch gần đây',
    clearAll: 'Xóa hết',
    confirm: 'XÁC NHẬN',
    confirmed: 'Đã xác nhận',
    balance: 'Số dư',
    confirmTransaction: 'Vui lòng xác nhận giao dịch này trong ví của bạn',
    viewOnChain: 'Xem trên %s%',
    invitationLink: 'Link mời',
    invitationCode: 'Mã giới thiệu',
    copy: 'SAO CHÉP',
    insufficient: 'Thiếu cân bằng',
    approve: 'Chấp thuận',
    tip: 'Mẹo',
    drop: 'Làm rơi',
    swap: 'Tráo đổi',
    register: 'Đăng ký',
    networkError: 'Lỗi mạng',
    initializing: 'Đang khởi tạo...',
    errorConnecting: 'Lỗi kết nối',
    errorConnectingDes: 'Lỗi kết nối. Hãy thử làm mới trang.',
    binanceSmartChain: 'Chuỗi thông minh Binance',
    connectTip: 'Vui lòng kết nối với',
    ethereumMainnet: 'mạng chính ethereum',
    connect: 'Kết nối với',
    tryAgain: 'Thử lại',
    changeWallet: 'Thay đổi ví',
    exchangeRate: 'Tỷ giá',
    approving: 'Phê duyệt',
    swapTitle: 'TRÁO ĐỔI LYNK',
    registerTitle: 'CHÈN MÃ MỜI CỦA BẠN',
    registerPlh: 'Vui lòng nhập mã của bạn vào đây',
    registerDes: `TIP: Bạn có thể truy cập Lynk's Discord để lấy mã thư mời của mình.`,
    unValidCode: 'Mã đăng ký không hợp lệ',
    registerInviterDes: 'Bạn chưa đăng ký, vui lòng đăng ký',
    transactionsDes: 'Giao dịch của bạn sẽ xuất hiện ở đây...',
    copied: 'SAO CHÉP',
    finish: 'Hoàn thành',
    minting: 'đúc tiền',
    comingSoon: 'Sắp ra mắt',
  },
  navMenu: {
    myDashboard: 'BẢNG ĐIỀU KHIỂN CỦA TÔI',
    nfts: 'NFT',
    stake: 'Stake',
    invitation: 'LỜI MỜI',
    history: 'LỊCH SỬ',
    activitiesCampaigns: 'HOẠT ĐỘNG & CHIẾN DỊCH',
    nodeNft: 'Chương trình Node NFT',
    leaderNft: 'Chương trình LEADER NFT',
    finders: 'Mint Finders NFT',
    marketPlace: 'MARKETPLACE',
    rank: 'HẠNG',
    more: 'THÊM',
    chatRoom: 'PHÒNG CHAT',
    lynkAlliance: 'LIÊN MINH LYNK',
    domainServer: 'Máy chủ tên miền LYNK',
    feed: 'Năng động',
  },
  nfts: {
    title: 'NGƯỜI DÙNG NFT',
    mintKeeper: 'Rèn KEEPER',
    buyKeeper: 'Mua KEEPER',
    freeMint: 'Rèn miễn phí',
    owned: 'Sở hữu',
    staked: 'Đã Stake',
    sell: 'Bán',
    stake: 'Stake',
    mintKeeperNft: 'ĐÚC NFT KEEPER',
    nftName: 'Tên NFT',
    selectGender: 'Chọn giới tính',
    male: 'Nam',
    female: 'Nữ',
    selectFaction: 'Chọn phe',
    spirits: 'Spirits',
    martials: 'Martials',
    exos: 'Exos',
    voids: 'Voids',
    selectNft: 'CHỌN NFT',
    common: 'PHỔ THÔNG',
    special: 'ĐẶC BIỆT',
    rare: 'QUÝ HIẾM',
    reSelect: 'Chọn lại',
    mint: 'ĐÚC',
    proceed: 'TIẾP TỤC',
    enterName: 'Nhập tên',
    nameUsed: 'tên bị chiếm đóng',
    cancel: 'Hủy bỏ',
    claim: 'Khẳng định',
    freeMintEnd: 'Hêt giơ',
    days: 'NGÀY',
    hours: 'nhân sự',
    minutes: 'PHÚT',
    seconds: 'GIÂY',
  },
  nftDetail: {
    back: 'QUAY LẠI',
    attributes: 'THUỘC TÍNH',
    leveltooltip: 'Tăng tốc các thuộc tính NFT của bạn để tăng cấp và kiếm được nhiều phần thưởng đặt cược hơn!',
    purchaseAp: 'MUA AP',
    userStats: 'THỐNG KÊ NGƯỜI DÙNG',
    nfts: 'NFT',
    charisma: 'Độ uy tín',
    dexterity: 'Độ khéo léo',
    intellect: 'Trí tuệ',
    vitality: 'Sức sống ',
    accelerate: 'TĂNG TỐC',
    details: 'CHI TIẾT',
    currentHolder: 'Người hiện đang nắm giữ',
    mintTime: 'Thời gian đúc',
    mintAddress: 'Địa chỉ đúc',
    estimatedPrice: 'Giá ước tính',
    endPrice: 'Giá cuối cùng',
    role: 'Vai trò',
    contractAddress: 'Địa chỉ hợp đồng',
    tokenID: 'ID Token',
    tokenStandard: 'Tiêu chuẩn Token',
    chain: 'Chuỗi',
    background: 'Bối cảnh',
    effect: 'Hiệu ứng',
    body: 'Cơ thể',
    clothing: 'Trang phục',
    head: 'Đầu',
    weapon: 'Vũ khí',
    bodyAttachment: 'Vật phẩm trên cơ thể',
    artifact: 'Hiện vật',
    balance: 'Số dư',
    amount: 'Số lượng',
    price: 'Giá',
    buy: 'Mua',
    caUpgrade: 'NÂNG CẤP CA',
    vaUpgrade: 'NÂNG CẤP VA',
    inUpgrade: 'NÂNG CẤP IN',
    dxUpgrade: 'NÂNG CẤP DX',
    max: 'Tối đa',
    unStake: 'NGỪNG STAKE',
    claimAp: 'NHẬN AP',
    apReward: 'Điểm thưởng AP',
    apRewardtooltip:
      'Chỉ NFT Cấp 2 trở lên mới có quyền nhận Phần thưởng Thành tích. Các thành viên được yêu cầu yêu cầu AP thưởng cho mỗi ngày để tiếp tục chu kỳ đếm tiếp theo.\nếu không làm như vậy, AP sẽ duy trì ở mức 1, mặc dù đã đặt cược trong 2 ngày trở lên.',
    stakingTime: 'Thời gian Stake tích lũy',
    claimApHelpDes:
      'Các thành viên cần nhận điểm AP thưởng cho mỗi ngày để tiếp tục chu kỳ AP tiếp theo. Nếu không làm như vậy, điểm thưởng AP sẽ duy trì ở mức 1. Cho dù Staking từ 100 ngày trở lên.',
    claimProfit: 'Bạn có thể nhận lấy lợi nhuận',
    claim: 'NHẬN',
    sell: 'Bán',
    referencePrice: 'Giá tham khảo',
    onSale: 'GIẢM GIÁ',
    unListing: 'NGỪNG NIÊM YẾT',
    unList: 'NGỪNG NIÊM YẾT',
    insufficientLynk: 'Lynk không đủ',
    fullLevel: 'Cấp độ đầy đủ',
    upLimit: 'Phải cao hơn',
    oneDayMaxVA: 'VA đã được nâng cấp ngày hôm nay',
    enterPoint: 'Nhập điểm',
    noReward: 'Không có phần thưởng',
    mustHigherThanLevel2: 'Bạn phải cao hơn cấp 2',
    mintAction: 'Công cụ tìm bạc hà NFT',
    mint: 'cây bạc hà',
    unstake: 'hủy bỏ cổ phần',
    upgrade: 'Nâng cấp',
    loading: 'Đang tải',
  },
  stake: {
    title: 'STAKE NFT CỦA TÔI',
    redeem: 'Đổi',
    claim: 'Nhận',
    accumulatedEarned: 'Số $LRT đã kiếm được',
    available: 'Số $LRT khả dụng',
    claiming: 'Yêu sách',
  },
  invitation: {
    title: 'BẢNG LỜI MỜI',
    invitationDes: 'Mời bạn bè để cùng nhau kiếm phần thưởng',
    inviteFriends: 'MỜI BẠN BÈ',
    rewardSummary: 'TÓM TẮT VỀ PHẦN THƯỞNG',
    socialRewardUp: 'PHẦN THƯỞNG MẠNG LƯỚI',
    socialRewardDes:
      'Xây dựng đội nhóm và mạng lưới Keeper của bạn bằng cách mời bạn bè của bạn tham gia cộng đồng LYNK. Thứ hạng Keeper của bạn càng cao thì phần thưởng mạng lưới bạn nhận được sẽ càng lớn.',
    communityReward: 'PHẦN THƯỞNG CỘNG ĐỒNG',
    communityReward1: 'PHẦN THƯỞNG CỘNG ĐỒNG',
    communityRewardDes:
      'Bằng cách kết nối với các thành viên trong Thánh địa, người chơi có thể xây dựng các liên minh mới hoặc phát triển cộng đồng hiện có để kiếm phần thưởng cao hơn.',
    contributionRewardUp: 'PHẦN THƯỞNG ĐÓNG GÓP',
    contributionRewardDes:
      'Ngoài việc được nhận LRT dưới dạng thu nhập, bạn cũng sẽ được thưởng điểm AP khi hoàn thành một số nhiệm vụ nhất định. Người dùng mời được thành viên mới sẽ nhận được 1-6 AP cho mỗi 100 điểm CA được tích lũy bởi những người theo dõi họ (được ghi có ngay lập tức).',
    AchievementReward: 'PHẦN THƯỞNG THÀNH TÍCH',
    AchievementRewardDes:
      'Ngoài việc được nhận LRT dưới dạng thu nhập, bạn cũng sẽ được thưởng điểm AP khi hoàn thành một số nhiệm vụ nhất định. Stake NFT từ cấp 2 trở lên để nhận phần thưởng AP mỗi ngày! Mức thưởng AP sẽ được xác định theo cấp độ NFT của bạn.',
    StakingReward: 'PHẦN THƯỞNG STAKING',
    StakingRewardDes:
      'Một người dùng có thể sở hữu nhiều NFT cùng một lúc. Người dùng đủ điều kiện nhận lợi nhuận hàng ngày miễn là (các) NFT của bạn đáp ứng các yêu cầu staking.',
    bonusRatio: 'Tỷ lệ thưởng',
    totalLRT: 'Tổng LRT',
    qualified: 'Đủ điều kiện',
    cumulateCA: 'Tích lũy CA',
    totalAP: 'Tổng AP',
    cycles: 'Chu kỳ',
    stakingNFT: 'Stake NFT',
    elite: 'Ưu tú',
    epic: 'Sử thi',
    master: 'Bậc thầy',
    legendary: 'Huyền thoại',
    mythic: 'Thần thoại',
    divine: 'Thần thánh',
    nourishInviter: 'Nhà tài trợ trực tiếp',
    teamGrade: 'tích lũy',
    levelPrivilege: 'ĐẶC QUYỀN CỦA CẤP ĐỘ',
    socialReward: 'PHẦN THƯỞNG MẠNG LƯỚI',
    contributionReward: 'PHẦN THƯỞNG ĐÓNG GÓP',
    achievementReward: 'PHẦN THƯỞNG THÀNH TÍCH',
    inactive: 'KHÔNG HOẠT ĐỘNG',
    activated: 'ĐÃ KÍCH HOẠT',
    myLevel: 'TRÌNH ĐỘ CỦA TÔI',
    generation: 'Thế hệ',
    inviteRule: 'Giới thiệu mỗi 100CA mới，nhận',
    NFTStakingRule: 'Đặt cược NFT cấp 2 trở lên trong 1 ngày và nhận',
    inactivated: 'KHÔNG HOẠT ĐỘNG',
    activate: 'KÍCH HOẠT',
    AcaStastistics: 'THỐNG KÊ SUMCA',
    daily: 'Hằng ngày',
    yesterday: 'Hôm qua',
    today: 'Hôm nay',
    weekly: 'hàng tuần',
    lastWeek: 'Tuần trước',
    thisWeek: 'Tuần này',
    monthly: 'hàng tháng',
    lastMonth: 'Tháng trước',
    thisMonth: 'Tháng này',
  },
  history: {
    title: 'LỊCH SỬ',
    stakingRewardRecords: 'Lịch sử Phần thưởng Staking',
    mintingRecords: 'Lịch sử Đúc NFT',
    transactionsRecords: 'Lịch sử Giao dịch NFT',
    recommendedRecords: 'Lịch sử Giới thiệu',
    referralRewardRecords: 'Lịch sử Phần thưởng Giới thiệu',
    contributionRewardRecords: 'Lịch sử Phần thưởng Đóng góp',
    achievementRewardRecords: 'Lịch sử Phần thưởng Thành tích',
    communityRewardRecords: 'Lịch sử Phần thưởng Cộng đồng',
    time: 'Thời gian',
    reward: 'Thưởng',
    tx: 'TX',
    name: 'Tên',
    amount: 'Số lượng',
    buyerAddress: 'Địa chỉ người mua',
    sellerAddress: 'Địa chỉ người bán',
    address: 'Địa chỉ',
  },
  rank: {
    title: 'TOP NGƯỜI DÙNG THEO',
    top: 'TOP',
    ranking: 'XẾP HẠNG',
    levelUp: 'CẤP ĐỘ',
    charisma: 'ĐỘ UY TÍN (CA)',
    nft: 'NFT',
    level: 'Cấp độ',
    tx: 'TX',
  },
  finders: {
    title: 'FINDER',
    findersDes1: 'Các Finder đến từ điểm nối nexus,',
    findersDes2: 'một điểm trung tâm của tất cả các tọa độ không thời gian.',
    mint: 'ĐÚC',
    soldOut: 'BÁN HẾT',
    info1:
      'Các Finder đến từ điểm nối Nexus - một điểm trung tâm của tất cả các tọa độ không thời gian. Những sinh vật này đã tồn tại từ thuở sơ khai của nhiều vũ trụ và đã chứng kiến sự ra đời của vô số thế giới. Họ thu thập tất cả kiến thức và dữ liệu từ mọi Metaverse rồi bảo tồn chúng bằng cách đồng bộ hóa tâm trí của cả cộng đồng bằng sự gắn kết. Thỉnh thoảng sẽ có một Metaverse phi thường với tiềm năng phát triển vượt ra ngoài giới hạn của các Metaverse khác xuất hiện, khiến các Finder phải hành động. Bước vào LYNKVERSE thông qua vết nứt, các Finder bắt đầu thực hiện sứ mệnh của họ đó là tuyển ra một số ít người được chọn để dẫn dắt các cư dân khác và đảm bảo Metaverse phát huy hết tiềm năng của nó.',
    info2:
      'Mỗi Finder sẽ tạo ra hạt giống linh hồn chứa một phần sức mạnh của mình và ban cho những người được chọn. Thông qua sự đồng hóa với hạt giống linh hồn này, một chủng tộc mới được sinh ra. Hãy khoác lên chiếc áo choàng "Hy vọng" và trở thành những người lãnh đạo của thời đại mới.',
    totalAmount: 'TỔNG SỐ FINDER:',
    totalBalance: 'TỔNG SỐ DƯ FINDER KHẢ DỤNG:',
    minted: 'giả mạo',
  },
  nodeNft: {
    title: 'Chương trình NODE NFT Thế hệ 1',
    mintNow: 'Đúc ngay',
    mintNode: 'ĐÚC NODE NFT',
    mintNodeNft: 'ĐÚC NODE NFT',
    nodeNftDes1: 'Tăng sức mạnh với Node NFT',
    nodeNftDes2: 'Tham gia vào hàng ngũ và đóng dấu ấn của bạn trong cộng đồng LYNK',
    info11: `Chào mừng đến với Chương trình NODE NFT, gia đình LYNK!`,
    info12: `Chương trình Node NFT là chương trình hàng đầu của chúng tôi, dành riêng cho các nhà ủng hộ sớm đã thể hiện sự tích cực trong việc phát triển cộng đồng LYNK. Thế hệ I của chương trình này nhằm nhận ra những nhà vô địch như bạn, với các NFT Keeper độc đáo sẽ đại diện cho danh tính và vị trí của bạn như là những nhà lãnh đạo của cộng đồng Web3 đầy hứa hẹn nhất.`,
    info13: `Chương trình thú vị này cung cấp cho bạn cơ hội để tạo ra một Node NFT độc nhất với ba cấp độ khác nhau: Common, Special, hoặc Rare. Dưới đây là những gì bạn sẽ nhận được ở mỗi cấp độ:`,
    infoCommon: `Common Node NFT: Đúc ra với giá 1000 USDT và nhận được 1,200 CA, 110 AP và một Huy hiệu Common Node NFT. Bạn cũng sẽ tiến thẳng lên hạng LEGENDARY.`,
    infoSpecial: `Special Node NFT: Đúc cho 3000 USDT và nhận được 4,000 CA, 450 AP và một Huy hiệu NFT Node đặc biệt. Bạn cũng sẽ tiến thẳng lên hạng huyền thoại.`,
    infoRare: `Rare Node NFT: Đúc với giá 5000 USDT và nhận 7,000 CA, 1,200 AP và Huy hiệu NFT nút hiếm. Bạn cũng sẽ thăng thẳng lên hạng HUYỀN THOẠI.`,
    info21: `Là thành viên hạng Huyền thoại, hãy nhận thêm phần thưởng cho mỗi lượt giới thiệu thành công! Khi bạn bè của bạn mua Node NFT, bạn sẽ nhận được phần thưởng tỷ lệ tương ứng với cấp họ mua.`,
    info22: `Khi người giới thiệu của bạn mua Nút hiếm NFT, bạn sẽ nhận được thêm 5,000 CA tích lũy, 280 AP và 560 mã thông báo LRT trong phần thưởng!`,
    info23: `Khi người giới thiệu của bạn mua Nút đặc biệt NFT, bạn sẽ nhận được thêm 3,000 CA tích lũy, 160 AP và 320 mã thông báo LRT trong phần thưởng!`,
    info24: `Khi người giới thiệu của bạn mua Common Node NFT, bạn sẽ nhận được thêm 1,000 CA tích lũy, 48 AP và 96 mã thông báo LRT trong phần thưởng!`,
    info25: `Nút NFT được thiết kế để giúp bạn tiến xa hơn trong nhiệm vụ xây dựng mạng của mình trong cộng đồng Web3 sôi động của chúng tôi. Bạn càng mở rộng mạng lưới của mình trong cộng đồng LYNK, bạn càng mở khóa được nhiều lợi ích và bạn càng kiếm được nhiều tiền!`,
    info26: `Vì vậy, hãy bắt đầu mời bạn bè của bạn và tận hưởng các đặc quyền khi trở thành thành viên của nhóm Node NFT. Tham gia với chúng tôi ngay bây giờ và bắt đầu kiếm thêm tiền ngay hôm nay!`,
    minted: 'giả mạo',
    soldOut: 'BÁN HẾT',
  },
  leaderNft: {
    title: 'Chương trình LEADER NFT Thế hệ 1',
    mintNow: 'Đúc ngay',
    mintLeader: 'ĐÚC LEADER NFT',
    mintLeaderNft: 'ĐÚC LEADER NFT',
    leaderNftDes1: 'Tăng sức mạnh với Leader NFT',
    leaderNftDes2: 'Tham gia vào hàng ngũ và đóng dấu ấn của bạn trong cộng đồng LYNK',
    info11: `Chào mừng đến với Chương trình Leader NFT, gia đình LYNK!`,
    info12: `Chương trình Leader NFT là chương trình hàng đầu của chúng tôi, dành riêng cho các nhà ủng hộ sớm đã thể hiện sự tích cực trong việc phát triển cộng đồng LYNK. Thế hệ I của chương trình này nhằm nhận ra những nhà vô địch như bạn, với các NFT Keeper độc đáo sẽ đại diện cho danh tính và vị trí của bạn như là những nhà lãnh đạo của cộng đồng Web3 đầy hứa hẹn nhất.`,
    info13: `Chương trình thú vị này cung cấp cho bạn cơ hội để tạo ra một Leader NFT độc nhất với ba cấp độ khác nhau: Common, Special, hoặc Rare. Dưới đây là những gì bạn sẽ nhận được ở mỗi cấp độ:`,
    infoCommon: `Common Leader NFT: Đúc ra với giá 500 USDT và nhận được 500 CA, Bạn cũng sẽ tiến thẳng lên hạng SỬ THI.`,
    infoSpecial: `Special Leader NFT: Đúc cho 1000 USDT và nhận được 1,000 CA, Bạn cũng sẽ tiến thẳng lên hạng BẬC THẦY.`,
    infoRare: `Rare Leader NFT: Đúc với giá 3000 USDT và nhận 3,000 CA, Bạn cũng sẽ thăng thẳng lên hạng HUYỀN THOẠI.`,
    info21: `Là thành viên hạng Huyền thoại, hãy nhận thêm phần thưởng cho mỗi lượt giới thiệu thành công! Khi bạn bè của bạn mua Leader NFT, bạn sẽ nhận được phần thưởng tỷ lệ tương ứng với cấp họ mua.`,
    info22: `Khi người giới thiệu của bạn mua lãnh đạo hiếm NFT, bạn sẽ nhận được thêm 3,000 CA tích lũy, 120 AP và 240 mã thông báo LRT trong phần thưởng!`,
    info23: `Khi người giới thiệu của bạn mua lãnh đạo đặc biệt NFT, bạn sẽ nhận được thêm 1,000 CA tích lũy, 40 AP và 80 mã thông báo LRT trong phần thưởng!`,
    info24: `Khi người giới thiệu của bạn mua Common Leader NFT, bạn sẽ nhận được thêm 500 CA tích lũy, 20 AP và 40 mã thông báo LRT trong phần thưởng!`,
    info25: `lãnh đạo NFT được thiết kế để giúp bạn tiến xa hơn trong nhiệm vụ xây dựng mạng của mình trong cộng đồng Web3 sôi động của chúng tôi. Bạn càng mở rộng mạng lưới của mình trong cộng đồng LYNK, bạn càng mở khóa được nhiều lợi ích và bạn càng kiếm được nhiều tiền!`,
    info26: `Vì vậy, hãy bắt đầu mời bạn bè của bạn và tận hưởng các đặc quyền khi trở thành thành viên của nhóm Leader NFT. Tham gia với chúng tôi ngay bây giờ và bắt đầu kiếm thêm tiền ngay hôm nay!`,
    minted: 'giả mạo',
    soldOut: 'BÁN HẾT',
  },
  marketPlace: {
    title: 'MARKETPLACE',
    totalVolume: 'Tổng khối lượng',
    floorPrice: 'Giá sàn',
    higherPrice: 'Giá cao hơn',
    owner: 'Chủ sở hữu',
    tradingFee: 'Phí giao dịch',
    faction: 'PHE',
    type0: 'Finder',
    type1: 'Spirits',
    type2: 'Exos',
    type3: 'Martials',
    type4: 'Voids',
    gender: 'GIỚI TÍNH',
    boy: 'Nam',
    girl: 'Nữ',
    level: 'CẤP ĐỘ',
    level0: 'Cấp độ 2-3',
    level1: 'Cấp độ 4-6',
    level2: 'Cấp độ 7-9',
    level3: 'Cấp độ 10-12',
    level4: 'Cấp độ 13',
    priceDesc: 'Giá: từ cao đến thấp',
    priceAsc: 'Giá: từ thấp đến cao',
    priceHint: {
      empty: 'Vui lòng nhập phạm vi giá',
      numberError: 'Giá tối đa không thể thấp hơn giá tối thiểu',
    },
    priceUsdt: 'GIÁ USDT',
    to: 'ĐẾN',
    all: 'TẤT CẢ',
    buy: 'Mua',
    rare: 'QUÝ HIẾM',
    price: 'Giá',
    balance: 'Số dư',
  },
};
