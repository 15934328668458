export default {
  global: {
    connectWallet: 'Hubungkan Dompet',
    swapLynk: 'SWAP $LYNK',
    connectToWallet: 'Hubungkan ke Dompet',
    metamask: 'METAMASK',
    walletConnect: 'HUBUNGKAN DOMPET',
    coinbaseWallet: 'DOMPET COINBASE',
    walletDes: '*Anda menyetujui Ketentuan Layanan dan Kebijakan Privasi Lynkverse dengan menautkan dompet.',
    connecting: 'Menghubungkan...',
    connectedWallet: 'Terhubung dengan %s%',
    wrongNetwork: 'Jaringan Salah',
    error: 'Kesalahan',
    myWallet: 'DOMPET SAYA',
    myAddress: 'Alamat Saya',
    addToken: 'tambahkan token',
    crossChain: 'rantai silang',
    logout: 'LOG OUT',
    change: 'GANTI',
    recentTransactions: 'Transaksi Terbaru',
    clearAll: 'Hapus Semua',
    confirm: 'KONFIRMASI',
    confirmed: 'Dikonfirmasi',
    balance: 'Saldo',
    confirmTransaction: 'Harap konfirmasikan transaksi ini di dompet Anda',
    viewOnChain: 'Lihat di %s%',
    invitationLink: 'Tautan Undangan',
    invitationCode: 'Kode Undangan',
    copy: 'SALIN',
    insufficient: 'Saldo tidak mencukupi',
    approve: 'Menyetujui',
    tip: 'Tip',
    drop: 'Menjatuhkan',
    swap: 'Menukar',
    register: 'Daftar',
    networkError: 'Kesalahan Jaringan',
    initializing: 'Inisialisasi...',
    errorConnecting: 'Kesalahan Menghubungkan',
    errorConnectingDes: 'Kesalahan menghubungkan. Coba muat ulang halaman.',
    binanceSmartChain: 'Rantai Cerdas Binance',
    connectTip: 'Silakan terhubung ke',
    ethereumMainnet: 'Mainnet ethereum',
    connect: 'Terhubung ke',
    tryAgain: 'Coba lagi',
    changeWallet: 'Ubah Dompet',
    exchangeRate: 'Kurs',
    approving: 'Menyetujui',
    swapTitle: 'MENUKAR LYNK',
    registerTitle: 'MASUKKAN KODE UNDANGAN ANDA',
    registerPlh: 'Silakan masukkan kode Anda di sini',
    registerDes: `TIP: Anda dapat pergi ke Lynk's Discord untuk mengambil kode undangan Anda.`,
    unValidCode: 'Kode pendaftaran tidak valid',
    registerInviterDes: 'Anda belum mendaftar, silakan daftar',
    transactionsDes: 'Transaksi Anda akan muncul di sini...',
    copied: 'TERSALIN',
    finish: 'Menyelesaikan',
    minting: 'Mencetak',
    comingSoon: 'Segera hadir',
  },
  navMenu: {
    myDashboard: 'DASBOR SAYA',
    nfts: 'NFTs',
    stake: 'STAKE',
    invitation: 'UNDANGAN',
    history: 'RIWAYAT',
    activitiesCampaigns: 'AKTIVITAS & KAMPANYE',
    nodeNft: 'Program Node NFT',
    leaderNft: 'Program LEADER NFT',
    finders: 'Mencetak NFT Finders',
    marketPlace: 'PASAR',
    rank: 'PERINGKAT',
    more: 'LEBIH',
    chatRoom: 'RUANG OBROLAN',
    lynkAlliance: 'ALIANSI LYNK',
    domainServer: 'Server Nama Domain LYNK',
    feed: 'Dinamis',
  },
  nfts: {
    title: 'PENGGUNA NFTs',
    mintKeeper: 'Penempaan KEEPER',
    buyKeeper: 'Membeli KEEPER',
    freeMint: 'Penempaan gratis',
    owned: 'dimiliki',
    staked: 'dipertaruhkan',
    sell: 'Jual',
    stake: 'Pertaruhkan',
    mintKeeperNft: 'MINT KEEPER NFT',
    nftName: 'Nama NFT',
    selectGender: 'Pilih jenis kelamin',
    male: 'Laki-laki',
    female: 'Perempuan',
    selectFaction: 'Pilih faksi',
    spirits: 'Jiwa',
    martials: 'Bela diri',
    exos: 'Exos',
    voids: 'Rongga',
    selectNft: 'PILIH NFT',
    common: 'UMUM',
    special: 'SPESIAL',
    rare: 'LANGKA',
    reSelect: 'Pilih ulang',
    mint: 'MINT',
    proceed: 'MELANJUTKAN',
    enterName: 'Masukkan nama',
    nameUsed: 'Nama ditempati',
    cancel: 'Membatalkan',
    claim: 'Mengeklaim',
    freeMintEnd: 'PENJUALAN BERAKHIR DI',
    days: 'HARI',
    hours: 'jam',
    minutes: 'MENIT',
    seconds: 'Detik',
  },
  nftDetail: {
    back: 'KEMBALI',
    attributes: 'ATRIBUT',
    leveltooltip: 'Percepat atribut NFT Anda untuk naik level dan dapatkan lebih banyak hadiah taruhan!',
    purchaseAp: 'PEMBELIAN AP',
    userStats: 'STATUS PENGGUNA',
    nfts: 'NFTs',
    charisma: 'Kharisma',
    dexterity: 'Ketangkasan',
    intellect: 'Kecerdasan',
    vitality: 'Vitalitas',
    accelerate: 'AKSELERASI',
    details: 'RINCIAN',
    currentHolder: 'Pemegang Saat Ini',
    mintTime: 'Waktu Mint',
    mintAddress: 'Alamat Mint',
    estimatedPrice: 'Estimasi Harga',
    endPrice: 'Harga Akhir',
    role: 'Peran',
    contractAddress: 'Alamat Kontrak',
    tokenID: 'Token ID',
    tokenStandard: 'Standar Token',
    chain: 'Rantai',
    background: 'Latar Belakang',
    effect: 'Efek',
    body: 'tubuh',
    clothing: 'Pakaian',
    head: 'Kepala',
    weapon: 'Senjata',
    bodyAttachment: 'Lampiran Tubuh',
    artifact: 'Artefak',
    purchaseAP: 'Beli AP',
    saldo: 'Saldo',
    amount: 'Jumlah',
    price: 'Harga',
    beli: 'beli',
    caUpgrade: 'CA UPGRADE',
    vaUpgrade: 'VA UPGRADE',
    inUpgrade: 'IN UPGRADE',
    dxUpgrade: 'DX UPGRADE',
    max: 'Maks',
    unStake: 'HAPUS',
    claimAp: 'KLAIM AP',
    apReward: 'Hadiah AP',
    apRewardtooltip:
      'Hanya NFT Level 2 ke atas yang berhak menerima Hadiah Pencapaian. Anggota harus mengklaim AP yang dihadiahkan setiap hari untuk melanjutkan siklus penghitungan berikutnya.\nKegagalan untuk melakukannya akan mengakibatkan AP tetap pada 1, meskipun dipertaruhkan selama 2 hari atau lebih.',
    stakingTime: 'Akumulasi Waktu Taruhan',
    claimApHelpDes:
      'Anggota diharuskan untuk mengklaim AP yang dihadiahkan setiap hari untuk melanjutkan siklus AP berikutnya. Kegagalan untuk melakukannya akan mengakibatkan hadiah AP tetap di 1. Meskipun dipertaruhkan selama 100 hari atau lebih.',
    claimProfit: 'Anda dapat mengklaim keuntungan',
    claim: 'KLAIM',
    sell: 'JUAL',
    referencePrice: 'Harga Referensi',
    onSale: 'DIJUAL',
    unListing: 'UNLISTING',
    unList: 'UNLIST',
    insufficientLynk: 'Lynk tidak cukup',
    fullLevel: 'Tingkat Penuh',
    upLimit: 'Harus lebih tinggi dari',
    oneDayMaxVA: 'VA telah ditingkatkan hari ini',
    enterPoint: 'Masukkan titik',
    noReward: 'Tidak ada hadiah yang tersedia',
    mustHigherThanLevel2: 'Anda harus lebih tinggi dari level 2',
    mintAction: 'NFT Pencari Mint',
    mint: 'Percetakan',
    unstake: 'Lepas pasak',
    upgrade: 'Meningkatkan',
    loading: 'Memuat',
  },
  stake: {
    title: 'PERTARUHKAN NFT KU',
    redeem: 'Tukar',
    claim: 'Klaim',
    accumulatedEarned: 'Akumulasi Perolehan $LRT',
    available: 'Tersedia $LRT',
    claiming: 'Mengklaim',
  },
  invitation: {
    title: 'PAPAN UNDANGAN',
    invitationDes: 'Undang teman untuk mendapatkan hadiah bersama',
    inviteFriends: 'UNDANG TEMAN',
    rewardSummary: 'Ringkasan Hadiah',
    socialRewardUp: 'HADIAH SOSIAL',
    socialRewardDes:
      'Bangun tim dan jaringan Penjaga Anda dengan mengundang teman Anda untuk bergabung dengan komunitas LYNK. Semakin tinggi peringkat Penjaga Anda, semakin tinggi imbalan sosial yang diperoleh.',
    communityReward: 'HADIAH KOMUNITAS',
    communityReward1: 'Hadiah Komunitas',
    communityRewardDes:
      'Dengan terhubung dengan anggota di Sanctuary, pemain dapat membangun aliansi baru atau yang sudah ada untuk mendapatkan hadiah yang lebih tinggi.',
    contributionRewardUp: 'HADIAH KONTRIBUSI',
    contributionRewardDes:
      'Selain menerima LRT sebagai penghasilan, AP juga akan diberikan untuk menyelesaikan tugas-tugas tertentu. Pengguna yang merekrut anggota baru akan menerima 1-6 AP untuk setiap 100 CA yang diakumulasikan oleh pengikutnya (segera dikreditkan).',
    AchievementReward: 'HADIAH PENCAPAIAN',
    AchievementRewardDes:
      'Selain menerima LRT sebagai penghasilan, AP juga akan diberikan untuk menyelesaikan tugas-tugas tertentu. Pertaruhkan NFT level 2 ke atas untuk menerima hadiah AP setiap hari! Jumlah hadiah AP akan ditentukan oleh level NFT Anda.',
    StakingReward: 'MENANGKAP HADIAH',
    StakingRewardDes:
      'Satu pengguna dapat memiliki beberapa NFT sekaligus. Pengguna memenuhi syarat untuk keuntungan harian selama NFT Anda memenuhi persyaratan taruhan.',
    bonusRatio: 'Rasio Bonus',
    totalLRT: 'Jumlah LRT',
    qualified: 'Berkualitas',
    cumulateCA: 'Kumpulkan CA',
    totalAP: 'Total AP',
    cycles: 'Siklus',
    stakingNFT: 'Berikrar NFT',
    elite: 'Elite',
    epic: 'Epic',
    master: 'Master',
    legendary: 'Legendary',
    mythic: 'Mythic',
    divine: 'Divine',
    nourishInviter: 'Sponsor Langsung',
    teamGrade: 'Akumulasi',
    levelPrivilege: 'TINGKAT PRIVILEG',
    socialReward: 'Hadiah Sosial',
    contributionReward: 'Hadiah Kontribusi',
    achievementReward: 'Hadiah Prestasi',
    inactive: 'TIDAK AKTIF',
    activated: 'DIAKTIFKAN',
    myLevel: 'TINGKAT SAYA',
    generation: 'Generasi',
    inviteRule: 'Rujuk setiap 100CA baru, dapatkan',
    NFTStakingRule: 'Taruhan NFT level 2 atau lebih tinggi selama 1 hari, dan dapatkan',
    inactivated: 'TIDAK AKTIF',
    activate: 'DIAKTIFKAN',
    AcaStastistics: 'STASTISTIK SUMCA',
    daily: 'ehari-hari',
    yesterday: 'Kemarin',
    today: 'Hari ini',
    weekly: 'Mingguan',
    lastWeek: 'Minggu lalu',
    thisWeek: 'Minggu ini',
    monthly: 'Bulanan',
    lastMonth: 'Bulan lalu',
    thisMonth: 'Bulan ini',
  },
  history: {
    title: 'RIWAYAT',
    stakingRewardRecords: 'Catatan Hadiah Staking',
    mintingRecords: 'Catatan Pencetakan NFT',
    transactionRecords: 'Catatan Transaksi NFT',
    recommendedRecords: 'Catatan yang Direkomendasikan',
    referralRewardRecords: 'Catatan Hadiah Rujukan',
    contributionRewardRecords: 'Catatan Hadiah Kontribusi',
    achievementRewardRecords: 'Catatan Hadiah Prestasi',
    communityRewardRecords: 'Rekaman Hadiah Komunitas',
    time: 'Waktu',
    reward: 'Hadiah',
    tx: 'TX',
    name: 'Nama',
    amount: 'Jumlah',
    buyerAddress: 'Alamat Pembeli',
    sellerAddress: 'Alamat Penjual',
    address: 'Alamat',
  },
  rank: {
    title: 'PENGGUNA TOP BERDASARKAN',
    top: 'TOP',
    rank: 'PERINGKAT',
    levelUp: 'TINGKAT',
    charisma: 'KHARISMA',
    nft: 'NFT',
    level: 'Tingkat',
    tx: 'TX',
  },
  finders: {
    title: 'FINDERS',
    findersDes1: 'The Finders berasal dari nexus,',
    findersDes2: 'titik sentral dari semua koordinat ruangwaktu.',
    mint: 'MINT',
    soldOut: 'SOLD OUT',
    info1:
      'Para Finders berasal dari Nexus, titik sentral dari semua koordinat ruangwaktu. Makhluk-makhluk ini telah ada sejak awal alam semesta yang diketahui dan telah mengamati kelahiran dunia yang tak terhitung jumlahnya. Mereka mengumpulkan semua pengetahuan dan data dari semua Metaverse, melestarikannya dengan menyinkronkan pikiran mereka dalam kohesi. Dari waktu ke waktu, Metaverse luar biasa dengan potensi untuk melampaui batas Metaverse lain muncul, mendorong para Penemu untuk bertindak. Melangkah ke LYNKVERSE melalui celah, para Finder berangkat untuk menjalankan misi mereka - untuk memilih beberapa orang terpilih untuk memandu penduduk lain dan memastikan Metaverse berkembang menjadi potensi penuhnya.',
    info2:
      'Setiap Finder menciptakan benih jiwa yang mengandung sebagian dari kekuatannya dan memberikannya kepada yang terpilih. Dengan berasimilasi dengan benih jiwa, lahirlah ras baru. Ayo bawa mantel harapan dan jadilah pemimpin zaman baru.',
    totalAmount: 'JUMLAH TOTAL PENEMUAN:',
    totalBalance: 'TOTAL SALDO FINDERS YANG TERSEDIA:',
    minted: 'DIBUAT',
  },
  nodeNft: {
    title: 'Program NODE NFT Generasi 1',
    mintNow: 'Mint Sekarang',
    mintNode: 'MINT NODE NFT',
    mintNodeNft: 'MINT NODE NFT',
    nodeNftDes1: 'Mendapatkan keuntungan dengan Node NFT',
    nodeNftDes2: 'Bergabunglah dan buatlah tanda Anda di Komunitas LYNK',
    info11: `Selamat datang ke Program Node NFT, ahli keluarga LYNK!`,
    info12: `Program Node NFT adalah program utama kami eksklusif bagi pendukung awal yang telah menunjukkan inisiatif kuat dalam mengembangkan komunitas LYNK. Generasi I dari program ini bertujuan untuk mengakui pahlawan seperti Anda, dengan memberikan NFT Keeper yang unik yang akan mewakili identitas dan status Anda sebagai pemimpin dari komunitas Web3 yang paling menarik.`,
    info13: `Program menarik ini menawarkan Anda kesempatan untuk mencetak Node NFT yang unik di salah satu dari tiga tingkatan: Common, Special, atau Rare. Inilah yang akan Anda dapatkan di setiap tingkatan:`,
    infoCommon: `Common Node NFT: Mint seharga 1000 USDT dan dapatkan 1,200 CA, 110 AP, dan Lencana NFT Common Node. Anda juga akan langsung naik ke peringkat LEGENDARIS.`,
    infoSpecial: `Special Node NFT: Mint seharga 3000 USDT dan dapatkan 4,000 CA, 450 AP, dan Lencana NFT Special Node. Anda juga akan langsung naik ke peringkat LEGENDARIS.`,
    infoRare: `Rare Node NFT: Mint seharga 5000 USDT dan dapatkan 7,000 CA, 1.200 AP, dan Rare Node NFT Badge. Anda juga akan langsung naik ke peringkat LEGENDARIS.`,
    info21: `Sebagai anggota peringkat Legendaris, dapatkan hadiah tambahan untuk setiap referensi yang berhasil! Ketika teman Anda membeli Node NFT, Anda akan menerima hadiah proporsional sesuai dengan tingkatan yang mereka beli.`,
    info22: `Ketika referal Anda membeli Rare Node NFT, Anda akan menerima 5,000 tambahan dalam bentuk akumulasi CA, 280 AP, dan 560 token LRT sebagai hadiah!`,
    info23: `Ketika referal Anda membeli NFT Node Khusus, Anda akan menerima tambahan 3,000 akumulasi CA, 160 AP, dan 320 token LRT sebagai hadiah!`,
    info24: `Ketika referal Anda membeli NFT Common Node, Anda akan menerima tambahan 1,000 dalam bentuk akumulasi CA, 48 AP, dan 96 token LRT sebagai hadiah!`,
    info25: `Node NFT dirancang untuk membantu Anda menjadi yang terdepan dalam upaya membangun jaringan Anda di komunitas Web3 kami yang dinamis. Semakin Anda memperluas jaringan Anda dalam komunitas LYNK, semakin banyak manfaat yang akan Anda dapatkan, dan semakin banyak yang akan Anda hasilkan!`,
    info26: `Jadi, mulailah mengundang teman-teman Anda dan nikmati keuntungan yang didapat dengan menjadi bagian dari kru Node NFT. Bergabunglah dengan kami sekarang dan mulailah menghasilkan lebih banyak hari ini!`,
    minted: 'DIBUAT',
    soldOut: 'SOLD OUT',
  },
  leaderNft: {
    title: 'Program LEADER NFT Generasi 1',
    mintNow: 'Mint Sekarang',
    mintLeader: 'MINT LEADER NFT',
    mintLeaderNft: 'MINT LEADER NFT',
    leaderNftDes1: 'Mendapatkan keuntungan dengan Leader NFT',
    leaderNftDes2: 'Bergabunglah dan buatlah tanda Anda di Komunitas LYNK',
    info11: `Selamat datang ke Program Leader NFT, ahli keluarga LYNK!`,
    info12: `Program Leader NFT adalah program utama kami eksklusif bagi pendukung awal yang telah menunjukkan inisiatif kuat dalam mengembangkan komunitas LYNK. Generasi I dari program ini bertujuan untuk mengakui pahlawan seperti Anda, dengan memberikan NFT Keeper yang unik yang akan mewakili identitas dan status Anda sebagai pemimpin dari komunitas Web3 yang paling menarik.`,
    info13: `Program menarik ini menawarkan Anda kesempatan untuk mencetak Leader NFT yang unik di salah satu dari tiga tingkatan: Common, Special, atau Rare. Inilah yang akan Anda dapatkan di setiap tingkatan:`,
    infoCommon: `Common Leader NFT: Mint seharga 500 USDT dan dapatkan 500 CA, Anda juga akan langsung naik ke peringkat EPIK.`,
    infoSpecial: `Special Leader NFT: Mint seharga 1000 USDT dan dapatkan 1,000 CA, Anda juga akan langsung naik ke peringkat MENGUASAI.`,
    infoRare: `Rare Leader NFT: Mint seharga 3000 USDT dan dapatkan 3,000 CA, 1.200 AP, Anda juga akan langsung naik ke peringkat LEGENDARIS.`,
    info21: `Sebagai anggota peringkat Legendaris, dapatkan hadiah tambahan untuk setiap referensi yang berhasil! Ketika teman Anda membeli Leader NFT, Anda akan menerima hadiah proporsional sesuai dengan tingkatan yang mereka beli.`,
    info22: `Ketika referal Anda membeli Rare Leader NFT, Anda akan menerima 3,000 tambahan dalam bentuk akumulasi CA, 120 AP, 240 token LRT sebagai hadiah!`,
    info23: `Ketika referal Anda membeli NFT Leader Khusus, Anda akan menerima tambahan 1,000 akumulasi CA, 40 AP, 80 token LRT sebagai hadiah!`,
    info24: `Ketika referal Anda membeli NFT Common Leader, Anda akan menerima tambahan 500 dalam bentuk akumulasi CA, 20 AP, 40 token LRT sebagai hadiah!`,
    info25: `Leader NFT dirancang untuk membantu Anda menjadi yang terdepan dalam upaya membangun jaringan Anda di komunitas Web3 kami yang dinamis. Semakin Anda memperluas jaringan Anda dalam komunitas LYNK, semakin banyak manfaat yang akan Anda dapatkan, dan semakin banyak yang akan Anda hasilkan!`,
    info26: `Jadi, mulailah mengundang teman-teman Anda dan nikmati keuntungan yang didapat dengan menjadi bagian dari kru Leader NFT. Bergabunglah dengan kami sekarang dan mulailah menghasilkan lebih banyak hari ini!`,
    minted: 'DIBUAT',
    soldOut: 'SOLD OUT',
  },
  marketPlace: {
    title: 'PASAR',
    totalVolume: 'Jumlah Volume',
    floorPrice: 'Harga Dasar',
    higherPrice: 'Harga Lebih Tinggi',
    owner: 'Pemilik',
    tradingFee: 'Biaya Trading',
    faction: 'FAKSI',
    type0: 'Finder',
    type1: 'Spirits',
    type2: 'Exos',
    type3: 'Martial',
    type4: 'Void',
    gender: 'JENIS KELAMIN',
    boy: 'Laki-laki',
    girl: 'Perempuan',
    level: 'TINGKAT',
    level0: 'Tingkat 2-3',
    level1: 'Tingkat 4-6',
    level2: 'Tingkat 7-9',
    level3: 'Tingkat 10-12',
    level4: 'Tingkat 13',
    priceDesc: 'Harga: tinggi ke rendah',
    priceAsc: 'Harga: rendah ke tinggi',
    priceHint: {
      empty: 'Masukkan kisaran harga',
      numberError: 'Harga maks tidak boleh lebih rendah dari harga min',
    },
    priceUsdt: 'HARGA USDT',
    to: 'UNTUK',
    all: 'semua',
    buy: 'beli',
    rare: 'LANGKA',
    price: 'Harga',
    balance: 'Saldo',
  },
};
