import React, { useState, useCallback } from 'react';
import { styled, Typography, Button, Stack, Paper } from '@mui/material';
import { useNavigate, createSearchParams } from 'react-router-dom';
import { useI18n } from 'react-simple-i18n';
import { useActiveWeb3React } from 'hooks';
import { useUserHasSubmitted } from 'hooks/useTransaction';
import NftCard from 'components/Card';
import _ from 'lodash';
import useAsyncMemo from 'hooks/useAsyncMemo';
import { getStakingList, getAccountNFT, NFT } from 'utils/fetch/graph';
import { useBlockNumber } from 'hooks/useStoreState';
import { useAppSelector } from 'hooks/redux';
import { useLYNKClaim, useStaking } from 'hooks/useStaking';
import { useAppDispatch } from 'hooks/redux';
import {
  showTransactionConfirmModal,
  showTransactionPendingModal,
  showTransactionErrorModal,
} from 'store/features/componentSlice';
import ClaimModal from 'components/ClaimModal';
import { showWalletModal } from 'store/features/componentSlice';
import { useValidAddress } from 'hooks/useUser';
import { showRegisterModal } from 'store/features/componentSlice';

const StakeContentBox = styled('div')(({ theme }) => ({
  gridArea: 'stakeB',
  display: 'grid',
  paddingTop: theme.spacing(5),
  gridTemplateRows: `${theme.spacing(26.5)} 1fr ${theme.spacing(3)}`,
  gridTemplateColumns: `1fr ${theme.spacing(31)}`,
  gridTemplateAreas: `
    'stakeList stakeInfo'
    'stakeList stakeNumberno'
    'stakeNumbernone stakeNumbernone'
  `,
  overflow: 'hidden',
  [theme.breakpoints.down('sm')]: {
    paddingTop: theme.spacing(0),
    gridTemplateRows: `${theme.spacing(4)} ${theme.spacing(23)} 1fr ${theme.spacing(2)}`,
    gridTemplateColumns: `1fr`,
    overflow: 'unset',
    gridTemplateAreas: `
      'stakeTitle'
      'stakeInfo'
      'stakeList'
      'stakeNumbernone'
    `,
  },
}));

const StakeTitle = styled('div')(({ theme }) => ({
  gridArea: 'stakeTitle',
  [theme.breakpoints.up('sm')]: {
    display: 'none',
  },
}));

const StakeList = styled('div')(({ theme }) => ({
  gridArea: 'stakeList',
  display: 'flex',
  alignContent: 'flex-start',
  gap: theme.spacing(4),
  flexWrap: 'wrap',
  [theme.breakpoints.down('sm')]: {
    gap: theme.spacing(1),
  },
  [theme.breakpoints.up('sm')]: {
    overflow: 'overlay',
  },
}));

const StakeInfoBox = styled('div')(({ theme }) => ({
  gridArea: 'stakeInfo',
  display: 'flex',
  justifyContent: 'flex-end',
  [theme.breakpoints.down('sm')]: {
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

const StakeInfo = styled('div')(({ theme }) => ({
  width: '90%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  [theme.breakpoints.down('sm')]: {
    height: '100%',
    width: '100%',
    justifyContent: 'space-around',
  },
}));

const TypographyDes = styled(Typography)(({ theme }) => ({
  fontSize: 32,
  fontWeight: 700,
  [theme.breakpoints.down('sm')]: {
    fontSize: 20,
  },
}));

const TypographyTotal = styled(Typography)(({ theme }) => ({
  fontSize: 32,
  fontWeight: 700,
  color: '#6BB9DD',
  [theme.breakpoints.down('sm')]: {
    fontSize: 25,
  },
}));

const TypographyTotalDes = styled(Typography)(({ theme }) => ({
  fontSize: 13,
  fontWeight: 500,
  color: '#000000',
  width: '120px',
  textAlign: 'right',
  [theme.breakpoints.down('sm')]: {
    fontSize: 12,
  },
}));

const PaperBox = styled(Paper)(({ theme }) => ({
  width: `calc(100% - ${theme.spacing(2)} - 4px)`,
  height: theme.spacing(8),
  border: '2px solid #000000',
  borderRadius: 7,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: theme.spacing(0, 1),
  [theme.breakpoints.down('sm')]: {
    height: theme.spacing(5),
    '& .totalDes': {
      display: 'flex',
      gap: 4,
    },
  },
}));

const StakeContent = () => {
  const { account } = useActiveWeb3React();
  const dispatch = useAppDispatch();
  const { t } = useI18n();
  const navigate = useNavigate();
  const { claim, earnedLYNK, claimableLYNK } = useLYNKClaim();
  const blockNumber = useBlockNumber();
  const { withdraw } = useStaking();
  const { submitted: claiming } = useUserHasSubmitted(`${account}_claim_lynk`);
  const [openClaimModal, setOpenClaimModal] = useState(false);
  const [claimTokenId, setClaimTokenId] = useState('');
  const registered = useValidAddress(account ?? undefined);
  const { inviter } = useAppSelector((state) => state.application);

  const getAllMyNFT = useCallback(async () => {
    if (!account) return [];
    return await getAccountNFT(account ?? '');
  }, [account]);

  const allNFTList: NFT[] = useAsyncMemo(getAllMyNFT, [], [blockNumber]);

  const getMyNFT = useCallback(async () => {
    if (!account) return [];
    return await getStakingList(account);
  }, [account]);

  const NFTList: NFT[] = useAsyncMemo(getMyNFT, [], [blockNumber]);

  const claimCallback = useCallback(async () => {
    if (!account) {
      dispatch(showWalletModal());
      return;
    }
    if (!registered) {
      dispatch(showRegisterModal());
      return;
    }
    dispatch(showTransactionPendingModal());
    claim()
      .then(() => {
        dispatch(showTransactionConfirmModal());
      })
      .catch((err: any) => {
        dispatch(
          showTransactionErrorModal(
            err.error && err.error.message
              ? err.error.message
              : err?.data?.message
              ? err?.data?.message
              : err.message ?? t('global.networkError')
          )
        );
        console.error(err);
      });
  }, [account, claim, dispatch, registered, t]);

  const withdrawCallback = useCallback(
    async (tokenId: string) => {
      dispatch(showTransactionPendingModal());
      withdraw(tokenId)
        .then(() => {
          dispatch(showTransactionConfirmModal());
        })
        .catch((err: any) => {
          dispatch(
            showTransactionErrorModal(
              err.error && err.error.message
                ? err.error.message
                : err?.data?.message
                ? err?.data?.message
                : err.message ?? t('global.networkError')
            )
          );
          console.error(err);
        });
    },
    [dispatch, t, withdraw]
  );
  console.log(NFTList);

  return (
    <StakeContentBox>
      {openClaimModal && (
        <ClaimModal open={openClaimModal} tokenId={claimTokenId} handleClose={() => setOpenClaimModal(false)} />
      )}
      <StakeTitle>
        <TypographyDes color={'#000000'}>{t('stake.title')}</TypographyDes>
      </StakeTitle>
      <StakeList>
        {NFTList.map((nft) => {
          return (
            <NftCard
              item={nft}
              key={nft.id}
              leftButton={() => withdrawCallback(nft.id)}
              rightButton={() => {
                setClaimTokenId(nft.id);
                setOpenClaimModal(true);
              }}
              goDetail={() =>
                navigate(
                  {
                    pathname: `/profile/stake/${nft.id}`,
                    search:
                      inviter &&
                      createSearchParams({
                        referrer: inviter,
                      }).toString(),
                  },
                  {
                    state: {
                      name: nft?.name,
                      ownNftNum: allNFTList?.length || 0,
                      stakeNftNum: _.filter(allNFTList, (o: NFT) => o.isStaking)?.length || 0,
                    },
                  }
                )
              }
              leftText={t('nftDetail.unStake')}
              rightText={t('stake.claim')}
            />
          );
        })}
      </StakeList>
      <StakeInfoBox>
        <StakeInfo>
          <PaperBox>
            <Stack direction="row" width={'100%'} spacing={1} alignItems="center" justifyContent={'space-between'}>
              <TypographyTotal variant="body1" minWidth={40}>
                {earnedLYNK ? earnedLYNK.toFixed(2, { groupSeparator: ',' }) : '--'}
              </TypographyTotal>
              <TypographyTotalDes variant="body1">{t('stake.accumulatedEarned')}</TypographyTotalDes>
            </Stack>
          </PaperBox>
          <PaperBox>
            <Stack direction="row" width={'100%'} spacing={1} alignItems="center" justifyContent={'space-between'}>
              <TypographyTotal variant="body1">
                {claimableLYNK ? claimableLYNK.toFixed(2, { groupSeparator: ',' }) : '--'}
              </TypographyTotal>
              <TypographyTotalDes variant="body1">{t('stake.available')}</TypographyTotalDes>
            </Stack>
          </PaperBox>
          <Button
            disabled={(claimableLYNK && claimableLYNK.equalTo('0')) || claiming}
            variant="contained"
            onClick={claimCallback}
            sx={{ width: '100%', height: 32 }}
          >
            {claiming ? t('stake.claim') : t('stake.claim')}
          </Button>
        </StakeInfo>
      </StakeInfoBox>
    </StakeContentBox>
  );
};

export default StakeContent;
