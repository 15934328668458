import { useEffect, useState } from 'react';
import { getRecordHistory } from 'utils/fetch/graph';
import { useUSDTAddr } from 'hooks/useNFT';

export function useHistory(account: string | null | undefined, curTab: number, size: number) {
  const [historyList, setHistoryList] = useState<(string | JSX.Element)[][]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const usdtAddr = useUSDTAddr();
  useEffect(() => {
    (async () => {
      getRecordHistory(account, curTab, size, currentPage, usdtAddr).then((resp) => {
        setHistoryList(resp);
      });
    })();
  }, [account, curTab, currentPage, size, usdtAddr]);

  return {
    page: {
      setCurrentPage,
      currentPage,
      hasPrev: currentPage > 1,
      hasNext: historyList?.length === size,
      size,
    },
    historyList,
  };
}
