import { MaxUint256 } from '@ethersproject/constants';
import { TransactionResponse } from '@ethersproject/providers';
import { useCallback, useMemo } from 'react';
import { useTokenAllowance } from 'data/Allowances';
import { useTransactionAdder, useHasPendingApproval } from 'hooks/useTransaction';
import { calculateGasMargin } from 'utils';
import { useTokenContract } from 'hooks/useContract';
import { useActiveWeb3React } from 'hooks';
import { CurrencyAmount, TokenAmount } from 'constants/token/fractions';
import { ETHER } from 'constants/token';
import { useAppDispatch } from 'hooks/redux';
import {
  showTransactionPendingModal,
  showTransactionConfirmModal,
  showTransactionErrorModal,
} from 'store/features/componentSlice';
import { useI18n } from 'react-simple-i18n';

export enum ApprovalState {
  UNKNOWN,
  NOT_APPROVED,
  PENDING,
  APPROVED,
}

// returns a variable indicating the state of the approval and a function which approves if necessary or early returns
export function useApproveCallback(
  amountToApprove?: CurrencyAmount,
  spender?: string
): [ApprovalState, () => Promise<void>] {
  // const { showModal, hideModal } = useModal();
  const { account } = useActiveWeb3React();
  const { t } = useI18n();
  const dispatch = useAppDispatch();
  const token = amountToApprove instanceof TokenAmount ? amountToApprove.token : undefined;
  const currentAllowance = useTokenAllowance(token, account ?? undefined, spender);
  const pendingApproval = useHasPendingApproval(token?.address, spender);
  // check the current approval status
  const approvalState: ApprovalState = useMemo(() => {
    if (!amountToApprove || !spender) return ApprovalState.UNKNOWN;
    if (amountToApprove.currency === ETHER) return ApprovalState.APPROVED;
    // we might not have enough data to know whether or not we need to approve
    if (!currentAllowance) return ApprovalState.UNKNOWN;

    // amountToApprove will be defined if currentAllowance is
    return currentAllowance.lessThan(amountToApprove)
      ? pendingApproval
        ? ApprovalState.PENDING
        : ApprovalState.NOT_APPROVED
      : ApprovalState.APPROVED;
  }, [amountToApprove, currentAllowance, pendingApproval, spender]);

  const tokenContract = useTokenContract(token?.address);
  const addTransaction = useTransactionAdder();

  const approve = useCallback(async (): Promise<void> => {
    if (approvalState !== ApprovalState.NOT_APPROVED) {
      console.error('approve was called unnecessarily');
      return;
    }
    if (!token) {
      console.error('no token');
      return;
    }

    if (!tokenContract) {
      console.error('tokenContract is null');
      return;
    }

    if (!amountToApprove) {
      console.error('missing amount to approve');
      return;
    }

    if (!spender) {
      console.error('no spender');
      return;
    }

    let useExact = false;
    const estimatedGas = await tokenContract.estimateGas.approve(spender, MaxUint256).catch(() => {
      // general fallback for tokens who restrict approval amounts
      useExact = true;
      return tokenContract.estimateGas.approve(spender, amountToApprove.raw.toString());
    });
    dispatch(showTransactionPendingModal());
    return tokenContract
      .approve(spender, useExact ? amountToApprove.raw.toString() : MaxUint256, {
        gasLimit: calculateGasMargin(estimatedGas),
      })
      .then((response: TransactionResponse) => {
        dispatch(showTransactionConfirmModal());
        addTransaction(response, {
          summary: '$%global.approve$% ' + amountToApprove.currency.symbol,
          approval: { tokenAddress: token.address, spender: spender },
        });
      })
      .catch((error: any) => {
        dispatch(
          showTransactionErrorModal(
            error.error && error.error.message
              ? error.error.message
              : error?.data?.message
              ? error?.data?.message
              : error.message ?? t('global.networkError')
          )
        );
        console.log('Failed to approve token', error);
        // throw error;
      });
  }, [approvalState, token, tokenContract, amountToApprove, spender, dispatch, addTransaction, t]);

  return [approvalState, approve];
}
