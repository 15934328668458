import { useActiveWeb3React } from './index';
import {
  useDBContract,
  useILYNKNFTContract,
  useLYNKNFTContract,
  useMarketContract,
  useSLYNKNFTContract,
} from './useContract';
import { useCallback, useMemo } from 'react';
import { calculateGasMargin, isAddress } from 'utils';
import { TransactionResponse } from '@ethersproject/providers';
import { useTransactionAdder } from 'hooks/useTransaction';
import { useSingleCallResult } from 'hooks/useRequest';
import { Attr, loadAttr } from 'utils/attr';
import { I_LYNK_NFT_ADDRESS, MARKET_ADDRESS, S_LYNK_NFT_ADDRESS, STAKING_ADDRESS } from 'constants/index';
import { CurrencyAmount } from '../constants/token';
import JSBI from 'jsbi';

export function useVAInfo(tokenId: string) {
  const currentTime = parseInt(Date.now() / 1000 + '');
  const contract = useLYNKNFTContract();
  const DBContract = useDBContract();
  const level = useLevel(tokenId);
  const info = useSingleCallResult(contract, 'addedVAInfoOf', [tokenId]);
  const duration = useSingleCallResult(DBContract, 'duration')?.result?.[0];
  const maxVAAddPerDayPerToken = useSingleCallResult(DBContract, 'maxVAAddPerDayPerTokens', [level?.toString()]);
  const lastAddedTime = info?.result?.lastAddedTime;
  const addedInDuration = info?.result?.addedInDuration;
  return useMemo(() => {
    return {
      loading: info.loading,
      maxValue:
        lastAddedTime?.toString() &&
        addedInDuration?.toString() &&
        duration?.toString() &&
        maxVAAddPerDayPerToken?.result?.toString()
          ? currentTime - lastAddedTime >= duration
            ? maxVAAddPerDayPerToken?.result?.[0]
            : maxVAAddPerDayPerToken?.result?.[0] - addedInDuration
          : JSBI.BigInt(0),
    };
  }, [addedInDuration, currentTime, duration, info.loading, lastAddedTime, maxVAAddPerDayPerToken]);
}

export function useNFT() {
  const { account } = useActiveWeb3React();
  const contract = useLYNKNFTContract();
  const addTransaction = useTransactionAdder();

  const mint = useCallback(
    async (tokenId: string, payment: string, name: string) => {
      if (!account) throw new Error('none account');
      if (!contract) throw new Error('none contract');
      const args = [tokenId, payment, name];
      const method = 'mint';
      return contract.estimateGas[method](...args, { from: account }).then((estimatedGasLimit) => {
        return contract[method](...args, {
          gasLimit: calculateGasMargin(estimatedGasLimit),
          // gasLimit: '3500000',
          from: account,
        }).then((response: TransactionResponse) => {
          addTransaction(response, {
            summary: `$%nftDetail.mint$% ${name}`,
          });
          return response.hash;
        });
      });
    },
    [account, addTransaction, contract]
  );

  const mintNode = useCallback(
    async (tokenId: string, payment: string, name: string) => {
      if (!account) throw new Error('none account');
      if (!contract) throw new Error('none contract');
      const args = [tokenId, payment, name];
      const method = 'mintNode';
      return contract.estimateGas[method](...args, { from: account }).then((estimatedGasLimit) => {
        return contract[method](...args, {
          gasLimit: calculateGasMargin(estimatedGasLimit),
          // gasLimit: '3500000',
          from: account,
        }).then((response: TransactionResponse) => {
          addTransaction(response, {
            summary: `$%nftDetail.mint$% ${name}`,
          });
          return response.hash;
        });
      });
    },
    [account, addTransaction, contract]
  );

  const upgrade = useCallback(
    async (attr: string, tokenId: string, tokenName: string, point: string, payment: string) => {
      if (!account) throw new Error('none account');
      if (!contract) throw new Error('none contract');
      const args = [attr, tokenId, point, payment];
      const method = 'upgrade';
      return contract.estimateGas[method](...args, { from: account }).then((estimatedGasLimit) => {
        return contract[method](...args, {
          gasLimit: calculateGasMargin(estimatedGasLimit),
          // gasLimit: '3500000',
          from: account,
        }).then((response: TransactionResponse) => {
          addTransaction(response, {
            summary: tokenName ? `${tokenName} $%nftDetail.upgrade$% ${attr}` : `$%nftDetail.upgrade$% ${attr}`,
            claim: { recipient: `${account}_upgrade${attr}_${tokenId}` },
          });
          return response.hash;
        });
      });
    },
    [account, addTransaction, contract]
  );

  return { mint, upgrade, mintNode };
}

export function useIsNodeNft(tokenId: string) {
  const contract = useLYNKNFTContract();
  const valid = useSingleCallResult(tokenId ? contract : null, '_isNodeNft', [tokenId])?.result?.[0];
  return valid;
}

export function useNodeMinted(account: string) {
  const contract = useLYNKNFTContract();
  const valid = useSingleCallResult(account ? contract : null, '_userNodeNft', [account])?.result?.[0];
  return valid;
}

export function useLeaderMinted(account: string) {
  const contract = useLYNKNFTContract();
  const valid = useSingleCallResult(account ? contract : null, '_userLeaderNft', [account])?.result?.[0];
  return valid;
}

export function useNameValid(name?: string) {
  const contract = useLYNKNFTContract();
  const valid = useSingleCallResult(contract, 'nameUsed', [name]);
  return {
    valid: !(0 < Number(name) && Number(name) <= 100000) && valid?.result ? !valid?.result?.[0] : false,
    loading: valid.loading,
  };
}

export function useActivityMint() {
  const contract = useLYNKNFTContract();
  const valid = useSingleCallResult(contract, 'getActivityMint')?.result?.[0];
  return valid || {};
}

export function useIsActivityMint(tokenId: string, account: string) {
  const contract = useLYNKNFTContract();
  const result = useSingleCallResult(tokenId && account ? contract : null, 'isActivityMint', [tokenId, account])
    ?.result?.[0];
  return result;
}

export function useLevel(tokenId: string) {
  const contract = useDBContract();
  return useSingleCallResult(contract, 'calcTokenLevel', [tokenId])?.result?.[0];
}

export function useUSDTAddr() {
  const contract = useDBContract();
  return useSingleCallResult(contract, 'USDT_TOKEN')?.result?.[0];
}

export function useNftMintEnable() {
  const contract = useDBContract();
  return useSingleCallResult(contract, 'nftMintEnable')?.result?.[0];
}

export function useAttr(tokenId: string) {
  const contract = useLYNKNFTContract();
  const attrRes = useSingleCallResult(contract, 'nftInfoOf', [tokenId]);
  const attr = attrRes?.result?.[0];
  const level = useLevel(tokenId);
  return {
    charisma: loadAttr(Attr.CA, Number(attr?.[0]), Number(level?.toString())),
    vitality: loadAttr(Attr.VA, Number(attr?.[1]), Number(level?.toString())),
    intellect: loadAttr(Attr.IN, Number(attr?.[2]), Number(level?.toString())),
    dexterity: loadAttr(Attr.DX, Number(attr?.[3]), Number(level?.toString())),
  };
}

export function useRecessiveAttrUri(tokenId: string) {
  const contract = useLYNKNFTContract();
  const recessiveRes = useSingleCallResult(contract, 'tokenURI', [tokenId]);
  return recessiveRes?.result?.[0];
}

export function useNFTStatus(tokenId: string) {
  const contract = useLYNKNFTContract();
  const slynkNFTContract = useSLYNKNFTContract();
  const ilynkNFTContract = useILYNKNFTContract();
  const marketContract = useMarketContract();
  const { account } = useActiveWeb3React();
  const owner = useSingleCallResult(contract, 'ownerOf', [tokenId])?.result?.[0];
  const stakeId = owner?.toLocaleString() === S_LYNK_NFT_ADDRESS.toLocaleString() ? tokenId : undefined;
  const listId = owner?.toLocaleString() === I_LYNK_NFT_ADDRESS.toLocaleString() ? tokenId : undefined;
  const stakeOwner = useSingleCallResult(slynkNFTContract, 'ownerOf', [stakeId])?.result?.[0];
  const listOwner = useSingleCallResult(ilynkNFTContract, 'ownerOf', [listId])?.result?.[0];
  const marketIndex = useSingleCallResult(marketContract, 'listIndexByTokenId', [listId])?.result?.[0];
  const saleInfo = useSingleCallResult(marketContract, 'listNFTs', [marketIndex?.toString()])?.result;
  const isMine =
    (!!account && isAddress(account) === isAddress(owner)) ||
    (!!account && isAddress(account) === isAddress(stakeOwner)) ||
    (!!account && isAddress(account) === isAddress(listOwner));
  const listed = isAddress(owner) === isAddress(MARKET_ADDRESS) || isAddress(owner) === isAddress(I_LYNK_NFT_ADDRESS);
  const staked = isAddress(owner) === isAddress(STAKING_ADDRESS) || isAddress(owner) === isAddress(S_LYNK_NFT_ADDRESS);
  return {
    isMine,
    listed,
    staked,
    saleToken: saleInfo?.acceptToken,
    salePrice: saleInfo ? CurrencyAmount.USDT(saleInfo.priceInAcceptToken) : undefined,
  };
}
