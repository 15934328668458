import React, { useEffect, useState, useCallback } from 'react';
import { AbstractConnector } from '@web3-react/abstract-connector';
import { UnsupportedChainIdError, useWeb3React } from '@web3-react/core';
import { WalletConnectConnector } from '@web3-react/walletconnect-connector';
import { Typography, Box, Button, styled, Stack } from '@mui/material';
import { fortmatic, injected, portis } from 'connectors';
import { OVERLAY_READY } from 'connectors/Fortmatic';
import { SUPPORTED_WALLETS } from 'constants/index';
import usePrevious from 'hooks/usePrevious';
import PendingView from 'components/PendingView';
import useBreakpoint from 'hooks/useBreakpoint';
import { ChainId, NETWORK_CHAIN_ID, SUPPORTED_NETWORKS } from 'constants/chain';
import { useI18n } from 'react-simple-i18n';
import { useAppSelector, useAppDispatch } from 'hooks/redux';
import { closeWalletModal } from 'store/features/componentSlice';
import BaseModal from 'components/BaseModal';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';

const WALLET_VIEWS = {
  OPTIONS: 'options',
  OPTIONS_SECONDARY: 'options_secondary',
  ACCOUNT: 'account',
  PENDING: 'pending',
};

const GreenCircle = styled('div')<{ active: boolean }>(({ theme, active }) => ({
  display: 'flex',
  flexFlow: 'row nowrap',
  justifyContent: 'center',
  alignItems: 'center',
  '& div ': {
    height: 8,
    width: 8,
    marginRight: 8,
    backgroundColor: active ? theme.palette.success.main : theme.palette.background.paper,
    borderRadius: '50%',
  },
}));

const WalletImage = styled('img')(({ theme }) => ({
  width: theme.spacing(3.5),
  height: theme.spacing(3.5),
}));

const WalletConnectButton = styled(ListItemButton)(({ theme }) => ({
  width: theme.spacing(25),
  height: theme.spacing(5.5),
  paddingLeft: theme.spacing(2),
  border: '2px solid #0A1A32',
  borderRadius: '10px',
  display: 'flex',
  gap: theme.spacing(1.5),
  alignItems: 'center',
  '& .MuiTypography-root': {
    fontWeight: 700,
  },
}));

const ErrorConnectTypography = styled(Typography)(({ theme }) => ({
  fontSize: 13,
  fontWeight: 500,
  lineHeight: theme.spacing(8),
  textAlign: 'center',
}));

export default function WalletModal(): JSX.Element {
  const isMobile = useBreakpoint();
  const { t } = useI18n();
  const dispatch = useAppDispatch();
  const walletModalOpen = useAppSelector((state) => state.component.walletModalOpen);
  // important that these are destructed from the account-specific web3-react context
  const { active, account, connector, activate, error } = useWeb3React();
  const [walletView, setWalletView] = useState(WALLET_VIEWS.ACCOUNT);
  const [pendingWallet, setPendingWallet] = useState<AbstractConnector | undefined>();
  const [pendingError, setPendingError] = useState<boolean>();
  // const walletModalOpen = useModalOpen(ApplicationModal.WALLET);
  // const toggleWalletModal = useWalletModalToggle();
  const previousAccount = usePrevious(account);

  // close on connection, when logged out before
  useEffect(() => {
    if (account && !previousAccount && walletModalOpen) {
      dispatch(closeWalletModal());
    }
  }, [account, dispatch, previousAccount, walletModalOpen]);

  // always reset to account view
  useEffect(() => {
    if (walletModalOpen) {
      setPendingError(false);
      setWalletView(WALLET_VIEWS.ACCOUNT);
    }
  }, [walletModalOpen]);

  // close modal when a connection is successful
  const activePrevious = usePrevious(active);
  const connectorPrevious = usePrevious(connector);
  useEffect(() => {
    if (walletModalOpen && ((active && !activePrevious) || (connector && connector !== connectorPrevious && !error))) {
      setWalletView(WALLET_VIEWS.ACCOUNT);
    }
  }, [setWalletView, active, error, connector, walletModalOpen, activePrevious, connectorPrevious]);

  const tryActivation = useCallback(
    async (connector: (() => Promise<AbstractConnector>) | AbstractConnector | undefined) => {
      const conn = typeof connector === 'function' ? await connector() : connector;

      setPendingWallet(conn); // set wallet for pending view
      setWalletView(WALLET_VIEWS.PENDING);

      // if the connector is walletconnect and the user has already tried to connect, manually reset the connector
      if (conn instanceof WalletConnectConnector && conn.walletConnectProvider) {
        conn.walletConnectProvider = undefined;
      }

      conn &&
        activate(conn, undefined, true).catch((error) => {
          console.log(error);
          if (error instanceof UnsupportedChainIdError) {
            activate(conn); // a little janky...can't use setError because the connector isn't set
          } else {
            setPendingError(true);
          }
        });
    },
    [activate]
  );

  // close wallet modal if fortmatic modal is active
  useEffect(() => {
    fortmatic.on(OVERLAY_READY, () => {
      dispatch(closeWalletModal());
    });
  }, [dispatch]);

  // get wallets user can switch too, depending on device/browser
  function getOptions() {
    const isMetamask = window.ethereum && window.ethereum.isMetaMask;
    return Object.keys(SUPPORTED_WALLETS).map((key) => {
      const option = SUPPORTED_WALLETS[key];
      // check for mobile options
      if (isMobile) {
        //disable portis on mobile for now
        if (option.connector === portis) {
          return null;
        }
        if (!window.web3 && !window.ethereum && option.mobile) {
          return (
            <ListItem disableGutters key={key} sx={{ pt: 0, width: 200 }}>
              <WalletConnectButton
                onClick={() => {
                  option.connector !== connector && !option.href && tryActivation(option.connector);
                }}
              >
                <Stack direction="row">
                  <GreenCircle active={option.connector === connector}>
                    <div />
                  </GreenCircle>
                  <WalletImage src={require(`../../assets/img/walletIcon/${option.iconName}`)?.default} />
                </Stack>
                <Typography>{option.name}</Typography>
              </WalletConnectButton>
            </ListItem>
            // <Option
            //   onClick={() => {
            //     option.connector !== connector && !option.href && tryActivation(option.connector);
            //   }}
            //   id={`connect-${key}`}
            //   key={key}
            //   active={option.connector && option.connector === connector}
            //   link={option.href}
            //   header={option.name}
            //   icon={require('../../../assets/walletIcon/' + option.iconName)?.default}
            // />
          );
        } else if (isMetamask && option.name === 'MetaMask') {
          return (
            <ListItem disableGutters key={key} sx={{ pt: 0, width: 200 }}>
              <WalletConnectButton
                onClick={() => {
                  option.connector !== connector && !option.href && tryActivation(option.connector);
                }}
              >
                <Stack direction="row">
                  <GreenCircle active={option.connector === connector}>
                    <div />
                  </GreenCircle>
                  <WalletImage src={require(`../../assets/img/walletIcon/${option.iconName}`)?.default} />
                </Stack>
                <Typography>{option.name}</Typography>
              </WalletConnectButton>
            </ListItem>
          );
        }
        return null;
      }

      // overwrite injected when needed
      if (option.connector === injected) {
        // don't show injected if there's no injected provider
        if (!(window.web3 || window.ethereum)) {
          if (option.name === 'MetaMask') {
            return (
              <ListItem disableGutters key={key}>
                <ListItemButton
                  sx={{ display: 'flex', justifyContent: 'center' }}
                  onClick={(event) => {
                    event.preventDefault();
                    window.location.href = 'https://metamask.io/';
                  }}
                >
                  {'Install Metamask'}
                </ListItemButton>
              </ListItem>
              // <Option
              //   id={`connect-${key}`}
              //   key={key}
              //   header={'Install Metamask'}
              //   link={'https://metamask.io/'}
              //   icon={MetamaskIcon}
              // />
            );
          } else {
            return null; //dont want to return install twice
          }
        }
        // don't return metamask if injected provider isn't metamask
        else if (option.name === 'MetaMask' && !isMetamask) {
          return null;
        }
        // likewise for generic
        else if (option.name === 'Injected' && isMetamask) {
          return null;
        }
      }

      // return rest of options
      return (
        !isMobile &&
        !option.mobileOnly && (
          <ListItem disableGutters key={key} sx={{ pt: 0, width: 200 }}>
            <WalletConnectButton
              onClick={() => {
                option.connector === connector
                  ? setWalletView(WALLET_VIEWS.ACCOUNT)
                  : !option.href && tryActivation(option.connector);
              }}
            >
              <Stack direction="row">
                <GreenCircle active={option.connector === connector}>
                  <div />
                </GreenCircle>
                <WalletImage src={require(`../../assets/img/walletIcon/${option.iconName}`)?.default} />
              </Stack>
              <Typography>{option.name}</Typography>
            </WalletConnectButton>
          </ListItem>
        )
      );
    });
  }

  function getModalContent() {
    if (error) {
      return (
        <>
          {/* <Typography variant="h6">
            {error instanceof UnsupportedChainIdError ? 'Wrong Network' : 'Error connecting'}
          </Typography> */}
          <ErrorConnectTypography>
            {error instanceof UnsupportedChainIdError
              ? `${t('global.connectTip')} ${
                  SUPPORTED_NETWORKS[NETWORK_CHAIN_ID]
                    ? t(SUPPORTED_NETWORKS[NETWORK_CHAIN_ID]?.chainName || t('global.ethereumMainnet'))
                    : t('global.binanceSmartChain')
                }.`
              : t('global.errorConnectingDes')}
          </ErrorConnectTypography>
          {window.ethereum && window.ethereum.isMetaMask && (
            <ListItem disableGutters sx={{ justifyContent: 'center' }}>
              <Button
                sx={{ width: 240 }}
                variant="contained"
                onClick={() => {
                  const id = Object.values(ChainId).find((val) => val === NETWORK_CHAIN_ID);
                  if (!id) {
                    return;
                  }
                  const params = SUPPORTED_NETWORKS[id as ChainId];
                  console.log(params?.nativeCurrency.name);
                  params?.nativeCurrency.name === 'Ethereum'
                    ? window.ethereum?.request?.({
                        method: 'wallet_switchEthereumChain',
                        params: [{ chainId: params.chainId }, account],
                      })
                    : window.ethereum?.request?.({ method: 'wallet_addEthereumChain', params: [params, account] });
                }}
              >
                {`${t('global.connect')} `}
                {SUPPORTED_NETWORKS[NETWORK_CHAIN_ID]
                  ? t(SUPPORTED_NETWORKS[NETWORK_CHAIN_ID]?.chainName || t('global.ethereumMainnet'))
                  : 'BSC'}
              </Button>
            </ListItem>
          )}
        </>
      );
    }
    if (account && walletView === WALLET_VIEWS.ACCOUNT) {
      return (
        <Box display="grid" width="100%" justifyContent="center">
          {getOptions()}
        </Box>
      );
    }
    return (
      <>
        {/* {walletView === WALLET_VIEWS.ACCOUNT && <Typography variant="h6">{t('global.connectToWallet')}</Typography>} */}

        {walletView === WALLET_VIEWS.PENDING ? (
          <PendingView
            connector={pendingWallet}
            error={pendingError}
            setPendingError={setPendingError}
            tryActivation={tryActivation}
          >
            <Button
              onClick={() => {
                setPendingError(false);
                setWalletView(WALLET_VIEWS.ACCOUNT);
              }}
              style={{ whiteSpace: 'nowrap' }}
              variant="outlined"
              sx={(theme) => ({ height: 29, color: '#000', [theme.breakpoints.down('sm')]: { zoom: 0.8 } })}
            >
              {t('global.changeWallet')}
            </Button>
          </PendingView>
        ) : (
          <Box
            display="flex"
            sx={(theme) => ({ [theme.breakpoints.down('sm')]: { zoom: 0.8 } })}
            flexDirection="column"
            width="100%"
            alignItems="center"
            justifyContent="center"
          >
            {getOptions()}
            {walletView === WALLET_VIEWS.ACCOUNT && (
              <Typography variant="caption" width={280}>
                {t('global.walletDes')}
              </Typography>
            )}
          </Box>
        )}
      </>
    );
  }

  return (
    <BaseModal
      open={walletModalOpen}
      contentWidth={error || walletView === WALLET_VIEWS.PENDING ? 434 : 312}
      headerHeight={20}
      title={
        error
          ? error instanceof UnsupportedChainIdError
            ? t('global.wrongNetwork')
            : t('global.errorConnecting')
          : walletView === WALLET_VIEWS.PENDING
          ? ''
          : t('global.connectToWallet')
      }
      handleClose={() => dispatch(closeWalletModal())}
    >
      <List sx={{ pt: 0, pb: 2 }}>{getModalContent()}</List>
    </BaseModal>
  );
}
