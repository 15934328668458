import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './pages/App';
import reportWebVitals from './reportWebVitals';
import { HashRouter } from 'react-router-dom';
import store from 'store';
import { Provider } from 'react-redux';
import getLibrary from 'utils/getLibrary';
import { NetworkContextName } from './constants';
import { createWeb3ReactRoot, Web3ReactProvider } from '@web3-react/core';
const Web3ProviderNetwork = createWeb3ReactRoot(NetworkContextName);

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <Provider store={store}>
    <Web3ReactProvider getLibrary={getLibrary}>
      <Web3ProviderNetwork getLibrary={getLibrary}>
        <HashRouter>
          <App />
        </HashRouter>
      </Web3ProviderNetwork>
    </Web3ReactProvider>
  </Provider>
);
reportWebVitals();
