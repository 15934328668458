export default {
  global: {
    connectWallet: 'Подключить кошелек',
    swapLynk: 'ОБМЕН $LYNK',
    connectToWallet: 'Подключиться к кошельку',
    metamask: 'METAMASK',
    walletConnect: 'ПОДКЛЮЧЕНИЕ КОШЕЛЬКА',
    coinbaseWallet: 'COINBASE КОШЕЛЕК',
    walletDes: '*Вы соглашаетесь с Условиями обслуживания и Политикой конфиденциальности Lynkverse, связывая кошелек.',
    connecting: 'Подключение...',
    connectedWallet: 'Связан с %s%',
    wrongNetwork: 'Неверная сеть',
    error: 'Ошибка',
    myWallet: 'МОЙ КОШЕЛЕК',
    myAddress: 'Мой адрес',
    addToken: 'добавить токен',
    crossChain: 'поперечная цепь',
    logout: 'ВЫЙТИ',
    change: 'ИЗМЕНИТЬ',
    recentTransactions: 'Недавние транзакции',
    clearAll: 'Очистить все',
    confirm: 'ПОДТВЕРДИТЬ',
    confirmed: 'Подтвержденный',
    balance: 'Баланс',
    confirmTransaction: 'Пожалуйста, подтвердите эту транзакцию в своем кошельке',
    viewOnChain: 'Посмотреть на %s%',
    invitationLink: 'Пригласительная ссылка',
    invitationCode: 'Код приглашения',
    copy: 'СКОПИРОВАТЬ',
    insufficient: 'Недостаточный баланс',
    approve: 'Утвердить',
    tip: 'Кончик',
    drop: 'Уронить',
    swap: 'Менять',
    register: 'регистр',
    networkError: 'Ошибка сети',
    initializing: 'Инициализация...',
    errorConnecting: 'Ошибка подключения',
    errorConnectingDes: 'Ошибка подключения. Попробуйте обновить страницу.',
    binanceSmartChain: 'Умная сеть Binance',
    connectTip: 'Пожалуйста, подключитесь к',
    ethereumMainnet: 'основная сеть эфириума',
    connect: 'Подключиться к',
    tryAgain: 'Попробуйте еще раз',
    changeWallet: 'Изменить кошелек',
    exchangeRate: 'Обменный курс',
    approving: 'Утверждение',
    swapTitle: 'ПОМЕНЯТЬ ЛИНК',
    registerTitle: 'ВСТАВЬТЕ СВОЙ ПРИГЛАШИТЕЛЬНЫЙ КОД',
    registerPlh: 'Пожалуйста, введите ваш код здесь',
    registerDes: 'TIP: Вы можете зайти на Lynk’s Discord, чтобы получить код приглашения..',
    unValidCode: 'Неверный регистрационный код',
    registerInviterDes: 'Вы еще не зарегистрированы, пожалуйста, зарегистрируйтесь',
    transactionsDes: 'Ваши транзакции будут отображаться здесь...',
    copied: 'скопировано',
    finish: 'Заканчивать',
    minting: 'Чеканка',
    comingSoon: 'Вскоре',
  },
  navMenu: {
    myDashboard: 'МОЯ ДОСКА',
    nfts: 'NFT',
    stake: 'СТАВКА',
    invitation: 'ПРИГЛАШЕНИЕ',
    history: 'ИСТОРИЯ',
    activitiesCampaigns: 'АКТИВНОСТИ И КАМПАНИИ',
    nodeNft: 'Node NFT-программа',
    leaderNft: 'LEADER NFT-программа',
    finders: 'Mint Finders NFT',
    marketPlace: 'МАРКЕТПЛЕЙС',
    rank: 'РАНГ',
    more: 'БОЛЬШЕ',
    chatRoom: 'ЧАТ КОМНАТА',
    lynkAlliance: 'ЛИНК АЛЬЯНС',
    domainServer: 'Сервер доменных имен LYNK',
    feed: 'динамичный',
  },
  nfts: {
    title: 'ПОЛЬЗОВАТЕЛЬСКИЕ NFT',
    mintKeeper: 'ковка KEEPER',
    buyKeeper: 'Купить KEEPER',
    freeMint: 'бесплатная ковка',
    owned: 'принадлежит',
    staked: 'ставит',
    sell: 'Продать',
    stake: 'Ставка',
    mintKeeperNft: 'NFT ХРАНИТЕЛЬ МЯТЫ',
    nftName: 'имя NFT',
    selectGender: 'Выбрать пол',
    male: 'Мужской',
    female: 'Женский',
    selectFaction: 'Выберите фракцию',
    spirits: 'Духи',
    martials: 'Боевые искусства',
    exos: 'Эксос',
    voids: 'Пустоты',
    selectNft: 'ВЫБРАТЬ NFT',
    common: 'ОБЩИЕ',
    special: 'СПЕЦИАЛЬНЫЕ',
    rare: 'РЕДКИЕ',
    reSelect: 'Перевыбрать',
    mint: 'Мята',
    proceed: 'ПРОДОЛЖИТЬ',
    enterName: 'Введите имя',
    nameUsed: 'Имя занято',
    cancel: 'Отмена',
    claim: 'Требовать',
    freeMintEnd: 'конец времени',
    days: 'ДНЕЙ',
    hours: 'час',
    minutes: 'МИН',
    seconds: 'СЭС',
  },
  nftDetail: {
    back: 'НАЗАД',
    attributes: 'АТРИБУТЫ',
    leveltooltip: 'Ускорьте свои атрибуты NFT, чтобы повысить уровень и заработать больше вознаграждений за ставки!',
    purchaseAp: 'КУПИТЬ АП',
    userStats: 'ПОЛЬЗОВАТЕЛЬСКАЯ СТАТИСТИКА',
    nfts: 'NFT',
    charisma: 'Харизма',
    dexterity: 'Ловкость',
    intellect: 'Интеллект',
    vitality: 'Жизнеспособность',
    accelerate: 'УСКОРИТЬ',
    details: 'ДЕТАЛИ',
    currentHolder: 'Текущий владелец',
    mintTime: 'Время монетного',
    mintAddress: 'Адрес монетного',
    estimatedPrice: 'Ориентировочная цена',
    endPrice: 'Конечная цена',
    role: 'Роль',
    contractAddress: 'Адрес контракта',
    tokenID: 'Идентификатор токена',
    tokenStandard: 'Стандарт токена',
    chain: 'Цепь',
    background: 'Фон',
    effect: 'Эффект',
    body: 'Тело',
    clothing: 'Одежда',
    head: 'Голова',
    weapon: 'Оружие',
    bodyAttachment: 'Приложение тела',
    artifact: 'Артефакт',
    purchaseAP: 'Купить АП',
    balance: 'Баланс',
    amount: 'Количество',
    price: 'Цена',
    buy: 'Купить',
    caUpgrade: 'ОБНОВЛЕНИЕ CA',
    vaUpgrade: 'ОБНОВЛЕНИЕ VA',
    inUpgrade: 'ОБНОВЛЕНИЕ IN',
    dxUpgrade: 'ОБНОВЛЕНИЕ DX',
    max: 'Макс',
    unStake: 'ОТМЕНИТЬ СТАВКУ',
    claimAp: 'ЗАЯВИТЬ АП',
    apReward: 'Награда АП',
    apRewardtooltip:
      'Только NFT уровня 2 и выше имеют право на получение награды за достижения. Участники должны требовать вознагражденные AP за каждый день, чтобы возобновить следующий цикл подсчета.\nНесоблюдение этого требования приведет к тому, что AP останется на уровне 1, несмотря на то, что стейкинг составляет 2 дня или более.',
    stakingTime: 'Накопленное время ставок',
    claimApHelpDes:
      'Участники должны каждый день запрашивать вознагражденные AP, чтобы возобновить следующий цикл AP. Если этого не сделать, вознаграждение в AP останется равным 1. Несмотря на стекинг в течение 100 дней и более.',
    claimProfit: 'Вы можете претендовать на прибыль',
    claim: 'ПОТРЕБОВАТЬ',
    sell: 'ПРОДАТЬ',
    referencePrice: 'Справочная цена',
    onSale: 'В ПРОДАЖЕ',
    unListing: 'УДАЛЕНИЕ',
    unList: 'УДАЛИТЬ',
    insufficientLynk: 'Недостаточный Линк',
    fullLevel: 'Полный уровень',
    upLimit: 'Должно быть выше, чем',
    oneDayMaxVA: 'VA был обновлен сегодня',
    enterPoint: 'Введите точку',
    noReward: 'Награда недоступна',
    mustHigherThanLevel2: 'Вы должны быть выше 2 уровня',
    mintAction: 'Поиск монетных дворов NFT',
    mint: 'Монетный двор',
    unstake: 'Отменить ставку',
    upgrade: 'Обновление',
    loading: 'Загрузка',
  },
  stake: {
    title: 'ПОСТАВЬТЕ МОИ NFT',
    redeem: 'Снять',
    claim: 'Потребовать',
    accumulatedEarned: 'Накопленный заработанный $LRT',
    available: 'Доступные $LRT',
    claiming: 'Претензия',
  },
  invitation: {
    title: 'ПРИГЛАШИТЕЛЬНАЯ ДОСКА',
    invitationDes: 'Приглашайте друзей, чтобы вместе зарабатывать награды',
    inviteFriends: 'ПРИГЛАСИТЬ ДРУЗЕЙ',
    rewardSummary: 'ОБЗОР ВОЗНАГРАЖДЕНИЙ',
    socialRewardUp: 'СОЦИАЛЬНОЕ ВОЗНАГРАЖДЕНИЕ',
    socialRewardDes:
      'Создайте свою команду и сеть хранителей, пригласив своих друзей присоединиться к сообществу LYNK. Чем выше ранг вашего Хранителя, тем выше заработанные социальные награды.',
    communityReward: 'НАГРАДЫ СООБЩЕСТВА',
    communityReward1: 'Hаграды Cообщества',
    communityRewardDes:
      'Подключаясь к участникам в Святилище, игроки могут создавать новые или существующие союзы, чтобы получать более высокие награды.',
    contributionRewardUp: 'ВОЗНАГРАЖДЕНИЕ ЗА ВКЛАД',
    contributionRewardDes:
      'Помимо получения LRT в качестве заработка, AP также будут начисляться за выполнение определенных заданий. Пользователь, который набирает нового члена, получит 1-6 AP за каждые 100 CA, накопленные его подписчиками (зачисляются немедленно).',
    AchievementReward: 'НАГРАДА ЗА ДОСТИЖЕНИЕ',
    AchievementRewardDes:
      'Помимо получения LRT в качестве заработка, AP также будут начисляться за выполнение определенных заданий. Ставьте NFT уровня 2 и выше, чтобы получать награды в AP каждый день! Количество наград AP будет определяться уровнем вашего NFT.',
    StakingReward: 'ВОЗНАГРАЖДЕНИЕ ЗА СТАВКУ',
    StakingRewardDes:
      'Один пользователь может одновременно владеть несколькими NFT. Пользователи имеют право на ежедневную прибыль, если ваши NFT соответствуют требованиям к стейкингу.',
    bonusRatio: 'Бонусное соотношение',
    totalLRT: 'Всего LRT',
    qualified: 'Соответствовать критериям',
    cumulateCA: 'Накопить CA',
    totalAP: 'Всего AP',
    cycles: 'Циклы',
    stakingNFT: 'Стейкинг NFT',
    elite: 'Элита',
    epic: 'Эпический',
    master: 'Мастер',
    legendary: 'Легендарный',
    mythic: 'Мифический',
    divine: 'Божественный',
    nourishInviter: 'Прямой спонсор',
    teamGrade: 'Накоплено',
    levelPrivilege: 'УРОВЕНЬ ПРИВИЛЕГИИ',
    socialReward: 'Социальная награда',
    contributionReward: 'Вознаграждение за вклад',
    achievementReward: 'Награда за достижение',
    inactive: 'НЕАКТИВНЫЙ',
    activated: 'АКТИВИРОВАНО',
    myLevel: 'МОЙ УРОВЕНЬ',
    generation: 'Поколение',
    inviteRule: 'Обратитесь к каждому новому 100CA，получите',
    NFTStakingRule: 'Ставка NFT уровня 2 или выше на 1 день и получите',
    inactivated: 'ИНАКТИВИРОВАНО',
    activate: 'АКТИВИРОВАТЬ',
    AcaStastistics: 'SUMCA СТАТИСТИКА',
    daily: 'Ежедневно',
    yesterday: 'Вчера',
    today: 'Сегодня',
    weekly: 'Еженедельно',
    lastWeek: 'На прошлой неделе',
    thisWeek: 'На этой неделе',
    monthly: 'Ежемесячно',
    lastMonth: 'Прошлый месяц',
    thisMonth: 'Этот месяц',
  },
  history: {
    title: 'ИСТОРИЯ',
    stakingRewardRecords: 'Рекорды вознаграждений за стекинг',
    mintingRecords: 'Записи чеканки NFT',
    transactionsRecords: 'Записи транзакций NFT',
    recommendedRecords: 'Рекомендуемые записи',
    referralRewardRecords: 'Рекорды реферальных вознаграждений',
    contributionRewardRecords: 'Записи о вознаграждении за вклад',
    achievementRewardRecords: 'Записи о наградах за достижения',
    communityRewardRecords: 'Отчеты о наградах сообщества',
    time: 'Время',
    reward: 'Награда',
    tx: 'TX',
    name: 'Имя',
    amount: 'Количество',
    buyerAddress: 'Адрес покупателя',
    sellerAddress: 'Адрес продавца',
    address: 'Адрес',
  },
  rank: {
    title: 'ТОП ПОЛЬЗОВАТЕЛЕЙ ПО',
    top: 'ТОП',
    ranking: 'РАНГ',
    levelUp: 'УРОВЕНЬ',
    charisma: 'ХАРИЗМА',
    nft: 'NFT',
    level: 'Уровень',
    tx: 'TX',
  },
  finders: {
    title: 'ИСКАТЕЛИ',
    findersDes1: 'Искатели приходят из нексуса,',
    findersDes2: 'центральной точки всех координат пространства-времени.',
    mint: 'МИНТ',
    soldOut: 'РАСПРОДАННЫЙ',
    info1:
      'Искатели приходят из Нексуса, центральной точки всех координат пространства-времени. Эти существа существовали с самого начала известной вселенной и наблюдали рождение бесчисленных миров. Они собирают все знания и данные со всех Метавселенных, сохраняя их, синхронизируя свои разумы в связке. Время от времени появляется экстраординарная Метавселенная, способная выйти за пределы других Метавселенных, что побуждает Искателей действовать. Войдя в LYNKVERSE через разлом, Искатели приступили к выполнению своей миссии — выбрать нескольких избранных, которые будут направлять других жителей и обеспечить, чтобы Метавселенная полностью раскрыла свой потенциал.',
    info2:
      'Каждый Искатель создает семена душ, содержащие часть его сил, и наделяет ими избранных. При ассимиляции с семенем души рождается новая раса. Приходите нести мантию надежды и станьте лидерами новой эры.',
    totalAmount: 'ОБЩЕЕ КОЛИЧЕСТВО ИСКАТЕЛЕЙ:',
    totalBalance: 'ОБЩИЙ БАЛАНС ДОСТУПНЫХ ПОИСКОВ:',
    minted: 'ЧЕРЕЗ',
  },
  nodeNft: {
    title: ' Программа NODE NFT Поколение 1',
    mintNow: 'Mint Now',
    mintNode: 'MINT NODE NFT',
    mintNodeNft: 'MINT NODE NFT',
    nodeNftDes1: 'Получите преимущество с Node NFT',
    nodeNftDes2: 'Присоединяйтесь к рядам и сделайте свой след в сообществе LYNK',
    info11: `Добро пожаловать в программу Node NFT, LYNK fam!`,
    info12: `Программа Node NFT — это наша главная программа, предназначенная исключительно для первых сторонников, которые продемонстрировали сильную инициативу в развитии сообщества LYNK. Поколение I программы предназначено для признания таких чемпионов, как вы, с уникальными NFT Keeper, которые будут представлять вашу личность и статус лидера самого интересного сообщества Web3.`,
    info13: `Эта захватывающая программа предлагает вам возможность создать уникальный Node NFT на одном из трех уровней: обычный, специальный или редкий. Вот что вы получите на каждом уровне:`,
    infoCommon: `Общий узел NFT: отчеканьте монету за 1000 USDT и получите 1,200 CA, 110 AP и значок NFT Common Node. Вы также перейдете прямо к ЛЕГЕНДАРНОМУ рангу.`,
    infoSpecial: `Специальный узел NFT: отчеканьте 3000 USDT и получите 4,000 CA, 450 AP и значок NFT специального узла. Вы также перейдете прямо к ЛЕГЕНДАРНОМУ рангу.`,
    infoRare: `Редкий узел NFT: отчеканьте монету за 5000 USDT и получите 7,000 CA, 1,200 AP и значок NFT Rare Node. Вы также перейдете прямо к ЛЕГЕНДАРНОМУ рангу.`,
    info21: `Как участник Легендарного ранга, получайте дополнительные награды за каждого успешного реферала! Когда ваши друзья покупают Node NFT, вы получаете пропорциональные вознаграждения, соответствующие приобретаемому ими уровню.`,
    info22: `Когда ваш реферал купит редкий узел NFT, вы получите дополнительные 5,000 накопленных CA, 280 AP и 560 токенов LRT в качестве вознаграждения!`,
    info23: `Когда ваш реферал приобретет специальный узел NFT, вы получите дополнительные 3,000 накопленных CA, 160 AP и 320 токенов LRT в качестве вознаграждения!`,
    info24: `Когда ваш реферал купит Common Node NFT, вы получите дополнительные 1,000 накопленных токенов CA, 48 AP и 96 токенов LRT в качестве вознаграждения!`,
    info25: `Node NFT разработаны, чтобы помочь вам продвинуться вперед в создании вашей сети в нашем динамичном сообществе Web3. Чем больше вы расширите свою сеть в сообществе LYNK, тем больше преимуществ вы откроете и тем больше заработаете!`,
    info26: `Итак, начните приглашать своих друзей и наслаждайтесь привилегиями, которые дает членство в команде Node NFT. Присоединяйтесь к нам сейчас и начните зарабатывать больше уже сегодня!`,
    minted: 'ЧЕРЕЗ',
    soldOut: 'РАСПРОДАННЫЙ',
  },
  leaderNft: {
    title: ' Программа LEADER NFT Поколение 1',
    mintNow: 'Mint Now',
    mintLeader: 'MINT LEADER NFT',
    mintLeaderNft: 'MINT LEADER NFT',
    leaderNftDes1: 'Получите преимущество с Leader NFT',
    leaderNftDes2: 'Присоединяйтесь к рядам и сделайте свой след в сообществе LYNK',
    info11: `Добро пожаловать в программу Leader NFT, LYNK fam!`,
    info12: `Программа Leader NFT — это наша главная программа, предназначенная исключительно для первых сторонников, которые продемонстрировали сильную инициативу в развитии сообщества LYNK. Поколение I программы предназначено для признания таких чемпионов, как вы, с уникальными NFT Keeper, которые будут представлять вашу личность и статус лидера самого интересного сообщества Web3.`,
    info13: `Эта захватывающая программа предлагает вам возможность создать уникальный Leader NFT на одном из трех уровней: обычный, специальный или редкий. Вот что вы получите на каждом уровне:`,
    infoCommon: `Общий лидер NFT: отчеканьте монету за 500 USDT и получите 500 CA. Вы также перейдете прямо к ЭПИЧЕСКИЙ рангу.`,
    infoSpecial: `Специальный лидер NFT: отчеканьте 1000 USDT и получите 1,000 CA. Вы также перейдете прямо к ВЛАДЕЛЕЦ рангу.`,
    infoRare: `Редкий лидер NFT: отчеканьте монету за 3000 USDT и получите 3,000 CA. Вы также перейдете прямо к ЛЕГЕНДАРНОМУ рангу.`,
    info21: `Как участник Легендарного ранга, получайте дополнительные награды за каждого успешного реферала! Когда ваши друзья покупают Leader NFT, вы получаете пропорциональные вознаграждения, соответствующие приобретаемому ими уровню.`,
    info22: `Когда ваш реферал купит редкий лидер NFT, вы получите дополнительные 3,000 накопленных CA, 120 AP и 240 токенов LRT в качестве вознаграждения!`,
    info23: `Когда ваш реферал приобретет специальный лидер NFT, вы получите дополнительные 1,000 накопленных CA, 40 AP и 80 токенов LRT в качестве вознаграждения!`,
    info24: `Когда ваш реферал купит Common Leader NFT, вы получите дополнительные 500 накопленных токенов CA, 20 AP и 40 токенов LRT в качестве вознаграждения!`,
    info25: `Leader NFT разработаны, чтобы помочь вам продвинуться вперед в создании вашей сети в нашем динамичном сообществе Web3. Чем больше вы расширите свою сеть в сообществе LYNK, тем больше преимуществ вы откроете и тем больше заработаете!`,
    info26: `Итак, начните приглашать своих друзей и наслаждайтесь привилегиями, которые дает членство в команде Leader NFT. Присоединяйтесь к нам сейчас и начните зарабатывать больше уже сегодня!`,
    minted: 'ЧЕРЕЗ',
    soldOut: 'РАСПРОДАННЫЙ',
  },
  marketPlace: {
    title: 'МАРКЕТПЛЕЙС',
    totalVolume: 'Общий объем',
    floorPrice: 'Минимальная цена',
    higherPrice: 'Самая высокая цена',
    owner: 'Владелец',
    tradingFee: 'Торговая комиссия',
    faction: 'ФРАКЦИЯ',
    type0: 'Искатель',
    type1: 'Духи',
    type2: 'Эксос',
    type3: 'Военные',
    type4: 'Пустоты',
    gender: 'ПОЛ',
    boy: 'Мужской',
    girl: 'Женский',
    level: 'УРОВЕНЬ',
    level0: 'Уровень 2-3',
    level1: 'Уровень 4-6',
    level2: 'Уровень 7-9',
    level3: 'Уровень 10-12',
    level4: 'Уровень 13',
    priceDesc: 'Цена: от высокой к низкой',
    priceAsc: 'Цена: от низкой к высокой',
    priceHint: {
      empty: 'Пожалуйста, введите ценовой диапазон',
      numberError: 'Максимальная цена не может быть ниже минимальной цены',
    },
    priceUsdt: 'ЦЕНА USDT',
    to: 'ДО',
    all: 'ВСЕ',
    buy: 'Купить',
    rare: 'РЕДКИЕ',
    price: 'Цена',
    balance: 'Баланс',
  },
};
